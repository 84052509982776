import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import useCheckout from '../../../../main/hooks/useCheckout';
import { LoadingIndicator } from '../../../aem-core-components';
import {
    HTGO_EMPTY_DELIVERY_PICKUP_TIME,
    RESET_CLICKS,
    RESET_GEOGRAPHY,
    RESET_ORDER_ESTIMATES,
    SET_LOCATION_DATA,
    SET_VIEW_CART_FIELDS
} from '../../../aem-core-components/actions/constants';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { VIEW_CART } from '../../../aem-core-components/components/Minicart/constants';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { getProducts } from '../../../constants/analyticsConstants/getProducts';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import useAnalytics from '../../../hooks/useAnalytics';
import { useCheckDeliveryCharge } from '../../../hooks/useCheckDeliveryCharge';
import { useCheckAuthorityType, useCheckUser } from '../../../hooks/useCheckUser';
import useComputeLocation from '../../../hooks/useComputeLocation';
import { useDidMount } from '../../../hooks/useDidMount';
import useMedia from '../../../hooks/useMedia';
import useRoundTrip from '../../../hooks/useRoundTrip';
import { useFilterState } from '../../cap';
import { SET_END_DATE, SET_START_DATE } from '../../cap/constants';
import { getDateDiffInHrs } from '../../cap/utils/atputils';
import Toggle from '../../global/atoms/Toggle/Toggle';
import { AUTHORITY_TYPE } from '../../global/constants';
import { getlatLongByAddress } from '../../global/modules/location-autocomplete/api/getLocations';
import RangePickerInput from '../../global/modules/rangepicker';
import { EditableView } from '../../global/modules/stepForm';
import UnavailableRental from '../../global/modules/unavailableRental/UnavailableRental';
import { checkIsEditQuoteFlow, isPaymentSectionVisited, isDateDisabled, setPrefilledAddress, validateZip } from '../../global/utils/commonUtils';
import { logError, logWarning } from '../../global/utils/logger';
import StoreModal from '../StoreModal';
import InStoreOrderDetails from '../checkoutAndOrderSummary/InStoreOrderDetails';
import RoundTripGuestOrderDetails from '../checkoutAndOrderSummary/RoundTripGuestUser/RoundTripGuestOrderDetails';
import RoundTripOrderDetails from '../checkoutAndOrderSummary/RoundtTripOrderDetails';
import classes from '../checkoutAndOrderSummary/checkoutAndOrderSummary.css';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import SuggestedAddressModal from '../suggestedAddressModal';
import { filterStores } from './utils/editOrderDetailsFunctions';
import { EVENT_P2P_NAMES_CONFIG } from '../../../constants/analyticsConstants/ProcureToPay';

const EditOrderDetails = props => {
    const headingRef = useRef(null);
    const intl = useIntl();
    const [isOpen, setIsOpen] = useState(false);
    const [isEndDateEmpty, setIsEndDateEmpty] = useState(false);
    const [isStartDateEarly, setIsStartDateEarly] = useState(false);
    const [rangeState, setRangeState] = useState([
        {
            key: 'selection',
            startDate: startDate ? new Date(startDate) : new Date(),
            endDate: endDate ? new Date(endDate) : new Date(),
            color: '#F2F2F2'
        }
    ]);
    const [
        {
            cart,
            userInfo,
            isLoading,
            howToGetYourOrderDetails,
            userAccount,
            consumables,
            isCheckoutEditResetComplete,
            clickedMakeChanges,
            tbdModal,
            calendarDateInteraction
        },
        dispatch
    ] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const [userState] = useUserContext();
    const { isUserProfileLoading, overridePCList } = userState;
    const { sendEventsForP2PCtaClick } = useAnalyticsContext();
    const [{ sendAnalyticsDatePickerViewEvent, payloadEcommerceActionAnalytics, sendAnalyticsDatePickerModalEvents }] =
        useAnalytics();
    const deliveryChargeFlag = useCheckDeliveryCharge();
    const {
        selectedEndDate,
        selectedStartDate,
        currentStep,
        setSelectedStartDate,
        setSelectedEndDate,
        handleStepChange,
        formStep,
        loading
    } = props;

    const { isInStorePickup, location, jobSiteCity } = viewCart;
    const [addressError, setAddressesError] = useState('');
    const [avsModal, setAvsModal] = useState(false);
    const [currentAddressObj, setCurrentAddressObj] = useState({});
    const mediaType = useMedia();
    const forMobile = mediaType === 'MOBILE' || false;
    const [isManualAddress, setIsManualAddress] = useState(false);
    const [addressChangedByGeocode, setAddressChangedByGeocode] = useState(false);
    const { sendEventsForEcommerceCheckout, sendEventsForDatePickerFormInteraction } = useAnalyticsContext();
    const didMount = useDidMount();
    const userType = useCheckUser();
    const { refreshCart, checkoutErrorAnalyticsEvent } = useCheckout();
    const [showWarning, setShowWarning] = useState(false);
    const [userPrefilledAddress, setUserPrefilledAddress] = useState(setPrefilledAddress(location, jobSiteCity) || '');
    const isHolidayDate = isDateDisabled();
    const authorityType = useCheckAuthorityType();
    const isP2P = [AUTHORITY_TYPE.P2P].includes(authorityType);


    useEffect(() => {
        if (didMount && howToGetYourOrderDetails.storesAPIStatus === 200) {
            validateStores();
        }
    }, [howToGetYourOrderDetails?.closestStoresData?.data, howToGetYourOrderDetails.storesAPIStatus]);

    const { computeInstore, computeContinueORCreditInstore, setCanadaRestrictStateError, canadaRestrictStateError } =
        useComputeLocation();
    const {
        handleRTApis,
        computeContinueORCreditRoundTrip,
        setAvsError,
        setEndDateError,
        setStartDateError,
        fetchTimeZoneAPI,
        dateSlotApi,
        setCanadaInvalidStateError,
        suggestedAddressList,
        avsError,
        startDateError,
        endDateError,
        canadaInvalidStateError,
        isCanadaStateValid
    } = useRoundTrip();

    useEffect(() => {
        customOnModalOpen();
    }, []);

    useEffect(() => {
        setCanadaRestrictStateError('');
        setCanadaInvalidStateError('');
    }, [
        viewCart?.location,
        viewCart?.jobSiteAddr2,
        viewCart?.jobSiteCity,
        viewCart?.jobSiteState,
        viewCart?.jobSiteZip,
        isInStorePickup,
        selectedEndDate,
        selectedStartDate
    ]);
    // this useEffect recalculates the total if the sections are edited
    useEffect(() => {
        if (isCheckoutEditResetComplete) {
            refreshCart();
        }
        headingRef?.current?.focus();
    }, [isCheckoutEditResetComplete]);

    useEffect(() => {
        if (suggestedAddressList?.length > 0) setAvsModal(true);
    }, [suggestedAddressList]);

    useEffect(() => {
        setAvsError('');
        setStartDateError('');
        setEndDateError('');
    }, [projectDetails?.projectName, userAccount?.accountName, selectedStartDate, selectedEndDate, isInStorePickup]);

    useEffect(() => {
        if (didMount) {
            if (cart?.items?.length > 0) {
                callEvents();
            }
        }
    }, [didMount, cart?.items?.length]);

    const callEvents = () => {
        const datesObj = {
            startDate,
            endDate
        };
        if (clickedMakeChanges || !isPaymentSectionVisited()) {
            sendEventsForEcommerceCheckout(2, getProducts(cart, datesObj, false, consumables));
        }
    };

    const checkoutErrorHandlingAnalytics = (error, field) => {
        checkoutErrorAnalyticsEvent(formStep, currentStep, error, field);
    };

    const onPressCreateProject = () => {
        handleChange(VIEW_CART.SHOW_CREATE_PROJECT, true);
        handleChange(VIEW_CART.CREATE_PROJECT_SOURCE, 'checkout');
    };

    const showAddressError = () => {
        setAddressesError(intl.formatMessage({ id: 'checkout:invalid-address-error' }));
        checkoutErrorHandlingAnalytics(
            intl.formatMessage({ id: 'checkout:invalid-address-error' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.ADDRESS
        );
        dispatch({ type: 'endLoading' });
    };

    const setNoStoresErrors = () => {
        setStartDateError(intl.formatMessage({ id: 'checkout:no-availability' }));
        checkoutErrorHandlingAnalytics(
            intl.formatMessage({ id: 'checkout:no-availability' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.START_DATE
        );
        datesErrorKeyboardNavigation();
        logWarning(intl.formatMessage({ id: 'checkout:no-availability' }));
        setAvsError(intl.formatMessage({ id: 'checkout:order-details-nostoreserror' }));
        logWarning(intl.formatMessage({ id: 'checkout:order-details-nostoreserror' }));
    };

    const showEmptyDatesError = () => {
        setStartDateError(intl.formatMessage({ id: 'checkout:date-selector-error' }));
        setEndDateError(intl.formatMessage({ id: 'checkout:date-selector-error' }));
        datesErrorKeyboardNavigation();
        logWarning(intl.formatMessage({ id: 'checkout:date-selector-error' }));
        checkoutErrorHandlingAnalytics(
            intl.formatMessage({ id: 'checkout:date-selector-error' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.START_DATE
        );
        checkoutErrorHandlingAnalytics(
            intl.formatMessage({ id: 'checkout:date-selector-error' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.END_DATE
        );
    };

    const datesErrorKeyboardNavigation = () => {
        document.getElementsByClassName('editable-header')[0]?.scrollIntoView(true);
        document.getElementsByClassName('rangepicker__input-wrap')[0]?.focus();
    };

    const handleChange = (key, value) => {
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
    };

    const handleToggleClick = currentSelected => {
        handleChange(VIEW_CART.IN_STORE, currentSelected);
        setAddressChangedByGeocode(false);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISINSTOREPICKUP, currentSelected);
    };

    const isLastResortStore = dataSet => {
        return dataSet?.length === 1 && dataSet[0]?.isLastResortBranch === true;
    };

    const checkIfDayDisabled = date => {
        return isHolidayDate(date);
    };

    const showDatePicker = () => {
        return userType != USER_TYPE.GUEST || isInStorePickup;
    };

    const isProfileLoading = () => {
        return loading;
    };

    const areAllStoresUnFullfilled = allStores => {
        for (const store of allStores) {
            if (store?.fulfillmentPercent === 1.0) {
                return false;
            }
        }
        return true;
    };
    const areAllStoresClosed = allStores => {
        for (const store of allStores) {
            if (store?.isOpenOnDateOut) {
                return false;
            }
        }
        return true;
    };
    const updateDateContext = (startDate, endDate) => {
        const diffInHours = getDateDiffInHrs(rangeState[0]?.startDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
        if (
            rangeState[0]?.startDate != '' &&
            new Date(rangeState[0]?.startDate).getTime() == new Date(rangeState[0]?.endDate).getTime()
        ) {
            setShowWarning(true);
            return false;
        } else if (rangeState[0]?.endDate == '' || rangeState[0]?.startDate == '') {
            setIsEndDateEmpty(true);
            setShowWarning(true);
            return false;
        } else if (diffInHours <= 48 && isP2P) {
            setIsStartDateEarly(true);
            setShowWarning(true);
            return false;
        } else {
            filterDispatch({
                type: SET_START_DATE,
                startDate: isNaN(new Date(startDate).getDate()) ? '' : startDate.toString()
            });
            filterDispatch({
                type: SET_END_DATE,
                endDate: isNaN(new Date(endDate).getDate()) ? '' : endDate.toString()
            });

            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, startDate);
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, endDate);
            return true;
        }
    };
    const handleToggle = isDateClick => {
        try {
            if (!isOpen) {
                handleDatePickerViewAnalytics({ isDateClick });
            }
            if (isOpen && rangeState?.[0]?.startDate != '' && rangeState?.[0]?.endDate == '') {
                setIsEndDateEmpty(true);
                setShowWarning(true);
                return;
            }
            setIsOpen(!isOpen);
            setShowWarning(false);
            setIsEndDateEmpty(false);
        } catch (error) {
            logError(error, false, 'isDateClick');
        }
    };
    const handleResetClick = () => {
        dispatch({ type: RESET_CLICKS });
        dispatch({ type: 'open' });
    };
    const handleDatePickerViewAnalytics = ({ isDateClick }) => {
        sendAnalyticsDatePickerViewEvent(isDateClick);
    };

    const defaultCreditUserTabsView = () => {
        return (
            <div className={classes.toggleOrderType} role="tablist">
                <Toggle
                    title={intl.formatMessage({ id: 'order-summary:round-trip-delivery' })}
                    isSelected={!isInStorePickup}
                    onClick={() => handleToggleClick(!isInStorePickup)}
                    testid={checkoutDatalocator.checkout_roundtrip_link_testid}
                />
                <Toggle
                    title={intl.formatMessage({ id: 'order-summary:instore-pickup' })}
                    isSelected={isInStorePickup}
                    onClick={() => handleToggleClick(!isInStorePickup)}
                    testid={checkoutDatalocator.checkout_instorePickUpLink_testid}
                />
            </div>
        );
    };

    const defaultGuestCashUserTabsView = () => {
        return (
            <div className={classes.toggleOrderType} role="tablist">
                <Toggle
                    title={intl.formatMessage({ id: 'order-summary:instore-pickup' })}
                    isSelected={isInStorePickup}
                    onClick={() => handleToggleClick(!isInStorePickup)}
                    testid={checkoutDatalocator.checkout_instorePickUpLink_testid}
                />
                <Toggle
                    title={intl.formatMessage({ id: 'order-summary:round-trip-delivery' })}
                    isSelected={!isInStorePickup}
                    onClick={() => handleToggleClick(!isInStorePickup)}
                    testid={checkoutDatalocator.checkout_roundtrip_link_testid}
                />
            </div>
        );
    };

    const renderTabsBasedOnUser = () => {
        if (
            (userType === USER_TYPE.CREDIT && overridePCList?.length === 0) ||
            (isUserProfileLoading && !isInStorePickup)
        ) {
            return defaultCreditUserTabsView();
        } else if (userType !== USER_TYPE.CREDIT) {
            return defaultGuestCashUserTabsView();
        } else if (overridePCList?.length !== 0) {
            return (
                <h6 className={classes.orderDetails_roundtrip_heading}>
                    {intl.formatMessage({ id: 'cart:round-trip-heading-pcoverride' })}
                </h6>
            );
        }
    };

    const checkAndSetAddressChange = (newValue, currentValue) => {
        if (newValue?.toLowerCase() !== currentValue?.toLowerCase()) {
            return true;
        }
        return false;
    };

    const validateStores = () => {
        let date = moment(startDate);
        const currentDatePlusOne = moment().add(1, 'days');
        const BMData = filterStores(howToGetYourOrderDetails?.bestMatchStoresData, startDate) || [];
        const CSData = filterStores(howToGetYourOrderDetails?.closestStoresData, startDate) || [];
        dispatch({ type: 'endLoading' });
        if (BMData?.length > 0 || CSData?.length > 0) {
            if (isLastResortStore(BMData) && isLastResortStore(CSData) && date?.isSameOrBefore(currentDatePlusOne)) {
                setNoStoresErrors();
            } else if (
                date?.isSameOrBefore(currentDatePlusOne) &&
                areAllStoresUnFullfilled(BMData) &&
                areAllStoresUnFullfilled(CSData)
            ) {
                setNoStoresErrors();
            } else if (areAllStoresClosed(BMData) && areAllStoresClosed(CSData)) {
                setNoStoresErrors();
            } else {
                if (!addressChangedByGeocode) {
                    handleStepChange(2);
                }
            }
        } else {
            setNoStoresErrors();
        }
    };

    const computeCreditSourcePCFlow = addressObj => {
        if (isInStorePickup) {
            const address = `${addressObj?.streetAddress}, ${addressObj?.selectedCity}, ${addressObj?.selectedState}`;
            if (viewCart.location?.split(',')[0] === addressObj?.selectedCity) {
                showAddressError();
                filterDispatch({
                    type: RESET_GEOGRAPHY
                });
                dispatch({
                    type: RESET_ORDER_ESTIMATES
                });
            } else {
                computeContinueORCreditInstore(address, true, handleStepChange);
            }
        } else {
            computeContinueORCreditRoundTrip(addressObj, true, handleStepChange, checkoutErrorHandlingAnalytics);
        }
    };

    const onPressContinueHandlerCredit = async () => {
        const isEditQuoteFlow = checkIsEditQuoteFlow();
        const displayQuoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
        isEditQuoteFlow && sendEventsForP2PCtaClick(
            EVENT_P2P_NAMES_CONFIG.P2P_CTA_CLICKED,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_SAVE_AND_CONTINUE,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_TYPE_ANCHOR,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_LOCATION_BODY,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_SAVE_AND_CONTINUE,
            { quote_id: displayQuoteId }
        );
        dispatch({
            type: HTGO_EMPTY_DELIVERY_PICKUP_TIME
        });
        if (startDate === '') {
            showEmptyDatesError();
        } else {
            dispatch({ type: 'beginLoading' });

            filterDispatch({ type: SET_START_DATE, startDate: selectedStartDate });
            filterDispatch({ type: SET_END_DATE, endDate: selectedEndDate });

            let addressObj = {
                lat: projectDetails.selectedProjectLatititude,
                long: projectDetails.selectedProjectLongitude,
                selectedCity: projectDetails.selectedProjectCity,
                streetAddress: projectDetails.projectAddress1,
                selectedZip: projectDetails.selectedProjectZip,
                selectedAddress2: projectDetails.projectAddress2,
                selectedState: projectDetails.selectedProjectState
            };

            if (overridePCList?.length !== 0) {
                computeContinueORCreditRoundTrip(addressObj, true, handleStepChange, checkoutErrorHandlingAnalytics);
            } else {
                computeCreditSourcePCFlow(addressObj);
            }
        }
    };

    const handleISPUManualAddress = async addressObj => {
        let manualAddress = `${addressObj.streetAddress}, ${addressObj.selectedCity}, ${addressObj.selectedState}, ${addressObj.selectedZip}`;
        const localLocation = await computeInstore('', '', manualAddress);

        if (!localLocation) {
            showAddressError();
            return;
        } else if (!validateZip(localLocation?.jobSiteZip)) {
            showAddressError();
            filterDispatch({
                type: RESET_GEOGRAPHY
            });
            dispatch({
                type: RESET_ORDER_ESTIMATES
            });
            return;
        } else {
            setAddressesError('');
            filterDispatch({
                type: SET_LOCATION_DATA,
                pc: localLocation?.pc,
                location: localLocation?.location,
                jobSiteCity: localLocation?.jobSiteCity,
                jobSiteState: localLocation?.jobSiteState,
                jobSiteZip: localLocation?.jobSiteZip,
                lat: localLocation?.lat,
                long: localLocation?.long,
                jobSiteAddr2: addressObj?.selectedAddress2,
                pcLat: localLocation?.pcLat,
                pcLong: localLocation?.pcLong
            });
            return localLocation;
        }
    };

    const handleInStorePickup = async addressObj => {
        if (addressObj?.streetAddress?.split(',')[0] === addressObj?.selectedCity) {
            showAddressError();
            filterDispatch({
                type: RESET_GEOGRAPHY
            });
            dispatch({
                type: RESET_ORDER_ESTIMATES
            });
        } else if (!isCanadaStateValid(addressObj?.selectedState)) {
            filterDispatch({
                type: RESET_GEOGRAPHY
            });
            dispatch({
                type: RESET_ORDER_ESTIMATES
            });
            setAddressesError(intl.formatMessage(intl.formatMessage({ id: 'error:canada-invalid-state' })));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage(intl.formatMessage({ id: 'error:canada-invalid-state' })),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.STATE
            );
        } else {
            if (isManualAddress) {
                const localLocation = await handleISPUManualAddress(addressObj);
                if (localLocation) {
                    computeContinueORCreditInstore(localLocation?.location, true, handleStepChange);
                    if (
                        checkAndSetAddressChange(localLocation?.location?.split(',')[0], addressObj?.streetAddress) ||
                        checkAndSetAddressChange(localLocation?.jobSiteCity, addressObj?.selectedCity) ||
                        checkAndSetAddressChange(localLocation?.jobSiteState, addressObj?.selectedState) ||
                        checkAndSetAddressChange(localLocation?.jobSiteZip, addressObj?.selectedZip)
                    ) {
                        setUserPrefilledAddress(
                            setPrefilledAddress(localLocation?.location, localLocation?.jobSiteCity)
                        );
                        setAddressChangedByGeocode(true);
                        setIsManualAddress(false);
                        return;
                    } else {
                        handleStepChange(2);
                    }
                }
            } else {
                if (addressChangedByGeocode) {
                    if (!avsError) {
                        setAddressChangedByGeocode(false);
                        handleStepChange(2);
                    }
                } else {
                    computeContinueORCreditInstore(viewCart?.location, true, handleStepChange);
                }
            }
        }
    };

    const onPressContinueHandler = async (addressObj, isValid) => {
        if (!(moment(startDate).isSame(selectedStartDate) && moment(endDate).isSame(selectedEndDate))) {
            filterDispatch({ type: SET_START_DATE, startDate: selectedStartDate });
            filterDispatch({ type: SET_END_DATE, endDate: selectedEndDate });
        }
        setAddressesError('');
        if (selectedStartDate === '') {
            showEmptyDatesError();
        } else if (!isValid) {
            filterDispatch({
                type: RESET_GEOGRAPHY
            });
            dispatch({
                type: RESET_ORDER_ESTIMATES
            });
        } else {
            setCurrentAddressObj(addressObj);
            if (isInStorePickup) {
                handleInStorePickup(addressObj);
            } else {
                const { address, error } = await handleRTApis(
                    addressObj,
                    handleStepChange,
                    isManualAddress,
                    setIsManualAddress,
                    addressChangedByGeocode,
                    setAddressChangedByGeocode,
                    checkoutErrorHandlingAnalytics
                );
                if (!error && address) {
                    setUserPrefilledAddress(address);
                }
            }
        }
    };

    const onPressAVSUpdateBtn = async addressObj => {
        const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
        const response = await getlatLongByAddress(
            companyID,
            `${addressObj?.address1}, ${addressObj?.city}, ${addressObj?.state}, ${addressObj?.zip}`
        );
        let lat = '';
        let long = '';
        if (response?.data?.results?.length > 0) {
            const res = response?.data;
            lat = res?.results[0]?.geometry?.location.lat;
            long = res?.results[0]?.geometry?.location.lng;
            filterDispatch({
                type: SET_LOCATION_DATA,
                location: addressObj.address1,
                jobSiteCity: addressObj.city,
                jobSiteState: addressObj.state,
                jobSiteZip: addressObj.zip,
                jobSiteAddr2: addressObj.address2,
                lat,
                long
            });
        }
        let offset = '';
        let addressObject = {
            lat,
            long,
            jobSiteZip: addressObj.zip
        };
        // added dateslot api call as was not happening on suggest address case
        if (addressObj.address1) {
            offset = await fetchTimeZoneAPI(addressObj.address1);
        }
        if (offset) {
            dateSlotApi(addressObject, offset, handleStepChange, checkoutErrorHandlingAnalytics);
        }
        // handleStepChange(2); handled from dateSlotApi
        setAvsModal(false);
    };

    const customOnModalOpen = () => {
        if (!isNaN(new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime())) {
            if (
                new Date(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE)).getTime() <
                new Date().setHours(0, 0, 0, 0)
            ) {
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, '');
                localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, '');
                filterDispatch({ type: SET_START_DATE, startDate: '' });
                filterDispatch({ type: SET_END_DATE, endDate: '' });
                return true;
            }
        }
        return false;
    };

    const handleDateEventsInteraction = date => {
        sendEventsForDatePickerFormInteraction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_INTERACTION,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_START_DATE_FORM_FIELD,
            date,
            payloadEcommerceActionAnalytics(false)
        );
    };

    const sendDatePickerModalEvents = (eventName, startDate, endDate) => {
        sendAnalyticsDatePickerModalEvents(eventName, startDate, endDate);
    };

    const showAVSModal = () => {
        return (
            <>
                {avsModal &&
                    (forMobile ? (
                        <SuggestedAddressModal
                            currentAddressObj={currentAddressObj}
                            suggestedAddressList={suggestedAddressList}
                            setShowSuggestedAddress={setAvsModal}
                            onPressUpdateBtn={onPressAVSUpdateBtn}
                            forMobile={forMobile}
                        />
                    ) : (
                        <StoreModal modalDialogClass={'modal-dialog-avs'} isOpen={avsModal}>
                            <SuggestedAddressModal
                                currentAddressObj={currentAddressObj}
                                suggestedAddressList={suggestedAddressList}
                                setShowSuggestedAddress={setAvsModal}
                                onPressUpdateBtn={onPressAVSUpdateBtn}
                                forMobile={forMobile}
                            />
                        </StoreModal>
                    ))}
            </>
        );
    };

    const renderBodyBasedOnUser = () => {
        return userType === USER_TYPE.CREDIT ? (
            <RoundTripOrderDetails
                projectDetails={projectDetails}
                onPressCreateProject={onPressCreateProject}
                onPressContinueHandlerCredit={onPressContinueHandlerCredit}
                userAccountName={userInfo.AccountName}
                errorText={avsError}
            />
        ) : (
            <>
                <h6
                    className={`${classes.addressSectionHeading} ${startDateError === intl.formatMessage({ id: 'checkout:no-delivery-slots-available' }) &&
                        classes.headerTopMargin
                        }`}
                    data-testid={checkoutDatalocator.checkout_address_label_testid}>
                    {isInStorePickup && intl.formatMessage({ id: 'checkout:in-store-address-heading' })}
                    {!isInStorePickup &&
                        userInfo.isAuthenticatedUser &&
                        intl.formatMessage({ id: 'checkout:delivery-address-heading' })}
                </h6>
                {showDatePicker() ? (
                    <InStoreOrderDetails
                        viewCart={viewCart}
                        addressError={addressError}
                        setAddressesError={setAddressesError}
                        onPressContinueHandler={onPressContinueHandler}
                        errorText={avsError}
                        canadaRestrictStateError={canadaRestrictStateError}
                        canadaInvalidStateError={canadaInvalidStateError}
                        setErrorText={setAvsError}
                        setStartDateError={setStartDateError}
                        setEndDateError={setEndDateError}
                        userInfo={userInfo}
                        setIsManualAddress={setIsManualAddress}
                        handleStepChange={handleStepChange}
                        addressChangedByGeocode={addressChangedByGeocode}
                        setAddressChangedByGeocode={setAddressChangedByGeocode}
                        isManualAddress={isManualAddress}
                        checkoutErrorHandlingAnalytics={checkoutErrorHandlingAnalytics}
                        setUserPrefilledAddress={setUserPrefilledAddress}
                        userPrefilledAddress={userPrefilledAddress}
                    />
                ) : (
                    <RoundTripGuestOrderDetails />
                )}
            </>
        );
    };

    const renderEditableOrderDetails = () => {
        return (
            <div className={`${isProfileLoading() ? 'clicks-disabled' : ''}`}>
                {isProfileLoading() && (
                    <div className={classes.orderSummaryLoader}>
                        <LoadingIndicator />
                    </div>
                )}
                {renderTabsBasedOnUser()}
                {showDatePicker() ? (
                    <RangePickerInput
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        selectedStartDate={selectedStartDate}
                        selectedEndDate={selectedEndDate}
                        setSelectedEndDate={setSelectedEndDate}
                        setSelectedStartDate={setSelectedStartDate}
                        startDateError={startDateError}
                        endDateError={endDateError}
                        setStartDateError={setStartDateError}
                        setEndDateError={setEndDateError}
                        customOnModalOpen={customOnModalOpen}
                        showWarning={showWarning}
                        handleToggle={handleToggle}
                        renderWarningComponent={
                            <UnavailableRental
                                showWarning={showWarning}
                                isOpen={isOpen}
                                handleToggle={handleToggle}
                                isEndDateEmpty={isEndDateEmpty}
                                isStartDateEarly={isStartDateEarly}
                            />
                        }
                        isEndDateEmpty={isEndDateEmpty}
                        setIsEndDateEmpty={setIsEndDateEmpty}
                        isStartDateEarly={isStartDateEarly}
                        setIsStartDateEarly={setIsStartDateEarly}
                        rangeState={rangeState}
                        setRangeState={setRangeState}
                        setShowWarning={setShowWarning}
                        checkIfDayDisabled={checkIfDayDisabled}
                        handleDatesOnDoneClick={updateDateContext}
                        handleResetClick={handleResetClick}
                        sendDatePickerViewAnalytics={handleDatePickerViewAnalytics}
                        handleDateEventsInteraction={handleDateEventsInteraction}
                        sendDatePickerModalEvents={sendDatePickerModalEvents}
                        calendarDateInteraction={calendarDateInteraction}
                        mediaType={mediaType}
                    />
                ) : null}
                {isUserProfileLoading ? (
                    <div className={classes.orderSummaryLoader}>
                        <LoadingIndicator />
                    </div>
                ) : (
                    renderBodyBasedOnUser()
                )}
                {showAVSModal()}
            </div>
        );
    };

    return (
        <EditableView
            ref={headingRef}
            children={renderEditableOrderDetails()}
            currentStep={currentStep}
            currentTitle={intl.formatMessage({ id: 'checkout:order-details-title' })}
            testId={checkoutDatalocator.orderdetails_Label_testid}
        />
    );
};

export default EditOrderDetails;
