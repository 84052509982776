import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { SET_HOW_TO_GET_YOUR_ORDER_FIELDS } from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import { HOW_TO_GET_YOUR_ORDER } from '../../../../aem-core-components/components/Minicart/constants';
import useConsumables from '../../../../aem-core-components/components/Minicart/hooks/useConsumables';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { EVENT_P2P_NAMES_CONFIG } from '../../../../constants/analyticsConstants/ProcureToPay';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { useDidMount } from '../../../../hooks/useDidMount';
import { useSunbeltLocation } from '../../../../hooks/useSunbeltLocation';
import { useFilterState } from '../../../cap';
import Alert from '../../../global/atoms/alert/alert';
import Button from '../../../global/atoms/button';
import Dropdown from '../../../global/atoms/dropdown';
import { checkIsEditQuoteFlow, getDifferenceinHours, getRentalDuration } from '../../../global/utils/commonUtils';
import { logError } from '../../../global/utils/logger';
import { CHECKOUT_AVAILABLE_TIME_ID, CHECKOUT_PICKUPTIME_ID } from '../../constants';
import { checkoutDatalocator } from '../dataLocators';
import classes from './HowToGetOrderD2C.css';

const HowToGetOrderD2C = props => {
    const [{ userInfo, cart, howToGetYourOrderDetails, projectInfo, timeZoneID }, dispatch] = useCartState();
    const [{viewCart, projectDetails, startDate, endDate}] = useFilterState();
    const { rentalStartDate, rentalEndDate, startDateSlots, endDateSlots, handleStepChange, formStep, checkoutErrorHandlingAnalytics } = props;
    const [chosenDeliveryTime, setChosenDeliveryTime] = useState(
        howToGetYourOrderDetails.selectedDeliveryTime.slot || ''
    );
    const [chosenPickUpTime, setChosenPickUpTime] = useState(howToGetYourOrderDetails.selectedPickUpTime.slot || '');
    const [isDeliveryError, setIsDeliveryError] = useState(false);
    const [isPickUpError, setIsPickUpError] = useState(false);
    const rentalAmount = cart?.estimatesResponse?.estimate?.totals?.rentalAmount || null;
    const [initialRentalAmount] = useState(rentalAmount);
    const [rentalRevisionAlert, setRentalRevisionAlert] = useState(false);
    const [{ getConsumables }] = useConsumables();
    const sunbeltLocation = useSunbeltLocation();
    const { sendEventsForUpdateVirtualPath, sendEventsForEcommerceCheckoutOption, sendEventsForP2PCtaClick } = useAnalyticsContext();
    const intl = useIntl();
    const didMount = useDidMount();
    const [{ overridePCList }] = useUserContext();
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const { deliveryPickUpCharges, salesTax } = orderEstimates?.totals || {};
    const currencyCode =
        localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US;
    const getDate = selectedDate => {
        const stringToDate = new Date(selectedDate);
        return moment(stringToDate).format('l');
    };

    useEffect(() => {
        if (chosenDeliveryTime) {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_DELIVERY_TIME,
                value: {
                    slot: chosenDeliveryTime,
                    label: `${moment(chosenDeliveryTime.split(' - ')[0]).format('hh:mm A')} - ${moment(
                        chosenDeliveryTime.split(' - ')[1]
                    ).format('hh:mm A')}`
                }
            });
        }
    }, [chosenDeliveryTime]);

    useEffect(() => {
        if (chosenPickUpTime) {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SET_PICKUP_TIME,
                value: {
                    slot: chosenPickUpTime,
                    label: `${moment(chosenPickUpTime.split(' - ')[0]).format('hh:mm A')} - ${moment(
                        chosenPickUpTime.split(' - ')[1]
                    ).format('hh:mm A')}`
                }
            });
        }
    }, [chosenPickUpTime]);

    // to show and hide the rentalAmount alert in case of updated amount
    useEffect(() => {
        if (didMount) {
            setRentalRevisionAlert(true);
        }
    }, [howToGetYourOrderDetails.estimatedTotal]);

    const setSelectedStoreDetailsValue = () => {
        try {
            if (overridePCList?.length > 0) {
                return overridePCList[0];
            } else {
                const storedValue = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDETAILS_FOR_ROUND_TRIP);
                if (storedValue) {
                    return JSON.parse(storedValue) || {};
                } else {
                    return {};
                }
            }
        } catch (error) {
            logError(error, false, 'setSelectedStoreDetailsValue HTGOD2c');
            return {};
        }
    };

    const handleDeliveryTime = selectedValue => {
        setChosenDeliveryTime(selectedValue);
        setIsDeliveryError(false);
        setRentalRevisionAlert(false);
    };
    const handlePickUpTime = selectedValue => {
        setChosenPickUpTime(selectedValue);
        setIsPickUpError(false);
        setRentalRevisionAlert(false);
    };
    const sendEventsForDelivery = stepNumber => {
        try {
            sendEventsForEcommerceCheckoutOption(stepNumber, {
                deliveryDate: getDate(startDate),
                deliveryTime: JSON.stringify(howToGetYourOrderDetails?.selectedDeliveryTime?.label),
                returnDate: getDate(endDate),
                returnTime: VARIABLE_CONFIG.EMPTY_STRING.PAGE_UNSPECIFIED,
                rentalDuration: getRentalDuration(startDate, endDate),
                timeZone: timeZoneID,
                rentalSubtotal: rentalAmount,
                deliveryFee: deliveryPickUpCharges,
                deliverySurcharge: deliveryPickUpCharges,
                estimatedSubtotal: rentalAmount + deliveryPickUpCharges + salesTax,
                deliveryInstructions:
                    projectInfo?.accessNotes ||
                    projectDetails?.accessNotes ||
                    VARIABLE_CONFIG.EMPTY_STRING.PAGE_UNSPECIFIED
            });
            sendEventsForUpdateVirtualPath(
                VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_DELIVERY_LOCATION,
                `/${currencyCode}/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.DELIVERY}`
            );
        } catch (error) {
            logError(error, false, 'sendEventsForDelivery');
        }
    };

    const handleSaveAndContinue = async () => {
        const isEditQuoteFlow = checkIsEditQuoteFlow();
        const displayQuoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
        isEditQuoteFlow && sendEventsForP2PCtaClick(
            EVENT_P2P_NAMES_CONFIG.P2P_CTA_CLICKED,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_SAVE_AND_CONTINUE,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_TYPE_ANCHOR,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_LOCATION_BODY,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_SAVE_AND_CONTINUE,
            { quote_id: displayQuoteId }
        );
        if (!chosenDeliveryTime) {
            setIsDeliveryError(true);
            checkoutErrorHandlingAnalytics(
                VARIABLE_CONFIG.CHECKOUT_ERROR_MESSAGES.EMPTY_DELIVERY_TIME,
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.DELIVERY_TIME
            ); // todo add error UI
        }
        if (!chosenPickUpTime && !userInfo.isCreditCustomer) {
            setIsPickUpError(true);
            checkoutErrorHandlingAnalytics(
                VARIABLE_CONFIG.CHECKOUT_ERROR_MESSAGES.EMPTY_PICKUP_TIME,
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.PICK_UP_TIME
            ); // todo add error UI
        }
        if (!userInfo.isCreditCustomer && chosenDeliveryTime !== '' && chosenPickUpTime !== '') {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_STORE_DETAILS,
                value: JSON.parse(
                    sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDETAILS_FOR_ROUND_TRIP)
                )
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_DELIVERY_TIME,
                value: {
                    slot: chosenDeliveryTime,
                    label: `${moment(chosenDeliveryTime.split(' - ')[0]).format('hh:mm A')} - ${moment(
                        chosenDeliveryTime.split(' - ')[1]
                    ).format('hh:mm A')}`
                }
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SET_PICKUP_TIME,
                value: {
                    slot: chosenPickUpTime,
                    label: `${moment(chosenPickUpTime.split(' - ')[0]).format('hh:mm A')} - ${moment(
                        chosenPickUpTime.split(' - ')[1]
                    ).format('hh:mm A')}`
                }
            });
            await getConsumables();
            handleStepChange(formStep.HTGO + 1);
        }
        if (userInfo.isCreditCustomer && chosenDeliveryTime !== '') {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_STORE_DETAILS,
                value: setSelectedStoreDetailsValue()
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_DELIVERY_TIME,
                value: {
                    slot: chosenDeliveryTime,
                    label: `${moment(chosenDeliveryTime.split(' - ')[0]).format('hh:mm A')} - ${moment(
                        chosenDeliveryTime.split(' - ')[1]
                    ).format('hh:mm A')}`
                }
            });
            await getConsumables(viewCart?.pc);
            handleStepChange(formStep.HTGO + 1);
        }

        try {
            sendEventsForDelivery(3);
        } catch (error) {
            logError(error, false, 'changeCUrrentStep');
        }
    };
    const makeSlots = (timeArr, date) => {
        let timeArr2 = [];
        timeArr?.forEach(time => {
            if (moment(time.start).format('MMM DD, YYYY') == date) {
                timeArr2.push({
                    value: `${time.start} - ${time.end}`,
                    label: `${moment(time.start).format('hh:mm A')} - ${moment(time.end).format('hh:mm A')}`
                });
            }
        });
        return timeArr2;
    };
    const getDeliveryTimeSlots = () => {
        return makeSlots(startDateSlots?.data?.data?.windows, rentalStartDate);
    };
    const getPickUpTimeSlots = () => {
        let temp1 = endDateSlots?.data?.data?.windows;
        let newTimeSlots1 = temp1?.filter(
            time => getDifferenceinHours(time.start, chosenDeliveryTime.split(' - ')[0]) >= 24
        );
        let newTimeSlots2 = makeSlots(newTimeSlots1, rentalEndDate);
        return newTimeSlots2;
    };

    return (
        <>
            <h6>{intl.formatMessage({ id: 'How-To-Get-Your-Order:deliveryAndPickupHeader' })}</h6>
            <div className={classes.driverText}>
                {intl.formatMessage({ id: 'How-To-Get-Your-Order:driver-drop-retrieve-text' })}
            </div>
            <div className={`${classes.dateInfo}  col-2-diff-mob`}>
                <div className={classes.rentalTimeInfo}>
                    <span className={classes.dateLabel}>
                        {intl.formatMessage({ id: 'How-To-Get-Your-Order:rentalStartDate' })}
                        {rentalStartDate}
                    </span>
                    <label htmlFor={CHECKOUT_AVAILABLE_TIME_ID}>
                        <span className={classes.timeLabel}>
                            {intl.formatMessage({ id: 'How-To-Get-Your-Order:DeliveryTime' })}
                        </span>
                    </label>
                    <Dropdown
                        isError={isDeliveryError}
                        className={classes.dropDownCLass}
                        selectedState={chosenDeliveryTime}
                        onChangeDropdownValue={handleDeliveryTime}
                        options={getDeliveryTimeSlots()}
                        inputlabel={CHECKOUT_AVAILABLE_TIME_ID}
                        placeholderText={intl.formatMessage({ id: 'How-To-Get-Your-Order:Available-times' })}
                        dropDownTestId={checkoutDatalocator.checkout_delivery_time_dropdown}
                    />
                </div>
                {!userInfo.isCreditCustomer ? (
                    <div className={classes.rentalTimeInfo}>
                        <span className={classes.dateLabel}>
                            {intl.formatMessage({ id: 'How-To-Get-Your-Order:rentalEndDate' })}
                            {rentalEndDate}
                        </span>
                        <label htmlFor={CHECKOUT_PICKUPTIME_ID}>
                            <span className={classes.timeLabel}>
                                {intl.formatMessage({ id: 'How-To-Get-Your-Order:PickupTime' })}
                            </span>
                        </label>
                        <Dropdown
                            isError={isPickUpError}
                            className={classes.dropDownCLass}
                            selectedState={chosenPickUpTime}
                            onChangeDropdownValue={handlePickUpTime}
                            options={getPickUpTimeSlots()}
                            inputlabel={CHECKOUT_PICKUPTIME_ID}
                            placeholderText={intl.formatMessage({ id: 'How-To-Get-Your-Order:Available-times' })}
                            dropDownTestId={checkoutDatalocator.checkout_pickup_time_dropdown}
                        />
                    </div>
                ) : null}
            </div>
            {userInfo.isCreditCustomer ? (
                <>
                    <div className={classes.estimatedEndDate}>
                        {intl.formatMessage({ id: 'How-To-Get-Your-Order:estimated-rentalEndDate' })}
                        {rentalEndDate}
                    </div>
                    <div className={classes.estimatedEndDateMessage}>
                        {intl.formatMessage({ id: 'How-To-Get-Your-Order:estimated-rentalEndDate-message' })}
                    </div>
                </>
            ) : null}
            {!userInfo.isCreditCustomer && userInfo.isAuthenticatedUser && rentalRevisionAlert && chosenDeliveryTime ? (
                <div className={classes.warningMessage}>
                    <Alert
                        className="alert"
                        type={'warning'}
                        message={intl.formatMessage({ id: 'How-To-Get-Your-Order:order-total-revision-warning' })}
                        useDefaultPadding={false}
                    />
                </div>
            ) : null}
            <div className={classes.continueBtn}>
                <Button
                    type="button"
                    onClick={handleSaveAndContinue}
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                    className="button button-primary button-block">
                    {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                </Button>
            </div>
        </>
    );
};
export default HowToGetOrderD2C;
