import { useMutation } from '@apollo/client';
import { useFilterState } from '../../../../components/cap';
import { getRentablesPrice } from '../../../../components/checkout/checkoutAndOrderSummary/api/getCheckoutAPIs';
import { FORCE_ITEM } from '../../../../components/checkout/constants';
import {
    extractCatAndClass,
    formatjobsiteZip,
    generateSKU,
    getAvailableConsumablesConfig,
    removeZeroPriceItems
} from '../../../../components/global/utils/commonUtils';
import { logError } from '../../../../components/global/utils/logger';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import { SET_CONSUMABLES, SET_SAVED_AVAILABLE_QUANTITY } from '../../../actions/constants';
import { getConsumablesAction } from '../../../actions/consumables';
import { useUserContext } from '../../../context/UserContext';
import mutation_consumables from '../../../queries/mutation_consumables.graphql';
import { useCartState } from '../cartContext';
import useCartEstimate from './useCartEstimate';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';

const useConsumables = () => {
    const [consumablesGQL] = useMutation(mutation_consumables);
    const [{ getStartDate, getEndDate }] = useCartEstimate();
    const [{ userProfile }] = useUserContext();
    const [{ cart, userInfo, isCreditNewAddress, userAccount, howToGetYourOrderDetails }, dispatch] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const userType = useCheckUser();
    const cartItems = cart?.items || [];

    const { selectedStoreDetails } = howToGetYourOrderDetails;
    const handlePayloadPC = () => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            return projectDetails?.locationPC;
        } else {
            return viewCart?.pc;
        }
    };
    const handlePayloadAccount = () => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            return userInfo?.AccountNumber;
        } else {
            return '';
        }
    };
    const handlePayloadJobNumber = () => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            return projectDetails?.selectedProjectJobId;
        } else {
            return '';
        }
    };

    const setRentablesPrice = async (items, pc) => {
        try {
            let zipCode = viewCart?.jobSiteZip || projectDetails?.selectedProjectZip || '';

            const drivingDistance = JSON.parse(
                sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.SELECTEDSTOREDETAILS_FOR_ROUND_TRIP) || '{}'
            )?.drivingDistanceFromJobsite;

            const browseLocationOBJ = JSON.parse(
                sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.LOCATIONPCOBJ) || '{}'
            )?.distance;
            let payload = {
                cartId: String(cart?.id) || '',
                pc: pc || handlePayloadPC(),
                jobSiteAddr1: viewCart?.location || projectDetails?.projectAddress1 || '',
                jobSiteAddr2: viewCart?.jobSiteAddr2 || projectDetails?.projectAddress2 || '',
                jobSiteCity: viewCart?.jobSiteCity || projectDetails?.selectedProjectCity || '',
                jobSiteState: viewCart?.jobSiteState || projectDetails?.selectedProjectState || '',
                jobSiteZip: formatjobsiteZip(zipCode),
                dlSt:
                    !userInfo?.isCreditCustomer && userInfo?.isAuthenticatedUser
                        ? userProfile?.driver_license_state
                        : '',
                dlNumber:
                    !userInfo?.isCreditCustomer && userInfo?.isAuthenticatedUser
                        ? userProfile?.driver_license_state
                        : '',
                startDateAndTime: startDate ? getStartDate() : '',
                endDateAndTime: endDate ? getEndDate() : '',
                lat: String(viewCart?.lat) || String(projectDetails?.selectedProjectLatititude) || '',
                long: String(viewCart?.long) || String(projectDetails?.selectedProjectLongitude) || '',
                distance: viewCart?.isInStorePickup ? 0 : drivingDistance || browseLocationOBJ || -1,
                timeType: viewCart?.isInStorePickup ? 'Pickup' : 'Delivery',
                lastResort: selectedStoreDetails?.isLastResortBranch ? true : false,
                fulfillment: selectedStoreDetails?.fulfillmentPercent || '',
                pcLat: String(viewCart?.pcLat) || '',
                pcLong: String(viewCart?.pcLong) || '',
                projectId: String(projectDetails?.selectedProjectJobId) || '',
                isDeliveryEstimate: viewCart?.isInStorePickup ? false : true
            };
            let payloadCredit = {
                ...payload,
                account: userAccount?.accountNumber
            };
            let rentablesWithPriceMap = {};
            if (items?.length > 0) {
                const mainCartItems = cartItems?.map(prodObj => {
                    const { sku = '' } = prodObj?.product;
                    const quantity = prodObj?.quantity;
                    const { catId, classId } = extractCatAndClass(sku);
                    return {
                        catId,
                        classId,
                        quantity
                    };
                });

                let payloadWithRentables =
                    userInfo?.isCreditCustomer && userInfo?.isAuthenticatedUser
                        ? { ...payloadCredit, equipment: mainCartItems, salesEquipments: [] }
                        : { ...payload, equipment: mainCartItems, salesEquipments: [] };
                for (let i in items) {
                    for (let j in items[i].rentables) {
                        payloadWithRentables = {
                            ...payloadWithRentables,
                            equipment: [
                                ...payloadWithRentables.equipment,
                                {
                                    catId: ('000' + items[i].rentables[j].rentalCat).slice(-3),
                                    classId: ('0000' + items[i].rentables[j].rentalClass).slice(-4),
                                    quantity: 1
                                }
                            ]
                        };
                    }

                    items[i].consumables.forEach(consumable => {
                        consumable?.specGroups.forEach(specGroup => {
                            specGroup?.items.forEach(item => {
                                if (item?.forceItem === FORCE_ITEM.YES) {
                                    payloadWithRentables = {
                                        ...payloadWithRentables,
                                        salesEquipments: [
                                            ...payloadWithRentables.salesEquipments,
                                            {
                                                itemNumber: item?.itemNumber,
                                                quantity: parseInt(item?.minQuantity),
                                                unitPrice: parseFloat(item?.sellingPrice),
                                                stockClass: item?.stockClass
                                            }
                                        ]
                                    };
                                }
                            });
                        });
                    });
                }

                if (payloadWithRentables?.equipment?.length > 0 || payloadWithRentables?.salesEquipments?.length > 0) {
                    const { data, error } = await getRentablesPrice(payloadWithRentables);
                    if (data) {
                        for (let k in data?.estimate?.itemizedCharges?.products) {
                            rentablesWithPriceMap[
                                generateSKU(
                                    data.estimate.itemizedCharges.products[k].catId,
                                    data.estimate.itemizedCharges.products[k].classId
                                )
                            ] = data?.estimate?.itemizedCharges?.products[k]?.unitRentalCost;
                        }
                    }
                    data?.estimate?.itemizedCharges?.salesItems.forEach(item => {
                        const key = item?.itemNumber + item?.stockClass;
                        rentablesWithPriceMap[key] = item?.unitPrice;
                    });
                }
            }
            return rentablesWithPriceMap;
        } catch (error) {
            logError(error, false, 'setRentablesPrice');
        }
    };

    const getConsumables = async (pc = '') => {
        const items = cart?.items?.map(item => item.product.sku);
        let payloadForConsumables = {
            pcId: pc || handlePayloadPC(),
            account: handlePayloadAccount(),
            jobNumber: handlePayloadJobNumber(),
            items
        };
        try {
            dispatch({ type: 'beginLoading' });
            const data = await getConsumablesAction({
                consumablesGQL,
                payloadForConsumables
            });
            const estimates = await setRentablesPrice(data, pc);
            dispatch({
                type: SET_CONSUMABLES,
                consumables: removeZeroPriceItems(data, estimates),
                availableQuantity: getAvailableConsumablesConfig(data)
            });
            dispatch({ type: SET_SAVED_AVAILABLE_QUANTITY, key: 'rentablesWithPrice', value: estimates });
            dispatch({ type: 'endLoading' });
            return data;
        } catch (error) {
            if (error?.response?.status >= 500) {
                logError(error, true, 'getConsumables', [pc]);
            } else {
                logError(error, false, 'getConsumables', [pc]);
            }
        }
    };

    const api = { dispatch, getConsumables };
    return [api];
};

export default useConsumables;
