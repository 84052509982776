import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Password } from '../../../aem-core-components';
import config from '../../App/config';
import Button from '../../global/atoms/button/button';
import useReservation from '../../checkout/hooks/useReservation';
import { hasLengthAtLeast, isRequired, validatePassword } from '../../../aem-core-components/utils/formValidators';
import combineValidators from '../../../aem-core-components/utils/combineValidators';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { SET_SUBMIT_RESERVATION } from '../../../aem-core-components/actions/constants';
import { triggerEvents } from '../../../constants/analyticsConstants/triggerEvents';
import { logError, logWarning } from '../../global/utils/logger';
import './confirmation.scss';
import InfoIcon from '../../../resources/images/question-mark-circle.svg';
import Error from '../../../resources/images/error.svg';
const CreateAccount = () => {
    const intl = useIntl();
    const [{ guestUserDetails }, dispatch] = useCartState();
    const [{ createGuestUser }] = useReservation();
    const [password, setPassword] = useState('');
    const [errorText, setErrorText] = useState('');
    const userType = useCheckUser();
    const { sendEventsForClick } = useAnalyticsContext();
    //handles on click of "skip account creation"
    const handleSkipAccountCreation = () => {
        try {
            triggerEvents(
                `${userType} select`,
                intl.formatMessage({ id: 'checkout:confirmation-skip-account-creation' }),
                sendEventsForClick
            );
        } catch (error) {
            logError(error, false, 'handleSkipAccountCreation');
        }
        window.location.href = config?.pagePaths?.homePage;
    };
    //handles create account click, rus password validatio
    const onCreateAccount = async () => {
        try {
            triggerEvents(
                `${userType} select`,
                intl.formatMessage({ id: 'account:create-account' }),
                sendEventsForClick
            );
        } catch (error) {
            logError(error, false, 'onCreateAccount');
        }
        try {
            if (runPasswordValidations()) {
                const { data, error } = await createGuestUser(password);
                if (data) {
                    // redirecting to Okta sign in
                    window.location.href = config?.pagePaths?.signInHandler(
                        config?.pagePaths?.ccEquipmentPage,
                        true,
                        false
                    );
                } else {
                    setErrorText(error?.errorKey.replaceAll('_', ' '));
                    logWarning(error?.errorKey.replaceAll('_', ' '));
                }
            }
        } catch (err) {
            logError(err, false, 'onCreateAccount');
        }
    };
    const isNameInPWD = (userName, password) => {
        if (password.indexOf(userName) != -1) {
            return true;
        }
        return false;
    };
    // runs through the password entered by the user and performs
    // all required validation and checks and return 'false'
    // is any validation fails and 'true' otherwise
    const runPasswordValidations = () => {
        let isPasswordValid = true;
        while (isPasswordValid) {
            if (password.length < 8) {
                setErrorText(intl.formatMessage({ id: 'submit_reservation_password_error_lengthWarning' }));
                logWarning(intl.formatMessage({ id: 'submit_reservation_password_error_lengthWarning' }));
                isPasswordValid = false;
                break;
            } else if (!/[0-9]+/.test(password)) {
                setErrorText(intl.formatMessage({ id: 'submit_reservation_password_error_numberWarning' }));
                logWarning(intl.formatMessage({ id: 'submit_reservation_password_error_numberWarning' }));
                isPasswordValid = false;
                break;
            } else if (!/[A-Z]+/.test(password)) {
                setErrorText(intl.formatMessage({ id: 'submit_reservation_password_error_uppercaseWarning' }));
                logWarning(intl.formatMessage({ id: 'submit_reservation_password_error_uppercaseWarning' }));
                isPasswordValid = false;
                break;
            } else if (!/[a-z]+/.test(password)) {
                setErrorText(intl.formatMessage({ id: 'submit_reservation_password_error_lowercaseWarning' }));
                logWarning(intl.formatMessage({ id: 'submit_reservation_password_error_lowercaseWarning' }));
                isPasswordValid = false;
                break;
            } else if (isUsernameInPWD(guestUserDetails?.email?.toLowerCase(), password?.toLowerCase())) {
                setErrorText(intl.formatMessage({ id: 'submit_reservation_password_error_emailWarning' }));
                logWarning(intl.formatMessage({ id: 'submit_reservation_password_error_emailWarning' }));
                isPasswordValid = false;
                break;
            } else if (isNameInPWD(guestUserDetails?.firstName?.toLowerCase(), password?.toLowerCase())) {
                setErrorText(intl.formatMessage({ id: 'submit_reservation_password_error_emailWarning' }));
                logWarning(intl.formatMessage({ id: 'submit_reservation_password_error_emailWarning' }));
                isPasswordValid = false;
                break;
            } else if (isNameInPWD(guestUserDetails?.lastName?.toLowerCase(), password?.toLowerCase())) {
                setErrorText(intl.formatMessage({ id: 'submit_reservation_password_error_emailWarning' }));
                logWarning(intl.formatMessage({ id: 'submit_reservation_password_error_emailWarning' }));
                isPasswordValid = false;
                break;
            } else {
                break;
            }
        }
        return isPasswordValid;
    };
    const isUsernameInPWD = (email, password) => {
        const usernameParts = new Set(email?.split(/[.,_#@-]/)?.filter(part => part?.length >= 4));
        for (const part of usernameParts) {
            if (password?.includes(part)) {
                return true;
            }
        }
        return false;
    };
    const handlePasswordChange = e => {
        setPassword(e.target.value);
        setErrorText('');
    };
    // handles password rules modal open/close
    const handleIconClick = () => {
        dispatch({
            type: SET_SUBMIT_RESERVATION,
            key: 'isPasswordModalOpen',
            value: true
        });
    };
    const renderLabel = () => {
        return (
            <div>
                <span className={`${errorText && `errorColor`}`} tabIndex={0}>
                    {intl.formatMessage({ id: 'submit_reservation_password' })}
                </span>
                <Button
                    className="toolTipIcon"
                    onClick={handleIconClick}
                    tabIndex={0}
                    customButtonAriaLabel={
                        intl.formatMessage({ id: 'submit_reservation_password_modal_title' }) + ' tooltip'
                    }>
                    <InfoIcon aria-hidden={true} tabIndex={'-1'} />
                </Button>
            </div>
        );
    };
    return (
        <div className="create-account__container">
            <div className="create-account__flex-container">
                <div className="create-account__head-section">
                    <h5 className="create-account__head-section__heading" tabIndex={0}>
                        {intl.formatMessage({ id: 'checkout:confirmation-create-account-heading' })}
                    </h5>
                    <span className="create-account__head-section__sub-heading" tabIndex={0}>
                        {intl.formatMessage({ id: 'checkout:confirmation-create-account-sub-head' })}
                    </span>
                </div>
                <div className={`create-account__password-section ${errorText && 'errorBorder'}`}>
                    <Password
                        fieldName="password"
                        label={renderLabel()}
                        validate={combineValidators([isRequired, [hasLengthAtLeast, 8], validatePassword])}
                        autoComplete="current-password"
                        isToggleButtonHidden={false}
                        buttonAriaLabel={intl.formatMessage({
                            id: 'guest_submit_reservation_password_visibility_toggle'
                        })}
                        onChange={e => handlePasswordChange(e)}
                    />
                    {errorText && (
                        <div className="dateselector__errorContainer">
                            <Error />
                            <span className="dateselector__errorText">{errorText}</span>
                        </div>
                    )}
                </div>
                <div className="create-account__create-accountCTA">
                    <Button
                        type="button"
                        onClick={onCreateAccount}
                        className="button button-primary button-block"
                        buttonAriaLabel={intl.formatMessage({ id: 'account:create-account' })}>
                        {intl.formatMessage({ id: 'account:create-account' })}
                    </Button>
                </div>
                <div className="create-account__skip">
                    <Button
                        type="button"
                        onClick={handleSkipAccountCreation}
                        className="button button-outline-primary button-pb0"
                        buttonAriaLabel={intl.formatMessage({ id: 'checkout:confirmation-skip-account-creation' })}>
                        {intl.formatMessage({ id: 'checkout:confirmation-skip-account-creation' })}
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default CreateAccount;
