import React, { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { CollapsedView } from '../../global/modules/stepForm';
import { getURLParams, isPaymentSectionVisited } from '../../global/utils/commonUtils';
import '../howToGetYourOrder/howToGetOrder.scss';
import EditCheckoutPayment from './EditCheckoutPayment';
import ReadOnlyCheckoutPayment from './ReadOnlyCheckoutPayment';

const CheckoutPayment = props => {
    const intl = useIntl();
    const [{ userInfo, clickedMakeChanges }, dispatch] = useCartState();
    const { currentStep, handleStepChange, formStep } = props;
    const stepNumber = formStep.PAYMENT;
    const checkoutStepsEndRef = useRef(null);

    const scrollToBottom = () => {
        checkoutStepsEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        const params = getURLParams();

        if ((isPaymentSectionVisited() || params.TRANSUCCESS==='FALSE') && !clickedMakeChanges && currentStep === formStep?.PAYMENT) {
            scrollToBottom();
        }
    }, [currentStep]);

    return (
        <div className={'howToGetOrder'} ref={checkoutStepsEndRef}>
            {currentStep < stepNumber && (
                <CollapsedView
                    testId={'checkout_payment_collapsed'}
                    stepNumber={formStep.PAYMENT}
                    stepTitle={intl.formatMessage({ id: 'checkout:payment-title' })}
                />
            )}
            {currentStep === stepNumber && (
                <EditCheckoutPayment
                    currentStep={currentStep}
                    handleStepChange={handleStepChange}
                    formStep={formStep}
                />
            )}
            {currentStep > stepNumber && (
                <ReadOnlyCheckoutPayment handleStepChange={handleStepChange} formStep={formStep} />
            )}
        </div>
    );
};

export default CheckoutPayment;
