import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Price } from '../../../aem-core-components';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons/AlertModalWithButtons';
import { ReadOnlyView } from '../../global/modules/stepForm';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import useCheckout from '../../../hooks/useCheckout';
import { getTotalFromConsumables } from '../../global/utils/commonUtils';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import SawBlade from '../../../resources/images/saw-blade.svg';
import DollarSign from '../../../resources/images/dollar-sign.svg';
import './accessoriesAndAddons.scss';

const ReadOnlyAccessoriesAndAddons = props => {
    const intl = useIntl();
    const { clearCheckout } = useCheckout();
    const [rentalObj, setRentalObj] = useState({ quantity: 0, totalPrice: 0 });
    const [addonObj, setAddonObj] = useState({ quantity: 0, totalPrice: 0 });
    const [showAlertModal, setShowAlertModal] = useState(false);
    const { handleStepChange, formStep } = props;
    const [{ consumables }] = useCartState();
    const { selected } = consumables;

    useEffect(() => {
        let consumablesItem = getTotalFromConsumables(selected);
        setRentalObj({ quantity: consumablesItem.totalRentalQty, totalPrice: consumablesItem.totalRentalPrice });
        setAddonObj({ quantity: consumablesItem.totalAddonQty, totalPrice: consumablesItem.totalAddonPrice });
    }, []);

    const editHandler = () => {
        setShowAlertModal(true);
    };

    const handleMakeChangesClick = () => {
        setShowAlertModal(false);
        handleStepChange(formStep.ACCESSORIES_AND_ADDONS);
        clearCheckout(formStep, formStep.ACCESSORIES_AND_ADDONS);
    };

    const handleCancelClick = () => {
        setShowAlertModal(false);
    };

    const renderReadOnlyBody = () => {
        return (
            <>
                <div className="readonly-details-item">
                    <div className="readonly-details-icon">
                        <SawBlade />
                    </div>
                    <div className="readonly-details-desc">
                        <div className="readonly-details-title">
                            {intl.formatMessage({ id: 'checkout:accessories-and-addons-edit-view-accessories' })}
                        </div>
                        <div className="readonly-details-addonscontainer">
                            <span className="readonly-details-items">
                                {rentalObj.quantity > 1
                                    ? `${rentalObj.quantity} ${intl.formatMessage({
                                          id: 'checkout:accessories-and-addons-items-added'
                                      })}`
                                    : `${rentalObj.quantity} ${intl.formatMessage({
                                          id: 'checkout:accessories-and-addons-item-added'
                                      })}`}
                            </span>
                            <span className="readonly-details-price">
                                {<Price value={rentalObj.totalPrice} currencyCode="USD" />}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="readonly-details-item">
                    <div className="readonly-details-icon">
                        <DollarSign />
                    </div>
                    <div className="readonly-details-desc">
                        <div className="readonly-details-title">
                            {intl.formatMessage({ id: 'checkout:accessories-and-addons-edit-view-addons' })}
                        </div>
                        <div className="readonly-details-addonscontainer">
                            <span className="readonly-details-items">
                                {addonObj.quantity > 1
                                    ? `${addonObj.quantity} ${intl.formatMessage({
                                          id: 'checkout:accessories-and-addons-items-added'
                                      })}`
                                    : `${addonObj.quantity} ${intl.formatMessage({
                                          id: 'checkout:accessories-and-addons-item-added'
                                      })}`}
                            </span>
                            <span className="readonly-details-price">
                                {<Price value={addonObj.totalPrice} currencyCode="USD" />}
                            </span>
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <ReadOnlyView
                children={renderReadOnlyBody()}
                title={intl.formatMessage({ id: 'checkout:accessories-and-addons-title' })}
                testId={checkoutDatalocator.checkout_howtogetyourorder_link_testid}
                onEdit={editHandler}
            />
            <AlertModalWithButtons
                isOpen={showAlertModal}
                title={intl.formatMessage({ id: 'checkout:alert-heading' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:alert-body-content' })}
                handleContinueClick={handleMakeChangesClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:alert-body-button' })}
                handleCancelClick={handleCancelClick}
                cancelButtonText={intl.formatMessage({ id: 'account:address-delete-cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_crossicon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_makechanges_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_cancel_CTA_testid}
                titleDataLocator={checkoutDatalocator.checkout_doyouwanttomakechanges_heading_testid}
                warningQuestionDataLocator={
                    checkoutDatalocator.checkout_changesrequiredyourinformartion_labels_testid
                }
                alertTooltipDataLocator={checkoutDatalocator.checkout_makechangesmodal_tooltip_testid}
            />
        </>
    );
};

export default ReadOnlyAccessoriesAndAddons;
