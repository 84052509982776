import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    RESET_GEOGRAPHY,
    RESET_ORDER_ESTIMATES,
    SET_LOCATION_DATA,
    SET_VIEW_CART_FIELDS
} from '../../../aem-core-components/actions/constants';
import Field from '../../../aem-core-components/components/Field';
import { useCartState } from '../../../aem-core-components/components/Minicart/cartContext';
import { VIEW_CART } from '../../../aem-core-components/components/Minicart/constants';
import Alert from '../../global/atoms/alert/alert';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../hooks/useCheckUser';
import useComputeLocation from '../../../hooks/useComputeLocation';
import Button from '../../global/atoms/button';
import Dropdown from '../../global/atoms/dropdown';
import Input from '../../global/atoms/input/Input';
import LocationAutocomplete from '../../global/modules/location-autocomplete';
import {
    getStateList,
    isPaymentSectionVisited,
    setPrefilledAddress,
    validateCity,
    validateZip
} from '../../global/utils/commonUtils';
import { logError } from '../../global/utils/logger';
import { CHECKOUT_ADDRESS2_ID, CHECKOUT_ADDRESS_ID, CHECKOUT_CITY_ID, CHECKOUT_STATE_ID } from '../constants';
import classes from './checkoutAndOrderSummary.css';
import { checkoutDatalocator } from './dataLocators';
import { useFilterState } from '../../cap';

const InStoreOrderDetails = props => {
    const {
        onPressContinueHandler,
        errorText,
        viewCart,
        setErrorText,
        addressError,
        setIsManualAddress,
        addressChangedByGeocode,
        setAddressChangedByGeocode,
        setStartDateError,
        setEndDateError,
        canadaRestrictStateError,
        canadaInvalidStateError,
        setAddressesError,
        isManualAddress,
        checkoutErrorHandlingAnalytics,
        setUserPrefilledAddress,
        userPrefilledAddress
    } = props;
    const [{ cartOverlay, clickedMakeChanges }, dispatch] = useCartState();
    const [{ startDate, endDate }, filterDispatch] = useFilterState();
    const { jobSiteAddr2, jobSiteZip, jobSiteCity, jobSiteState, location, lat, long } = viewCart || '';
    const [streetAddress, setStreetAddress] = useState(location || '');
    const intl = useIntl();
    const userCompanyID = parseInt(localStorage.getItem('companyID')) || 1;
    const userType = useCheckUser();
    const { sendEventsForPageUserError, sendEventsForUpdateVirtualPath, sendEventsForEcommerceCheckoutOption } =
        useAnalyticsContext();
    const { computeInstore } = useComputeLocation();

    useEffect(() => {
        if (!canadaRestrictStateError && !canadaInvalidStateError) {
            setSmoneElseError({ ...smoneElseError, address1: addressError });
        }
    }, [addressError]);

    const [smoneElseError, setSmoneElseError] = useState({
        address1: '',
        city: '',
        zip: '',
        state: ''
    });
    useEffect(() => {
        if (smoneElseError.zip !== '') {
            document.getElementsByClassName('dropdownRoot')[0].classList.add(classes.fieldTopMargin);
        } else {
            document.getElementsByClassName('dropdownRoot')[0].classList?.remove(classes.fieldTopMargin);
        }
    }, [smoneElseError.zip]);

    const handleChange = (key, value) => {
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
    };

    const handleStateChangeOption = value => {
        setIsManualAddress(true);
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.STATE, value: value });
        setSmoneElseError({ ...smoneElseError, state: '' });
        setAddressChangedByGeocode(false);
        setStartDateError('');
        setEndDateError('');
        setErrorText('');
    };

    const handleCityChange = text => {
        setIsManualAddress(true);
        setSmoneElseError({ ...smoneElseError, city: '' });
        setAddressChangedByGeocode(false);
        handleChange(VIEW_CART.CITY, text.target.value);
        setStartDateError('');
        setEndDateError('');
        setErrorText('');
    };
    const handleZipChange = text => {
        setIsManualAddress(true);
        setSmoneElseError({ ...smoneElseError, zip: '' });
        setAddressChangedByGeocode(false);
        handleChange(VIEW_CART.ZIP_CODE, text.target.value);
        setStartDateError('');
        setEndDateError('');
        setErrorText('');
    };
    const handleAddress1Change = text => {
        setStreetAddress(text);
        setIsManualAddress(true);
        handleChange(VIEW_CART.LOCATION, text);
        setSmoneElseError({ ...smoneElseError, address1: '' });
        setAddressChangedByGeocode(false);
        setUserPrefilledAddress(text);
        setErrorText('');
        setStartDateError('');
        setEndDateError('');
    };

    const onAutoSelectAddress = async placeId => {
        setIsManualAddress(false);
        const localLocation = await computeInstore(placeId, streetAddress, '', true);
        setAddressChangedByGeocode(false);
        if (!localLocation) {
            setAddressesError(intl.formatMessage({ id: 'checkout:invalid-address-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'checkout:invalid-address-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.ADDRESS
            );
            return;
        }
        filterDispatch({
            type: SET_LOCATION_DATA,
            pc: localLocation?.pc,
            location: localLocation?.location,
            jobSiteCity: localLocation?.jobSiteCity,
            jobSiteState: localLocation?.jobSiteState,
            jobSiteZip: localLocation?.jobSiteZip,
            lat: localLocation?.lat,
            long: localLocation?.long,
            jobSiteAddr2: localLocation?.jobSiteAddr2,
            pcLat: localLocation?.pcLat,
            pcLong: localLocation?.pcLong
        });
        if (!validateZip(localLocation?.jobSiteZip)) {
            filterDispatch({
                type: RESET_GEOGRAPHY
            });
            dispatch({
                type: RESET_ORDER_ESTIMATES
            });
            setAddressesError(intl.formatMessage({ id: 'checkout:invalid-address-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'checkout:invalid-address-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.ADDRESS
            );
            return;
        }
        const errors = {
            address1: '',
            city: '',
            zip: '',
            state: ''
        };
        setSmoneElseError({ ...errors });
    };

    const postalZipErrorLabelHandler = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'order-details:postal-error-label' })
            : intl.formatMessage({ id: 'order-details:zip-error-label' });
    };

    const provinceStateErrorLabelHandler = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'order-details:province-error-label' })
            : intl.formatMessage({ id: 'order-details:state-error-label' });
    };

    const validateSmonElse = addressObj => {
        let isValid = true;
        const errors = {
            address1: '',
            city: '',
            zip: '',
            state: ''
        };
        if (!location || setPrefilledAddress(addressObj?.streetAddress, jobSiteCity) == '') {
            errors.address1 = intl.formatMessage({ id: 'order-details:address-error-label' });
            if (userType === USER_TYPE.CASH) {
                try {
                    sendEventsForPageUserError(
                        VARIABLE_CONFIG.ADDRESS_ERROR.ADDRESS1,
                        cartOverlay,
                        intl.formatMessage({ id: 'order-details:address-error-label' })
                    );
                    checkoutErrorHandlingAnalytics(
                        intl.formatMessage({ id: 'order-details:address-error-label' }),
                        VARIABLE_CONFIG.ADDRESS_ERROR.ADDRESS1
                    );
                } catch (error) {
                    logError(error, false, 'validateSmonElse');
                }
            }
            isValid = false;
        } else {
            errors.address1 = '';
        }

        if (!jobSiteCity) {
            isValid = false;
            errors.city = intl.formatMessage({ id: 'order-details:city-error-label' });
            if (userType === USER_TYPE.CASH) {
                try {
                    sendEventsForPageUserError(
                        VARIABLE_CONFIG.ADDRESS_ERROR.CITY,
                        cartOverlay,
                        intl.formatMessage({ id: 'order-details:city-error-label' })
                    );
                    checkoutErrorHandlingAnalytics(
                        intl.formatMessage({ id: 'order-details:city-error-label' }),
                        VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.CITY
                    );
                } catch (error) {
                    logError(error, false, 'validateSmonElse');
                }
            }
        } else if (jobSiteCity) {
            if (!validateCity(jobSiteCity)) {
                errors.city = intl.formatMessage({ id: 'order-details:city-error-splchar-label' });
                if (userType === USER_TYPE.CASH) {
                    try {
                        sendEventsForPageUserError(
                            VARIABLE_CONFIG.ADDRESS_ERROR.CITY,
                            cartOverlay,
                            intl.formatMessage({ id: 'order-details:city-error-splchar-label' })
                        );
                        checkoutErrorHandlingAnalytics(
                            intl.formatMessage({ id: 'order-details:city-error-label' }),
                            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.CITY
                        );
                    } catch (error) {
                        logError(error, false, 'validateSmonElse');
                    }
                }
                isValid = false;
            } else {
                errors.city = '';
            }
        }

        if (!jobSiteZip) {
            errors.zip = postalZipErrorLabelHandler();
            isValid = false;
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'order-details:zip-error-label' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.ZIP
            );
            if (userType === USER_TYPE.CASH) {
                try {
                    sendEventsForPageUserError(
                        VARIABLE_CONFIG.ADDRESS_ERROR.ZIPCODE,
                        cartOverlay,
                        intl.formatMessage({ id: 'order-details:zip-error-label' })
                    );
                } catch (error) {
                    logError(error, false, 'validateSmonElse');
                }
            }
        } else if (jobSiteZip) {
            if (!validateZip(jobSiteZip)) {
                errors.zip = postalZipErrorLabelHandler();
                checkoutErrorHandlingAnalytics(
                    intl.formatMessage({ id: 'order-details:zip-error-label' }),
                    VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.ZIP
                );
                isValid = false;
            } else {
                errors.zip = '';
            }
        }

        if (!jobSiteState) {
            errors.state = provinceStateErrorLabelHandler();
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'order-details:address-error-label' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.STATE
            );
            isValid = false;
        } else {
            errors.state = '';
        }

        setSmoneElseError({ ...errors });
        return isValid;
    };

    const continueHandler = () => {
        let addressObj = {
            lat: lat,
            long: long,
            selectedCity: jobSiteCity,
            streetAddress: viewCart?.isInStorePickup ? location : location?.split(',')[0],
            selectedZip: jobSiteZip,
            selectedAddress2: jobSiteAddr2,
            selectedState: jobSiteState,
            startDate,
            endDate
        };
        if (isManualAddress) {
            addressObj.streetAddress =  userPrefilledAddress
        }
        const isValid = validateSmonElse(addressObj);
        onPressContinueHandler && onPressContinueHandler(addressObj, isValid);
        try {
            if (isValid) {
                const currencyCode =
                    localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US;
                if (clickedMakeChanges || !isPaymentSectionVisited()) {
                    sendEventsForEcommerceCheckoutOption(2, {
                        rentalMethod: VARIABLE_CONFIG.RENTAL_METHOD.PICKUP
                    });
                    sendEventsForUpdateVirtualPath(
                        `${VARIABLE_CONFIG.ECOMMERCE_PAGE.CHECKOUT}_${VARIABLE_CONFIG.RENTAL_METHOD.PICKUP}`,
                        `/${currencyCode}/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.DETAILS}/${VARIABLE_CONFIG.RENTAL_METHOD.PICKUP}`
                    );
                }
            }
        } catch (error) {
            logError(error, false, 'changeCUrrentStep');
        }
    };

    const handleStateOrProvinceLabel = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-province' })
            : intl.formatMessage({ id: 'cart:form-state' });
    };
    const handleZipOrPostalCodeLabel = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-postalcode' })
            : intl.formatMessage({ id: 'cart:form-zipcode' });
    };
    const handleStateOrProvincePlaceholder = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-province-placeholder' })
            : intl.formatMessage({ id: 'cart:form-state-placeholder' });
    };
    const handleZipOrPostalCodePlaceholder = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-postal-placeholder' })
            : intl.formatMessage({ id: 'cart:form-zip-placeholder' });
    };

    return (
        <>
            <div className={classes.addressSectionBody}>
                <Field
                    label={intl.formatMessage({ id: 'cart:form-address-line-1' })}
                    htmlFor="address"
                    errorMsg={smoneElseError.address1}>
                    <LocationAutocomplete
                        onSelectAddress={onAutoSelectAddress}
                        handleLocationChange={handleAddress1Change}
                        selectedAddress={val => {
                            setStreetAddress(val);
                        }}
                        prefilledAddress={userPrefilledAddress}
                        showResetBtn={false}
                        placeholderText={intl.formatMessage({ id: 'cart:form-address-placeholder' })}
                        labelTestId={checkoutDatalocator.checkout_address_label_testid}
                        inputTestId={checkoutDatalocator.checkout_address_txtfield_testid}
                        errorMsg={smoneElseError.address1}
                        inputlabel={CHECKOUT_ADDRESS_ID}
                        isManualAddress={isManualAddress}
                    />
                    {smoneElseError?.address1 ? (
                        <span className="error_input" tabindex="0" aria-live="assertive" role="alert">
                            {smoneElseError?.address1}
                        </span>
                    ) : (
                        ''
                    )}
                </Field>
                <Field
                    label={intl.formatMessage({ id: 'cart:form-address-line-2' })}
                    htmlFor="address2"
                    lbltestid={checkoutDatalocator.checkout_addressline2_label_testid}>
                    <input
                        id={CHECKOUT_ADDRESS2_ID}
                        className="cmp-Field__field__input"
                        placeholder={intl.formatMessage({ id: 'optional' })}
                        field="address2"
                        onChange={text => {
                            setIsManualAddress(true);
                            handleChange(VIEW_CART.ADDRESS2, text.target.value);
                            setErrorText('');
                            setStartDateError('');
                            setEndDateError('');
                        }}
                        value={jobSiteAddr2}
                        data-testid={checkoutDatalocator.checkout_addressline2_txtfield_testid}
                    />
                </Field>
                <Input
                    name="city"
                    label={intl.formatMessage({ id: 'cart:form-city' })}
                    lbltestid={checkoutDatalocator.checkout_city_label_testid}
                    value={jobSiteCity}
                    handleInputChange={handleCityChange}
                    errorMsg={smoneElseError.city}
                    dataTestId={checkoutDatalocator.checkout_city_txtfield_testid}
                    placeholder={intl.formatMessage({ id: 'cart:form-city-placeholder' })}
                    inputAriaLabel={intl.formatMessage({ id: 'cart:form-city-placeholder' })}
                    inputlabel={CHECKOUT_CITY_ID}
                    // handleInputBlur={fireEvents}
                />
                <div className={classes.zipstate}>
                    <Field
                        label={handleStateOrProvinceLabel()}
                        htmlFor="state"
                        lbltestid={checkoutDatalocator.checkout_state_label_testid}
                        errorMsg={smoneElseError.state || canadaRestrictStateError || canadaInvalidStateError}>
                        <Dropdown
                            inputlabel={CHECKOUT_STATE_ID}
                            isError={
                                smoneElseError.state || canadaRestrictStateError || canadaInvalidStateError
                                    ? true
                                    : false
                            }
                            options={getStateList()}
                            onChangeDropdownValue={handleStateChangeOption}
                            selectedState={jobSiteState}
                            placeholderText={handleStateOrProvincePlaceholder()}
                            dropDownTestId={checkoutDatalocator.checkout_state_drpdwn_testid}
                            inputAriaLabel={handleStateOrProvincePlaceholder()}
                        />
                        {smoneElseError.state ? (
                            <span className="error_input" tabindex="0" aria-live="assertive" role="alert">
                                {smoneElseError.state}
                            </span>
                        ) : (
                            ''
                        )}
                    </Field>

                    <Input
                        name="zipcode"
                        label={handleZipOrPostalCodeLabel()}
                        lbltestid={checkoutDatalocator.checkout_zipcode_label_testid}
                        value={jobSiteZip}
                        handleInputChange={handleZipChange}
                        errorMsg={smoneElseError.zip}
                        dataTestId={checkoutDatalocator.checkout_zipcode_txtfield_testid}
                        placeholder={handleZipOrPostalCodePlaceholder()}
                        inputAriaRequired={true}
                        inputAriaLabel={handleZipOrPostalCodePlaceholder()}
                        zipcustomclass="zipcodewithnobottommargin" // asigning direct to input element, so lowercase prop is recommended
                    />
                </div>

                {(canadaRestrictStateError !== '' || canadaInvalidStateError !== '' || errorText !== '') && (
                    <Alert
                        localStyle={`alert-withbottommargin ${classes.alertPadding}`}
                        type={'error'}
                        message={canadaRestrictStateError || canadaInvalidStateError || errorText}
                    />
                )}

                {addressChangedByGeocode && !canadaRestrictStateError && !canadaInvalidStateError && (
                    <Alert
                        localStyle="alert-withbottommargin"
                        type={'warning'}
                        data-testid={'alert-warning'}
                        message={intl.formatMessage({ id: 'order-details:address-changed-notification-label' })}
                    />
                )}
            </div>
            <div className={classes.continueBtnCashGuest} data-testid={checkoutDatalocator.checkout_continue_CTA}>
                <Button
                    type="button"
                    onClick={continueHandler}
                    className="button button-primary button-block"
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                    data-testid={checkoutDatalocator.checkout_continue_cta_testid}>
                    {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                </Button>
            </div>
        </>
    );
};

export default InStoreOrderDetails;
