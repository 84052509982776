import React, { useEffect, useState } from 'react';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import { useDidMount } from '../../../hooks/useDidMount';
import { useFilterState } from '../../cap';
import { AUTHORITY_TYPE } from '../../global/constants';
import EditOrderDetails from './EditOrderDetails';
import ReadOnlyOrderDetails from './ReadOnlyOrderDetails';
import './orderDetailsCheckout.scss';

const OrderDetailsCheckout = ({ currentStep, handleStepChange, currentOffSet, formStep }) => {
    const [{ userInfo, isLoading }, dispatch] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();
    const [loading, setLoading] = useState(true);
    const didMount = useDidMount();
    const authorityType = useCheckAuthorityType();

    // useEffect(() => {
    //     if (location) {
    //         setSelectedStartDate(location?.startDateAndTime);
    //         setSelectedEndDate(location?.endDateAndTime);
    //     }
    // }, [cart?.location?.startDateAndTime, cart?.location?.endDateAndTime]);
    // TODO: implement start date and end date from graphql

    useEffect(() => {
        if (authorityType === AUTHORITY_TYPE.P2P) {
            didMount && setLoading(isLoading);
        }
    }, [isLoading]);

    useEffect(() => {
        if (authorityType !== AUTHORITY_TYPE.P2P) {
            didMount && setLoading(isLoading);
        }
    }, [isLoading, didMount]);

    useEffect(() => {
        if (didMount && currentStep == formStep.ORDER_DETAILS) {
            //on editing order details
            setSelectedStartDate(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE) || '');
            setSelectedEndDate(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE) || '');
        }
    }, [didMount, currentStep]);

    const getPrefilledLocation = () => {
        if (userInfo?.isCreditCustomer) {
            return projectDetails.projectAddress1;
        } else {
            return viewCart.location;
        }
    };
    return (
        <div className="orderdetails-root">
            {currentStep > formStep.ORDER_DETAILS &&
                selectedStartDate != '' &&
                selectedEndDate != '' &&
                getPrefilledLocation() != '' ? (
                <ReadOnlyOrderDetails
                    handleStepChange={handleStepChange}
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    formStep={formStep}
                    currentStep={currentStep}
                />
            ) : (
                <EditOrderDetails
                    selectedStartDate={selectedStartDate}
                    selectedEndDate={selectedEndDate}
                    currentStep={currentStep}
                    handleStepChange={handleStepChange}
                    setSelectedStartDate={setSelectedStartDate}
                    setSelectedEndDate={setSelectedEndDate}
                    currentOffSet={currentOffSet}
                    formStep={formStep}
                    loading={loading}
                />
            )}
        </div>
    );
};

export default OrderDetailsCheckout;
