import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { ENV_CONFIG } from '../../../constants/envConfig';
import config from '../../App/config';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons';
import HelplineNumber from '../../global/atoms/helplineNumber/HelplineNumber';
import { basePathForPunchout, redirectToQuotesPageWithQuoteId } from '../../global/utils/commonUtils';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { CREATE_QUOTE, SAVE_FOR_LATER, TRANSMIT_NOW, TRANSMIT_STATUS_FALSE, VIEW_QUOTE } from '../constants';
import './TransmitOrSave.scss';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';

const TransmitOrSaveModals = ({
    isOpen,
    selectedTransmitOrSave,
    handleTransmitOrSaveCTA,
    setSaveOrTransmitAPIStatus,
    setIsOpen,
    qouteTransmitStatus,
    source = CREATE_QUOTE
}) => {
    const intl = useIntl();
    const phoneNumber = ENV_CONFIG.PHONE_NUMBER;
    // const { pagePaths } = partialConfig;
    const getAlertMsgWithHelpline = (customAlertClass, alertMessageId, helplineMessageId, additionalMsg) => {
        return (
            <>
                {additionalMsg && <p> {intl.formatMessage({ id: additionalMsg })}</p>}
                <p className={customAlertClass}>{intl.formatMessage({ id: alertMessageId })}</p>
                <HelplineNumber
                    customClass={'helpline_message'}
                    message={helplineMessageId ? intl.formatMessage({ id: helplineMessageId }) : ''}
                    phoneNumber={phoneNumber}
                    data-testid={checkoutDatalocator.checkout_helipline_number_testid}
                />
            </>
        );
    };

    const handleModalToggle = () => {
        setSaveOrTransmitAPIStatus('');
        setIsOpen(!isOpen);
    };

    const redirectToHomePage = () => {
        window.location = basePathForPunchout;
    };

    const redirectToQuotesPage = () => {
        const quoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || ''; 
        redirectToQuotesPageWithQuoteId(quoteId);
    };

    if (isOpen && selectedTransmitOrSave === TRANSMIT_NOW) {
        return (
            <>
                {qouteTransmitStatus === TRANSMIT_STATUS_FALSE ? (
                    <AlertModalWithButtons
                        isOpen={isOpen}
                        showClose
                        title={intl.formatMessage({ id: 'checkout:save-quote-failure-titles' })}
                        warningQuestion={getAlertMsgWithHelpline(
                            'save_fail_message',
                            'checkout:transmission-failure-modal-business-days',
                            'checkout:transmission-failure-modal-immediate-assistance',
                            source === VIEW_QUOTE
                                ? 'quoteDetails:transmission-failure-modal-text'
                                : 'checkout:transmission-failure-modal-text'
                        )}
                        handleContinueClick={redirectToQuotesPage}
                        continueButtonText={intl.formatMessage({
                            id: 'createQuote:save-for-later-view-saved-quote-cta'
                        })}
                        continueButtonDataLocator={checkoutDatalocator.checkout_view_saved_quote_cta_testid}
                        handleCancelClick={redirectToHomePage}
                        cancelButtonText={intl.formatMessage({ id: 'createQuote:transmit-go-tohomepage-cta' })}
                        cancelButtonDataLocator={checkoutDatalocator.checkout_go_to_home_page_cta_testid}
                        customClass={'alert-modal-transmit-and-save-failure'}
                    />
                ) : (
                    <AlertModalWithButtons
                        isOpen={isOpen}
                        title={intl.formatMessage({ id: 'checkout:save-quote-failure-titles' })}
                        warningQuestion={getAlertMsgWithHelpline(
                            'transmit_or_save_fail_message',
                            'checkout:transmission-save-failure-modal-text',
                            'checkout:transmission-save-failure-modal-immediate-assistance'
                        )}
                        handleContinueClick={handleTransmitOrSaveCTA}
                        handleCancelClick={handleModalToggle}
                        continueButtonText={intl.formatMessage({ id: 'checkout:retry-cta' })}
                        continueButtonDataLocator={checkoutDatalocator.checkout_retry_cta_testid}
                        customClass={'alert-modal-transmit-and-save-failure'}
                    />
                )}
            </>
        );
    }
    
    if (selectedTransmitOrSave === SAVE_FOR_LATER) {
        return (
            <AlertModalWithButtons
                isOpen
                showClose
                title={intl.formatMessage({ id: 'checkout:save-quote-failure-titles' })}
                warningQuestion={getAlertMsgWithHelpline(
                    'save_fail_message',
                    'checkout:save-fail-modal-30-days-text',
                    'checkout:save-fail-modal-text',
                    'checkout:save-fail-modal-not-save-text'
                )}
                handleContinueClick={handleTransmitOrSaveCTA}
                continueButtonText={intl.formatMessage({ id: 'checkout:retry-save' })}
                continueButtonDataLocator={checkoutDatalocator.checkout_retry_save_cta_testid}
                handleCancelClick={redirectToHomePage}
                cancelButtonText={intl.formatMessage({ id: 'createQuote:transmit-go-tohomepage-cta' })}
                cancelButtonDataLocator={checkoutDatalocator.checkout_go_to_home_page_cta_testid}
            />
        );
    }
};

TransmitOrSaveModals.propTypes = {
    isOpen: PropTypes.bool,
    selectedTransmitOrSave: PropTypes.string,
    handleTransmitOrSaveCTA: PropTypes.func.isRequired
};

export default TransmitOrSaveModals;
