import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import {
    validatePrimaryContact,
    validatePhone,
    validateAccessNotes,
    validatePONumber,
    setPrefilledAddressForCreateProject
} from '../../../global/utils/commonUtils';

export const validateProjectAddress = (createProjectAddress, createProjectAddressError, intl) => {
    const { address, city, state, zipcode } = createProjectAddress;
    let isValid = true;
    const errors = {
        address: '',
        city: '',
        state: '',
        zipcode: ''
    };

    if (!address || setPrefilledAddressForCreateProject(address, city) == '') {
        errors.address = intl.formatMessage({ id: 'common:required-input-error' });
        isValid = false;
    } else {
        errors.address = '';
    }

    if (!city) {
        errors.city = intl.formatMessage({ id: 'common:required-input-error' });
        isValid = false;
    } else {
        errors.city = '';
    }

    if (!state) {
        errors.state = intl.formatMessage({ id: 'common:required-input-error' });
        isValid = false;
    } else {
        errors.state = '';
    }

    if (!zipcode) {
        errors.zipcode = intl.formatMessage({ id: 'common:required-input-error' });
        isValid = false;
    } else {
        errors.zipcode = '';
    }

    createProjectAddressError({ ...errors });
    return isValid;
};

export const validateProjectInstructions = (projectInstructions, setProjectInstructionsError, intl) => {
    const { accessNotes, poNumber, primaryContact } = projectInstructions;
    let isValid = true;
    const errors = {
        primaryContact: '',
        accessNotes: '',
        poNumber: ''
    };

    if (!primaryContact) {
        errors.primaryContact = intl.formatMessage({ id: 'common:required-input-error' });
        isValid = false;
    } else if (primaryContact) {
        if (!validatePrimaryContact(primaryContact)) {
            isValid = false;
            errors.primaryContact = intl.formatMessage({ id: 'common:input-format-error' });
        } else {
            errors.primaryContact = '';
        }
    }

    if (accessNotes) {
        if (!validateAccessNotes(accessNotes)) {
            isValid = false;
            errors.accessNotes = intl.formatMessage({ id: 'common:input-format-error' });
        } else {
            errors.accessNotes = '';
        }
    }

    if (poNumber) {
        if (!validatePONumber(poNumber)) {
            isValid = false;
            errors.poNumber = intl.formatMessage({ id: 'common:input-format-error' });
        } else {
            errors.poNumber = '';
        }
    }

    setProjectInstructionsError({ ...errors });
    return isValid;
};

export const checkValidAccessNotes = (accessNotes, setAccessNotesError, intl, errorAnalytics) => {
    let isValid = true;
    let error = '';
    if (!validateAccessNotes(accessNotes?.trim())) {
        isValid = false;
        error = intl.formatMessage({ id: 'project-info:primary-access-notes-format-error' });

        errorAnalytics(
            intl.formatMessage({ id: 'project-info:primary-access-notes-format-error' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.ACCESS_NOTES
        );
    }
    setAccessNotesError(error);
    return isValid;
};

export const checkValidPONumber = (poNumber, setPONumberError, intl, errorAnalytics) => {
    let isValid = true;
    let error = '';
    if (!validatePONumber(poNumber?.trim())) {
        isValid = false;
        error = intl.formatMessage({ id: 'project-info:primary-po-number-format-error' });
        errorAnalytics(
            intl.formatMessage({ id: 'project-info:primary-po-number-format-error' }),
            VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.PO_NUMBER
        );
    }
    setPONumberError(error);
    return isValid;
};

export const resetError = (setFeatureState, name) => {
    setFeatureState(prevState => ({ ...prevState, [name]: '' }));
};
