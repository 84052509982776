import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { useDidMount } from '../../../../hooks/useDidMount';
import HideIcon from '../../../../resources/images/hide.svg';
import ShowEyeIcon from '../../../../resources/images/showeye.svg';
import Button from '../../../global/atoms/button';
import MapLocationMarker from '../../../global/modules/mapLocationMarker';
import MobileView from '../../../global/modules/mobileView/MobileView';
import { logError } from '../../../global/utils/logger';
import StoreDetails from '../../StoreDetails';
import StoreModal from '../../StoreModal';
import Stores from '../../Stores';
import { getDirectionCTA, getSelectedlatLong } from '../utils/editModuleFunctions';
import './../howToGetOrder.scss';
import Close from '../../../../resources/images/close.svg';
import { EVENT_PAGE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Page';
import useAnalytics from '../../../../hooks/useAnalytics';
import { useFilterState } from '../../../cap';

export const ChooseAStoreView = props => {
    const [{ userInfo }] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();
    const intl = useIntl();
    const didMount = useDidMount();
    const { sendEventsForClick, sendEventsForPageUser, sendEventsForEcommercePage } = useAnalyticsContext();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    const {
        makeSelectedClicked,
        handleBackClickMobile,
        mobileCloseButton,
        onStoreDetailsClick,
        mapToggle,
        storesData,
        selectedStoreValue,
        setSelectedStoreValue,
        storeDetailData,
        mediaType,
        setIsBestMatch,
        isBestMatch,
        handleToggle,
        handleChooseThisStoreClick,
        onStoreDetailClick,
        saveStoreDetails,
        setOnStoreDetailsClick,
        handleChooseStoreFromStoreDetails,
        handleMapToggle,
        handleCloseClick,
        nextAvailableStartDate
    } = props;

    const callEvents = () => {
        try {
            sendEventsForEcommercePage(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.CHECKOUT,
                localStorage.getItem('companyID') == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA
            );
            sendEventsForPageUser(EVENT_PAGE_NAMES_CONFIG.PAGE_USER, payloadEcommerceLocationActionAnalytics());
        } catch (error) {
            logError(error, false, 'callEvents');
        }
    };
    useEffect(() => {
        didMount && callEvents();
    }, [didMount]);

    const handleOnPressDirection = () => {
        getDirectionCTA(storeDetailData, storesData, viewCart, userInfo, projectDetails);
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_STORE,
                `${storeDetailData?.pc || storesData?.data[0].pc} ${VARIABLE_CONFIG.EVENT_LABEL.GET_DIRECTIONS}`
            );
        } catch (error) {
            logError(error, false, 'handleOnPressDirection');
        }
    };

    const renderStoresView = () => {
        return (
            <>
                {!onStoreDetailsClick ? (
                    <>
                        <Stores
                            isMobile={mediaType === 'MOBILE'}
                            storesData={storesData}
                            setIsBestMatch={setIsBestMatch}
                            isBestMatch={isBestMatch}
                            handleToggle={handleToggle}
                            setSelectedStoreValue={setSelectedStoreValue}
                            selectedStoreValue={selectedStoreValue ? selectedStoreValue : storesData?.data[0]?.pc || ''}
                            handleChooseThisStoreClick={handleChooseThisStoreClick}
                            onStoreDetailClick={onStoreDetailClick}
                            onPressRadioButton={saveStoreDetails}
                            nextAvailableStartDate={nextAvailableStartDate}
                            onStoreDetailsClick={onStoreDetailsClick}
                            handleCloseClick={handleCloseClick}
                            setOnStoreDetailsClick={setOnStoreDetailsClick}
                        />
                    </>
                ) : (
                    <StoreDetails
                        onPressGetDirection={handleOnPressDirection}
                        storeDetailData={storeDetailData || storesData?.data[0]}
                        setOnStoreDetailsClick={setOnStoreDetailsClick}
                        handleChooseThisStoreClick={handleChooseStoreFromStoreDetails}
                        isMobile={mediaType === 'MOBILE'}
                    />
                )}
            </>
        );
    };
    const renderMapMarkers = isMobileView => {
        return (
            <div className={isMobileView ? `${'mapStoresBoxMobile'}` : `${'mapStoresBox'}`}>
                <MapLocationMarker
                    selectedLatLong={getSelectedlatLong(storesData, selectedStoreValue)}
                    defaultCenter={{
                        lat: storesData?.data[0]?.latitude,
                        long: storesData?.data[0]?.longitude
                    }}
                    onClickMarker={item => {
                        setSelectedStoreValue(item);
                    }}
                    selectedStoreValue={selectedStoreValue ? selectedStoreValue : storesData?.data[0]?.pc || ''}
                    storesData={!onStoreDetailsClick ? storesData : storeDetailData || storesData?.data[0]}
                    onStoreDetailsClick={onStoreDetailsClick}
                    saveStoreDetails={saveStoreDetails}
                />
            </div>
        );
    };
    return (
        <>
            {mediaType === 'MOBILE' ? (
                <MobileView
                    rootClass={`mobile-view-root`}
                    isOpen={makeSelectedClicked}
                    onRequestClose={handleCloseClick}
                    handleClose={handleBackClickMobile}
                    footerContent={mobileCloseButton}
                    isChooseStoreView={true}>
                    {!onStoreDetailsClick && (
                        <button className={`${'mapButton'} button`} onClick={handleMapToggle} tabIndex={'0'}>
                            {mapToggle ? (
                                <>
                                    <HideIcon className="buttton_icon" aria-hidden={true} tabIndex={'-1'} /> Hide map{' '}
                                </>
                            ) : (
                                <>
                                    <ShowEyeIcon className="buttton_icon" aria-hidden={true} tabIndex={'-1'} /> Show map{' '}
                                </>
                            )}
                        </button>
                    )}
                    {mapToggle ? renderMapMarkers(true) : ''}
                    {renderStoresView()}
                </MobileView>
            ) : (
                <StoreModal
                    modalDialogClass={'modal-map-wrap'}
                    isChooseStoreView={true}
                    onRequestClose={handleCloseClick}>
                    <div className="modal-map">
                        {renderMapMarkers(false)}
                        {!onStoreDetailsClick ? (
                            <Button
                                tabIndex={0}
                                customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                                className="modal-map-rightheader-cross"
                                onClick={handleCloseClick}>
                                <Close tabIndex={'-1'} aria-hidden={true} />
                            </Button>
                        ) : (
                            <Button
                                tabIndex={0}
                                customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                                className="modal-map-rightheader-cross"
                                onClick={() => setOnStoreDetailsClick(false)}>
                                <Close tabIndex={'-1'} aria-hidden={true} />
                            </Button>
                        )}
                        {renderStoresView()}
                    </div>
                </StoreModal>
            )}
        </>
    );
};
