import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LoadingIndicator } from '../../../aem-core-components';
import {
    SET_CLICKED_MAKE_CHANGES,
    SET_PAYMENT,
    SET_PAYMENT_SESSION,
    SET_SELECTED_PAYMENT_METHOD
} from '../../../aem-core-components/actions/constants';
import { useCartState } from '../../../aem-core-components/components/Minicart/cartContext';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { getProducts } from '../../../constants/analyticsConstants/getProducts';
import { USER_TYPE } from '../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../hooks/useCheckUser';
import useCheckout from '../../../hooks/useCheckout';
import CheckedRadio from '../../../resources/images/checked.svg';
import CreditCard from '../../../resources/images/credit-card.svg';
import UncheckedRadio from '../../../resources/images/unchecked.svg';
import { useFilterState } from '../../cap';
import Alert from '../../global/atoms/alert/alert';
import Button from '../../global/atoms/button';
import CardRadioButton from '../../global/atoms/cardRadioButton';
import { EditableView } from '../../global/modules/stepForm';
import { getURLParams, isPaymentSectionVisited } from '../../global/utils/commonUtils';
import { logError } from '../../global/utils/logger';
import { getPaymentAPI, getPaymentSessionAPI } from '../checkoutAndOrderSummary/api/getCheckoutAPIs';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import useReservation from '../hooks/useReservation';
import { filterPayments } from './checkOutFunctions';
import './checkoutPayment.scss';
import { cardTypeMapper, formattedCardTitle, isCardExpired } from './paymentHelper';

const EditCheckoutPayment = ({ handleStepChange, currentStep, formStep }) => {
    const [{ createDummyUser }] = useReservation();
    const intl = useIntl();
    const userTypeCookie = useCheckUser();
    const [
        {
            paymentData,
            sessionAPIResponse,
            userInfo,
            isLoading,
            paymentTokenData,
            clickedMakeChanges,
            consumables,
            cart
        },
        dispatch
    ] = useCartState();
    const [{ viewCart, startDate, endDate }] = useFilterState();
    const [selectedValue, setSelectedValue] = useState(paymentData?.selectedPaymentMethod || 0);
    const [filteredPaymentData, setFilteredPaymentData] = useState([]);
    const cartContext = useCartState();
    const filterContext = useFilterState();
    const {
        sendEventsForClick,
        sendEventsForUpdateVirtualPath,
        sendEventsForEcommerceCheckoutOption,
        sendEventsForEcommerceCheckout
    } = useAnalyticsContext();
    const params = getURLParams();
    const { getCustomerId } = useCheckout();
    useEffect(() => {
        const datesObj = {
            startDate,
            endDate
        };
        if (!isPaymentSectionVisited() || clickedMakeChanges) {
            sendEventsForEcommerceCheckout(5, getProducts(cart, datesObj, false, consumables));
        }
        fetchPaymentSessionAPI();
        if (
            !clickedMakeChanges &&
            ((!userInfo.isAuthenticatedUser && isPaymentSectionVisited()) || params.CUSTOM2 === 'FALSE') &&
            params.TRANSUCCESS === 'TRUE'
        ) {
            const paymentData = {
                tokenID: paymentTokenData?.paymentToken,
                tokenPvID: paymentTokenData?.tokenProvider,
                tokenExpirationDate: paymentTokenData?.ccExp
            };
            dispatch({
                type: SET_SELECTED_PAYMENT_METHOD,
                key: 'selectedPaymentMethod',
                value: selectedValue
            });
            dispatch({
                type: SET_SELECTED_PAYMENT_METHOD,
                key: 'selectedPaymentDetails',
                value: paymentData
            });
            handleStepChange(formStep.SUBMIT_RESERVATION);
        }
        if (userInfo.isAuthenticatedUser) {
            fetchPaymentAPI();
        }
        sessionStorage.setItem('cartContext', JSON.stringify(cartContext));
        sessionStorage.setItem('filterContext', JSON.stringify(filterContext));
        localStorage.setItem('viewCart', JSON.stringify(viewCart));
        sessionStorage.setItem('paymentStep', JSON.stringify(formStep.PAYMENT));
        sessionStorage.setItem('formStep', JSON.stringify(formStep));

        if (!userInfo.isAuthenticatedUser) {
            sessionStorage.setItem('guestUserEmail', JSON.stringify(cartContext[0].guestUserDetails.email));
            if (!isPaymentSectionVisited() || clickedMakeChanges) {
                createDummyUser();
            }
        }
    }, []);

    async function fetchPaymentSessionAPI() {
        try {
            dispatch({ type: 'beginLoading' });
            const response = await getPaymentSessionAPI(getCustomerId(), userInfo);
            dispatch({
                type: SET_PAYMENT_SESSION,
                sessionAPIResponse: response
            });
            dispatch({ type: 'endLoading' });
        } catch (err) {
            logError(err, false, ' fetchPaymentSessionAPI ');
        }
    }

    async function fetchPaymentAPI() {
        try {
            dispatch({ type: 'beginLoading' });
            const paymentResponse = await getPaymentAPI();
            let cardData = paymentResponse?.data?.data?.customerPaymentToken?.filter(card => {
                if (card?.statusAfterTransaction !== 'D') {
                    return true;
                }
            });
            dispatch({
                type: SET_PAYMENT,
                key: 'customerPaymentToken',
                value: cardData
            });
            dispatch({ type: 'endLoading' });
        } catch (err) {
            logError(err, false, 'fetchPaymentAPI');
        }
    }

    useEffect(() => {
        let filterPaymentsResponse = filterPayments(paymentData);
        setFilteredPaymentData(filterPaymentsResponse.filteredList);
        if (selectedValue === 0) {
            if (filterPaymentsResponse?.latestToBeSelected > -1) {
                setSelectedValue(filterPaymentsResponse.latestToBeSelected);
            } else {
                setSelectedValue(filterPaymentsResponse?.filteredList.length || 0);
            }
        }
    }, [paymentData?.customerPaymentToken]);

    const onRadioSelectHandler = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userTypeCookie.toLowerCase()} select`,
                VARIABLE_CONFIG.EVENT_LABEL.SAVED_PAYMENT
            );
        } catch (error) {
            logError(error, false, 'onRadioSelectHandler');
        }
    };

    const cardStyle = data => {
        const isExpired = isCardExpired(data.expiryDate);
        const infoText = isExpired ? 'Expired' : `Expires ${moment(data.expiryDate, 'MMYY').format('MM/YY')}`;
        const cardTitle = data.isNewCard ? data.name : formattedCardTitle(data.paymentType, data.last4Digits);
        return (
            <div className={'accountCardRoot'}>
                <span className={`${'cardTitle'} ${isExpired && 'expiredTitle'}`}>{cardTitle}</span>
                <small className={`${'cardContent'} ${isExpired && 'expiredText'}`}>{infoText}</small>
            </div>
        );
    };

    const onPressNewPayment = () => {
        setSelectedValue(filteredPaymentData?.length);
    };

    const onContinue = () => {
        try {
            if (!isPaymentSectionVisited() || clickedMakeChanges) {
                sendEventsForEcommerceCheckoutOption(5, {
                    paymentMethod:
                        cardTypeMapper(filteredPaymentData[selectedValue]?.paymentType).toLowerCase() ||
                        paymentTokenData?.ccBrand
                });
                sendEventsForUpdateVirtualPath(
                    VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_PAYMENT,
                    `/${
                        localStorage.getItem('companyID') == 2
                            ? VARIABLE_CONFIG.REGION.CANADA
                            : VARIABLE_CONFIG.REGION.US
                    }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.CHECKOUT_PAYMENT}`
                );
            }
        } catch (error) {
            logError(error, false, 'onContinue');
        }
        dispatch({
            type: SET_SELECTED_PAYMENT_METHOD,
            key: 'selectedPaymentMethod',
            value: selectedValue
        });
        dispatch({
            type: SET_SELECTED_PAYMENT_METHOD,
            key: 'selectedPaymentDetails',
            value: {
                tokenID: filteredPaymentData[selectedValue].token,
                tokenPvID: filteredPaymentData[selectedValue].providerId,
                tokenExpirationDate: filteredPaymentData[selectedValue].expiryDate,
                paymentType: filteredPaymentData[selectedValue].paymentType
            }
        });
        dispatch({ type: SET_CLICKED_MAKE_CHANGES, value: false });

        handleStepChange(formStep.SUBMIT_RESERVATION);
    };

    const displayNewPaymentCard = () => {
        const tempusURL = document.querySelector('meta[name="generic-variables"]')?.dataset?.tempusUrl;
        const iFrameURL = tempusURL
            ? tempusURL + sessionAPIResponse?.data?.data?.sessionId
            : 'https://iframesbrdevweb.sunbeltrentalspayments.com/PP?PAGE=HOSTEDPAYMENT&SESSIONID=' +
              sessionAPIResponse?.data?.data?.sessionId;
        if (filteredPaymentData?.length > 0) {
            return (
                <>
                    <div
                        onClick={onPressNewPayment}
                        className={
                            selectedValue === filteredPaymentData?.length
                                ? 'newPaymentCard newPaymentCardSelected'
                                : 'newPaymentCard'
                        }
                        data-testid={checkoutDatalocator.checkout_new_payment}>
                        <div className="rootRadioButton">
                            {selectedValue === filteredPaymentData?.length ? <CheckedRadio /> : <UncheckedRadio />}
                        </div>
                        <div className="newPaymentText">
                            {intl.formatMessage({ id: 'checkout:payment-newpaymentmethod' })}
                        </div>
                        <div className="newPaymentIcon">
                            <CreditCard />
                        </div>
                    </div>
                    {selectedValue === filteredPaymentData?.length && (
                        <div className="iframeParentWithPaymentMethods">
                            <iframe
                                src={iFrameURL}
                                style={{
                                    borderStyle: 'none',
                                    width: '100%',
                                    // height: mediaType === 'DESKTOP' ? '40rem' : '43rem'
                                    height: '44.5rem'
                                }}
                                title="New Payment Method"
                                scrolling="yes"></iframe>
                        </div>
                    )}
                </>
            );
        } else if (sessionAPIResponse?.data) {
            return (
                <div className="iframeParent">
                    <iframe
                        src={iFrameURL}
                        style={{
                            borderStyle: 'none',
                            width: '100%',
                            // height: mediaType === 'DESKTOP' ? '40rem' : '43rem'
                            height: '43rem'
                        }}
                        title="New Payment Method"
                        scrolling="yes"></iframe>
                </div>
            );
        }
    };

    const renderSaveAndContinueButton = () => {
        if (selectedValue === filteredPaymentData?.length || !paymentData?.customerPaymentToken) {
            return <></>;
        }

        return (
            <Button
                type="button"
                onClick={onContinue}
                className="button button-primary button-block"
                buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                // data-testid={checkoutDatalocator.checkout_continue_CTA}
            >
                {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
            </Button>
        );
    };

    const displayPayments = (data, index) => {
        return (
            <div className="cards" key={index}>
                <CardRadioButton
                    index={index}
                    item={data}
                    cardStyle={cardStyle}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    onPressRadioButton={onRadioSelectHandler}
                    isCard={true}
                    isExpired={isCardExpired(data.expiryDate)}
                />
            </div>
        );
    };

    const renderEditableView = () => {
        return (
            <>
                {isLoading && (
                    <div className="orderSummaryLoader">
                        <LoadingIndicator />
                    </div>
                )}{' '}
                {!isLoading && (
                    <div className={`bottomwarning ${userTypeCookie === USER_TYPE.CASH && 'no-left-padding'}`}>
                        <small>{intl.formatMessage({ id: 'checkout:payment-bottomwarning' })}</small>
                    </div>
                )}
                {filteredPaymentData?.map((item, index) => {
                    return displayPayments(item, index);
                })}
                {displayNewPaymentCard()}
                {params.TRANSUCCESS === 'FALSE' &&
                    !clickedMakeChanges &&
                    selectedValue !== filteredPaymentData?.length && (
                        <div className="failedPaymentAlertWrapper">
                            <Alert
                                localStyle={`alert-withbottommargin`}
                                type={'error'}
                                message={intl.formatMessage({ id: 'payment:card-failed-text' })}
                            />
                        </div>
                    )}
                {renderSaveAndContinueButton()}
            </>
        );
    };

    return (
        <EditableView
            children={renderEditableView()}
            currentStep={currentStep}
            currentTitle={intl.formatMessage({ id: 'checkout:payment-title' })}
            testId={checkoutDatalocator.checkout_payment_heading}
        />
    );
};
export default EditCheckoutPayment;
