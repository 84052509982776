import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button } from '../../../aem-core-components';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { EVENT_P2P_NAMES_CONFIG } from '../../../constants/analyticsConstants/ProcureToPay';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import useMedia from '../../../hooks/useMedia';
import ButtonWithTnCCheckbox from '../../global/atoms/ButtonWithTnCCheckbox';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { TRANSMIT_NOW } from '../constants';
import './TransmitOrSave.scss';

const TransmitOrSaveButtons = ({ selectedTransmitOrSave, handleTransmitOrSaveCTA }) => {
    const intl = useIntl();
    const mediaType = useMedia();
    const { sendEventsForP2PCtaClick } = useAnalyticsContext();

    const analyticsTransmitOrSaveQuoeCTAClick = () => {
        let linkName;
        let destinationLink;
        if (selectedTransmitOrSave === TRANSMIT_NOW) {
            linkName = EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_TRANSMIT_QUOTE;
            destinationLink = EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_TRANSMIT_QUOTE;
        } else {
            linkName = EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_SAVE_QUOTE;
            destinationLink = EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_SAVE_QUOTE;
        }
        sendEventsForP2PCtaClick(
            EVENT_P2P_NAMES_CONFIG.P2P_CTA_CLICKED,
            linkName,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_TYPE_BUTTON,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_LOCATION_BODY,
            destinationLink
        );
    };
    const renderDesktopBtns = () => {
        const buttonTextId =
            selectedTransmitOrSave === TRANSMIT_NOW ? 'checkout:transmit-quote-cta' : 'checkout:save-quote-cta';
        const buttonAriaLabel = intl.formatMessage({ id: buttonTextId });

        return (
            <Button
                type="button"
                onClick={() => {
                    handleTransmitOrSaveCTA();
                    analyticsTransmitOrSaveQuoeCTAClick();
                }}
                className="button button-primary button-block transmit_or_save_button"
                dataTestid={checkoutDatalocator.checkout_transmit_now_cta_testid}
                buttonAriaLabel={buttonAriaLabel}>
                {intl.formatMessage({ id: buttonTextId })}
            </Button>
        );
    };

    const renderDefaultBtns = () => {
        const buttonTitleId =
            selectedTransmitOrSave === TRANSMIT_NOW ? 'checkout:transmit-quote-cta' : 'checkout:save-quote-cta';
        const buttonTitle = intl.formatMessage({ id: buttonTitleId });

        return (
            <ButtonWithTnCCheckbox
                customClass={'submitCTASmallScreen'}
                buttonTitle={buttonTitle}
                handleButtonClick={handleTransmitOrSaveCTA}
            />
        );
    };

    return <>{mediaType === MEDIA_TYPE.DESKTOP ? renderDesktopBtns() : renderDefaultBtns()}</>;
};

TransmitOrSaveButtons.propTypes = {
    selectedTransmitOrSave: PropTypes.string,
    handleTransmitOrSaveCTA: PropTypes.func.isRequired
};

export default TransmitOrSaveButtons;
