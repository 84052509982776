import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { LoadingIndicator } from '../../../../aem-core-components';
import {
    RESET_CONSUMABLES,
    SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
    SET_VIEW_CART_FIELDS,
    UPDATE_PROJECT
} from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../aem-core-components/components/Minicart/cartContext';
import { HOW_TO_GET_YOUR_ORDER, VIEW_CART } from '../../../../aem-core-components/components/Minicart/constants';
import useConsumables from '../../../../aem-core-components/components/Minicart/hooks/useConsumables';
import AccountSelector from '../../../../aem-core-components/components/Minicart/miniCartHeader/Components/accountSelector';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { getProducts } from '../../../../constants/analyticsConstants/getProducts';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import useCheckout from '../../../../hooks/useCheckout';
import useMedia from '../../../../hooks/useMedia';
import useRoundTrip from '../../../../hooks/useRoundTrip';
import { useSunbeltLocation } from '../../../../hooks/useSunbeltLocation';
import useUserData from '../../../../hooks/useUserData';
import AlertCircle from '../../../../resources/images/alert-circle.svg';
import { useFilterState } from '../../../cap';
import { SET_END_DATE, SET_SELECTED_STORE_DETAILS, SET_START_DATE } from '../../../cap/constants';
import Alert from '../../../global/atoms/alert/alert';
import Button from '../../../global/atoms/button';
import CardRadioButton from '../../../global/atoms/cardRadioButton';
import Input from '../../../global/atoms/input/Input';
import { EditableView } from '../../../global/modules/stepForm';
import {
    formatMobileNumber,
    getRentalDuration,
    getTimeStops,
    getURLParams,
    makeTimeInterval,
    tommorrowDate,
    isPaymentSectionVisited
} from '../../../global/utils/commonUtils';
import { logError, logWarning } from '../../../global/utils/logger';
import HowToGetOrderD2C from '../../checkoutAndOrderSummary/HowToGetOrderD2C/HowToGetOrderD2C';
import { cardData } from '../../checkoutAndOrderSummary/constants';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import {
    checkFulfillmentInstore,
    filterStores,
    getStores
} from '../../orderDetailsCheckout/utils/editOrderDetailsFunctions';
import useFulfillmentHooks from '../../orderDetailsCheckout/utils/useFulFillmentHooks';
import {
    continueHandler,
    getAddress,
    getLeadTime,
    getTodayCondition,
    getTomorrowCondition,
    validateSmonElse,
    validateSmonElseOnToggle
} from '../utils/editModuleFunctions';
import timeSlots from '../utils/timeSlots';
import './../howToGetOrder.scss';
import { ChooseAStoreView } from './ChooseAStoreView';
import { PickUpandReturnTime } from './PickUpandReturnTime';

const EditHowToGetOrder = ({
    endDate,
    startDate,
    currentOffSet,
    handleStepChange,
    currentStep,
    formStep,
    isCustomAccount
}) => {
    const [
        {
            startDateSlots,
            endDateSlots,
            userInfo,
            cart,
            howToGetYourOrderDetails,
            isLoading,
            isCreditNewAddress,
            punchoutUserData,
            optionalPlan,
            projectInfo,
            consumables,
            isCheckoutEditResetComplete,
            timeZoneID
        },
        dispatch
    ] = useCartState();
    const [{ viewCart, projectDetails, selectedStoreDetails }, filterDispatch] = useFilterState();

    const {
        sendEventsForClick,
        sendEventsForEcommerceCheckout,
        sendEventsForEcommerceCheckoutOption,
        sendEventsForUpdateVirtualPath
    } = useAnalyticsContext();
    const mediaType = useMedia();
    const [selectedValue, setSelectedValue] = useState(howToGetYourOrderDetails.pickupType);
    const [selectedPickupType, setSelectedPickupType] = useState();
    const [selectedStorePC, setSelectedStorePC] = useState();
    const [selectedPickupTime, setSelectedPickupTime] = useState(howToGetYourOrderDetails.selectedPickupTime);
    const [selectedReturnTime, setSelectedReturnTime] = useState(howToGetYourOrderDetails.selectedReturnTime);
    const [makeSelectedClicked, setMakeSelectedClicked] = useState(false);
    const [pickUp, setPickUp] = useState(false);
    const [isBestMatch, setIsBestMatch] = useState(howToGetYourOrderDetails.isBestMatch);
    //const selectedStorePC = Object.keys(selectedStoreDetails).length > 0 ? selectedStoreDetails?.pc : '';
    const [accountSelectorStore, setAccountSelectorStore] = useState(selectedStorePC);
    const [selectedStoreValue, setSelectedStoreValue] = useState(selectedStorePC);
    const [selectedStoreValueForDate, setSelectedStoreValueForDate] = useState(selectedStorePC);
    const [onStoreDetailsClick, setOnStoreDetailsClick] = useState(false);
    const [storeDetailData, setStoreDetailData] = useState('');
    const [storeOperatingHours, setStoreOperatingHours] = useState([]);
    const [storeOpeningTimeForPickup, setstoreOpeningTimeForPickup] = useState('');
    const [storeClosingTimeForPickup, setstoreClosingTimeForPickup] = useState('');
    const [storeOpeningTimeForReturn, setstoreOpeningTimeForReturn] = useState('');
    const [storeClosingTimeForReturn, setstoreClosingTimeForReturn] = useState('');
    const [isReturnError, setIsReturnError] = useState(false);
    const [someoneElse, setSomeoneElse] = useState({
        firstname: howToGetYourOrderDetails.someoneElse?.firstname,
        lastname: howToGetYourOrderDetails.someoneElse?.lastname,
        phone: howToGetYourOrderDetails.someoneElse?.phone,
        email: howToGetYourOrderDetails.someoneElse?.email
    });
    const phoneNumberDisclaimerOther = document.querySelector('.checkout')?.dataset?.phonedisclaimerother || '';

    useEffect(() => {
        let storeData = getAddress(
            selectedStoreDetails?.pc,
            howToGetYourOrderDetails?.bestMatchStoresData,
            howToGetYourOrderDetails?.closestStoresData
        );
        if (!storeData) {
            filterDispatch({
                type: SET_SELECTED_STORE_DETAILS,
                selectedStoreDetails: {}
            });
            setSelectedStorePC('');
            setAccountSelectorStore('');
            setSelectedStoreValueForDate('');
            setSelectedStoreValue('');
        } else {
            setSelectedStorePC(selectedStoreDetails?.pc);
            setAccountSelectorStore(selectedStoreDetails?.pc);
            setSelectedStoreValueForDate(selectedStoreDetails?.pc);
            setSelectedStoreValue(selectedStoreDetails?.pc);
        }
    }, [
        howToGetYourOrderDetails.isBestMatch,
        howToGetYourOrderDetails.bestMatchStoresData,
        howToGetYourOrderDetails.closestStoresData
    ]);

    const [storesData, setStoresData] = useState(
        howToGetYourOrderDetails.isBestMatch
            ? howToGetYourOrderDetails.bestMatchStoresData
            : howToGetYourOrderDetails.closestStoresData
    );
    const [smoneElseError, setSmoneElseError] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        email: ''
    });
    const [pickUpTimeSlots, setPickUpTimeSlots] = useState(null);
    const [returnTimeSlots, setReturnTimeSlots] = useState(null);
    const [isSelectStoreError, setIsSelectStoreError] = useState(false);
    const [mapToggle, setMapToggle] = useState(true);
    const [endDateError, setEndDateError] = useState('');
    const [startDateError, setStartDateError] = useState('');
    const intl = useIntl();
    const [selectedStartDate, setSelectedStartDate] = useState(startDate || '');
    const [nextAvailableStartDate, setNextAvailableStartDate] = useState('');
    const [selectedEndDate, setSelectedEndDate] = useState(endDate || '');
    const isRoundTrip = userInfo?.isAuthenticatedUser && !viewCart?.isInStorePickup;
    const [newDatesRequired, setNewDatesRequired] = useState(false);
    const [initialRentalAmount] = useState(howToGetYourOrderDetails.estimatedTotal);
    const [rentalRevisionAlert, setRentalRevisionAlert] = useState(false);
    const [isPickUpError, setIsPickUpError] = useState(false);
    const [{ getConsumables }] = useConsumables();
    const { isIn24Hrs } = useFulfillmentHooks();
    const sunbeltLocation = useSunbeltLocation();
    const { fetchTimeZoneAPI } = useRoundTrip();
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const { rentalAmount, salesTax } = orderEstimates?.totals || {};
    const params = getURLParams();
    const [{ getAssets, updateGeography }] = useUserData();
    const { checkoutErrorAnalyticsEvent, refreshCart } = useCheckout();
    const userType = useCheckUser();

    const assets = getAssets();

    useEffect(() => {
        const datesObj = {
            startDate,
            endDate
        };
        setNextAvailableStartDate(selectedStartDate);
        if ((!isPaymentSectionVisited() && params.TRANSUCCESS) || makeSelectedClicked) {
            sendEventsForEcommerceCheckout(3, getProducts(cart, datesObj, false, consumables));
            dispatch({
                type: RESET_CONSUMABLES,
                consumables: {
                    data: {},
                    selected: {},
                    selectedRentalEquipment: {},
                    showRentalEquipmentScreen: false,
                    isRentalEquipmentsAdded: false,
                    saveAndContinue: false,
                    orderSummaryRentals: {},
                    selectedRentalQtyAndPrice: {
                        totalRentalQty: 0,
                        totalAddonPrice: 0,
                        totalAddonQty: 0,
                        totalRentalPrice: 0
                    }
                }
            });
        }
        clearInterval(viewCart?.checkPCInterval);
    }, []);

    // this useEffect recalculates the total if the sections are edited
    useEffect(() => {
        if (isCheckoutEditResetComplete) {
            refreshCart();
        }
    }, [isCheckoutEditResetComplete]);

    useEffect(() => {
        if (makeSelectedClicked) {
            setStartDateError('');
            setEndDateError('');
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [makeSelectedClicked]);

    useEffect(() => {
        setRentalRevisionAlert(false);
    }, [makeSelectedClicked, pickUpTimeSlots, returnTimeSlots]);

    const updateStoreDetais = store => {
        filterDispatch({
            type: SET_SELECTED_STORE_DETAILS,
            selectedStoreDetails: store
        });
        updatePCInContext(store);
    };

    useEffect(() => {
        /* Filter the stores based on availability and than filtering the selected store PC */
        let filterStoreBasedOnDate = filterStores(howToGetYourOrderDetails.bestMatchStoresData, startDate);
        let store = filterStoreBasedOnDate?.find(item => {
            return item?.pc === selectedStoreDetails?.pc;
        });
        if (!store) {
            let filterClosestMatchData = filterStores(howToGetYourOrderDetails.closestStoresData, startDate);
            let closestStoreData = filterClosestMatchData?.find(item => {
                return item?.pc === selectedStoreDetails?.pc;
            });
            if (closestStoreData) {
                updateStoreDetais(closestStoreData);
            }
            setIsBestMatch(false);
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.IS_BEST_MATCH,
                value: false
            });
        } else {
            updateStoreDetais(store);
            setIsBestMatch(true);
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.IS_BEST_MATCH,
                value: true
            });
        }
    }, [
        howToGetYourOrderDetails.bestMatchStoresData,
        howToGetYourOrderDetails.closestStoresData,
        selectedStoreValueForDate
    ]);

    useEffect(() => {
        /*  Filter the best and closest match store data if not set pickupdatetime as empty*/
        let filteredStores;
        let filterBestMatchData = filterStores(howToGetYourOrderDetails.bestMatchStoresData, startDate);
        let filterClosestMatchData = filterStores(howToGetYourOrderDetails.closestStoresData, startDate);

        if (isBestMatch) {
            filteredStores = filterBestMatchData;
        } else {
            filteredStores = filterClosestMatchData;
        }
        setStoresData({ data: filteredStores });

        if (!filterBestMatchData?.length && !filterClosestMatchData?.length) {
            setPickUpTimeSlots([]);
            filterDispatch({
                type: SET_SELECTED_STORE_DETAILS,
                selectedStoreDetails: {}
            });
            setSelectedStorePC('');
            setAccountSelectorStore('');
            setSelectedStoreValueForDate('');
            setSelectedStoreValue('');
        }
    }, [isBestMatch, makeSelectedClicked]);
    useEffect(() => {
        const pickupDateWithTime = newDatesRequired
            ? new Date(selectedStartDate) || new Date()
            : new Date(startDate) || new Date();
        const returnDateWithTime = new Date(selectedEndDate || endDate) || new Date();
        const returnDay = returnDateWithTime.getDay();

        const pickupDay = pickupDateWithTime.getDay();
        if (storesData && selectedStoreValue && viewCart?.isInStorePickup) {
            timeSlots(
                selectedStoreValue,
                storesData,
                setstoreOpeningTimeForPickup,
                setstoreClosingTimeForPickup,
                setstoreOpeningTimeForReturn,
                setstoreClosingTimeForReturn,
                setStoreOperatingHours,
                pickupDay,
                returnDay
            );
        }
    }, [startDate, endDate, selectedStoreValueForDate, newDatesRequired, selectedStartDate, selectedEndDate]);

    useEffect(() => {
        if (viewCart.isInStorePickup) {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_START_DATE,
                value: selectedStartDate
            });
        }
    }, [selectedStartDate]);
    useEffect(() => {
        if (viewCart.isInStorePickup) {
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_END_DATE,
                value: selectedEndDate
            });
        }
    }, [selectedEndDate]);

    const resetPickupAndReturnTime = () => {
        dispatch({
            type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
            key: HOW_TO_GET_YOUR_ORDER.SELECTED_PICKUP_TIME,
            value: ''
        });
        dispatch({
            type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
            key: HOW_TO_GET_YOUR_ORDER.SELECTED_RETURN_TIME,
            value: ''
        });
        setSelectedPickupTime('');
        setSelectedReturnTime('');
    };
    //To get Timeslots for instore pickup
    useEffect(() => {
        if (selectedStartDate !== '' && storeOpeningTimeForPickup && storeClosingTimeForPickup) {
            const leadTime = getLeadTime(cart);
            const tomorrow = tommorrowDate();
            let storeClosingTime = moment(storeClosingTimeForPickup, 'hh:mm A');
            let storeOpeningTime = moment(storeOpeningTimeForPickup, 'hh:mm A');
            let formattedStartDate = moment(selectedStartDate).format('LL');
            let formattedCurrentDate = moment().format('LL');

            if (moment(formattedStartDate).isSame(formattedCurrentDate)) {
                getTodayCondition(
                    storeClosingTime,
                    storeOpeningTime,
                    leadTime,
                    setPickUpTimeSlots,
                    setSelectedPickupTime,
                    currentOffSet
                );
            }
            // TOMORROW
            else if (moment(formattedStartDate).isSame(tomorrow)) {
                getTomorrowCondition(
                    storeOpeningTime,
                    storeClosingTime,
                    leadTime,
                    setPickUpTimeSlots,
                    setSelectedPickupTime,
                    currentOffSet
                );
            } else {
                // Timeslots for T+1 dates (no lead time added)
                const currentTime = makeTimeInterval();
                const date1 = new Date(currentTime);
                const date2 = new Date(currentOffSet);
                let estOffSet = (Math.abs(date1 - date2) / 36e5) * 3600;
                if (date1 > date2) {
                    estOffSet = '-' + estOffSet;
                }
                const startTimeWithOffset = storeOpeningTime.clone().add(estOffSet, 'seconds');
                const roundOff = makeTimeInterval(startTimeWithOffset);
                let timeSlops = '';
                if (roundOff > storeOpeningTime) {
                    timeSlops = getTimeStops(roundOff, storeClosingTime);
                } else {
                    timeSlops = getTimeStops(storeOpeningTime, storeClosingTime);
                }
                setPickUpTimeSlots(timeSlops);
            }
        }
    }, [storeOpeningTimeForPickup, storeClosingTimeForPickup, selectedStartDate]);

    useEffect(() => {
        const returnDateWithTime = new Date(selectedEndDate || endDate) || new Date();
        const returnDay = returnDateWithTime.getDay();
        if (returnDay !== '' && storeOpeningTimeForReturn && storeClosingTimeForReturn) {
            const timeSlops = getTimeStops(storeOpeningTimeForReturn, storeClosingTimeForReturn);
            setReturnTimeSlots(timeSlops);
        }
    }, [storeOpeningTimeForReturn, storeClosingTimeForReturn, selectedEndDate]);

    // to show and hide the rentalAmount alert in case of updated amount
    useEffect(() => {
        setRentalRevisionAlert(true);
    }, [howToGetYourOrderDetails.estimatedTotal]);

    const checkoutErrorHandlingAnalytics = (error, field) => {
        checkoutErrorAnalyticsEvent(formStep, currentStep, error, field);
    };

    const handleMapToggle = () => {
        setMapToggle(!mapToggle);
    };

    const updateAccountDetailsHandler = data => {
        setSelectedPickupType(data);
        //TODO need to check logic
        if (data?.jobNumber === 2) {
            validateSmonElseOnToggle(someoneElse, setSmoneElseError, intl, checkoutErrorHandlingAnalytics);
        }
    };

    const updatePCInContext = storeDetails => {
        if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
            filterDispatch({ type: UPDATE_PROJECT, ...projectDetails, locationPC: storeDetails?.pc });
        }
        updateGeography(
            storeDetails?.pc,
            storeDetails?.latitude,
            storeDetails?.longitude,
            storeDetails?.drivingDistanceFromJobsite,
            storeDetails?.specialtyTypes,
            '',
            ''
        );
        dispatch({
            type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
            key: HOW_TO_GET_YOUR_ORDER.SELECTED_STORE_DETAILS,
            value: storeDetails
        });
    };
    const handleChooseThisStoreClick = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_STORE,
                `${storeDetailData?.pc || storesData?.data[0]?.pc} ${VARIABLE_CONFIG.EVENT_LABEL.CHOOSE_STORE}`
            );
        } catch (error) {
            logError(error, false, 'handleChooseThisStoreClick');
        }
        if (storesData?.data) {
            if (selectedStoreValue) {
                const storeDetails = storesData?.data?.filter(store => store?.pc == selectedStoreValue);
                filterDispatch({
                    type: SET_SELECTED_STORE_DETAILS,
                    selectedStoreDetails: storeDetails?.[0]
                });
                setSelectedStorePC(selectedStoreValue);
                setAccountSelectorStore(selectedStoreValue);
                setSelectedStoreValueForDate(selectedStoreValue);
                if (selectedStoreValue) {
                    updatePCInContext(storeDetails?.[0]);
                }
            } else {
                setSelectedStoreValue(storesData?.data[0]?.pc);
                filterDispatch({
                    type: SET_SELECTED_STORE_DETAILS,
                    selectedStoreDetails: storesData?.data?.[0]
                });
                setSelectedStorePC(storesData?.data[0]?.pc);
                setSelectedStoreValueForDate(storesData?.data[0]?.pc);
                setAccountSelectorStore(storesData?.data[0]?.pc);
                updatePCInContext(storesData?.data?.[0]);
            }
        }
        dispatch({
            type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
            key: HOW_TO_GET_YOUR_ORDER.IS_BEST_MATCH,
            value: isBestMatch
        });
        resetPickupAndReturnTime();
        setMakeSelectedClicked(false);
    };

    const handleChooseStoreFromStoreDetails = () => {
        let choosenStore = storeDetailData || storesData?.data[0];
        filterDispatch({
            type: SET_SELECTED_STORE_DETAILS,
            selectedStoreDetails: choosenStore
        });
        setAccountSelectorStore(choosenStore?.pc);
        setSelectedStoreValue(choosenStore?.pc);
        setSelectedStoreValueForDate(choosenStore?.pc);
        saveStoreDetails(choosenStore);
        updatePCInContext(choosenStore);
        setMakeSelectedClicked(false);
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_STORE,
                `${storeDetailData?.pc || storesData?.data[0]?.pc} ${VARIABLE_CONFIG.EVENT_LABEL.CHOOSE_STORE}`
            );
        } catch (error) {
            logError(error, false, 'handleChooseStoreFromStoreDetails');
        }
    };

    const checkPcAvailablity = (pc, addr1) => {
        const checkIfPcAvailable = async () => {
            if (cart?.items?.length > 0) {
                const offset = await fetchTimeZoneAPI(addr1);
                const currStoresData = await getStores(
                    viewCart,
                    selectedStartDate,
                    selectedEndDate,
                    projectDetails,
                    userInfo,
                    dispatch,
                    assets,
                    howToGetYourOrderDetails,
                    false,
                    viewCart?.isInStorePickup ? false : isIn24Hrs(selectedStartDate, offset)
                );
                for (let i in currStoresData?.data?.data) {
                    if (
                        pc === currStoresData?.data?.data[i]?.pc &&
                        checkFulfillmentInstore(currStoresData?.data?.data[i], selectedStartDate)
                    ) {
                        return;
                    }
                }
                handleChange(VIEW_CART.SHOW_INACTIVE_PC_ALERT, true);
                logWarning('Store not available');
            } else {
                clearInterval(viewCart?.checkPCInterval);
            }
        };
        clearInterval(viewCart?.checkPCInterval);
        const interval = setInterval(checkIfPcAvailable, 300000);
        handleChange(VIEW_CART.CHECK_PC_INTERVAL, interval);
    };

    const handleToggle = currentSelected => {
        setIsBestMatch(currentSelected);
        dispatch({
            type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
            key: HOW_TO_GET_YOUR_ORDER.IS_BEST_MATCH,
            value: currentSelected
        });
    };
    const handleMakeSelectionClick = () => {
        if (!accountSelectorStore) {
            setIsBestMatch(true);
            dispatch({ type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS, key: HOW_TO_GET_YOUR_ORDER.IS_BEST_MATCH, value: true });
        }
        setMakeSelectedClicked(true);
        setOnStoreDetailsClick(false);
    };
    const handleCloseClick = () => {
        setMakeSelectedClicked(false);
    };
    const handleBackClickMobile = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_STORE,
                `${storeDetailData?.pc || storesData?.data[0]?.pc} ${VARIABLE_CONFIG.EVENT_LABEL.BACK}`
            );
        } catch (error) {
            logError(error.false, 'handleBackClickMobile');
        }
        if (onStoreDetailsClick) {
            setOnStoreDetailsClick(false);
        } else {
            setMakeSelectedClicked(false);
        }
    };

    const cardStyle = data => {
        return (
            <div className={'HTGO_accountCard'}>
                <span className={'HTGO_center'}>{data.content}</span>
            </div>
        );
    };

    const displaySearchResults = data => {
        return (
            <CardRadioButton
                key={data?.jobNumber}
                item={data}
                cardStyle={cardStyle}
                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                radioButtonAriaLabel={data.content}
                onPressRadioButton={updateAccountDetailsHandler}
                isAccount={false}
            />
        );
    };

    const onStoreDetailClick = item => {
        setStoreDetailData(item);
        setOnStoreDetailsClick(true);
        setMapToggle(true);
    };

    const saveStoreDetails = details => {
        details?.operatingHours?.sort((day1, day2) => day1.dayOfWeek - day2.dayOfWeek);
        setStoreDetailData(details);
        setSelectedStoreValue(details?.pc);
    };

    const handleSmonElse = e => {
        const { name, value } = e.target;
        if (name === 'phone') {
            const val = formatMobileNumber(value);
            setSomeoneElse(prevState => ({ ...prevState, [name]: val }));
            setSmoneElseError(prevState => ({ ...prevState, [name]: '' }));
        } else {
            setSomeoneElse(prevState => ({ ...prevState, [name]: value }));
            setSmoneElseError(prevState => ({ ...prevState, [name]: '' }));
        }
    };

    const validateForm = consumablesData => {
        const HowToGetOrderStep = formStep.HTGO;
        const OptionalPlansOrAddOnStep = HowToGetOrderStep + 1;
        if (!selectedPickupTime || !selectedReturnTime || !selectedStoreValue) {
            //invalid
            handleStepChange(HowToGetOrderStep);
            return;
        }
        if (selectedPickupType?.jobNumber === 2 || selectedValue === 2) {
            const isValid = validateSmonElse(someoneElse, setSmoneElseError, intl, checkoutErrorHandlingAnalytics);
            if (isValid) {
                dispatch({
                    type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                    key: HOW_TO_GET_YOUR_ORDER.SOMEONE_ELSE,
                    value: {
                        firstname: someoneElse?.firstname?.trim(),
                        lastname: someoneElse?.lastname?.trim(),
                        phone: someoneElse?.phone,
                        email: someoneElse?.email
                    }
                });
                handleStepChange(OptionalPlansOrAddOnStep);
            } else {
                handleStepChange(HowToGetOrderStep);
            }
        } else {
            if (punchoutUserData?.isPunchoutUser && viewCart?.isInStorePickup) {
                if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                    handleStepChange(formStep.OPTIONAL_PLANS);
                } else {
                    let nextStep;
                    if (consumablesData?.length > 0) {
                        nextStep = OptionalPlansOrAddOnStep;
                    } else if (isCustomAccount) {
                        nextStep = formStep.CUSTOM_ACCOUNT_INFO;
                    } else {
                        nextStep = formStep.TRANSFER_ORDER;
                    }
                    handleStepChange(nextStep);
                }
            } else {
                handleStepChange(OptionalPlansOrAddOnStep);
            }
            //validate regular
        }
    };

    const mobileCloseButton = () => {
        return (
            <div className="mobile-view-footer mobile-view-footer-white">
                <Button
                    type="button"
                    className="button button-primary button-block"
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:choose-this-store' })}
                    onClick={onStoreDetailsClick ? handleChooseStoreFromStoreDetails : handleChooseThisStoreClick}
                    data-testid={checkoutDatalocator.mobile_close_button}>
                    {intl.formatMessage({ id: 'checkout:choose-this-store' })}
                </Button>
            </div>
        );
    };
    const handleChange = (key, value) => {
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
    };
    const currencyCode =
        localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US;
    const getDate = selectedDate => {
        const stringToDate = new Date(selectedDate);
        return moment(stringToDate).format('l');
    };
    const sendEventsForPickup = stepNumber => {
        try {
            sendEventsForEcommerceCheckoutOption(stepNumber, {
                pickupDate: getDate(startDate),
                pickupTime: howToGetYourOrderDetails?.selectedPickupTime,
                returnDate: getDate(endDate),
                returnTime: howToGetYourOrderDetails?.selectedReturnTime,
                rentalDuration: getRentalDuration(startDate, endDate),
                timeZone: timeZoneID,
                rentalSubtotal: rentalAmount,
                estimatedSubtotal: rentalAmount + salesTax,
                pickupInstructions:
                    projectInfo?.accessNotes ||
                    projectDetails?.accessNotes ||
                    VARIABLE_CONFIG.EMPTY_STRING.PAGE_UNSPECIFIED,
                sunbeltLocation: sunbeltLocation
            });
            sendEventsForUpdateVirtualPath(
                VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_PICKUP_LOCATION,
                `/${currencyCode}/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.PICKUP_LOCATION}`
            );
        } catch (error) {
            logError(error, false, 'sendEventsForPickup');
        }
    };
    const onContinue = async () => {
        setIsPickUpError(false);
        if (newDatesRequired && selectedStartDate && selectedEndDate) {
            filterDispatch({ type: SET_START_DATE, startDate: selectedStartDate });
            filterDispatch({ type: SET_END_DATE, endDate: selectedEndDate });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE, selectedStartDate);
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE, selectedEndDate);
        }
        if (!selectedStoreValueForDate) {
            setIsSelectStoreError(true);
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'choose-store:select-store-errormsg' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.STORE_SELECTION
            );
        } else if (newDatesRequired && (selectedStartDate === '' || selectedEndDate === '')) {
            if (selectedStartDate === '') {
                setStartDateError(intl.formatMessage({ id: 'checkout:date-selector-error' }));
                logWarning(intl.formatMessage({ id: 'checkout:date-selector-error' }));
                checkoutErrorHandlingAnalytics(
                    intl.formatMessage({ id: 'checkout:date-selector-error' }),
                    VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.START_DATE
                );
            }
            if (selectedEndDate === '') {
                setEndDateError(intl.formatMessage({ id: 'checkout:date-selector-error' }));
                logWarning(intl.formatMessage({ id: 'checkout:date-selector-error' }));
                checkoutErrorHandlingAnalytics(
                    intl.formatMessage({ id: 'checkout:date-selector-error' }),
                    VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.END_DATE
                );
            }
        } else if (viewCart?.isInStorePickup && !selectedPickupTime) {
            setIsPickUpError(true);
            logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:required-input-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.PICK_UP_TIME
            );
        } else if (viewCart?.isInStorePickup && !selectedReturnTime) {
            setIsReturnError(true);
            logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:required-input-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.RETURN_TIME
            );
        } else if (returnTimeSlots?.length === 0) {
            setIsReturnError(true);
            logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'common:required-input-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.RETURN_TIME
            );
        } else {
            let filteredBestMatchData = filterStores(howToGetYourOrderDetails.bestMatchStoresData, startDate);
            let store = filteredBestMatchData.find(item => {
                return item?.pc === selectedStoreValueForDate;
            });
            if (!store) {
                let filteredClosestStoreData = filterStores(howToGetYourOrderDetails.closestStoresData, startDate);
                store = filteredClosestStoreData.find(item => {
                    return item?.pc === selectedStoreValueForDate;
                });
            }
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_STORE_DETAILS,
                value: store
            });
            const consumablesData = await getConsumables(store?.pc);
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_RETURN_TIME,
                value: selectedReturnTime
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.SELECTED_PICKUP_TIME,
                value: selectedPickupTime
            });
            dispatch({
                type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
                key: HOW_TO_GET_YOUR_ORDER.PICKUP_TYPE,
                value: selectedValue
            });
            if (viewCart?.isInStorePickup) {
                checkPcAvailablity(store?.pc, store?.street);
            }
            continueHandler(
                selectedStartDate,
                selectedEndDate,
                selectedPickupTime,
                selectedReturnTime,
                setIsReturnError,
                validateForm,
                consumablesData,
                intl,
                checkoutErrorHandlingAnalytics
            );
        }
        try {
            sendEventsForPickup(3);
        } catch (error) {
            logError(error, false, 'changeCUrrentStep');
        }
    };
    const handlePickUpTimeChange = selectedValue => {
        setSelectedPickupTime(selectedValue);
        setRentalRevisionAlert(false);
    };
    const handleReturnTimeChange = selectedValue => {
        setSelectedReturnTime(selectedValue);
        setRentalRevisionAlert(false);
    };
    const getFullAddress = getAddress(
        accountSelectorStore,
        howToGetYourOrderDetails.bestMatchStoresData,
        howToGetYourOrderDetails.closestStoresData
    );

    const renderEditableView = () => {
        if (isRoundTrip) {
            return (
                <>
                    {isLoading && (
                        <div className={'orderSummaryLoader'}>
                            <LoadingIndicator />
                        </div>
                    )}
                    <HowToGetOrderD2C
                        handleStepChange={handleStepChange}
                        rentalStartDate={moment(startDate).format('MMM DD, YYYY')}
                        rentalEndDate={moment(endDate).format('MMM DD, YYYY')}
                        startDateSlots={startDateSlots}
                        endDateSlots={endDateSlots}
                        formStep={formStep}
                        checkoutErrorHandlingAnalytics={checkoutErrorHandlingAnalytics}
                    />
                </>
            );
        } else {
            return (
                <div>
                    {isLoading && (
                        <div className={'orderSummaryLoader'}>
                            <LoadingIndicator />
                        </div>
                    )}
                    <h6
                        className={'chooseStoreHeading'}
                        data-testid={checkoutDatalocator.checkout_chooseastore_label_testid}>
                        {intl.formatMessage({ id: 'checkout:choose-a-store' })}
                    </h6>
                    {accountSelectorStore ? (
                        <AccountSelector
                            isSelectStoreError={false}
                            projectName={getFullAddress}
                            buttonAriaLabel={`${getFullAddress} selected`}
                            selectedProjectAddress1={''}
                            accountClick={handleMakeSelectionClick}
                        />
                    ) : (
                        <AccountSelector
                            customClassname={'makeASelectionText'}
                            isSelectStoreError={isSelectStoreError}
                            projectName={intl.formatMessage({ id: 'checkout:make-a-selection' })}
                            selectedProjectAddress1={''}
                            accountClick={handleMakeSelectionClick}
                            buttonAriaLabel={intl.formatMessage({ id: 'checkout:choose-a-store' })}
                            testid={checkoutDatalocator.checkout_makeaselection_drpdown_testid}
                        />
                    )}
                    {isSelectStoreError && !accountSelectorStore && (
                        <span className={'storeErrorText'} aria-live="polite">
                            <AlertCircle tabindex="-1" aria-hidden="true" />
                            {intl.formatMessage({ id: 'choose-store:select-store-errormsg' })}
                        </span>
                    )}
                    {makeSelectedClicked && (
                        <ChooseAStoreView
                            makeSelectedClicked={makeSelectedClicked}
                            handleBackClickMobile={handleBackClickMobile}
                            mobileCloseButton={mobileCloseButton}
                            onStoreDetailsClick={onStoreDetailsClick}
                            mapToggle={mapToggle}
                            storesData={storesData}
                            selectedStoreValue={selectedStoreValue}
                            setSelectedStoreValue={setSelectedStoreValue}
                            storeDetailData={storeDetailData}
                            mediaType={mediaType}
                            setIsBestMatch={setIsBestMatch}
                            isBestMatch={isBestMatch}
                            handleToggle={handleToggle}
                            handleChooseThisStoreClick={handleChooseThisStoreClick}
                            onStoreDetailClick={onStoreDetailClick}
                            saveStoreDetails={saveStoreDetails}
                            setOnStoreDetailsClick={setOnStoreDetailsClick}
                            handleChooseStoreFromStoreDetails={handleChooseStoreFromStoreDetails}
                            handleMapToggle={handleMapToggle}
                            handleCloseClick={handleCloseClick}
                            nextAvailableStartDate={nextAvailableStartDate}
                        />
                    )}
                    <h6
                        className={'pickRetTimeHeading'}
                        data-testid={checkoutDatalocator.checkout_PickUpAndReturnTime_label_testid}>
                        {intl.formatMessage({ id: 'checkout:pickup-and-return-time' })}
                    </h6>
                    <>
                        {selectedStoreValueForDate ? (
                            <PickUpandReturnTime
                                isPickUpError={isPickUpError}
                                setIsPickUpError={setIsPickUpError}
                                pickUpTimeSlots={pickUpTimeSlots}
                                returnTimeSlots={returnTimeSlots}
                                selectedReturnTime={selectedReturnTime}
                                selectedPickupTime={selectedPickupTime}
                                isReturnError={isReturnError}
                                setIsReturnError={setIsReturnError}
                                setSelectedPickupTime={handlePickUpTimeChange}
                                setSelectedReturnTime={handleReturnTimeChange}
                                currentOffSet={currentOffSet}
                                selectedStoreValueForDate={selectedStoreValueForDate}
                                endDateError={endDateError}
                                setEndDateError={setEndDateError}
                                setStartDateError={setStartDateError}
                                startDateError={startDateError}
                                selectedStartDate={selectedStartDate}
                                selectedEndDate={selectedEndDate}
                                setSelectedEndDate={setSelectedEndDate}
                                setSelectedStartDate={setSelectedStartDate}
                                setNewDatesRequired={setNewDatesRequired}
                                resetPickupAndReturnTime={resetPickupAndReturnTime}
                                checkoutErrorHandlingAnalytics={checkoutErrorHandlingAnalytics}
                            />
                        ) : (
                            <div className={'chooseStoreAvailableText'}>
                                {intl.formatMessage({ id: 'checkout:choose-store' })}
                            </div>
                        )}
                        {rentalRevisionAlert && selectedReturnTime && selectedPickupTime && (
                            <div className="rentalUpdateAlert">
                                <Alert
                                    className="alert alert-withmargin"
                                    type={'warning'}
                                    message={intl.formatMessage({
                                        id: 'How-To-Get-Your-Order:order-total-revision-warning'
                                    })}
                                />
                            </div>
                        )}
                    </>
                    {!punchoutUserData?.isPunchoutUser && (
                        <>
                            <h6
                                className={'pickRetTimeHeading'}
                                data-testid={checkoutDatalocator.checkout_whowillpickupitems_labels_testid}>
                                {intl.formatMessage({ id: 'checkout:pickup-type-heading' })}
                            </h6>
                            <div className={'HTGO_cardContainerRoot'}>
                                <div
                                    className={'HTGO_cardContainer'}
                                    data-testid={checkoutDatalocator.checkout_iwillpickitup_radiobutton_testid}>
                                    {displaySearchResults(cardData[0])}
                                </div>
                                <div
                                    className={'HTGO_cardContainer'}
                                    data-testid={checkoutDatalocator.checkout_someoneelsewill_radiobutton_testid}>
                                    {displaySearchResults(cardData[1])}
                                </div>
                            </div>
                        </>
                    )}
                    {(selectedPickupType?.jobNumber === 2 || selectedValue === 2) && (
                        <div className={'marginTopPickup'}>
                            <Input
                                name="firstname"
                                label={intl.formatMessage({ id: 'checkout:pickup-type-first-name' })}
                                value={someoneElse.firstname}
                                handleInputChange={handleSmonElse}
                                inputAriaLabel={intl.formatMessage({ id: 'checkout:pickup-type-first-name' })}
                                inputAriaRequired={true}
                                errorMsg={smoneElseError.firstname}
                                dataTestId={checkoutDatalocator.checkout_firstNameofpickUpcontact_txtfield_testid}
                            />
                            <Input
                                name="lastname"
                                label={intl.formatMessage({ id: 'checkout:pickup-type-last-name' })}
                                value={someoneElse.lastname}
                                handleInputChange={handleSmonElse}
                                errorMsg={smoneElseError.lastname}
                                inputAriaLabel={intl.formatMessage({ id: 'checkout:pickup-type-last-name' })}
                                dataTestId={checkoutDatalocator.checkout_lastnameofpickupcontact_txtfield_testid}
                            />
                            <Input
                                name="phone"
                                label={intl.formatMessage({ id: 'checkout:phone-number' })}
                                value={someoneElse.phone}
                                errorMsg={smoneElseError.phone}
                                handleInputChange={handleSmonElse}
                                inputAriaLabel={intl.formatMessage({ id: 'checkout:phone-number' })}
                                inputAriaRequired={true}
                                dataTestId={checkoutDatalocator.checkout_phoneNumber_txtfield_testid}
                                disclaimerMsg={phoneNumberDisclaimerOther}
                            />
                            <Input
                                name="email"
                                label={intl.formatMessage({ id: 'checkout:email-address' })}
                                value={someoneElse.email}
                                errorMsg={smoneElseError.email}
                                handleInputChange={handleSmonElse}
                                inputAriaLabel={intl.formatMessage({ id: 'checkout:email-address' })}
                                inputAriaRequired={true}
                                dataTestId={checkoutDatalocator.checkout_emailAddress_txtfield_testid}
                            />
                        </div>
                    )}
                    <div className={'HTGO_continueBtn'}>
                        <Button
                            type="button"
                            onClick={onContinue}
                            className="button button-primary button-block"
                            buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                            data-testid={checkoutDatalocator.checkout_howtogetyourorder_Continue_cta_testid}>
                            {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                        </Button>
                    </div>
                </div>
            );
        }
    };
    return (
        <EditableView
            children={renderEditableView()}
            currentStep={currentStep}
            currentTitle={intl.formatMessage({ id: 'checkout:how-to-get-your-order-title' })}
            testId={checkoutDatalocator.checkout_howtogetyourorder_link_testid}
        />
    );
};
export default EditHowToGetOrder;
