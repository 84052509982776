import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from './Header';
import Footer from './Footer';
import Body from './Body';
import './mobileView.scss';
import { CHECKOUT_CHOOSE_STORE_HEADER_ID } from '../../../checkout/constants';

export default function MobileView(props) {
    const {
        rootClass,
        customHeader,
        showHeader,
        children,
        handleClose,
        customHeaderClass,
        headerTitle,
        footerContent,
        mobileBodyClass,
        customFooterClass,
        isChooseStoreView
    } = props;
    
    useEffect(() => {
        document.body.classList.add('overflow-hidden');
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, []);

    const renderHeader = () => {
        return (
            <div className="mobile-view-header" id={isChooseStoreView && CHECKOUT_CHOOSE_STORE_HEADER_ID}>
                {customHeader || (
                    <Header customHeaderClass={customHeaderClass} title={headerTitle} handleBtnClick={handleClose} />
                )}
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className={`mobile-view-footer ${customFooterClass} ${mobileBodyClass}`}>
                {<Footer> {footerContent()}</Footer>}
            </div>
        );
    };

    return (
        <div className={`mobile-view-root ${rootClass}`}>
            {showHeader && renderHeader()}
            <Body className={mobileBodyClass}>{children}</Body>

            {!!footerContent && renderFooter()}
        </div>
    );
}

MobileView.propTypes = {
    handleClose: PropTypes.func,
    showHeader: PropTypes.bool,
    children: PropTypes.node,
    customHeader: PropTypes.node,
    headerTitle: PropTypes.string,
    footerContent: PropTypes.func,
    customHeaderClass: PropTypes.string,
    mobileBodyClass: PropTypes.string,
    rootClass: PropTypes.string,
    customFooterClass: PropTypes.string
};

MobileView.defaultProps = {
    handleClose: () => {},
    showHeader: true,
    children: null,
    customHeader: null,
    footerContent: () => {},
    headerTitle: 'Back',
    customHeaderClass: '',
    mobileBodyClass: '',
    rootClass: '',
    customFooterClass: '',
    isChooseStoreView: false
};
