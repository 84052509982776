import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { SET_OPTIONAL_PLANS_CHECKED, SET_OPTIONAL_PLANS_IS_EDIT } from '../../../aem-core-components/actions/constants';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { getProducts } from '../../../constants/analyticsConstants/getProducts';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import config from '../../App/config';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons';
import Button from '../../global/atoms/button/button';
import { AUTHORITY_TYPE } from '../../global/constants';
import { EditableView } from '../../global/modules/stepForm';
import { checkIsEditQuoteFlow, isPaymentSectionVisited } from '../../global/utils/commonUtils';
import { logError } from '../../global/utils/logger';
import classes from '../checkoutAndOrderSummary/checkoutAndOrderSummary.css';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { CHECKOUT_OPTIONAL_FUEL_CHECKBOX_ID, CHECKOUT_OPTIONAL_RPP_CHECKBOX_ID } from '../constants';
import OptionalPlanCheckbox from './OptionalPlanCheckbox';
import { useFilterState } from '../../cap';
import { EVENT_P2P_NAMES_CONFIG } from '../../../constants/analyticsConstants/ProcureToPay';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';

export default function EditOptionalPlans({
    currentStep,
    handleStepChange,
    formStep,
    isRPPCheckboxVisible,
    isFuelCheckboxVisible,
    isCustomAccount
}) {
    const intl = useIntl();
    const authorityType = useCheckAuthorityType();
    const [{ userInfo, cart, optionalPlan, punchoutUserData, consumables, clickedMakeChanges }, dispatch] =
        useCartState();
    const [{ viewCart, startDate, endDate }, filterDispatch] = useFilterState();

    const { isInStorePickup } = viewCart;
    const {
        sendEventsForClick,
        sendEventsForUpdateVirtualPath,
        sendEventsForEcommerceCheckoutOption,
        sendEventsForEcommerceCheckout,
        sendEventsForP2PCtaClick
    } = useAnalyticsContext();
    const [isRPPChecked, setIsRPPChecked] = useState(optionalPlan?.isRPPChecked);
    const [isFuelChargeChecked, setisFuelChargeChecked] = useState(optionalPlan?.isFuelChargeChecked);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const { fuelCharges, rppCharges } = cart?.estimatesResponse?.estimate?.totals || {};
    const nextStep = authorityType === AUTHORITY_TYPE.P2P ? formStep.TRANSMIT_OR_SAVE : formStep.PROJECT_DETAILS;

    const callEvents = () => {
        const datesObj = {
            startDate,
            endDate
        };
        if (!isPaymentSectionVisited() || clickedMakeChanges) {
            sendEventsForEcommerceCheckout(4, getProducts(cart, datesObj, false, consumables));
        }
    };

    useEffect(() => {
        callEvents();
    }, []);

    const handleOnClick = type => {
        if (type === 'rpp') {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_OPTIONAL_PLANS,
                isRPPChecked
                    ? VARIABLE_CONFIG.EVENT_LABEL.UNCHECKED_RENTAL_PROTECTION
                    : VARIABLE_CONFIG.EVENT_LABEL.CHECKED_RENTAL_PROTECTION
            );
            setIsRPPChecked(prevVal => !prevVal);
        } else {
            setisFuelChargeChecked(prevVal => !prevVal);
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_OPTIONAL_PLANS,
                isFuelChargeChecked
                    ? VARIABLE_CONFIG.EVENT_LABEL.UNCHECKED_PREPAY_FUEL
                    : VARIABLE_CONFIG.EVENT_LABEL.CHECKED_PREPAY_FUEL
            );
        }
    };

    const continueHandler = () => {
        const isEditQuoteFlow = checkIsEditQuoteFlow();
        const displayQuoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
        isEditQuoteFlow && sendEventsForP2PCtaClick(
            EVENT_P2P_NAMES_CONFIG.P2P_CTA_CLICKED,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_SAVE_AND_CONTINUE,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_TYPE_ANCHOR,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_LOCATION_BODY,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_SAVE_AND_CONTINUE,
            { quote_id: displayQuoteId }
        );
        dispatch({
            type: SET_OPTIONAL_PLANS_CHECKED,
            isRPPChecked: isRPPChecked,
            isFuelChargeChecked: isFuelChargeChecked
        });
        dispatch({
            type: SET_OPTIONAL_PLANS_IS_EDIT,
            isEdit: false
        });

        try {
            if (!isPaymentSectionVisited() || clickedMakeChanges) {
                if (isFuelChargeChecked || isRPPChecked) {
                    sendEventsForEcommerceCheckoutOption(4, {
                        rentalProtectionPlan: optionalPlan?.isRPPChecked ? rppCharges : 0,
                        fuelConveniencePlan: optionalPlan?.isFuelChargeChecked ? fuelCharges : 0
                    });
                }
                sendEventsForUpdateVirtualPath(
                    VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_OPTIONAL_PLANS,
                    `/${
                        localStorage.getItem('companyID') == 2
                            ? VARIABLE_CONFIG.REGION.CANADA
                            : VARIABLE_CONFIG.REGION.US
                    }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.OPTIONAL_PLANS}`
                );
            }
        } catch (error) {
            logError(error, false, 'continueHandler');
        }
        navigateToNextSteps();
    };

    const navigateToNextSteps = () => {
        if (userInfo?.isCreditCustomer) {
            if (punchoutUserData?.isPunchoutUser && isInStorePickup && isCustomAccount) {
                handleStepChange(formStep.CUSTOM_ACCOUNT_INFO);
            } else if (punchoutUserData?.isPunchoutUser && isInStorePickup) {
                handleStepChange(formStep.TRANSFER_ORDER);
            } else {
                handleStepChange(nextStep);
            }
        } else {
            handleStepChange(formStep.PAYMENT);
        }
    };

    const onSaveAndContinueClick = () => {
        switch (authorityType) {
            case AUTHORITY_TYPE.P2P:
                if (isFuelCheckboxVisible && !isFuelChargeChecked) {
                    setShowConfirmModal(true);
                    break;
                }

            default:
                continueHandler();
                break;
        }
    };

    const handleModalToggle = () => {
        setShowConfirmModal(!showConfirmModal);
        setisFuelChargeChecked(!isFuelChargeChecked);
    };

    const renderEditableOptionalPlans = () => {
        return (
            <>
                {isRPPCheckboxVisible && (
                    <OptionalPlanCheckbox
                        isChecked={isRPPChecked}
                        handleOnClick={handleOnClick}
                        type={'rpp'}
                        amount={rppCharges}
                        title={intl.formatMessage({ id: 'checkout:optional-rpp-title' })}
                        description={intl.formatMessage({ id: 'checkout:optional-rpp-description' })}
                        dataTestId={checkoutDatalocator.checkout_optional_rpp_checkbox_testid}
                        id={CHECKOUT_OPTIONAL_RPP_CHECKBOX_ID}
                        viewMoreLink={config.pagePaths.rppPage}
                    />
                )}
                {isFuelCheckboxVisible && (
                    <OptionalPlanCheckbox
                        isChecked={isFuelChargeChecked}
                        type={'fuel'}
                        handleOnClick={handleOnClick}
                        amount={fuelCharges}
                        title={intl.formatMessage({ id: 'order-summary:fuel-convenience-plan' })}
                        description={intl.formatMessage({ id: 'checkout:optional-fuel-description' })}
                        dataTestId={checkoutDatalocator.checkout_optional_fuel_checkbox_testid}
                        id={CHECKOUT_OPTIONAL_FUEL_CHECKBOX_ID}
                        viewMoreLink={config.pagePaths.fuelPage}
                    />
                )}
                <div className={classes.optionalContinueCtaContainer}>
                    <Button
                        type="button"
                        onClick={onSaveAndContinueClick}
                        className="button button-primary button-block"
                        data-testid={checkoutDatalocator.checkout_continue_optional_cta_testid}
                        buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}>
                        {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                    </Button>
                </div>
                <AlertModalWithButtons
                    isOpen={showConfirmModal}
                    handleContinueClick={continueHandler}
                    continueButtonText={intl.formatMessage({
                        id: 'createquote:fuel-convenience-confirm-modal-continueButtonText'
                    })}
                    cancelButtonText={intl.formatMessage({ id: 'common:cancel' })}
                    handleCancelClick={handleModalToggle}
                    title={intl.formatMessage({ id: 'createquote:fuel-convenience-confirm-modal-title' })}
                    warningQuestion={intl.formatMessage({
                        id: 'createquote:fuel-convenience-confirm-modal-warningQuestion'
                    })}
                />
            </>
        );
    };

    return (
        <EditableView
            children={renderEditableOptionalPlans()}
            currentStep={currentStep}
            currentTitle={intl.formatMessage({ id: 'checkout:optional-plans-title' })}
            testId={checkoutDatalocator.checkout_optionplans_link_testid}
        />
    );
}
