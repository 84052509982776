import React, { useEffect } from 'react';
import { useCartState } from '../../../aem-core-components/components/Minicart/cartContext';
import useMedia from '../../../hooks/useMedia';
import paths from '../../App/config';
import { crossIconSvg } from '../checkoutAndOrderSummary/constants';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import './confirmation.scss';
import CreateAccount from './CreateAccount';
import MobileGuestReservationConfirmation from './MobileGuestReservationConfirmation';
import ReservationConfirmationStatus from './ReservationConfirmationStatus';
import CheckoutPageAlerts from '../../checkout/checkoutPageAlert';
import { trapFocus } from '../../global/utils/commonUtils';
import { SUNBELT_HEADER_LOGO_CTA_ID, BACK_TO_CART_CTA_ID } from '../constants';
export default function ReservationConfirmation() {
    const [{ userInfo, submitReservation }] = useCartState();
    const mediaType = useMedia();
    //useEffect to do header's custom styling and replacing
    //event listener functions
    useEffect(() => {
        const body = document.querySelector('body');
        body.classList.add('overflow-hidden');
        const header = document.getElementsByClassName('header__checkout')[0];
        header.classList.add('fixedHeader');
        const headerBackBtn = document.getElementById(BACK_TO_CART_CTA_ID);
        headerBackBtn.setAttribute('aria-label', 'Click here to go to home page');
        const crossImage = document.createElement('img');
        crossImage.setAttribute('src', crossIconSvg);
        crossImage.setAttribute('alt', 'Close icon');
        crossImage.ariaHidden = true;
        crossImage.tabIndex = '-1';
        headerBackBtn.innerText = '';
        headerBackBtn.appendChild(crossImage);
        headerBackBtn.removeAttribute('href');
        headerBackBtn.setAttribute('data-testid', checkoutDatalocator.reservation_crossbtn);
        headerBackBtn.onclick = () => {
            window.location.href = paths?.pagePaths?.homePage;
        };
        const headerLogo = document?.getElementById(SUNBELT_HEADER_LOGO_CTA_ID);
        if (headerLogo) {
            headerLogo.setAttribute('aria-label', 'Sunbelt Rentals Header Logo');
            // Replaces onclick EventListener from sbr-logo which triggers <AlertModalWithButtons />
            // Redirects user to homepage upon click event
            headerLogo.onclick = () => {
                window.location.href = paths?.pagePaths?.homePage;
            };
        }
    }, []);
    useEffect(() => {
        if (submitReservation?.isSubmitReservationScreenVisible && mediaType !== 'MOBILE') {
            document.body.classList.add('overflow-hidden');
            trapFocus(document.querySelector('#submitReservation'));
        }
    }, [submitReservation?.isSubmitReservationScreenVisible, mediaType]);
    const liveChatButton = document.querySelector('.chat-button');
    useEffect(() => {
        if (submitReservation?.isSubmitReservationScreenVisible) {
            const reservation_selector = document?.querySelector('#reservation_resultLabel');
            reservation_selector?.focus();
        }
        if (submitReservation?.isSubmitReservationScreenVisible) {
            if (liveChatButton) {
                liveChatButton.ariaHidden = 'true';
                liveChatButton.tabIndex = '-1';
            }
        } else {
            if (liveChatButton) {
                liveChatButton.ariaHidden = 'false';
                liveChatButton.tabIndex = '0';
            }
        }
    }, [submitReservation?.isSubmitReservationScreenVisible]);
    const onCreateAccount = () => {};
    return (
        <div
            id="submitReservation"
            className={`reservation-wrapper ${!userInfo.isAuthenticatedUser ? 'reservation-guest-wrapper' : ''}`}>
            {mediaType === 'MOBILE' && !userInfo.isAuthenticatedUser ? (
                <MobileGuestReservationConfirmation />
            ) : (
                <>
                    <div
                        className={
                            userInfo.isAuthenticatedUser ? 'reservation-status-container' : 'reservation-status-left'
                        }>
                        <div className={!userInfo.isAuthenticatedUser && 'reservation-status'}>
                            <ReservationConfirmationStatus isGuestUser={!userInfo.isAuthenticatedUser} />
                        </div>
                    </div>
                    {!userInfo.isAuthenticatedUser && (
                        <div className="reservation-status-right">
                            <div className={'reservation-status reservation-create-account'}>
                                <CreateAccount
                                    onCreateAccount={onCreateAccount}
                                    createAccountCTADataLocator={'create-account-testid'}
                                />
                            </div>
                        </div>
                    )}
                </>
            )}
            <CheckoutPageAlerts />
        </div>
    );
}
