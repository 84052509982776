import React from 'react';
import { object } from 'prop-types';
import { useIntl } from 'react-intl';
import { CollapsedView } from '../../global/modules/stepForm';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';

const CollapsedYourDetails = ({ formStep }) => {
    const intl = useIntl();

    return (
        <CollapsedView
            stepNumber={formStep.YOUR_DETAILS}
            stepTitle={intl.formatMessage({ id: 'checkout:your-details-title' })}
            testId={checkoutDatalocator.checkout_yourdetails_link_testid}
        />
    );
};

export default CollapsedYourDetails;

CollapsedYourDetails.propTypes = {
    formStep: object
};
