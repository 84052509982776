import moment from "moment";

const timeSlots = (
    selectedStoreValue,
    storesData,
    setstoreOpeningTimeForPickup,
    setstoreClosingTimeForPickup,
    setstoreOpeningTimeForReturn,
    setstoreClosingTimeForReturn,
    setStoreOperatingHours,
    pickupDay,
    returnDay
) => {
    if (selectedStoreValue !== '' && storesData?.data?.length > 0) {
        const slots = storesData?.data.find(item => {
            return item?.pc === selectedStoreValue;
        });

        const data = slots?.operatingHours?.sort((day1, day2) => day1.pickupDay - day2.pickupDay);

        setStoreOperatingHours(data);

        const storeTimingForPickupDay = data?.[pickupDay];
        const storeTimingForReturnDay = data?.[returnDay];

        const storeOpeningHourForPickupDay = storeTimingForPickupDay?.isOpen
            ? moment(storeTimingForPickupDay?.open).format('hh')
            : '';
        const storeOpeningMinForPickupDay = storeTimingForPickupDay?.isOpen
            ? moment(storeTimingForPickupDay?.open).format('mm')
            : '';
        const storeClosingHourForPickupDay = storeTimingForPickupDay?.isOpen
            ? moment(storeTimingForPickupDay?.close).format('HH')
            : '';
        const storeClosingMinForPickupDay = storeTimingForPickupDay?.isOpen
            ? moment(storeTimingForPickupDay?.close).format('mm')
            : '';
        const storeOpeningHourForReturnDay = storeTimingForReturnDay?.isOpen
            ? moment(storeTimingForReturnDay?.open).format('hh')
            : '';
        const storeOpeningMinForReturnDay = storeTimingForReturnDay?.isOpen
            ? moment(storeTimingForReturnDay?.open).format('mm')
            : '';
        const storeClosingHourForReturnDay = storeTimingForReturnDay?.isOpen
            ? moment(storeTimingForReturnDay?.close).format('HH')
            : '';
        const storeClosingMinForReturnDay = storeTimingForReturnDay?.isOpen
            ? moment(storeTimingForReturnDay?.close).format('mm')
            : '';
        setstoreOpeningTimeForPickup(`${storeOpeningHourForPickupDay}:${storeOpeningMinForPickupDay}`);
        setstoreClosingTimeForPickup(`${storeClosingHourForPickupDay}:${storeClosingMinForPickupDay}`);
        setstoreOpeningTimeForReturn(`${storeOpeningHourForReturnDay}:${storeOpeningMinForReturnDay}`);
        setstoreClosingTimeForReturn(`${storeClosingHourForReturnDay}:${storeClosingMinForReturnDay}`);
    }
};
export default timeSlots;