import React from 'react';
import ReactModal from 'react-modal';
import { useIntl } from 'react-intl';
import HelplineNumber from '../../global/atoms/helplineNumber';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { LoadingIndicator } from '../../../aem-core-components';
import UserFriends from '../../../resources/images/user-friends.svg';
import './quoteTransmitModal.scss';
import { checkoutDatalocator } from '../../checkout/checkoutAndOrderSummary/dataLocators';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { TRANSMIT_NOW } from '../../checkout/constants';

const QuoteTransmitModal = ({ isModalOpen, handleModalOpen }) => {
    const intl = useIntl();
    const [{ selectedTransmitOrSave }] = useCartState();

    const isTransmitNow = selectedTransmitOrSave === TRANSMIT_NOW;

    const transmitModalHeading =
        isTransmitNow
            ? intl.formatMessage({ id: 'createquote:transmit-modal-heading' })
            : intl.formatMessage({ id: 'createquote:save-later-modal-heading' });
    
    const transmitModalDescription =
        isTransmitNow
            ? intl.formatMessage({ id: 'createquote:transmit-quote-modal-description' })
            : intl.formatMessage({ id: 'createquote:transmit-modal-description' });
    
    const showLoadingIndicator = () => {
        return (
            <LoadingIndicator
                customLoaderClass={'loader_container'}
                customLoaderIndicatorClass={'loader_indicator'} />);
    }

    const showHelplineFooter = () => {
        return isTransmitNow ?
            <div className="transmit_modal_question">
                <span>
                    <UserFriends />
                </span>
                <span>
                    <HelplineNumber
                        message={intl.formatMessage({ id: 'reservation:helpine-support' })}
                        textDataLocator={checkoutDatalocator.reservation_question_call}
                        phonenumberDataLocator={checkoutDatalocator.reservation_phonenumber}
                        phoneNumber={ENV_CONFIG.PHONE_NUMBER}
                        hideDotNextToNumber={true}
                    />
                </span>
            </div> : null;
    }

    return (
        <>
            {isModalOpen && (
                <ReactModal
                    isOpen={isModalOpen}
                    role="none"
                    onRequestClose={handleModalOpen}
                    className="transmit_modal_container">
                    <div className="transmit_modal">
                        {!isTransmitNow && showLoadingIndicator()}
                        
                        <h2 className="transmit_modal_heading" tabIndex={'0'}>
                            {transmitModalHeading}
                        </h2>

                        {isTransmitNow && showLoadingIndicator()}

                        <p className="transmit_modal_description">
                            {transmitModalDescription}
                        </p>

                        {showHelplineFooter()}
                        
                    </div>
                </ReactModal>
            )}
        </>
    );
};
export default React.memo(QuoteTransmitModal);
