import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { SET_SELECTED_TRANSMIT_OR_SAVE_FOR_LATER } from '../../../aem-core-components/actions/constants';
import { useCartState } from '../../../aem-core-components/components/Minicart/cartContext';
import { MEDIA_TYPE } from '../../../constants/screenConstants';
import useMedia from '../../../hooks/useMedia';
import CardRadioButton from '../../global/atoms/cardRadioButton';
import TransmitOrSaveMain from './TransmitOrSaveMain';
import { SAVE_FOR_LATER, TRANSMIT_NOW } from '../constants';
import './TransmitOrSave.scss';

const TransmitOrSave = () => {
    const mediaType = useMedia();
    const intl = useIntl();
    const [{ selectedTransmitOrSave }, dispatch] = useCartState();

    const radioButtonData = [
        { value: TRANSMIT_NOW, label: `${intl.formatMessage({ id: 'checkout:transmit-now-label' })}` },
        { value: SAVE_FOR_LATER, label: `${intl.formatMessage({ id: 'checkout:save-for-later-label' })}` }
    ];

    const onPressHandler = value => {
        dispatch({ type: SET_SELECTED_TRANSMIT_OR_SAVE_FOR_LATER, value });
    };

    const cardStyle = item => {
        return <p>{item?.label}</p>;
    };

    return (
        <div className="transmit_or_save_container">
            <h2 className="transmit_or_save_heading" tabIndex={'0'}>
                {intl.formatMessage({ id: 'checkout:transmit-now-or-later-heading' })}
            </h2>
            {radioButtonData.map(item => (
                <CardRadioButton
                    key={item.value}
                    item={item}
                    selectedValue={selectedTransmitOrSave}
                    cardStyle={cardStyle}
                    setSelectedValue={onPressHandler}
                    isTransmit={true}
                    customCardClass={'transmit_or_save_radio_button'}
                />
            ))}

            {mediaType === MEDIA_TYPE.DESKTOP && <TransmitOrSaveMain selectedTransmitOrSave={selectedTransmitOrSave} />}
        </div>
    );
};

export default memo(TransmitOrSave);
