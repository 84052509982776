import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { useIntl } from 'react-intl';
import { Button } from '../../../../aem-core-components';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import Alert from '../../../global/atoms/alert/alert';
import Input from '../../../global/atoms/input/Input';
import TextArea from '../../../global/atoms/textArea';
import { validateAccessNotes, validatePONumber, validatePrimaryContact } from '../../../global/utils/commonUtils';
import { PROJECT_ACCESS_NOTES_ID } from '../../constants';
import { createProjectDataLocators } from '../dataLocators';
import { resetError } from '../utils/validateFunctions';
import './projectInstructions.scss';

const ProjectInstructions = props => {
    const {
        primaryContact,
        setPrimaryContact,
        accessNotes,
        setAccessNotes,
        poNumber,
        setPONumber,
        onContinueHandler,
        projectInstructionsError,
        setProjectInstructionsError,
        errorText,
        isCreateProjectLoading,
        canadaRestrictStateError,
        addressChangedByAvs,
        avsErrorText
    } = props;
    const intl = useIntl();
    const [{ punchoutUserData }] = useCartState();
    const onChangePrimaryContact = text => {
        if (validatePrimaryContact(text.target.value)) {
            setPrimaryContact(text.target.value);
            resetError(setProjectInstructionsError, text.target.name);
        }
    };
    const onChangeAccessNotes = text => {
        if (validateAccessNotes(text.target.value)) {
            setAccessNotes(text.target.value);
            resetError(setProjectInstructionsError, text.target.name);
        }
    };
    const onChangePONumber = text => {
        if (validatePONumber(text.target.value)) {
            setPONumber(text.target.value);
            resetError(setProjectInstructionsError, text.target.name);
        }
    };
    return (
        <div>
            <div className="projectInstructionHeader">
                <h6
                    tabIndex={0}
                    aria-label={`${intl.formatMessage({ id: 'create-project:instructions-heading' })}
                    ${intl.formatMessage({ id: 'form-landmark' })}`}
                    data-testid={createProjectDataLocators.instructionsHeaderText}>
                    {intl.formatMessage({ id: 'create-project:instructions-heading' })}
                </h6>
            </div>
            <div className="projectInstructionsBody">
                <Input
                    name="primaryContact"
                    label={intl.formatMessage({ id: 'create-project:primary-contact-label' })}
                    value={primaryContact}
                    maxLength="30"
                    handleInputChange={onChangePrimaryContact}
                    placeholder={intl.formatMessage({ id: 'create-project:primary-contact-placeholder' })}
                    lbltestid={createProjectDataLocators.primaryContactHelperText}
                    dataTestId={createProjectDataLocators.primaryContactPlaceHolder}
                    errorMsg={projectInstructionsError.primaryContact}
                    inputAriaLabel={intl.formatMessage({ id: 'create-project:primary-contact-placeholder' })}
                />
                <TextArea
                    name={PROJECT_ACCESS_NOTES_ID}
                    label={intl.formatMessage({ id: 'create-project:access-notes-label' })}
                    value={accessNotes}
                    handleChange={onChangeAccessNotes}
                    placeholder={intl.formatMessage({ id: 'create-project:access-notes-placeholder' })}
                    classes="accessNotesTextArea"
                    fieldCustomClass="createProjectTextarea"
                    maxLength="160"
                    lbltestid={createProjectDataLocators.accessNotesHelperText}
                    dataTestId={createProjectDataLocators.accessNotesPlaceHolder}
                    errorMsg={projectInstructionsError.accessNotes}
                    textAriaLabel={intl.formatMessage({ id: 'create-project:access-notes-placeholder' })}
                />
                <small data-testid={createProjectDataLocators.letUsKnowIfThereAreText} className="accessNotesHelpText">
                    {intl.formatMessage({ id: 'create-project:instructions-let-us-know' })}
                </small>
                {!punchoutUserData?.isPunchoutUser && (
                    <>
                        <Input
                            name="poNumber"
                            label={intl.formatMessage({ id: 'create-project:po-number-label' })}
                            value={poNumber}
                            handleInputChange={onChangePONumber}
                            placeholder={intl.formatMessage({ id: 'create-project:po-number-placeholder' })}
                            maxLength="26"
                            dataTestId={createProjectDataLocators.defaultPOnumberPlaceHolder}
                            errorMsg={projectInstructionsError.poNumber}
                            inputAriaLabel={intl.formatMessage({ id: 'create-project:po-number-placeholder' })}
                            fieldcustomclass="poNumberTextBox"
                        />
                        <small
                            data-testid={createProjectDataLocators.ifYouWantToUseText}
                            className="defaultPONumberText">
                            {intl.formatMessage({ id: 'create-project:default-po-number-text' })}
                        </small>
                        <small className="projectInstructionPOText">
                            {intl.formatMessage({ id: 'create-project:po-number-otherwise-text' })}
                        </small>
                    </>
                )}
                {errorText !== '' && (
                    <Alert
                        className="alert"
                        type={'error'}
                        message={errorText}
                        data-testid={createProjectDataLocators.createProjectError}
                        localStyle={'alert-withmargin'}
                    />
                )}

                {(canadaRestrictStateError !== '' || avsErrorText !== '') && (
                    <Alert
                        localStyle={'alert-withmargin'}
                        type={'error'}
                        data-testid={createProjectDataLocators.createProjectAddressError}
                        message={canadaRestrictStateError || avsErrorText}
                    />
                )}
                {addressChangedByAvs && !canadaRestrictStateError && (
                    <Alert
                        localStyle={'alert-withmargin'}
                        type={'warning'}
                        message={intl.formatMessage({ id: 'order-details:address-changed-notification-label' })}
                    />
                )}
                <Button
                    type="button"
                    onClick={onContinueHandler}
                    className="button button-primary button-block createProjectButton"
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                    data-testid={createProjectDataLocators.saveAndContinueCTA}
                    disabled={isCreateProjectLoading}>
                    {intl.formatMessage({ id: 'location-overlay:save-and-continue' })}
                </Button>
            </div>
        </div>
    );
};

export default ProjectInstructions;

ProjectInstructions.propTypes = {
    primaryContact: string,
    setPrimaryContact: func,
    accessNotes: string,
    setAccessNotes: func,
    poNumber: string,
    setPONumber: func,
    onContinueHandler: func,
    projectInstructionsError: object,
    setProjectInstructionsError: func,
    errorText: string,
    isCreateProjectLoading: bool,
    canadaRestrictStateError: string,
    addressChangedByAvs: bool,
    avsErrorText: string
};

ProjectInstructions.defaultProps = {
    primaryContact: '',
    setPrimaryContact: () => {},
    accessNotes: '',
    setAccessNotes: () => {},
    poNumber: '',
    setPONumber: () => {},
    onContinueHandler: () => {},
    projectInstructionsError: {},
    setProjectInstructionsError: () => {},
    errorText: '',
    isCreateProjectLoading: false,
    canadaRestrictStateError: '',
    addressChangedByAvs: false,
    avsErrorText: ''
};