import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { SHOW_GUEST_CHECKOUT_LOGIN } from '../../../aem-core-components/actions/constants';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import config from '../../App/config';
import Button from '../../global/atoms/button';
import CheckoutPageAlerts from '../checkoutPageAlert';
import './checkoutGuestLogin.scss';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { guestCheckoutDatalocators } from './dataLocators';
import { triggerEvents } from '../../../constants/analyticsConstants/triggerEvents';
import { logError } from '../../global/utils/logger';
import { ENV_CONFIG } from '../../../constants/envConfig';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Ecommerce';
import useAnalytics from '../../../hooks/useAnalytics';

const CheckoutGuestLogin = () => {
    const [{ cart }, dispatch] = useCartState();
    const intl = useIntl();
    const userType = useCheckUser();
    const { sendEventsForClick, sendEventsForUpdateVirtualPath, sendEventsForEcommerceAction } = useAnalyticsContext();
    const [{ payloadEcommerceActionAnalytics }] = useAnalytics();

    useEffect(() => {
        if (cart?.id) {
            const additionalPayload = {
                page_title: VARIABLE_CONFIG.EVENT_PAGE_TITLE.GUEST_CHECKOUT_AUTHENTICATION_PAGE,
                page_location: VARIABLE_CONFIG.EVENT_PAGE_LOCATIONS.GUEST_CHECKOUT_AUTHENTICATION_PAGE_LOCATION
            };
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_PAGE_VIEWED,
                payloadEcommerceActionAnalytics(),
                additionalPayload
            );
        }
    }, [cart?.id]);
    const ctaClickEvent = (linkName, linkType, linkLocation, linkURL) => {
        const additionalPayload = {
            link_name: linkName,
            link_type: linkType,
            link_location: linkLocation,
            link_destination: linkURL
        };
        sendEventsForEcommerceAction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CTA_CLICKED,
            payloadEcommerceActionAnalytics(),
            additionalPayload
        );
    };
    const continueAsGuestHandler = () => {
        try {
            triggerEvents(
                `${userType} select`,
                intl.formatMessage({ id: 'guest-checkout:continue-as-guest-cta' }),
                sendEventsForClick
            );
            ctaClickEvent(
                VARIABLE_CONFIG.EVENT_LABEL.CONTINUE_AS_GUEST,
                VARIABLE_CONFIG.TYPE.BUTTON,
                VARIABLE_CONFIG.EVENT_LINK_LOCATION.BODY,
                VARIABLE_CONFIG.EVENT_LINK_DESTINATION.CHECKOUT_PAGE
            );
        } catch (error) {
            logError(error, false, 'continueAsGuestHandler');
        }
        sendEventsForUpdateVirtualPath(
            `${VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_IDENTIFICATION_GUEST}`,
            `/${localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US}/${
                VARIABLE_CONFIG.VIRTUAL_PAGE_URL.IDENTIFICATION
            }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.GUEST}`
        );
        dispatch({
            type: SHOW_GUEST_CHECKOUT_LOGIN,
            value: false
        });
        localStorage.setItem('showGuestCheckoutLogin', false);
    };
    const signInHandler = () => {
        try {
            triggerEvents(
                `${userType} select`,
                intl.formatMessage({ id: 'guest-checkout:signin-cta' }),
                sendEventsForClick
            );
            ctaClickEvent(
                VARIABLE_CONFIG.EVENT_LABEL.SIGNIN,
                VARIABLE_CONFIG.TYPE.BUTTON,
                VARIABLE_CONFIG.EVENT_LINK_LOCATION.BODY,
                VARIABLE_CONFIG.EVENT_LINK_DESTINATION.SIGN_IN_PAGE
            );
        } catch (error) {
            logError(error, false, 'signInHandler');
        }
        localStorage.setItem('showGuestCheckoutLogin', false);
        sendEventsForUpdateVirtualPath(
            `${VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_IDENTIFICATION_LOGIN}`,
            `/${localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US}/${
                VARIABLE_CONFIG.VIRTUAL_PAGE_URL.IDENTIFICATION
            }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.LOGIN}`
        );
        window.location.href = config.pagePaths.signInHandler();
    };
    const createAccountHandler = () => {
        localStorage.setItem('showGuestCheckoutLogin', false);
        try {
            triggerEvents(`${userType} select`, VARIABLE_CONFIG.EVENT_LABEL.CREATE_ACCOUNT, sendEventsForClick);
            ctaClickEvent(
                VARIABLE_CONFIG.EVENT_LABEL.CREATE_A_SUNBELT_ACC,
                VARIABLE_CONFIG.TYPE.BUTTON,
                VARIABLE_CONFIG.EVENT_LINK_LOCATION.BODY,
                VARIABLE_CONFIG.EVENT_LINK_DESTINATION.CREATE_ACCOUNT
            );
        } catch (error) {
            logError(error, false, 'createAccountHandler');
        }
        sendEventsForUpdateVirtualPath(
            `${VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_IDENTIFICATION_ACCOUNT_CREATION}`,
            `/${localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US}/${
                VARIABLE_CONFIG.VIRTUAL_PAGE_URL.IDENTIFICATION
            }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.ACCOUNT_CREATION}`
        );
        window.location.href = config.pagePaths.createAccount;
    };
    return (
        <section className="block block--login">
            <div className="login__bg-wrap">
                <div className="login__bg-wrap-right">
                    <img
                        src={ENV_CONFIG.CheckoutImageSrc}
                        srcSet={ENV_CONFIG.CheckoutImageSrcset}
                        alt={ENV_CONFIG.CheckoutImageAlt}
                        className="checkoutguest-login-left-heroimage"
                    />
                </div>
                <div className="login__bg-wrap-left"></div>
            </div>
            <div className="container">
                <div className="row login__row">
                    <div className="col-md-9 col-lg-5">
                        <div className="login__card">
                            <h5
                                className="login__card-title"
                                data-testid={guestCheckoutDatalocators.guest_checkout_signin_heading_testid}>
                                {intl.formatMessage({ id: 'guest-checkout:title1' })}
                            </h5>
                            <p className="login__card-subtitle">
                                {intl.formatMessage({ id: 'guest-checkout:subheading1-1' })}{' '}
                                <b>{intl.formatMessage({ id: 'guest-checkout:subheading1-2' })}</b>{' '}
                                {intl.formatMessage({ id: 'guest-checkout:subheading1-3' })}
                            </p>
                            <Button
                                onClick={signInHandler}
                                type="button"
                                className={'button button-block button-primary login__card-signin'}
                                buttonAriaLabel={intl.formatMessage({ id: 'guest-checkout:signin-cta' })}
                                data-testid={guestCheckoutDatalocators.guest_checkout_signin_cta_testid}>
                                {intl.formatMessage({ id: 'guest-checkout:signin-cta' })}
                            </Button>
                            <Button
                                onClick={createAccountHandler}
                                type="button"
                                buttonAriaLabel={intl.formatMessage({ id: 'guest-checkout:create-account-cta' })}
                                className={
                                    'button button-block button-outline-primary button-pb0 login__card-createacc'
                                }
                                data-testid={guestCheckoutDatalocators.guest_checkout_create_acc_link_testid}>
                                {intl.formatMessage({ id: 'guest-checkout:create-account-cta' })}
                            </Button>
                        </div>
                        <div className="login__card login__card-guest">
                            <h5
                                className="login__card-title"
                                data-testid={guestCheckoutDatalocators.guest_checkout_guestcheckout_heading_testid}>
                                {intl.formatMessage({ id: 'guest-checkout:title2' })}
                            </h5>
                            <p className="login__card-subtitle">
                                {intl.formatMessage({ id: 'guest-checkout:subheading2-1' })}{' '}
                                <b>{intl.formatMessage({ id: 'guest-checkout:subheading2-2' })}</b>{' '}
                                {intl.formatMessage({ id: 'guest-checkout:subheading2-3' })}
                            </p>
                            <Button
                                onClick={continueAsGuestHandler}
                                type="button"
                                buttonAriaLabel={intl.formatMessage({ id: 'guest-checkout:continue-as-guest-cta' })}
                                className={'button button-block button-outline-primary'}
                                data-testid={guestCheckoutDatalocators.guest_checkout_continue_as_guest_cta_testid}>
                                {intl.formatMessage({ id: 'guest-checkout:continue-as-guest-cta' })}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <CheckoutPageAlerts />
        </section>
    );
};
export default CheckoutGuestLogin;
