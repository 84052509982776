export const createProjectDataLocators = {
    projectDetailsHeaderText: 'createJobSite_projectDetails_Header_txt',
    requiredFieldsSubText: 'createJobSite_requiredFields_Sub_txt',
    projectNamePlaceHolder: 'createJobSite_projectName_InputField',
    yourColleaguesAtAcmeText: 'createJobSite_yourColleagues_txt',
    streetAddressPlaceHolder: 'createJobSite_streetAddress_InputField',
    addressLine2PlaceHolder: 'createJobSite_addressLine_InputField',
    cityPlaceHolder: 'createJobSite_city_InputField',
    stateDropDown: 'createJobSite_state_InputField',
    zipCodePlaceHolder: 'createJobSite_zipCode_InputField',
    phoneNumberPlaceHolder: 'createJobSite_phoneNum_InputField',
    noStreetAddressPinaLocation: 'createjobsite_pinlocation_link',
    createProjectError: 'createJobSite_error',
    createProjectAddressError: 'createAddress_error',
    instructionsHeaderText: 'instructions_instruct_Header_txt',
    primaryContactHelperText: 'instructions_primaryCont_Helper_txt',
    primaryContactPlaceHolder: 'instructions_primaryCont_InputField',
    accessNotesHelperText: 'instructions_accessNotes_Helper_txt',
    accessNotesPlaceHolder: 'instructions_accessNotes_InputField',
    letUsKnowIfThereAreText: 'instructions_letUsKnow_txt',
    defaultPOnumberPlaceHolder: 'instructions_defaultPOnum_InputField',
    ifYouWantToUseText: 'instructions_ifYouWant_txt',
    saveAndContinueCTA: 'checkout_saveandcontinue_CTA',
    // Review duplicates data locators below
    reviewDuplicateHeading: 'reviewDuplicateHeading',
    youEnteredLabel: 'youenteredLabel',
    radioButton: 'radioButton',
    duplicateProjectName: 'duplicate_projectName',
    duplicateProjectAddress: 'duplicate_projectAddress',
    duplicatePossibleProjectDuplicates: 'duplicate_possibleProjectDuplicates',
    duplicateCreateProjectCta: 'duplicate_createProjectCta',
    duplicateUseExistingProjectCta: 'duplicate_useExistingProjectCta',
    duplicateReviewCloseIcon: 'duplicate_closeIcon'
};
