import React, { useEffect } from 'react';
import { bool, func, object, string } from 'prop-types';
import { useIntl } from 'react-intl';
import LocationAutocomplete from '../../../global/modules/location-autocomplete/LocationAutocomplete';
import Dropdown from '../../../global/atoms/dropdown/dropdown';
import classes from './createProjectAddress.css';
import Field from '../../../../aem-core-components/components/Field';
import Input from '../../../global/atoms/input/Input';
import PhoneNumber from '../../../global/modules/formFields/phoneNumber';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { ADDRESS_COMPONENTS } from '../../../../aem-core-components/components/Minicart/constants';
import { ADDRESS_COMPONENT_TYPE } from '../../../../aem-core-components/components/Minicart/roundtrip/Constants';
import { createProjectDataLocators } from '../dataLocators';
import { resetError } from '../utils/validateFunctions';
import { CHECKOUT_ADDRESS_ID, CHECKOUT_ADDRESS2_ID, CHECKOUT_STATE_ID } from '../../constants';
import { getLocationsDetailsByPlaceId } from '../../../global/modules/location-autocomplete/api/getLocations';
import isObjectEmpty from '../../../../aem-core-components/utils/isObjectEmpty';
import useComputeLocation from '../../../../hooks/useComputeLocation';
import Alert from '../../../global/atoms/alert/alert';
import { SET_CREDIT_NEWADDR_FLAG } from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import { determineCityComponent, getStateList, setPrefilledAddressForCreateProject } from '../../../global/utils/commonUtils';
import { useFilterState } from '../../../cap';

const CreateProjectAddress = props => {
    const required = true;
    const intl = useIntl();
    const userCompanyID = parseInt(localStorage.getItem('companyID')) || 1;
    const [{ }, dispatch] = useCartState();
    const [{viewCart, projectDetails}, filterDispatch] = useFilterState();

    const {
        streetAddress,
        setStreetAddress,
        selectedState,
        setSelectedState,
        selectedCity,
        setSelectedCity,
        selectedZip,
        setSelectedZip,
        selectedAddress2,
        setSelectedAddress2,
        selectedPhoneNumber,
        setSelectedPhoneNumber,
        createProjectAddressError,
        setCreateProjectAddressError,
        setValidPhoneNumber,
        submit,
        setSubmit,
        setIsManualAddress,
        canadaRestrictStateError,
        setAddressChangedByAvs,
        setErrorText,
        setSuggestedAddressList,
        setUserPrefilledAddress,
        userPrefilledAddress,
        isManualAddress
    } = props;

    const { cityAndZipValidationAddressComponent } = useComputeLocation();

    useEffect(() => {
        dispatch({
            type: SET_CREDIT_NEWADDR_FLAG,
            isCreditNewAddress: true
        });
    }, []);

    const handleAddress1Change = value => {
        setIsManualAddress(true);
        setStreetAddress(value);
        setErrorText('');
        setUserPrefilledAddress(value);
        resetError(setCreateProjectAddressError, 'address');
    };
    const handleCityChange = text => {
        setIsManualAddress(true);
        setSelectedCity(text.target.value);
        setErrorText('');
        resetError(setCreateProjectAddressError, text.target.name);
    };
    const handleStateChangeOption = value => {
        setIsManualAddress(true);
        setSelectedState(value);
        setErrorText('');
        resetError(setCreateProjectAddressError, 'state');
    };
    const handleZipChange = text => {
        setIsManualAddress(true);
        setSelectedZip(text.target.value);
        setErrorText('');
        resetError(setCreateProjectAddressError, text.target.name);
    };
    const handlePhoneNumberChange = text => {
        setSelectedPhoneNumber(text.target.value);
    };

    const onAutoSelectAddress = async selectedAddress => {
        const responseFromPlaceDetails = await getLocationsDetailsByPlaceId(selectedAddress);
        if (
            responseFromPlaceDetails?.error ||
            isObjectEmpty(responseFromPlaceDetails?.data?.result) ||
            !cityAndZipValidationAddressComponent(responseFromPlaceDetails?.data?.result)
        ) {
            setCreateProjectAddressError({
                ...createProjectAddressError,
                address: intl.formatMessage({ id: 'order-details:address-error-label' })
            });
        } else {
            setSelectedAddress2('');
            setCreateProjectAddressError({
                ...createProjectAddressError,
                address: '',
                city: '',
                state: '',
                zipcode: ''
            });
            if (!isEmpty(responseFromPlaceDetails?.data?.result)) {
                computeAddress(responseFromPlaceDetails?.data?.result);
            }
            setIsManualAddress(false);
            setAddressChangedByAvs(false);
        }
    };

    const determineTypeOfAddress = component => {
        const types = get(component, 'types', []);
        if (
            types.includes(ADDRESS_COMPONENTS.LOCALITY) ||
            types.includes(ADDRESS_COMPONENTS.AL3) ||
            types.includes(ADDRESS_COMPONENTS.AL2)
        ) {
            return ADDRESS_COMPONENT_TYPE.CITY;
        }
        if (types.includes(ADDRESS_COMPONENTS.AL1)) {
            return ADDRESS_COMPONENT_TYPE.STATE;
        }

        if (types.includes(ADDRESS_COMPONENTS.POSTAL)) {
            return ADDRESS_COMPONENT_TYPE.POSTAL_CODE;
        }

        if (types.includes(ADDRESS_COMPONENTS.STREET_NO)) {
            return ADDRESS_COMPONENT_TYPE.STREET;
        }
        if (types.includes(ADDRESS_COMPONENTS.COUNTRY)) {
            return ADDRESS_COMPONENT_TYPE.COUNTRY;
        }
        if (types.includes(ADDRESS_COMPONENTS.ROUTE)) {
            return ADDRESS_COMPONENT_TYPE.ROUTE;
        }
        if (types.includes(ADDRESS_COMPONENTS.SUBLOCALITY)) {
            return ADDRESS_COMPONENT_TYPE.SUBLOCALITY;
        }
    };
    const computeAddress = details => {
        const addressComponents = get(details, 'addressComponents', []);
        let street = '';
        let neighborhood = '';
        let routeAddress = '';
        let address = '';
        const combineAddr = () => {
            let addr = '';
            if (neighborhood && !street) {
                addr = neighborhood;
            }
            if (street) {
                addr = street;
            }
            address = addr;
        };
        let citySelected = false;
        if (addressComponents?.length > 0) {
            addressComponents?.map(component => {
                const type = determineTypeOfAddress(component);
                switch (type) {
                    case ADDRESS_COMPONENT_TYPE.CITY: {
                        const cityComp = determineCityComponent(addressComponents);
                        setSelectedCity(cityComp);
                        citySelected = true;
                        break;
                    }
                    case ADDRESS_COMPONENT_TYPE.SUBLOCALITY: {
                        if (!citySelected) {
                            setSelectedCity(component.longName);
                        }
                        break;
                    }

                    case ADDRESS_COMPONENT_TYPE.STATE: {
                        handleStateChangeOption(component.shortName);
                        break;
                    }

                    case ADDRESS_COMPONENT_TYPE.POSTAL_CODE: {
                        setSelectedZip(component.longName);
                        break;
                    }
                    // case ADDRESS_COMPONENT_TYPE.AREA_L2: {
                    //     setSelectedAddress2(component.longName);
                    //     break;
                    // }

                    case ADDRESS_COMPONENT_TYPE.STREET: {
                        street = component.longName;
                        combineAddr();
                        break;
                    }

                    case ADDRESS_COMPONENT_TYPE.ROUTE: {
                        routeAddress = component.longName;
                        combineAddr();
                        break;
                    }

                    case ADDRESS_COMPONENT_TYPE.NEIGHBORHOOD: {
                        neighborhood = component.longName;
                        combineAddr();
                        break;
                    }

                    default:
                        break;
                }
            });
        }
    };

    const handleStateOrProvinceLabel = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-province' })
            : intl.formatMessage({ id: 'cart:form-state' });
    };
    const handleZipOrPostalCodeLabel = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-postalcode' })
            : intl.formatMessage({ id: 'cart:form-zipcode' });
    };
    const handleStateOrProvincePlaceholder = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-province-placeholder' })
            : intl.formatMessage({ id: 'cart:form-state-placeholder' });
    };
    const handleZipOrPostalCodePlaceholder = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'cart:form-postal-placeholder' })
            : intl.formatMessage({ id: 'cart:form-zip-placeholder' });
    };

    const handleAddress2Change = val => {
        setSelectedAddress2(val.target.value);
        setIsManualAddress(true);
        setErrorText('');
    };

    return (
        <div className={classes.newProjectAddressRoot}>
            <div className={classes.headerTitle}>
                <h6
                    tabIndex={0}
                    aria-label={`${intl.formatMessage({ id: 'create-project:project-address' })}
                    ${intl.formatMessage({ id: 'form-landmark' })}`}>
                    {intl.formatMessage({ id: 'create-project:project-address' })}
                </h6>
            </div>
            <div className={classes.addressSectionBody}>
                <Field
                    errorMsg={createProjectAddressError?.address && !canadaRestrictStateError}
                    label={intl.formatMessage({ id: 'cart:form-address-line-1' })}
                    htmlFor="address">
                    <LocationAutocomplete
                        onSelectAddress={selectedAddress => onAutoSelectAddress(selectedAddress)}
                        handleLocationChange={handleAddress1Change}
                        selectedAddress={val => {
                            setStreetAddress(val);
                        }}
                        prefilledAddress={userPrefilledAddress}
                        showResetBtn={false}
                        placeholderText={intl.formatMessage({ id: 'cart:form-address-placeholder' })}
                        buttonTestId={createProjectDataLocators.streetAddressPlaceHolder}
                        errorMsg={createProjectAddressError?.address && !canadaRestrictStateError}
                        inputAriaLabel={intl.formatMessage({ id: 'cart:form-address-placeholder' })}
                        inputlabel={CHECKOUT_ADDRESS_ID}
                        isManualAddress={isManualAddress}
                    />
                    {createProjectAddressError?.address && !canadaRestrictStateError ? (
                        <span className="error_input" tabIndex={0} aria-live="assertive" role="alert">
                            {createProjectAddressError?.address}
                        </span>
                    ) : (
                        ''
                    )}
                </Field>
                {/* <div className={classes.createNewBtnView}>
                    <button
                        data-testid={createProjectDataLocators.noStreetAddressPinaLocation}
                        className={classes.pinLocationButton}
                        onClick={onPressCreate}>
                        <PinGreen />
                        {intl.formatMessage({ id: 'create-project:pin-a-location' })}
                    </button>
                </div> 
                    to be implemented later
                */}
                <Field label={intl.formatMessage({ id: 'cart:form-address-line-2' })} htmlFor="address2">
                    <input
                        id={CHECKOUT_ADDRESS2_ID}
                        className="cmp-Field__field__input"
                        placeholder={intl.formatMessage({ id: 'optional' })}
                        field="address2"
                        onChange={handleAddress2Change}
                        data-testid={createProjectDataLocators.addressLine2PlaceHolder}
                        value={selectedAddress2}
                    />
                </Field>
                <Input
                    name="city"
                    label={intl.formatMessage({ id: 'cart:form-city' })}
                    value={selectedCity}
                    handleInputChange={handleCityChange}
                    placeholder={intl.formatMessage({ id: 'cart:form-city-placeholder' })}
                    dataTestId={createProjectDataLocators.cityPlaceHolder}
                    errorMsg={createProjectAddressError.city}
                    inputAriaLabel={intl.formatMessage({ id: 'cart:form-city-placeholder' })}
                />
                <div className={classes.stateAndZip}>
                    <div className={classes.state}>
                        <Field
                            errorMsg={createProjectAddressError?.state || canadaRestrictStateError}
                            label={handleStateOrProvinceLabel()}
                            htmlFor="state">
                            <Dropdown
                                options={getStateList()}
                                onChangeDropdownValue={handleStateChangeOption}
                                selectedState={selectedState}
                                placeholderText={handleStateOrProvincePlaceholder()}
                                dropDownTestId={createProjectDataLocators.stateDropDown}
                                isError={createProjectAddressError?.state || canadaRestrictStateError}
                                className={classes.selectStateClass}
                                inputlabel={CHECKOUT_STATE_ID}
                            />
                            {createProjectAddressError?.state ? (
                                <span className="error_input" tabindex="0" aria-live="assertive" role="alert">
                                    {createProjectAddressError?.state}
                                </span>
                            ) : (
                                ''
                            )}
                        </Field>
                    </div>
                    <div className={classes.zip}>
                        <Input
                            name="zipcode"
                            label={handleZipOrPostalCodeLabel()}
                            value={selectedZip}
                            handleInputChange={handleZipChange}
                            placeholder={handleZipOrPostalCodePlaceholder()}
                            dataTestId={createProjectDataLocators.zipCodePlaceHolder}
                            errorMsg={createProjectAddressError.zipcode}
                            inputAriaLabel={intl.formatMessage({ id: 'cart:form-zip-placeholder' })}
                        />
                    </div>
                </div>
                {canadaRestrictStateError && (
                    <Alert localStyle={`alert-withbottommargin`} type={'error'} message={canadaRestrictStateError} />
                )}
                <PhoneNumber
                    label={intl.formatMessage({ id: 'create-project:phone-number' })}
                    value={selectedPhoneNumber}
                    handleInputChange={handlePhoneNumberChange}
                    placeholder={intl.formatMessage({ id: 'create-project:phone-number-placeholder' })}
                    dataTestId={createProjectDataLocators.phoneNumberPlaceHolder}
                    onValidate={setValidPhoneNumber}
                    required={required}
                    submit={submit}
                    setSubmit={setSubmit}
                    inputAriaLabel={intl.formatMessage({ id: 'create-project:phone-number-placeholder' })}
                />
            </div>
        </div>
    );
};
export default CreateProjectAddress;

CreateProjectAddress.propTypes = {
    streetAddress: string,
    setStreetAddress: func,
    selectedState: string,
    setSelectedState: func,
    selectedCity: string,
    setSelectedCity: func,
    selectedZip: string,
    setSelectedZip: func,
    selectedAddress2: string,
    setSelectedAddress2: func,
    selectedPhoneNumber: string,
    setSelectedPhoneNumber: func,
    createProjectAddressError: object,
    setCreateProjectAddressError: func,
    setValidPhoneNumber: func,
    submit: bool,
    setSubmit: func,
    setIsManualAddress: func,
    canadaRestrictStateError: string,
    setAddressChangedByAvs: func,
    setErrorText: func
};

CreateProjectAddress.defaultProps = {
    streetAddress: '',
    setStreetAddress: () => {},
    selectedState: '',
    setSelectedState: () => {},
    selectedCity: '',
    setSelectedCity: () => {},
    selectedZip: '',
    setSelectedZip: () => {},
    selectedAddress2: '',
    setSelectedAddress2: () => {},
    selectedPhoneNumber: '',
    setSelectedPhoneNumber: () => {},
    createProjectAddressError: {},
    setCreateProjectAddressError: () => {},
    setValidPhoneNumber: () => {},
    submit: false,
    setSubmit: () => {},
    setIsManualAddress: () => {},
    canadaRestrictStateError: '',
    setAddressChangedByAvs: () => {},
    setErrorText: () => {}
};
