import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useCookieValue } from '../../../../aem-core-components/utils/hooks';
import { basePathForPunchout } from '../../../global/utils/commonUtils';
import useReservation from '../../hooks/useReservation';
import TransferOrderConfirmationScreen from '../../../global/modules/TransferOrderConfirmationScreen/TransferOrderConfirmationScreen';
import { checkoutDatalocator } from '../../../checkout/checkoutAndOrderSummary/dataLocators';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { EVENT_P2P_NAMES_CONFIG } from '../../../../constants/analyticsConstants/ProcureToPay';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import './quoteConfirmation.scss';

const TransmitConfirmation = ({ confirmationType, transmittedQuoteId, salesforceQuoteId }) => {
    const intl = useIntl();
    const [{ resetDetailsAfterQuoteSaveOrTransmit }] = useReservation();
    const [punchoutRedirectURL] = useCookieValue('punchout_redirect_url');
    const { sendEventsForP2PApplication, sendEventsForP2PCtaClick } = useAnalyticsContext();

    useEffect(() => {
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.SUCCESSFULLY_TRANSMITTED_QUOTE_ID);
        resetDetailsAfterQuoteSaveOrTransmit();
        sendEventsForP2PApplication(
            EVENT_P2P_NAMES_CONFIG.P2P_QUOTE_TRANSMITTED,
            {
                quote_id: salesforceQuoteId, // salesforce ID
                transmitted_id: transmittedQuoteId.slice(2) // rentalman ID
            }
        );
    }, []);

    const getConfirmationTitle = () => {
        const formattedConfirmationType = confirmationType?.replace(/'/g, '').toLowerCase();
        return intl.formatMessage({ id: `createQuote:transmit-${formattedConfirmationType}` });
    };

    const renderDetail = () => {
        return (
            <div className="details_container" tabIndex={0}>
                <span>{intl.formatMessage({ id: 'createQuote:transmit-confirmation-message' })}</span>
                <div className="number_container">
                    <h5 data-testid={checkoutDatalocator?.reservation_Number_testid}>{transmittedQuoteId}</h5>
                </div>
                <span
                    className="success-delivery-message"
                    data-testid={checkoutDatalocator?.reservation_successSummary_msg_testid}>
                    {intl.formatMessage({ id: 'createQuote:transmit-success-finilize-rental' })}
                </span>
            </div>
        );
    };

    const redirectToTCportal = () => {
        sendEventsForP2PCtaClick(
            EVENT_P2P_NAMES_CONFIG.P2P_CTA_CLICKED,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_NAME_BACK_TO_PROCUREMENT,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_TYPE_BUTTON,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_LOCATION_BODY,
            EVENT_P2P_NAMES_CONFIG.P2P_LINK_DESTINATION_BACK_TO_PROCUREMENT
        );
        window.parent.location.href = punchoutRedirectURL;
    };

    return (
        <TransferOrderConfirmationScreen
            confirmationType={confirmationType}
            handlePrimaryButton={redirectToTCportal}
            renderDetail={renderDetail}
            primaryButtonLabel={intl.formatMessage({ id: 'createQuote:transmit-back-to-procurement-system-cta' })}
            confirmationTitle={getConfirmationTitle()}
            primaryButtonDataTestId={checkoutDatalocator.reservation_view_rentals_cta_testid}
        />
    );
};

export default React.memo(TransmitConfirmation);

TransmitConfirmation.propTypes = {
    confirmationType: PropTypes.string,
    transmittedQuoteId: PropTypes.string
};

TransmitConfirmation.defaultProps = {
    confirmationType: null,
    transmittedQuoteId: ''
};
