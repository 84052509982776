import moment from 'moment';
import { func, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { mktoLeadCreation, mktoLogin } from '../../../../../api/mktoLeadCreation';
import {
    SET_IS_RESERVATION_CLICKED,
    SET_SUBMIT_RESERVATION,
    SET_VIEW_CART_FIELDS
} from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import { DELIVERY_CHARGE_FLAG, VIEW_CART } from '../../../../aem-core-components/components/Minicart/constants';
import { useUserContext } from '../../../../aem-core-components/context/UserContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import { getProducts } from '../../../../constants/analyticsConstants/getProducts';
import { triggerEvents } from '../../../../constants/analyticsConstants/triggerEvents';
import { STORAGE_CONFIG } from '../../../../constants/storageConfig';
import { USER_TYPE } from '../../../../constants/userDetailsConstants';
import useAnalytics from '../../../../hooks/useAnalytics';
import useUserData from '../../../../hooks/useUserData';
import { useCheckDeliveryCharge } from '../../../../hooks/useCheckDeliveryCharge';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import useCheckout from '../../../../hooks/useCheckout';
import { useDidMount } from '../../../../hooks/useDidMount';
import useMarketo from '../../../../hooks/useMarketo';
import { useSunbeltLocation } from '../../../../hooks/useSunbeltLocation';
import config from '../../../App/config';
import { useFilterState } from '../../../cap';
import '../../../checkout/checkout.scss';
import { getAddPaymentAPI } from '../../../checkout/checkoutAndOrderSummary/api/getCheckoutAPIs';
import { cardTypeMapper } from '../../../checkout/checkoutPayment/paymentHelper';
import useReservation from '../../../checkout/hooks/useReservation';
import {
    hasDateEllapsed,
    hasTimeEllapsed
} from '../../../checkout/orderDetailsCheckout/utils/editOrderDetailsFunctions';
import { clearLocalStorage, getRentalDuration, getTimeFromCity, getURLParams } from '../../utils/commonUtils';
import { deleteCookie } from '../../../../aem-core-components/utils/cookieUtils';
import { isValidString, logError } from '../../utils/logger';
import ButtonWithTnCCheckbox from '../ButtonWithTnCCheckbox/ButtonWithTnCCheckbox';

export default function SubmitReservationButton({ customClass, currentOffSet, handleStepChange, currentStep }) {
    const intl = useIntl();
    const [isChecked, setIsChecked] = useState(false);
    const [isMarketingChecked, setIsMarketingChecked] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [
        {
            userInfo,
            howToGetYourOrderDetails,
            timeZoneID,
            cart,
            consumables,
            guestUserDetails,
            paymentData,
            paymentTokenData,
            isLoading,
            orderSummaryDetails
        },
        dispatch
    ] = useCartState();
    const [{ viewCart, projectDetails, startDate, endDate }, filterDispatch] = useFilterState();
    const { isInStorePickup } = viewCart;
    const [userState, { setCustomerCart }] = useUserContext();
    const { userProfile } = userState;
    const { fulfillmentPercent, isOpenOnDateOut, isLastResortBranch } = howToGetYourOrderDetails?.selectedStoreDetails;
    const {
        sendEventsForClick,
        sendEventsForEcommercePurchase,
        sendEventsForEcommerceCheckout,
        sendEventsForUpdateVirtualPath,
        sendEventsForEcommerceAction
    } = useAnalyticsContext();
    const sunbeltLocation = useSunbeltLocation();
    const [{ payloadEcommerceActionAnalytics }] = useAnalytics();
    const [{ getCartDetailsForMarketo }] = useMarketo();
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const { rentalAmount, deliveryPickUpCharges, fuelCharges, rppCharges, salesTax, allOtherCharges } =
        orderEstimates?.totals || {};
    const envFeeObj = orderEstimates?.miscCharges?.find(item => item.type.indexOf('ENVIRONMENTAL') > -1);
    const { charge = 0 } = envFeeObj || {};
    const [{ submitReservation, getConfirmationMessage }] = useReservation();
    const { checkoutStepPayload, getCheckoutStepDetails, getCustomerId } = useCheckout();
    const userType = useCheckUser();
    const didMount = useDidMount();
    const params = getURLParams();
    const deliveryChargeFlag = useCheckDeliveryCharge();
    const [{ clearProjectDetailCookies }] = useUserData();

    const onCheckboxClick = () => {
        try {
            triggerEvents(
                VARIABLE_CONFIG.EVENT_ACTION.SELECT_TERMS_AND_CONDITIONS,
                VARIABLE_CONFIG.EVENT_LABEL.CHECKED_TERMS_AND_CONDITIONS,
                sendEventsForClick
            );
        } catch (error) {
            logError(error, false, 'triggerEvents');
        }
        setErrorText('');
        setIsChecked(prev => !prev);
    };
    const onMarketingCheckBoxClick = () => {
        setIsMarketingChecked(prev => !prev);
    };

    useEffect(() => {
        const datesObj = {
            startDate,
            endDate
        };
        if (didMount && !sessionStorage.getItem('checkoutSummaryFlag')) {
            sessionStorage.setItem('checkoutSummaryFlag', true);
            sendEventsForEcommerceCheckout(6, getProducts(cart, datesObj, false, consumables));
        } else if (!didMount) {
            sessionStorage.removeItem('checkoutSummaryFlag');
        }
    }, [didMount]);

    const isMktoCallRequired = () => {
        if (!isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISUSERPRESUBSCRIBED))) {
            if (parseInt(localStorage.getItem('companyID')) === 2) {
                if (isMarketingChecked) {
                    //Canada user checked the box
                    return true;
                }
            } else {
                // US user
                return true;
            }
        }
        return false;
    };
    //this function is called when we click on
    //submit reservation CTA
    const onPressSubmit = async () => {
        const viewCartObj = {
            startDate,
            endDate,
            isInStorePickup: viewCart?.isInStorePickup
        };
        const additionalPayload = {
            link_name: VARIABLE_CONFIG.EVENT_LABEL.SAVE_AND_CONTINUE,
            link_type: VARIABLE_CONFIG.TYPE.BUTTON,
            ...getCheckoutStepDetails({ SUBMIT_RESERVATION: 'SUBMIT_RESERVATION' }, currentStep, true)
        };
        sendEventsForEcommerceAction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_SUBMITTED,
            payloadEcommerceActionAnalytics(),
            additionalPayload
        );
        try {
            dispatch({ type: SET_IS_RESERVATION_CLICKED, key: 'isReservationClicked', value: true });
            dispatch({ type: 'beginLoading' });
            if (userInfo?.isAuthenticatedUser) {
                let currentOffSet = getTimeFromCity(timeZoneID);
                if (
                    !hasDateEllapsed(currentOffSet, startDate) &&
                    !hasTimeEllapsed(viewCartObj, howToGetYourOrderDetails, timeZoneID, cart)
                ) {
                    if (userType === USER_TYPE.CASH && params.CUSTOM2 === 'FALSE') {
                        async function fetchAddPaymentAPI() {
                            try {
                                await getAddPaymentAPI(false);
                            } catch (err) {
                                logError(err, false, 'fetchAddPaymentAPI');
                                dispatch({
                                    type: SET_IS_RESERVATION_CLICKED,
                                    key: 'isReservationClicked',
                                    value: false
                                });
                            }
                        }
                        fetchAddPaymentAPI();
                    }
                    if (isMktoCallRequired()) {
                        //if user is not presubscribed AND  US user or if Canada user and checked the box
                        callMktoLeads(true);
                    }
                    await processReservation();
                }
            } else {
                if (isChecked) {
                    let currentOffSet = getTimeFromCity(timeZoneID);
                    if (
                        !hasDateEllapsed(currentOffSet, startDate) &&
                        !hasTimeEllapsed(viewCartObj, howToGetYourOrderDetails, timeZoneID, cart)
                    ) {
                        callMktoLeads(false);
                        await processReservation();
                    }
                } else {
                    dispatch({ type: 'endLoading' });
                    dispatch({ type: SET_IS_RESERVATION_CLICKED, key: 'isReservationClicked', value: false });
                    setErrorText(intl.formatMessage({ id: 'terms&conditions:error' }));
                    return;
                }
            }
            dispatch({ type: 'endLoading' });
            dispatch({ type: SET_IS_RESERVATION_CLICKED, key: 'isReservationClicked', value: false });
            handleStepChange(currentStep + 1);
        } catch (err) {
            dispatch({ type: SET_IS_RESERVATION_CLICKED, key: 'isReservationClicked', value: false });
            dispatch({ type: 'endLoading' });
            logError(err, false, 'onPressSubmit ');
        }
    };

    //This is the API call for creating reservation
    //and on the basis of API response we further
    //perform next operations
    const processReservation = async () => {
        try {
            clearInterval(viewCart?.checkPCInterval);
            filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.CHECK_PC_INTERVAL, value: 0 });
            filterDispatch({ type: SET_VIEW_CART_FIELDS, key: VIEW_CART.SHOW_INACTIVE_PC_ALERT, value: false });
            const { payload, data, error } = await submitReservation();
            if (userInfo.isAuthenticatedUser) {
                const { loginToken, accessToken } = JSON.parse(localStorage.getItem('user-login'));
                clearLocalStorage([STORAGE_CONFIG.LOCAL_STORAGE.FIRSTNAME]);
                const user = { loginToken, accessToken };
                localStorage.setItem('user-login', JSON.stringify(user));
            }
            localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.FOURHOURRENTALS);
            localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE);
            localStorage.removeItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE);
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE);
            if (data) {
                if (isMarketingChecked && userInfo.isAuthenticatedUser) {
                    sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISUSERPRESUBSCRIBED, true);
                }
                dispatch({
                    type: SET_SUBMIT_RESERVATION,
                    key: 'refNumber',
                    value: parseInt(data?.createReservation?.data?.oms_order_id)
                });
                mktoLeadCreation(getCartDetailsForMarketo(data?.createReservation?.data?.oms_order_id));
                if (!data?.createReservation?.data?.oms_order_id) {
                    logError(data, true, 'processReservation ');
                } else {
                    setCustomerCart('', 0);
                    deleteCookie(STORAGE_CONFIG.COOKIES.CIF_MAGENTOTOKEN);
                    sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.IS_CREDIT_FULFILLMENT_SET);
                    dispatch({ type: 'reset' });
                    localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CARTTOTALQUANTITY, 0);
                    if(userType === USER_TYPE.CREDIT) {
                        clearProjectDetailCookies();
                    }
                    onSuccess(payload, data);
                }
            } else if (error) {
                logError(error, true, 'processReservation');
            }
        } catch (err) {
            logError(err, true, 'processReservation');
        }
    };

    //to check if selected startDate is in next 24 hours or not
    const isIn24Hrs = (selectedStartDate, currentOffSet) => {
        let formattedCurrentOffSet = moment(currentOffSet);
        let formattedSelectedStartDate = moment(selectedStartDate);
        formattedSelectedStartDate = formattedSelectedStartDate.set({
            hour: formattedCurrentOffSet.get('hour'),
            minute: formattedCurrentOffSet.get('minute'),
            second: formattedCurrentOffSet.get('second'),
            millisecond: 0
        });
        const timeDiff = formattedSelectedStartDate.diff(formattedCurrentOffSet, 'seconds');
        if (timeDiff <= 86400) {
            return true;
        } else return false;
    };

    const getDeliveryFee = payload => {
        if (deliveryChargeFlag === DELIVERY_CHARGE_FLAG.TBD) {
            return DELIVERY_CHARGE_FLAG.TBD;
        }
        return payload?.deliveryCharge;
    };

    const onSuccess = (payload, data) => {
        const datesObj = {
            startDate,
            endDate
        };
        dispatch({ type: SET_SUBMIT_RESERVATION, key: 'isSubmitReservationScreenVisible', value: true });
        try {
            sendEventsForEcommerceCheckout(7, getProducts(cart, datesObj, false, consumables));
            sendEventsForEcommercePurchase(
                payload?.companyId === '2' ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA : VARIABLE_CONFIG.CURRENCY_CODE.USA,
                data?.createReservation?.data?.oms_order_id,
                parseFloat(orderSummaryDetails?.estimatedSubtotal),
                salesTax,
                rentalAmount + charge,
                parseFloat(allOtherCharges).toFixed(2),
                payload?.optOutRPP === 'Y' ? rppCharges : 0,
                payload?.optOutFuelCharge === 'Y' ? fuelCharges : 0,
                charge,
                getDeliveryFee(payload),
                deliveryPickUpCharges,
                payload?.zip,
                payload?.zip,
                userType !== USER_TYPE.CREDIT
                    ? cardTypeMapper(paymentData?.selectedPaymentDetails?.paymentType)?.toLowerCase() ||
                          paymentTokenData?.ccBrand
                    : VARIABLE_CONFIG.ACCOUNT_PAYMENT_TYPE.CREDIT,
                payload?.deliveryCharge === 0
                    ? VARIABLE_CONFIG.RENTAL_METHOD.PICKUP
                    : VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY,
                getConfirmationMessage().message,
                getRentalDuration(startDate, endDate),
                sunbeltLocation,
                getProducts(cart, datesObj, false, consumables)
            );
            sendEventsForUpdateVirtualPath(
                VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CONFIRMATION,
                `/${
                    localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US
                }/${VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CONFIRMATION}`
            );
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STEP_COMPLETED,
                payloadEcommerceActionAnalytics(),
                checkoutStepPayload({ SUBMIT_RESERVATION: 'SUBMIT_RESERVATION' }, currentStep, 0, true)
            );
        } catch (error) {
            logError(error, false, 'onSuccess');
        }
    };
    const triggerEvent = () => {
        triggerEvents(
            VARIABLE_CONFIG.EVENT_ACTION.SELECT_TERMS_AND_CONDITIONS,
            intl.formatMessage({ id: 'terms&conditions:label' }),
            sendEventsForClick
        );
    };

    //send details to marketo to subscribe feeds
    const callMktoLeads = async isAuthenticatedUser => {
        let mktoLoginPayload;
        if (isAuthenticatedUser) {
            mktoLoginPayload = {
                email: userProfile?.email || '',
                oktaId: userProfile?.oktaUserId || '',
                firstName: userProfile?.firstName || '',
                lastName: userProfile?.lastName || '',
                phone: userProfile?.phone || '',
                address: userProfile?.address1 || '',
                city: userProfile?.city || '',
                state: userProfile?.state || '',
                country: localStorage.getItem('companyID') == 2 ? 'CA' : 'US',
                zipCode: userProfile?.zip || '',
                unsubscribed: false
            };
        } else {
            mktoLoginPayload = {
                email: guestUserDetails?.email || '',
                oktaId: '',
                firstName: guestUserDetails?.firstName || '',
                lastName: guestUserDetails?.lastName || '',
                phone: guestUserDetails?.phoneNumber || '',
                address: viewCart?.location,
                city: viewCart?.jobSiteCity,
                state: viewCart?.jobSiteState,
                country: localStorage.getItem('companyID') == 2 ? 'CA' : 'US',
                zipCode: viewCart?.jobSiteZip,
                lastWebLogin: moment(new Date()).format(),
                unsubscribed: localStorage.getItem('companyID') == 2 ? !guestUserDetails?.marketoFeed : false
            };
        }
        try {
            const { data, error } = await mktoLogin(mktoLoginPayload);
            if (error) {
                logError(error, false);
            }
        } catch (err) {
            logError(err, false);
        }
    };

    //renders TnC checkbox for guest user
    const renderLabel = () => {
        if (!userInfo.isAuthenticatedUser) {
            return (
                <span className="tnC">
                    {intl.formatMessage({ id: 'terms&conditions:prefix' })}{' '}
                    <span onClick={triggerEvent}>
                        <a
                            href={
                                parseInt(localStorage.getItem('companyID')) === 2
                                    ? config?.pagePaths?.tncCA
                                    : config?.pagePaths?.tncUS
                            }
                            target="_blank"
                            className="highlighted_tnC">
                            {intl.formatMessage({ id: 'terms&conditions:label' })}
                        </a>
                    </span>
                    .
                </span>
            );
        } else {
            return (
                <span className="tnC">
                    {intl.formatMessage({ id: 'marketing-opt-in-message' })}{' '}
                    <span>
                        <a href={config?.pagePaths?.privacyPolicyCA} target="_blank" className="highlighted_tnC">
                            {intl.formatMessage({ id: 'privacy-policy' })}
                        </a>
                    </span>
                    .
                </span>
            );
        }
    };
    if (!userInfo.isAuthenticatedUser) {
        return (
            <>
                <ButtonWithTnCCheckbox
                    customClass={customClass}
                    isCheckboxVisible={true}
                    isChecked={isChecked}
                    onCheckboxClick={onCheckboxClick}
                    checkboxLabel={renderLabel}
                    buttonTitle={intl.formatMessage({ id: 'checkout:submitReservation' })}
                    handleButtonClick={onPressSubmit}
                    errorText={errorText}
                    disableClick={isLoading && 'clicks-disabled'}
                />
            </>
        );
    } else {
        return (
            <>
                <ButtonWithTnCCheckbox
                    customClass={customClass}
                    isCheckboxVisible={
                        !isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISUSERPRESUBSCRIBED)) &&
                        parseInt(localStorage.getItem('companyID')) === 2
                    }
                    isChecked={isMarketingChecked}
                    onCheckboxClick={onMarketingCheckBoxClick}
                    checkboxLabel={renderLabel}
                    buttonTitle={intl.formatMessage({ id: 'checkout:submitReservation' })}
                    handleButtonClick={onPressSubmit}
                    errorText={''}
                    disableClick={isLoading && 'clicks-disabled'}
                />
            </>
        );
    }
}

SubmitReservationButton.propsType = {
    customClass: string,
    handleStepChange: func,
    currentStep: number
};

SubmitReservationButton.defaultProps = {
    customClass: '',
    handleStepChange: () => {},
    currentStep: 1
};
