import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import AccessoryItemDesc from './AccessoryItemDesc';
import AccessoriesForRent from './AccessoriesForRent';
import AddonsForPurchase from './AddonsForPurchase';
import RentalHeader from './RentalHeader';
import RentalFooter from './RentalFooter';
import ViewAll from '../../../global/modules/viewAll';
import { useCartState } from '../../../../aem-core-components/components/Minicart/cartContext';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import '../accessoriesAndAddons.scss';
import { ENV_CONFIG } from '../../../../constants/envConfig';
import { FORCE_ITEM } from '../../constants';

const RentalEquipment = ({
    selectedItems,
    selectedItemsDetails,
    addAccessory,
    addAddon,
    saveChanges,
    handleCloseClick
}) => {
    const [{ consumables }] = useCartState();
    const intl = useIntl();
    const [isAddonViewAll, setIsAddonViewAll] = useState(false);
    const maxAddOnsLimit = parseInt(ENV_CONFIG.MAXADDONS);
    let currentCount = 0;
    const renderItem = item => {
        const filteredSpecGroups = item?.specGroups?.reduce((acc, item) => {
            const filteredItems = item?.items?.filter(item => item.forceItem !== FORCE_ITEM.YES);
            if (filteredItems.length > 0) {
                acc.push({
                    ...item,
                    items: filteredItems
                });
            }
            return acc;
        }, []);

        return (
            <>
                {filteredSpecGroups?.map((consumableChild, index) => {
                    if (currentCount < maxAddOnsLimit || isAddonViewAll) {
                        currentCount++;

                        return (
                            <div className="rental-addon-container" key={index.toString()}>
                                <AddonsForPurchase
                                    selectedItems={selectedItems}
                                    consumableGroup={consumableChild}
                                    addAddon={addAddon}></AddonsForPurchase>
                            </div>
                        );
                    }
                })}
            </>
        );
    };

    const renderAccessories = () => {
        const rentables = consumables?.selectedRentalEquipment?.equipmentItems?.rentables?.reduce((acc, item) => {
            if (item.forceItem !== FORCE_ITEM.YES) {
                acc.push(item);
            }
            return acc;
        }, []);
        return (
            <>
                {rentables?.length > 0 && (
                    <>
                        <h6
                            className="modal-rental-equipment-header"
                            data-testid={checkoutDatalocator.checkout_rental_equipment_accessories_heading}>
                            {intl.formatMessage({ id: 'checkout:rental-equipments-accessories-header' })}
                        </h6>
                        <div className="rental-accessories-container">
                            <AccessoriesForRent
                                selectedItems={selectedItems}
                                rentables={rentables}
                                addAccessory={addAccessory}></AccessoriesForRent>
                        </div>
                    </>
                )}
            </>
        );
    };

    const renderAddons = () => {
        let isAddonsAvailable = false;
        const addOns = consumables?.selectedRentalEquipment?.equipmentItems?.consumables?.reduce((acc, item) => {
            const filteredSpecGroups = item.specGroups
                .map(specGroup => {
                    const filteredItems = specGroup.items.filter(item => item.forceItem !== FORCE_ITEM.YES);
                    return {
                        ...specGroup,
                        items: filteredItems
                    };
                })
                .filter(specGroup => specGroup.items.length > 0);
            if (filteredSpecGroups.length > 0) {
                acc.push({
                    ...item,
                    specGroups: filteredSpecGroups
                });
            }
            return acc;
        }, []);

        for (let index = 0; index < addOns?.length; index++) {
          if(addOns[index]?.specGroups?.length > 0) {
            isAddonsAvailable = true;
            break;
          }
        }
        return (
            <>
                {isAddonsAvailable && (
                    <>
                        <h6
                            className="modal-rental-equipment-header"
                            data-testid={checkoutDatalocator.checkout_rental_equipment_addons_heading}>
                            {intl.formatMessage({ id: 'checkout:rental-equipments-addons-header' })}
                        </h6>
                        <ViewAll
                            items={addOns}
                            renderItem={renderItem}
                            limit={maxAddOnsLimit}
                            viewAllClass="view-all-btn"
                            count={
                                consumables?.availableQuantity?.totalAddOns[
                                    consumables?.selectedRentalEquipment?.equipmentInfo?.sku
                                ]
                            }
                            handleViewAll={setIsAddonViewAll}
                            containerClass="addon-bottom-margin"
                        />
                    </>
                )}
            </>
        );
    };

    return (
        <>
            <RentalHeader onClose={handleCloseClick}></RentalHeader>
            <div className="modal-content-fixed-body modal-rental-body">
                <h5 className="mobile-view-rental-heading">
                    {intl.formatMessage({ id: 'checkout:rental-equipments-header' })}
                </h5>
                <div className="accessory-container accessory-container--wrap">
                    <AccessoryItemDesc
                        title={consumables?.selectedRentalEquipment?.equipmentInfo?.name}
                        imgSrc={consumables?.selectedRentalEquipment?.equipmentInfo?.img}
                        quantity={consumables?.selectedRentalEquipment?.equipmentInfo?.qty}></AccessoryItemDesc>
                </div>
                <div className="accessory-addon-container">
                    {renderAccessories()}
                    {renderAddons()}
                </div>
            </div>
            <div className="modal-content-fixed-footer modal-rental-footer">
                <RentalFooter
                    selectedItems={selectedItems}
                    saveChanges={saveChanges}
                    selectedItemsDetails={selectedItemsDetails}
                    onCloseClick={handleCloseClick}></RentalFooter>
            </div>
        </>
    );
};

export default RentalEquipment;
