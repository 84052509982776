import React, { useEffect, useState } from 'react';
import classes from './suggestedAddressModal.css';
import { useIntl } from 'react-intl';
import Footer from '../../aem-core-components/components/Minicart/viewcart/footer';
import Button from '../global/atoms/button';
import Close from '../../resources/images/close.svg';
import CardRadioButton from '../global/atoms/cardRadioButton';
import { checkoutDatalocator } from './checkoutAndOrderSummary/dataLocators';
import MobileView from '../global/modules/mobileView/MobileViewWithoutModal';
import EditButton from '../global/atoms/editButton';

const SuggestedAddressModal = ({
    setShowSuggestedAddress,
    suggestedAddressList,
    currentAddressObj,
    onPressUpdateBtn,
    forMobile
}) => {
    const intl = useIntl();
    const [selectedAddress, setSelectedAddress] = useState({});
    const [selectedAddressId, setSelectedAddressId] = useState(0);
    const [suggestedAddressListData, setSuggestedAddressListData] = useState([]);
    const { streetAddress, selectedAddress2, selectedCity, selectedState, selectedZip } = currentAddressObj || '';

    useEffect(() => {
        const newArr = suggestedAddressList.map((item, index) => ({ ...item, id: index + 1 }));
        setSuggestedAddressListData(newArr);
    }, []);

    const updateAdressDetailsHandler = data => {
        setSelectedAddressId(data?.id);
        setSelectedAddress(data);
    };

    const onPressUpdateButton = () => {
        onPressUpdateBtn(selectedAddress);
    };

    const cardStyle = data => {
        return (
            <div className={classes.accountCardRoot}>
                <span className={classes.cardTitle}>{data.address1}</span>
                <small className={classes.cardContent}>{data.address2}</small>
                <small className={classes.cardContent}>{`${data.city}, ${data.state} ${data.zip}`}</small>
            </div>
        );
    };

    const closeModal = () => {
        setShowSuggestedAddress(false);
    };

    const suggestedAddressModalFooter = () => {
        return (
            <Button
                type="button"
                data-testid={checkoutDatalocator.avs_updateandContinuebutton_text}
                className={'button button-primary button-block'}
                onClick={onPressUpdateButton}>
                {intl.formatMessage({ id: 'updateAndCont' })}
            </Button>
        );
    };

    const suggestedAddressView = () => {
        return (
            <>
                <div className={classes.parentContainer}>
                    {!forMobile && (
                        <Button
                            tabIndex={0}
                            data-testid={checkoutDatalocator.avs_crossicon_link}
                            className={classes.avsCrossIcon}
                            onClick={closeModal}>
                            <Close aria-hidden="true" tabIndex={'-1'} />
                        </Button>
                    )}

                    <h5
                        data-testid={checkoutDatalocator.avs_confirmyouraddress_heading}
                        className={classes.titleHeading}>
                        {intl.formatMessage({ id: 'addressConfirm' })}
                    </h5>

                    <div className={classes.addressSubHeading} data-testid={checkoutDatalocator.avs_subheading_text}>
                        {intl.formatMessage({ id: 'addressSubheading' })}
                    </div>
                    <div className={classes.chooseAccountbody}>
                        <h6
                            data-testid={checkoutDatalocator.avs_address_label_text}
                            className={classes.chooseAccountHeading}>
                            {'Address'}
                        </h6>
                        <EditButton handleOnClick={closeModal} />
                    </div>
                    <div className={classes.addressTextDiv}>
                        <p data-testid={checkoutDatalocator.avs_streetAddress_text} className={classes.addressText}>
                            {streetAddress?.split(',')[0]}
                        </p>
                        <p data-testid={checkoutDatalocator.avs_address2_text} className={classes.addressText}>
                            {selectedAddress2}
                        </p>
                        <p
                            data-testid={checkoutDatalocator.avs_city_state_text}
                            className={classes.addressText}>{`${selectedCity}, ${selectedState} ${selectedZip}`}</p>
                    </div>
                </div>
                <div className={`${classes.listContainer} ${selectedAddressId > 0 ? classes.listContainerfooter : ''}`}>
                    <h5
                        className={classes.listContainerTitle}
                        data-testid={checkoutDatalocator.avs_suggestedaddress_label_text}>
                        {intl.formatMessage({ id: 'suggestedAddresses' })}
                    </h5>
                    {suggestedAddressListData &&
                        suggestedAddressListData.map((item, index) => {
                            return (
                                <div className={classes.radioContainer}>
                                    <CardRadioButton
                                        key={index.toString()}
                                        item={item}
                                        cardStyle={cardStyle}
                                        selectedValue={selectedAddressId}
                                        setSelectedValue={setSelectedAddressId}
                                        onPressRadioButton={updateAdressDetailsHandler}
                                        isAddress={true}
                                    />
                                </div>
                            );
                        })}
                </div>
            </>
        );
    };

    return (
        <>
            {forMobile ? (
                <MobileView
                    handleClose={closeModal}
                    footerContent={selectedAddressId > 0 && suggestedAddressModalFooter}
                    mobileBodyClass={'suggestedAddressModal'}
                    rootClass={'suggestedAddressroot'}>
                    {suggestedAddressView()}
                </MobileView>
            ) : (
                <>
                    {suggestedAddressView()}
                    {selectedAddressId > 0 && (
                        <Footer className="minicart-footer-actions">{suggestedAddressModalFooter()}</Footer>
                    )}
                </>
            )}
        </>
    );
};

export default SuggestedAddressModal;
