import React, { Suspense, memo, useEffect, useState } from 'react';
import { getCartDetails } from '../../aem-core-components/actions/cart';
import {
    GET_DEDICATED_PC,
    RESET_EDIT_ON_CHECKOUT_COMPLETE,
    SET_CART_CONTEXT_FIELDS,
    SET_CLICKED_MAKE_CHANGES,
    SET_CREDIT_NEWADDR_FLAG,
    SET_PAYMENT_TOKEN_DATA,
    SET_PUNCHOUT_USER_DATA,
    SET_SELECTED_PAYMENT_METHOD,
    SET_SOURCES_LOADING_FLAG,
    SET_VIEW_CART_FIELDS,
    SET_YOUR_DETAILS,
    SHOW_GUEST_CHECKOUT_LOGIN
} from '../../aem-core-components/actions/constants';
import { useCartState } from '../../aem-core-components/components/Minicart/cartContext';
import useCartEstimate from '../../aem-core-components/components/Minicart/hooks/useCartEstimate';
import { useUserContext } from '../../aem-core-components/context/UserContext';
import CART_DETAILS_QUERY from '../../aem-core-components/queries/query_cart_details.graphql';
import { cookieValue } from '../../aem-core-components/utils/cookieUtils';
import { useAwaitQuery, useCookieValue } from '../../aem-core-components/utils/hooks';
import { useAnalyticsContext } from '../../config/GoogleTagManagerEvents';
import { useAuthorityToken } from '../../aem-core-components/utils/hooks';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../constants/analyticsConstants/Ecommerce';
import { EVENT_PAGE_NAMES_CONFIG } from '../../constants/analyticsConstants/Page';
import { EVENT_P2P_NAMES_CONFIG } from '../../constants/analyticsConstants/ProcureToPay';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables';
import { MEDIA_TYPE } from '../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { USER_TYPE } from '../../constants/userDetailsConstants';
import useAnalytics from '../../hooks/useAnalytics';
import { useCheckAuthorityType, useCheckUser } from '../../hooks/useCheckUser';
import useCheckout from '../../hooks/useCheckout';
import { useDidMount } from '../../hooks/useDidMount';
import { useQuotesState } from '../quotes/context';
import useMedia from '../../hooks/useMedia';
import { useAtp } from '../cap/hooks/useATP';
import config from '../App/config';
import { useFilterState } from '../cap';
import { AUTHORITY_TYPE, SUCCESS } from '../global/constants';
import { RESET_SELECTED_STORE_DETAILS, SET_END_DATE, SET_START_DATE } from '../cap/constants';
import { checkIsEditQuoteFlow, isAccountBlocked, isAccountClosed, isPaymentSectionVisited } from '../global/utils/commonUtils';
import { isValidString, logError } from '../global/utils/logger';
import CheckoutStepsAndOrderSummary from './CheckoutStepsAndOrderSummary';
import { getAddPaymentAPI, getCustomAccountFields, getTokenAPI } from './checkoutAndOrderSummary/api/getCheckoutAPIs';
import {
    DUKE_CORP_LINK,
    FORM_STEP_CASH,
    FORM_STEP_CASH_ADDONS,
    FORM_STEP_CASH_ADDONS_WO_OPTIONAL,
    FORM_STEP_CASH_WO_OPTIONAL,
    FORM_STEP_CREDIT,
    FORM_STEP_CREDIT_ADDONS,
    FORM_STEP_CREDIT_ADDONS_WO_OPTIONAL,
    FORM_STEP_CREDIT_WO_OPTIONAL,
    FORM_STEP_CUSTOM_CREDIT,
    FORM_STEP_CUSTOM_CREDIT_ADDONS,
    FORM_STEP_CUSTOM_CREDIT_ADDONS_WO_OPTIONAL,
    FORM_STEP_CUSTOM_CREDIT_WO_OPTIONAL,
    FORM_STEP_GUEST,
    FORM_STEP_GUEST_ADDONS,
    FORM_STEP_GUEST_ADDONS_WO_OPTIONAL,
    FORM_STEP_GUEST_WO_OPTIONAL
} from './constants';
import QuoteTransmitModal from '../quotes/qouteTransmitModal/QuoteTransmitModal';
import TransmitOrSaveMain from './transmitOrSave/TransmitOrSaveMain';
import './checkout.scss';
import { TRANSMIT_NOW } from './constants';
import { SET_TRANSMITTED_QUOTE_ID } from '../quotes/actionTypes/actionTypes';
import { SET_SELECTED_TRANSMIT_OR_SAVE_FOR_LATER } from '../../aem-core-components/actions/constants';

const { SESSION_STORAGE, LOCAL_STORAGE, COOKIES } = STORAGE_CONFIG;

const Fallback = memo(() => <span>loading</span>);
const LazySubmitReservationButton = React.lazy(() =>
    import(/* webpackMode: "eager" */ '../global/atoms/SubmitReservation')
);
const LazyCheckoutGuestUser = React.lazy(() => import(/* webpackMode: "eager" */ './CheckoutGuestUser'));
const LazyCheckoutCashUser = React.lazy(() => import(/* webpackMode: "eager" */ './CheckoutCashUser'));
const LazyCheckoutCreditUser = React.lazy(() => import(/* webpackMode: "eager" */ './CheckoutCreditUser'));
const LazyPunchoutOrderConfirmation = React.lazy(() =>
    import(/* webpackMode: "eager" */ './punchoutOrderConfirmation/PunchoutOrderConfirmation')
);
const LazyCheckoutGuestLogin = React.lazy(() => import(/* webpackMode: "eager" */ './checkoutGuestLogin'));
const LazyCreateProject = React.lazy(() => import(/* webpackMode: "eager" */ './createProject'));
const LazyReservationConfirmation = React.lazy(() => import(/* webpackMode: "eager" */ './confirmation'));

const LazyQuoteConfirmation = React.lazy(() =>
    import(/* webpackMode: "eager" */ './transmitOrSave/QuoteConfirmation/QuoteConfirmation')
);

const Checkout = () => {
    const [
        {
            cart,
            userInfo,
            cartId,
            currentOffset,
            consumables,
            submitReservation,
            guestUserDetails,
            isCreditNewAddress,
            punchoutUserData,
            userAccount,
            optionalPlan,
            clickedMakeChanges,
            selectedTransmitOrSave,
            orderSummaryDetails
        },
        dispatch
    ] = useCartState();
    const [{ isCheckoutLoading }] = useUserContext();
    const { rentalSubtotal } = orderSummaryDetails;
    const [{ viewCart, projectDetails, endDate, startDate }, filterDispatch] = useFilterState();
    const mediaType = useMedia();
    const authorityType = useCheckAuthorityType();
    const [currentOffSet, setCurrentOffSet] = useState('');
    const [currentStep, setCurrentStep] = useState(1);
    const [isStepForward, setIsStepForward] = useState(false);
    const [formStep, setFormStep] = useState({});
    const [{ isTransmitting, transmittedQuoteId, salesforceQuoteId }, quoteDispatch] = useQuotesState();
    const userType = useCheckUser();
    const orderEstimates = cart?.estimatesResponse?.estimate;
    const [accessToken] = useAuthorityToken(COOKIES.ACCESSTOKEN);

    const { sendEventsForEcommercePage, sendEventsForPageUser, sendEventsForP2PApplication } = useAnalyticsContext();
    const { remainingAccessories, totalAddOns } = consumables?.availableQuantity || '';
    const [customBillingFields, setCustomBillingFields] = useState({});
    const [isCustomAccount, setIsCustomAccount] = useState(false);
    const viewCartdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE.VIEWCART)) || '';
    const isGuestCheckoutLogin = JSON.parse(localStorage.getItem(LOCAL_STORAGE.SHOWGUESTCHECKOUTLOGIN));
    const [isCCLogin, setIsCCLogin] = useState(false);
    const [sixDigitLat, setSixDigitLat] = useState(0);
    const [locationSet, setLocationSet] = useState(false);
    const sessionStartDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.STARTDATE);
    const sessionEndDate = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.ENDDATE);
    const [
        {
            payloadEcommerceActionAnalytics,
            payloadEcommercePageData,
            payloadEcommerceLocationActionAnalytics,
            createPayloadTransmitQuotePageLoadActionAnalytics
        }
    ] = useAnalytics();
    const sucessfullyTransmittedQuoteId = sessionStorage.getItem(
        STORAGE_CONFIG.SESSION_STORAGE.SUCCESSFULLY_TRANSMITTED_QUOTE_ID
    );
    // const [settingsCookie] = useCookieValue('Settings');
    useEffect(() => {
        //if loggin user with cart loaded && cc login
        if (viewCart?.lat) {
            setSixDigitLat(Math.floor(Number(viewCart?.lat) * 1000000) / 1000000);
        } else {
            setSixDigitLat(0);
        }
    }, [viewCart?.lat]);

    useEffect(() => {
        const isEditQuoteFlow = checkIsEditQuoteFlow();
        if (rentalSubtotal && !isEditQuoteFlow) {
            sendEventsForP2PApplication(
                EVENT_P2P_NAMES_CONFIG.P2P_CREATE_QUOTE_STARTED,
                createPayloadTransmitQuotePageLoadActionAnalytics()
            );
        }
    }, [rentalSubtotal]);

    useEffect(() => {
        if (sucessfullyTransmittedQuoteId) {
            dispatch({ type: SET_SELECTED_TRANSMIT_OR_SAVE_FOR_LATER, value: TRANSMIT_NOW });
            quoteDispatch({ type: SET_TRANSMITTED_QUOTE_ID, payload: sucessfullyTransmittedQuoteId });
            return;
        }
    }, []);

    // check if any accessories and addons are available to show consumables step in checkout page
    const isAccessoriesAddonsAvailable = () => {
        if (isPaymentSectionVisited() && !clickedMakeChanges) {
            return sessionStorage.getItem(SESSION_STORAGE.ISADDONVISIBLE)
                ? JSON.parse(sessionStorage.getItem(SESSION_STORAGE.ISADDONVISIBLE))
                : false;
        } else if (
            (remainingAccessories && Object.keys(remainingAccessories)?.length > 0) ||
            (totalAddOns && Object.keys(totalAddOns)?.length > 0)
        ) {
            sessionStorage.setItem(SESSION_STORAGE.ISADDONVISIBLE, true);
            return true;
        } else {
            sessionStorage.setItem(SESSION_STORAGE.ISADDONVISIBLE, false);
            return false;
        }
    };

    const triggerCheckoutEvents = () => {
        try {
            sendEventsForEcommercePage(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.CHECKOUT,
                localStorage.getItem('companyID') == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA
            );
            sendEventsForPageUser(EVENT_PAGE_NAMES_CONFIG.PAGE_USER, payloadEcommerceLocationActionAnalytics());
        } catch (error) {
            logError(error, false, 'triggerCheckoutEvents');
        }
    };

    const changeCurrentStep = stepToSet => {
        setIsStepForward(stepToSet > currentStep);
        setCurrentStep(stepToSet);
        triggerCheckoutEvents();
    };

    const showQuoteSuccessfullUi = () => {
        if (authorityType === AUTHORITY_TYPE.P2P && selectedTransmitOrSave !== TRANSMIT_NOW) {
            if (transmittedQuoteId || sucessfullyTransmittedQuoteId) {
                return true;
            }
        }
        return false;
    }

    const renderSteps = () => {
        if (userType == USER_TYPE.GUEST) {
            return (
                <Suspense
                    fallback={
                        <>
                            <Fallback />
                        </>
                    }>
                    <LazyCheckoutGuestUser
                        currentStep={currentStep}
                        setCurrentStep={changeCurrentStep}
                        currentOffSet={currentOffSet}
                        formStep={formStep}
                        isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                    />
                </Suspense>
            );
        } else if (userType == USER_TYPE.CREDIT) {
            return (
                <Suspense fallback={<Fallback />}>
                    <LazyCheckoutCreditUser
                        currentStep={currentStep}
                        setCurrentStep={changeCurrentStep}
                        currentOffSet={currentOffSet}
                        isCustomAccount={isCustomAccount}
                        customBillingFields={customBillingFields}
                        formStep={formStep}
                        isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                    />
                </Suspense>
            );
        } else {
            return (
                <Suspense fallback={<Fallback />}>
                    <LazyCheckoutCashUser
                        currentStep={currentStep}
                        setCurrentStep={changeCurrentStep}
                        currentOffSet={currentOffSet}
                        formStep={formStep}
                        isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                    />
                </Suspense>
            );
        }
    };

    /** Below is UI rendering */

    // keeping this condition at top to avoid any other UI to load in checkout page
    if (showQuoteSuccessfullUi()) {
        // if create-quote is successful
        return (
            <Suspense fallback={<Fallback />}>
                <LazyQuoteConfirmation
                    isTransmit={selectedTransmitOrSave === TRANSMIT_NOW || sucessfullyTransmittedQuoteId}
                    confirmationType={SUCCESS}
                    transmittedQuoteId={transmittedQuoteId || sucessfullyTransmittedQuoteId}
                    salesforceQuoteId={salesforceQuoteId}
                />
            </Suspense>
        );
    } else if (
        (userType == USER_TYPE.CREDIT && viewCartdata && viewCartdata?.showCreateProject) ||
        viewCart?.showCreateProject
    ) {
        return (
            <Suspense fallback={<Fallback />}>
                <LazyCreateProject />;
            </Suspense>
        );
    } else if (isGuestCheckoutLogin && !accessToken) {
        //import(/* webpackChunkName: "checkoutGuestLogin" */ './checkoutGuestLogin/CheckoutGuestLogin.js').then(module => {
        // use module
        return (
            <Suspense fallback={<Fallback />}>
                <LazyCheckoutGuestLogin />
            </Suspense>
        );
    } else if (submitReservation?.isSubmitReservationScreenVisible) {
        return (
            <Suspense fallback={<Fallback />}>
                <LazyReservationConfirmation />
            </Suspense>
        );
    } else {
        //  const CheckoutStepsAndOrderSummary = React.lazy(() => import(/* webpackMode: "eager" */ './CheckoutStepsAndOrderSummary'))
        return (
            <>
                {/*  <Suspense fallback={<Fallback/>}> */}
                {!isCheckoutLoading && (
                    <CheckoutStepsAndOrderSummary
                        isRentalDatesEmpty={!endDate}
                        orderEstimates={orderEstimates}
                        renderSteps={renderSteps}
                        setCurrentStep={setCurrentStep}
                        formStep={formStep}
                        setFormStep={setFormStep}
                        currentStep={currentStep}
                        setCustomBillingFields={setCustomBillingFields}
                        isCustomAccount={isCustomAccount}
                        setIsCustomAccount={setIsCustomAccount}
                        isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                        setCurrentOffSet={setCurrentOffSet}
                        isStepForward={isStepForward}
                    />
                )}
                {/*  </Suspense> */}
                {mediaType !== MEDIA_TYPE.DESKTOP && currentStep === formStep.SUBMIT_RESERVATION && (
                    <Suspense fallback={<Fallback />}>
                        <LazySubmitReservationButton
                            customClass={'submitCTASmallScreen'}
                            currentOffSet={currentOffSet}
                            handleStepChange={setCurrentStep}
                            currentStep={currentStep}
                        />
                    </Suspense>
                )}
                {mediaType !== MEDIA_TYPE.DESKTOP &&
                    authorityType === AUTHORITY_TYPE.PUNCHOUT &&
                    currentStep === formStep.TRANSFER_ORDER && ( 
                        <Suspense fallback={<Fallback />}>
                            <LazyPunchoutOrderConfirmation
                                customClass={'submitCTASmallScreen'}
                                currentOffSet={currentOffSet}
                                handleStepChange={setCurrentStep}
                                currentStep={currentStep}
                            />
                        </Suspense>
                    )}
                {mediaType !== MEDIA_TYPE.DESKTOP &&
                    currentStep === formStep.TRANSMIT_OR_SAVE &&
                    authorityType === AUTHORITY_TYPE.P2P && (
                        <TransmitOrSaveMain selectedTransmitOrSave={selectedTransmitOrSave} />
                    )}
                <QuoteTransmitModal isModalOpen={isTransmitting} />
            </>
        );
    }
};

export default memo(Checkout);
