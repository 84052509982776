import React from 'react';
import { useIntl } from 'react-intl';
import Button from '../../../global/atoms/button';
import MobileView from '../../../global/modules/mobileView/MobileViewWithoutModal';
import ReviewDuplicatesView from './ReviewDuplicatesView';
import StoreModal from '../../StoreModal';
import useMedia from '../../../../hooks/useMedia';
import { createProjectDataLocators } from '../dataLocators';
import './reviewDuplicates.scss';

const ReviewDuplicates = props => {
    const {
        newProject,
        duplicates,
        isNewProject,
        handleClose,
        createProject,
        onPressProject,
        showReviewDuplicates,
        setShowReviewDuplicates,
        isCreateProjectLoading
    } = props;
    let mediaTypeMobile = useMedia();
    mediaTypeMobile = mediaTypeMobile === 'MOBILE' ? true : false;
    const intl = useIntl();
    const createProjectCTA = () => {
        return (
            <Button
                type="button"
                className={'button button-primary button-block'}
                onClick={createProject}
                data-testid={
                    isNewProject === true
                        ? createProjectDataLocators.duplicateCreateProjectCta
                        : createProjectDataLocators.duplicateUseExistingProjectCta
                }>
                {isNewProject === true
                    ? intl.formatMessage({ id: 'checkout:review-duplicates-create-project' })
                    : intl.formatMessage({ id: 'checkout:review-duplicates-use-existing-project' })}
            </Button>
        );
    };

    return (
        <>
            {mediaTypeMobile ? (
                <MobileView
                    handleClose={handleClose}
                    footerContent={createProjectCTA}
                    rootClass="review-duplicate-container">
                    <ReviewDuplicatesView
                        newProject={newProject}
                        duplicates={duplicates}
                        isCreateProjectLoading={isCreateProjectLoading}
                        onPressProject={onPressProject}></ReviewDuplicatesView>
                </MobileView>
            ) : (
                <StoreModal
                    classes="modal-locationdialog"
                    modalDialogClass={'modal-locationdialog'}
                    isOpen={showReviewDuplicates}
                    onRequestClose={setShowReviewDuplicates}>
                    <ReviewDuplicatesView
                        newProject={newProject}
                        duplicates={duplicates}
                        onPressProject={onPressProject}
                        handleClose={handleClose}
                        isCreateProjectLoading={isCreateProjectLoading}
                        footer={createProjectCTA}></ReviewDuplicatesView>
                </StoreModal>
            )}
        </>
    );
};

export default ReviewDuplicates;
