import { uuid4 } from '@sentry/utils';
import {
    RESET_ACCESSORIES_AND_ADDONS,
    RESET_EDIT_ON_CHECKOUT_COMPLETE,
    RESET_GEOGRAPHY,
    RESET_HTGO,
    RESET_HTGO_FROM_YOUR_DETAILS,
    RESET_ORDER_ESTIMATES,
    RESET_PAYMENT,
    RESET_PROJECT_DETAILS,
    SET_OPTIONAL_PLANS_CHECKED,
    SET_OPTIONAL_PLANS_IS_EDIT,
    SET_SOURCES_LOADING_FLAG,
    SET_VIEW_CART_FIELDS,
    UPDATE_PROJECT
} from '../aem-core-components/actions/constants';
import { useCartState } from '../aem-core-components/components/Minicart';
import useCartEstimate from '../aem-core-components/components/Minicart/hooks/useCartEstimate';
import { useUserContext } from '../aem-core-components/context/UserContext';
import { shouldFuelChargeBeChecked } from '../components/checkout/checkoutUtils';
import { useCookieValue } from '../aem-core-components/utils/hooks';
import { useFilterState } from '../components/cap';
import {
    ACCESSORIES_AND_ADDONS,
    HTGO,
    OPTIONAL_PLANS,
    PAYMENT,
    PROJECT_DETAILS,
    YOUR_DETAILS
} from '../components/checkout/constants';
import { checkIsEditQuoteFlow, validateZip } from '../components/global/utils/commonUtils';
import { isValidString, logError } from '../components/global/utils/logger';
import { useAnalyticsContext } from '../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../constants/analyticsConstants/Variables';
import { STORAGE_CONFIG } from '../constants/storageConfig';
import { USER_TYPE } from '../constants/userDetailsConstants';
import useAnalytics from './useAnalytics';
import { useCheckUser } from './useCheckUser';
import useComputeLocation from './useComputeLocation';

const useCheckout = () => {
    /**
     * function to reset particular steps data from context
     * @param {Object} formStepObj
     * @param {Number} stepToEdit
     */
    const [{ cart, userInfo, isCreditNewAddress, optionalPlan }, dispatch] = useCartState();
    const [{ viewCart, projectDetails }, filterDispatch] = useFilterState();

    const { getPC } = useComputeLocation();
    const [{ getEstimates }] = useCartEstimate();
    const [{ isUserProfileLoading }] = useUserContext();
    const { sendEventsForEcommerceAction } = useAnalyticsContext();
    const [{ payloadEcommerceActionAnalytics }] = useAnalytics();
    const userType = useCheckUser();
    const [guestUserId, setGuestUserId] = useCookieValue('guest_user_id');
    const { fuelCharges } = cart?.estimatesResponse?.estimate?.totals || {};

    const refreshCart = async () => {
        dispatch({ type: 'beginLoading' });
        if (!validateZip(viewCart?.jobSiteZip) && userType !== USER_TYPE.CREDIT) {
            // Added code to prevent estimates with invalid zip on checkout step 1 DOTC-30234
            filterDispatch({
                type: RESET_GEOGRAPHY
            });
            dispatch({
                type: RESET_ORDER_ESTIMATES
            });
            dispatch({ type: 'endLoading' });
            return;
        }
        if (
            cart?.items?.length &&
            ((viewCart?.lat && viewCart?.long) || (userType === USER_TYPE.CREDIT && !isCreditNewAddress)) &&
            !isUserProfileLoading
        ) {
            const { pcVal, pcLat, pcLong } = await getPC();
            if (pcVal && pcLat && pcLong) {
                if (userType === USER_TYPE.CREDIT && !isCreditNewAddress) {
                    filterDispatch({ type: UPDATE_PROJECT, ...projectDetails, locationPC: pcVal });
                } else {
                    filterDispatch({ type: SET_VIEW_CART_FIELDS, key: 'pc', value: pcVal });
                }
                dispatch({
                    type: SET_SOURCES_LOADING_FLAG,
                    isSourcesLoading: false
                });
                sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.ISSOURCESLOADING, false);
                getEstimates(undefined, pcVal, pcLat, pcLong);
            }
        }
        dispatch({ type: 'endLoading' });
    };

    const clearCheckout = (formStepObj, stepToEdit, isGuestUser = false) => {
        dispatch({ type: RESET_EDIT_ON_CHECKOUT_COMPLETE, isCheckoutEditResetComplete: false });
        for (let item in formStepObj) {
            if (formStepObj[item] > stepToEdit) {
                switch (item) {
                    case YOUR_DETAILS:
                        break;
                    case HTGO:
                        clearInterval(viewCart?.checkPCInterval);
                        if (isGuestUser) {
                            dispatch({ type: RESET_HTGO_FROM_YOUR_DETAILS });
                        } else {
                            dispatch({ type: RESET_HTGO });
                        }
                        break;
                    case ACCESSORIES_AND_ADDONS:
                        dispatch({ type: RESET_ACCESSORIES_AND_ADDONS });
                        break;
                    case OPTIONAL_PLANS:
                        dispatch({
                            type: SET_OPTIONAL_PLANS_CHECKED,
                            isRPPChecked: optionalPlan?.initialRppSelection,
                            isFuelChargeChecked: optionalPlan?.initialFuelChargeSelection
                        });
                        dispatch({
                            type: SET_OPTIONAL_PLANS_IS_EDIT,
                            isEdit: false
                        });
                        break;
                    case PAYMENT:
                        dispatch({ type: RESET_PAYMENT });
                        break;
                    case PROJECT_DETAILS:
                        dispatch({ type: RESET_PROJECT_DETAILS });
                        break;
                    default:
                        break;
                }
            }
        }
        dispatch({ type: RESET_EDIT_ON_CHECKOUT_COMPLETE, isCheckoutEditResetComplete: true });
    };

    const getCheckoutStepDetails = (formStep, currentStep, reservation = false) => {
        let currentStepName;
        if (reservation) {
            currentStepName = Object.keys(formStep)[0];
        } else {
            currentStepName = Object.keys(formStep)[currentStep - 1];
        }
        return {
            form_name: `${VARIABLE_CONFIG.CHECKOUT_PAGE_TITLE[currentStepName]}`,
            checkout_step: currentStep
        };
    };

    const checkoutFormStart = (elem, formStep, currentStep) => {
        if (
            elem?.target?.ariaLabel == VARIABLE_CONFIG.EVENT_LABEL.ARIA_LABEL_SAVE_AND_CONTINUE &&
            Object.keys(formStep)[currentStep - 1] != ACCESSORIES_AND_ADDONS
        ) {
            // match save and continue aria label
            const additionalPayload = {
                link_name: VARIABLE_CONFIG.EVENT_LABEL.SAVE_AND_CONTINUE,
                link_type: VARIABLE_CONFIG.TYPE.BUTTON,
                ...getCheckoutStepDetails(formStep, currentStep)
            };
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_SUBMITTED,
                payloadEcommerceActionAnalytics(),
                additionalPayload
            );
        }
    };

    const checkoutStepPayload = (formStep, currentStep, stepTypeValue, reservation = false) => {
        const displayQuoteId = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.DISPLAY_QUOTE_ID) || '';
        const isEditQuoteFlow = checkIsEditQuoteFlow();
        let currentStepName;
        // 'reservation' will be true only when we trigger event for submit-reservation
        if (reservation) {
            currentStepName = Object.keys(formStep)[0];
        } else {
            // 'stepTypeValue' will be 0 if we trigger step_viewed event
            // 'stepTypeValue' will be 1 if we trigger step_completed event
            currentStepName = Object.keys(formStep)[currentStep - (stepTypeValue + 1)];
        }
        const checkoutSteps = {
            page_title: `${VARIABLE_CONFIG.CHECKOUT_PAGE_TITLE[currentStepName]}`,
            page_location: `${VARIABLE_CONFIG?.CHECKOUT_PAGE_LOCATION[currentStepName]}`,
            step_number: currentStep - stepTypeValue
        };
        if (isEditQuoteFlow) {
            checkoutSteps.quote_id = displayQuoteId;
        }
        return checkoutSteps;
    };

    const checkoutErrorAnalyticsEvent = (formStep, currentStep, formError, formField) => {
        const additionalPayload = {
            form_error: formError,
            form_field: formField,
            ...getCheckoutStepDetails(formStep, currentStep)
        };
        sendEventsForEcommerceAction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_FORM_ERROR,
            payloadEcommerceActionAnalytics(),
            additionalPayload
        );
    };

    const getCustomerId = () => {
        try {
            const userProfileLocalStorage = sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.USERPROFILE);
            const userProfileObj = userProfileLocalStorage ? JSON.parse(userProfileLocalStorage) : {};
            let customerID;

            if (userType === USER_TYPE.CASH) {
                customerID = userProfileObj?.oktaUserId
                    ? `${userProfileObj?.oktaUserId}${uuid4().substring(0, 4)}`
                    : uuid4();
            } else {
                if (!isValidString(guestUserId)) {
                    let newGuestId = uuid4().substring(0, 25);
                    setGuestUserId(newGuestId);
                    customerID = newGuestId;
                } else {
                    customerID = guestUserId;
                }
            }

            sessionStorage.setItem(STORAGE_CONFIG.SESSION_STORAGE.CUSTIDENT, customerID);
            return customerID;
        } catch (error) {
            logError(error, false, 'getCustomerId', []);
        }
    };

    return {
        clearCheckout,
        refreshCart,
        checkoutStepPayload,
        getCheckoutStepDetails,
        checkoutFormStart,
        checkoutErrorAnalyticsEvent,
        getCustomerId
    };
};

export default useCheckout;
