import React, { useState } from 'react';
import { func, object } from 'prop-types';
import { useIntl } from 'react-intl';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons/AlertModalWithButtons';
import { ReadOnlyView } from '../../global/modules/stepForm';
import { useCartState } from '../../../aem-core-components/components/Minicart/cartContext';
import useCheckout from '../../../hooks/useCheckout';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { checkoutDatalocator } from '../../checkout/checkoutAndOrderSummary/dataLocators';
import TickIcon from '../../../resources/images/tick.svg';
import InstoreIcon from '../../../resources/images/instore.svg';
import UserIcon from '../../../resources/images/user.svg';
import CarIcon from '../../../resources/images/car.svg';
import './yourDetails.scss';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { logError } from '../../global/utils/logger';
import { useFilterState } from '../../cap';

const ReadOnlyYourDetails = props => {
    const { handleStepChange, formStep } = props;
    const [{userInfo, guestUserDetails }] = useCartState();
    const [{viewCart, projectDetails}, filterDispatch] = useFilterState();
    const { isInStorePickup } = viewCart;
    const { firstName, lastName, phoneNumber, email, marketoFeed } = guestUserDetails;
    const getPrefilledLocationObject = () => {
        if (userInfo?.isCreditCustomer) {
            return projectDetails;
        } else {
            return viewCart;
        }
    };
    const intl = useIntl();
    const { clearCheckout } = useCheckout();
    const [address, setAddress] = useState(getPrefilledLocationObject());
    const [showAlertModal, setShowAlertModal] = useState(false);
    const userType = useCheckUser();
    const { sendEventsForClick } = useAnalyticsContext();
    const userCompanyID = parseInt(localStorage.getItem('companyID')) || 1;
    const editHandler = () => {
        setShowAlertModal(true);
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userType.toLowerCase()} select`,
                `${intl.formatMessage({ id: 'checkout:your-details-title' }).toLowerCase()} edit`
            );
        } catch (error) {
            logError(error, false, 'editHandler');
        }
    };

    const handleMakeChangesClick = () => {
        setShowAlertModal(false);
        handleStepChange(formStep.YOUR_DETAILS);
        clearCheckout(formStep, formStep.YOUR_DETAILS, true);
    };

    const handleCancelClick = () => {
        setShowAlertModal(false);
    };

    const renderYourDetails = () => {
        return (
            <>
                <div className="readonly-details-item">
                    <div className="readonly-details-icon">
                        <UserIcon />
                    </div>
                    <div className="readonly-details-desc">
                        <div
                            className="readonly-details-title"
                            data-testid={checkoutDatalocator.checkout_yourdetails_name_label_testid}>
                            {firstName} {lastName}
                        </div>
                        <span
                            className="readonly-details-address"
                            data-testid={checkoutDatalocator.checkout_yourdetails_phoneNumber_label_testid}>
                            {phoneNumber}
                        </span>
                        {userCompanyID == 2 && marketoFeed && (
                            <span className="readonly-details-address">
                                <div className="check-order-update">
                                    <TickIcon /> {intl.formatMessage({ id: 'marketo-feed-opted-in' })}
                                </div>
                            </span>
                        )}
                        <span
                            className="readonly-details-address"
                            data-testid={checkoutDatalocator.checkout_yourdetails_emailAddress_label_testid}>
                            {email}
                        </span>
                    </div>
                </div>
                <div className="readonly-details-item">
                    <div className="readonly-details-icon">{isInStorePickup ? <InstoreIcon /> : <CarIcon />}</div>
                    <div className="readonly-details-desc">
                        <span
                            className="readonly-details-address"
                            data-testid={checkoutDatalocator.checkout_InstorePickUp_Address_label_testid}>
                            {address?.location || address?.projectAddress1}
                        </span>
                        <span className="readonly-details-address">{address?.jobSiteAddr2 || ''}</span>
                        <span className="readonly-details-address">
                            {address?.jobSiteCity || address?.selectedProjectCity}
                            {', '}
                            {address?.jobSiteState || address?.selectedProjectState}{' '}
                            {address?.jobSiteZip || address?.selectedProjectZip}
                        </span>
                    </div>
                </div>
            </>
        );
    };

    return (
        <>
            <ReadOnlyView
                children={renderYourDetails()}
                title={intl.formatMessage({ id: 'checkout:your-details-title' })}
                testId={checkoutDatalocator.checkout_yourdetails_link_testid}
                onEdit={editHandler}
            />
            <AlertModalWithButtons
                isOpen={showAlertModal}
                title={intl.formatMessage({ id: 'checkout:alert-heading' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:alert-body-content' })}
                handleContinueClick={handleMakeChangesClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:alert-body-button' })}
                handleCancelClick={handleCancelClick}
                cancelButtonText={intl.formatMessage({ id: 'account:address-delete-cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_crossicon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_makechanges_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_cancel_CTA_testid}
                titleDataLocator={checkoutDatalocator.checkout_doyouwanttomakechanges_heading_testid}
                warningQuestionDataLocator={checkoutDatalocator.checkout_changesrequiredyourinformartion_labels_testid}
                alertTooltipDataLocator={checkoutDatalocator.checkout_makechangesmodal_tooltip_testid}
            />
        </>
    );
};

export default ReadOnlyYourDetails;

ReadOnlyYourDetails.propTypes = {
    handleStepChange: func,
    formStep: object
};
