import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import isObjectEmpty from '../../../../aem-core-components/utils/isObjectEmpty';
import useCheckout from '../../../../hooks/useCheckout';
import AlertModalWithButtons from '../../../global/atoms/AlertModalWithButtons';
import { ReadOnlyView } from '../../../global/modules/stepForm';
import { DUKE_CORP_LINK } from '../../constants';
import { checkoutDatalocator } from '../dataLocators';

const ReadOnlyCustomAccountsDetails = props => {
    const [{ userAccount }] = useCartState();
    const { handleStepChange, formStep, customBillingFields, corpLink } = props;
    const [showAlertModal, setShowAlertModal] = useState(false);
    const { clearCheckout } = useCheckout();
    const intl = useIntl();

    const isDisney = !isObjectEmpty(customBillingFields)
        ? JSON.parse(customBillingFields?.isDisneyAccount?.toLowerCase())
        : false;
    const isDuke = corpLink === DUKE_CORP_LINK ? true : false;

    const sectionTitle = isDisney
        ? intl.formatMessage({ id: 'checkout:Disney-title' })
        : isDuke
            ? intl.formatMessage({ id: 'checkout:Duke-energy-title' })
            : userAccount.accountName;

    const editHandler = () => {
        setShowAlertModal(true);
    };
    const handleMakeChangesClick = () => {
        setShowAlertModal(false);
        handleStepChange(formStep.CUSTOM_ACCOUNT_INFO);
        clearCheckout(formStep, formStep.CUSTOM_ACCOUNT_INFO);
    };
    const handleCancelClick = () => {
        setShowAlertModal(false);
    };
    return (
        <>
            <ReadOnlyView children={null} title={sectionTitle} onEdit={editHandler} />
            <AlertModalWithButtons
                isOpen={showAlertModal}
                title={intl.formatMessage({ id: 'checkout:alert-heading' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:alert-body-content' })}
                handleContinueClick={handleMakeChangesClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:alert-body-button' })}
                handleCancelClick={handleCancelClick}
                cancelButtonText={intl.formatMessage({ id: 'account:address-delete-cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_crossicon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_makechanges_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_cancel_CTA_testid}
                titleDataLocator={checkoutDatalocator.checkout_doyouwanttomakechanges_heading_testid}
                warningQuestionDataLocator={checkoutDatalocator.checkout_changesrequiredyourinformartion_labels_testid}
                alertTooltipDataLocator={checkoutDatalocator.checkout_makechangesmodal_tooltip_testid}
            />
        </>
    );
};
export default ReadOnlyCustomAccountsDetails;
