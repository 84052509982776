import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { SET_CREDIT_NEWADDR_FLAG } from '../../../aem-core-components/actions/constants';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import AccountSelector from '../../../aem-core-components/components/Minicart/miniCartHeader/Components/accountSelector';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { SCREEN_NAME } from '../../../constants/screenConstants';
import Alert from '../../global/atoms/alert/alert';
import Button from '../../global/atoms/button';
import { isPaymentSectionVisited } from '../../global/utils/commonUtils';
import CreditLocationOverlay from '../../pdp/location/LocationOverlayModal/CreditLocationOverlay';
import classes from './checkoutAndOrderSummary.css';
import { checkoutDatalocator } from './dataLocators';

const RoundTripOrderDetails = props => {
    const { projectDetails, onPressContinueHandlerCredit, userAccountName, onPressCreateProject, errorText } = props;
    const [{clickedMakeChanges}, dispatch] = useCartState();
    const [showProjectModal, setShowProjectModal] = useState(false);
    const [showAccountModal, setShowAccountModal] = useState(false);
    const intl = useIntl();
    const { sendEventsForUpdateVirtualPath, sendEventsForEcommerceCheckoutOption } = useAnalyticsContext();

    const continueHandler = () => {
        onPressContinueHandlerCredit && onPressContinueHandlerCredit();
        try {
            const currencyCode =
                localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US;
            if (clickedMakeChanges || !isPaymentSectionVisited()) {
                sendEventsForEcommerceCheckoutOption(2, {
                    rentalMethod: VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY
                });
                sendEventsForUpdateVirtualPath(
                    `${VARIABLE_CONFIG.ECOMMERCE_PAGE.CHECKOUT}_${VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY}`,
                    `/${currencyCode}/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.DETAILS}/${VARIABLE_CONFIG.RENTAL_METHOD.DELIVERY}`
                );
            }
        } catch (error) {
            logError(error, false, 'changeCUrrentStep');
        }
    };

    const onPressCreate = () => {
        onPressCreateProject && onPressCreateProject();
    };

    const setIsCreditNewAddress = value => {
        dispatch({
            type: SET_CREDIT_NEWADDR_FLAG,
            isCreditNewAddress: value
        });
    };
    const handleAccountClick = () => {
        setShowProjectModal(true);
    };

    return (
        <div className={classes.pickupViewContainer}>
            <h6 className={classes.chooseProjectHeading} data-testid={checkoutDatalocator.checkout_choose_a_project}>
                {intl.formatMessage({ id: 'existing-account:choose-a-project' })}
            </h6>
            <AccountSelector
                projectName={userAccountName || ''}
                selectedProjectAddress1={projectDetails.projectName || 'Select a project to continue'}
                buttonAriaLabel={intl.formatMessage({ id: 'existing-account:choose-a-project' })}
                accountClick={handleAccountClick}
                testid={checkoutDatalocator.checkout_select_a_project_to_continue}
                projectTestId={checkoutDatalocator.checkout_projectName_label_testid}
                accountTestId={checkoutDatalocator.checkout_accountName_label_testid}
            />

            {(showProjectModal || showAccountModal) && (
                <CreditLocationOverlay
                    screenName={SCREEN_NAME.CHECKOUT_SCREEN}
                    showLocationOverlayModal={showProjectModal}
                    showAccountOverlayModal={showAccountModal}
                    handleLocationOverlay={setShowProjectModal}
                    handleAccountOverlay={setShowAccountModal}
                    setIsCreditNewAddress={setIsCreditNewAddress}
                />
            )}

            <div className={classes.createNewBtnView}>
                <button
                    className={classes.createNewBtn}
                    onClick={onPressCreate}
                    data-testid={checkoutDatalocator.checkout_create_a_new_project}>
                    {intl.formatMessage({ id: 'checkout:create-a-new-project' })}
                </button>
            </div>
            {errorText !== '' && (
                <div className={classes.deliveryUnavailableErrorView}>
                    <Alert className="alert alert-withmargin" type={'error'} message={errorText} />
                </div>
            )}

            <div className={classes.continueBtn} data-testid={checkoutDatalocator.checkout_continue_CTA}>
                <Button
                    type="button"
                    onClick={continueHandler}
                    className="button button-primary button-block"
                    data-testid={checkoutDatalocator.checkout_continue_cta_testid}
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}>
                    {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                </Button>
            </div>
        </div>
    );
};

export default RoundTripOrderDetails;
