import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ReadOnlyView } from '../../global/modules/stepForm';
import CreditCard from '../../../resources/images/credit-card.svg';
import moment from 'moment';
import { formattedCardTitle } from './paymentHelper';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import './checkoutPayment.scss';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons/AlertModalWithButtons';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { filterPayments } from './checkOutFunctions';
import { getURLParams } from '../../global/utils/commonUtils';

export default function ReadOnlyCheckoutPayment(props) {
    const intl = useIntl();
    const { formStep, handleStepChange } = props;
    const [{ userInfo, paymentData, sessionAPIResponse, paymentTokenData }, dispatch] = useCartState();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [filteredPaymentData, setFilteredPaymentData] = useState([]);
    const params = getURLParams();
    const editHandler = () => {
        setShowAlertModal(true);
    };

    const handleMakeChangesClick = () => {
        setShowAlertModal(false);
        handleStepChange(formStep.PAYMENT);
    };
    const handleCancelClick = () => {
        setShowAlertModal(false);
    };
    useEffect(() => {
        let filterPaymentsResponse = filterPayments(paymentData);
        setFilteredPaymentData(filterPaymentsResponse.filteredList);
    }, []);

    const renderReadOnlyBody = () => {
        if (Object.keys(paymentTokenData)?.length > 0) {
            const accnumber = paymentTokenData?.acctLastFour;
            const lastFourDigits = accnumber.substring(accnumber.length - 4);
            const infoText = `Expires ${moment(paymentTokenData?.ccExp, 'MMYY').format('MM/YY')}`;
            const cardTitle = `${paymentTokenData?.ccBrand} ****${lastFourDigits}`;
            return (
                <>
                    {' '}
                    <div className="readonly-details-item" key={accnumber}>
                        <div className="readonly-details-icon">
                            <CreditCard />
                        </div>
                        <div className="readonly-details-desc">
                            <div className={'accountCardRoot'}>
                                <span className={'cardTitle'}>{cardTitle}</span>
                                <small className={'cardContent'}>{infoText}</small>
                            </div>
                        </div>
                    </div>
                </>
            );
        }
        else if (filteredPaymentData.length > 0) {
            return (
                <>
                    {' '}
                    {filteredPaymentData.map((item, index) => {
                        if (paymentData?.selectedPaymentMethod === index) {
                            const infoText = `Expires ${moment(item.expiryDate, 'MMYY').format('MM/YY')}`;
                            const cardTitle = item.isNewCard
                                ? item.name
                                : formattedCardTitle(item.paymentType, item.last4Digits);

                            return (
                                <div className="readonly-details-item" key={index}>
                                    <div className="readonly-details-icon">
                                        <CreditCard />
                                    </div>
                                    <div className="readonly-details-desc">
                                        <div className={'accountCardRoot'}>
                                            <span className={'cardTitle'}>{cardTitle}</span>
                                            <small className={'cardContent'}>{infoText}</small>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    })}
                </>
            );
        }
    };

    return (
        <>
            <ReadOnlyView
                children={renderReadOnlyBody()}
                title={intl.formatMessage({ id: 'checkout:payment-title' })}
                testId={checkoutDatalocator.checkout_payment_edit}
                onEdit={editHandler}
            />
            <AlertModalWithButtons
                isOpen={showAlertModal}
                title={intl.formatMessage({ id: 'checkout:alert-heading' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:alert-body-content' })}
                handleContinueClick={handleMakeChangesClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:alert-body-button' })}
                handleCancelClick={handleCancelClick}
                cancelButtonText={intl.formatMessage({ id: 'account:address-delete-cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_crossicon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_makechanges_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_cancel_CTA_testid}
                titleDataLocator={checkoutDatalocator.checkout_doyouwanttomakechanges_heading_testid}
                warningQuestionDataLocator={checkoutDatalocator.checkout_changesrequiredyourinformartion_labels_testid}
                alertTooltipDataLocator={checkoutDatalocator.checkout_makechangesmodal_tooltip_testid}
            />
        </>
    );
}
