import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { LoadingIndicator } from '../../../../aem-core-components';
import CardRadioButton from '../../../global/atoms/cardRadioButton';
import { createProjectDataLocators } from '../dataLocators';
import Close from '../../../../resources/images/close.svg';
import './reviewDuplicates.scss';
import Button from '../../../global/atoms/button/button';

const ReviewDuplicatesView = ({
    newProject,
    duplicates,
    onPressProject,
    handleClose,
    footer,
    isCreateProjectLoading
}) => {
    const intl = useIntl();
    const [selectedValue, setSelectedValue] = useState(newProject.jobNumber);

    const cardStyle = data => {
        return (
            <div className="review-duplicates__card">
                <span
                    className="review-duplicates__card-title"
                    data-testid={createProjectDataLocators.duplicateProjectName}>
                    {data.jobName}
                </span>
                <small
                    className="review-duplicates__card-content"
                    data-testid={createProjectDataLocators.duplicateProjectAddress}>
                    {data.address1}
                </small>
                <small
                    className="review-duplicates__card-content"
                    data-testid={createProjectDataLocators.duplicateProjectAddress}>
                    {data.address2}
                </small>
                <small
                    className="review-duplicates__card-content"
                    data-testid={
                        createProjectDataLocators.duplicateProjectAddress
                    }>{`${data.city}, ${data.state} ${data.zip}`}</small>
            </div>
        );
    };

    return (
        <div
            className={`review-duplicates__container ${footer && `mobile-view`} ${
                isCreateProjectLoading ? `clicks-disabled` : ''
            }`}>
            {footer && (
                <Button
                    data-testid={createProjectDataLocators.duplicateReviewCloseIcon}
                    className="review-duplicates__close-icon"
                    onClick={handleClose}
                    tabIndex={0}>
                    <Close aria-hidden="true" tabIndex={'-1'} />
                </Button>
            )}
            <div className="review-duplicates__head">
                <h5
                    className="review-duplicates__head__heading"
                    data-testid={createProjectDataLocators.reviewDuplicateHeading}>
                    {intl.formatMessage({ id: 'checkout:review-duplicates-title' })}
                </h5>
                <span className="review-duplicates__head__sub-heading">
                    {intl.formatMessage({ id: 'checkout:review-duplicates-similar-project-title' })}
                </span>
                <h6
                    className="review-duplicates__head__entered-heading"
                    data-testid={createProjectDataLocators.youEnteredLabel}>
                    {intl.formatMessage({ id: 'checkout:review-duplicates-you-entered' })}
                </h6>
                <CardRadioButton
                    item={newProject}
                    cardStyle={cardStyle}
                    selectedValue={selectedValue}
                    setSelectedValue={setSelectedValue}
                    onPressRadioButton={onPressProject}
                    isProject={true}
                    radioBtnDataLocator={createProjectDataLocators.radioButton}
                />
            </div>
            <div className={`review-duplicates__list-container ${duplicates && `mobile-view`}`}>
                <h6
                    className="review-duplicates__list-container__heading"
                    data-testid={createProjectDataLocators.duplicatePossibleProjectDuplicates}>
                    {intl.formatMessage({ id: 'checkout:review-duplicates-possible-duplicates' })}
                </h6>
                {isCreateProjectLoading && (
                    <div className={'orderSummaryLoader'}>
                        <LoadingIndicator />
                    </div>
                )}
                {duplicates.map(item => {
                    return (
                        <div className="review-duplicates__radio-container" key={item.jobNumber}>
                            <CardRadioButton
                                item={item}
                                cardStyle={cardStyle}
                                selectedValue={selectedValue}
                                setSelectedValue={setSelectedValue}
                                onPressRadioButton={onPressProject}
                                isProject={true}
                                radioBtnDataLocator={createProjectDataLocators.radioButton}
                            />
                        </div>
                    );
                })}
            </div>
            {footer && <div className="review-duplicates__cta-container">{footer()}</div>}
        </div>
    );
};

export default ReviewDuplicatesView;
