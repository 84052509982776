import React from 'react';
import { useIntl } from 'react-intl';
import Close from '../../../../resources/images/close.svg';
import Button from '../../../global/atoms/button';
import '../../../global/modules/modals/fixedHeaderFooter/fixedHeaderFooterModal.scss';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';

const RentalHeader = ({ onClose }) => {
    const intl = useIntl();
    return (
        <div className="modal-content-fixed-header modal-rental-header">
            <h5
                className="modal-content-heading-label"
                data-testid={checkoutDatalocator.checkout_rental_equipment_heading}
                tabIndex={0}>
                {intl.formatMessage({ id: 'checkout:rental-equipments-header' })}
            </h5>
            <Button
                data-testid={checkoutDatalocator.checkout_rental_equipment_close}
                className="modal-content-close"
                customButtonAriaLabel={intl.formatMessage({ id: 'common:close-dialog' })}
                onClick={onClose}
                tabIndex={0}>
                <Close aria-hidden={true} tabIndex={'-1'}/>
            </Button>
        </div>
    );
};

export default RentalHeader;
