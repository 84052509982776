import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { ReadOnlyView } from '../../global/modules/stepForm';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons/AlertModalWithButtons';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { useCheckUser } from '../../../hooks/useCheckUser';
import useCheckout from '../../../hooks/useCheckout';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import CheckMarkWavy from '../../../resources/images/checkmark-wavy.svg';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { SET_OPTIONAL_PLANS_IS_EDIT } from '../../../aem-core-components/actions/constants';
import { logError } from '../../global/utils/logger';

export default function ReadOnlyOptionalPlans(props) {
    const intl = useIntl();
    const { formStep, handleStepChange, testId } = props;
    const [{ optionalPlan }, dispatch] = useCartState();
    const { clearCheckout } = useCheckout();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const userType = useCheckUser();
    const { sendEventsForClick } = useAnalyticsContext();

    const editHandler = () => {
        setShowAlertModal(true);
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userType.toLowerCase()} select`,
                `${intl.formatMessage({ id: 'checkout:optional-plans-title' }).toLowerCase()} edit`
            );
        } catch (error) {
            logError(error, false, 'editHandler');
        }
    };

    const handleMakeChangesClick = () => {
        setShowAlertModal(false);
        dispatch({
            type: SET_OPTIONAL_PLANS_IS_EDIT,
            isEdit: true
        });
        handleStepChange(formStep.OPTIONAL_PLANS);
        clearCheckout(formStep, formStep.OPTIONAL_PLANS);
    };

    const handleCancelClick = () => {
        setShowAlertModal(false);
        dispatch({
            type: SET_OPTIONAL_PLANS_IS_EDIT,
            isEdit: false
        });
    };

    const renderReadOnlyBody = () => {
        return (
            <>
                {optionalPlan?.isRPPChecked && (
                    <div className="readonly-details-item">
                        <div className="readonly-details-icon">
                            <CheckMarkWavy />
                        </div>
                        <div className="readonly-details-desc">
                            <span className="readonly-details-title">
                                {intl.formatMessage({ id: 'checkout:optional-rpp-title' })}
                            </span>
                        </div>
                    </div>
                )}
                {optionalPlan?.isFuelChargeChecked && (
                    <div className="readonly-details-item">
                        <div className="readonly-details-icon">
                            <CheckMarkWavy />
                        </div>
                        <div className="readonly-details-desc">
                            <span className="readonly-details-title">
                                {intl.formatMessage({ id: 'order-summary:fuel-convenience-plan' })}
                            </span>
                        </div>
                    </div>
                )}
                {!optionalPlan?.isRPPChecked && !optionalPlan?.isFuelChargeChecked && (
                    <div className="readonly-details-desc">
                        <span className="readonly-details-title">
                            {intl.formatMessage({ id: 'checkout:no-optional-plan' })}
                        </span>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <ReadOnlyView
                children={renderReadOnlyBody()}
                title={intl.formatMessage({ id: 'checkout:optional-plans-title' })}
                testId={testId}
                onEdit={editHandler}
            />
            <AlertModalWithButtons
                isOpen={showAlertModal}
                title={intl.formatMessage({ id: 'checkout:alert-heading' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:alert-body-content' })}
                handleContinueClick={handleMakeChangesClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:alert-body-button' })}
                handleCancelClick={handleCancelClick}
                cancelButtonText={intl.formatMessage({ id: 'account:address-delete-cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_crossicon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_makechanges_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_cancel_CTA_testid}
                titleDataLocator={checkoutDatalocator.checkout_doyouwanttomakechanges_heading_testid}
                warningQuestionDataLocator={checkoutDatalocator.checkout_changesrequiredyourinformartion_labels_testid}
                alertTooltipDataLocator={checkoutDatalocator.checkout_makechangesmodal_tooltip_testid}
            />
        </>
    );
}
