import React, { useEffect, useState } from 'react';
import { func, number, object } from 'prop-types';
import { useIntl } from 'react-intl';
import Button from '../../global/atoms/button';
import PhoneNumber from '../../global/modules/formFields/phoneNumber';
import Name from '../../global/modules/formFields/name';
import Email from '../../global/modules/formFields/email';
import Checkbox from '../../global/atoms/Checkbox/Checkbox';
import { EditableView } from '../../global/modules/stepForm';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { SET_GUEST_USER_DETAILS } from '../../../aem-core-components/actions/constants';
import { useCartState } from '../../../aem-core-components/components/Minicart/cartContext';
import { getProducts } from '../../../constants/analyticsConstants/getProducts';
import config from '../../App/config';
import './yourDetails.scss';
import { logError } from '../../global/utils/logger';
import { LoadingIndicator } from '../../../aem-core-components';
import useCheckout from '../../../../main/hooks/useCheckout';
import { isPaymentSectionVisited } from '../../global/utils/commonUtils';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import { YOUR_DETAILS_FIELDS } from '../constants';
import { useFilterState } from '../../cap';

const EditYourDetails = props => {
    const { currentStep, handleStepChange, formStep } = props;
    const [
        { guestUserDetails, cart, consumables, isLoading, isCheckoutEditResetComplete, clickedMakeChanges },
        dispatch
    ] = useCartState();
    const [{ startDate, endDate }, filterDispatch] = useFilterState();
    const [, { checkIsEmailAvailable }] = useUserContext();
    const { refreshCart, checkoutErrorAnalyticsEvent } = useCheckout();
    const intl = useIntl();
    const [validFirstName, setValidFirstName] = useState(false);
    const [validLastName, setValidLastName] = useState(false);
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [validEmail, setValidEmail] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [required, setRequired] = useState(true);
    const [isExistingUser, setIsExistingUser] = useState(false);
    const [isMarketoConsentChecked, setIsMarketoConsentChecked] = useState(guestUserDetails.marketoFeed);
    const userCompanyID = parseInt(localStorage.getItem('companyID')) || 1;
    const phoneNumberDisclaimerSelf = document.querySelector('.checkout')?.dataset?.phonedisclaimerself || '';
    const { sendEventsForPageUserError, sendEventsForEcommerceCheckout, sendEventsForUpdateVirtualPath } =
        useAnalyticsContext();

    const callEvents = () => {
        const datesObj = {
            startDate,
            endDate
        };
        if (!isPaymentSectionVisited() || clickedMakeChanges) {
            sendEventsForEcommerceCheckout(3, getProducts(cart, datesObj, false, consumables));
        }
    };

    // this useEffect recalculates the total if the sections are edited
    useEffect(() => {
        if (isCheckoutEditResetComplete) {
            refreshCart();
        }
    }, [isCheckoutEditResetComplete]);

    useEffect(() => {
        callEvents();
    }, []);
    const handleFieldChange = text => {
        handleChange(text.target.name, text.target.value);
    };

    const handleChange = (key, value) => {
        dispatch({ type: SET_GUEST_USER_DETAILS, key, value });
    };

    const onErrorAnalytics = (error, fieldName) => {
        checkoutErrorAnalyticsEvent(formStep, currentStep, error, fieldName);
    };
    const onContinue = () => {
        if (!submit) {
            if (!validFirstName || !validLastName || !validPhoneNumber || !validEmail) {
                try {
                    sendEventsForPageUserError(
                        VARIABLE_CONFIG.ERROR.FORMFIELD,
                        intl.formatMessage({ id: 'checkout:save-continue-cta' }),
                        intl.formatMessage({ id: 'common:required-input-error' })
                    );
                } catch (error) {
                    logError(error, false, ' onContinue');
                }
            }
        }
        try {
            sendEventsForUpdateVirtualPath(
                VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_YOUR_DETAILS,
                `/${
                    localStorage.getItem('companyID') == 2 ? VARIABLE_CONFIG.REGION.CANADA : VARIABLE_CONFIG.REGION.US
                }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.GUEST_DETAILS}`
            );
        } catch (error) {
            logError(error, false, 'onContinue');
        }

        handleChange('marketoFeed', isMarketoConsentChecked);
        setSubmit(true);
    };

    const renderMarketoLabel = () => {
        return (
            <div className="mktoFeed">
                <small className="mktoFeedCheckBox">
                    {intl.formatMessage({ id: 'marketing-opt-in-message' })}
                    <a href={config?.pagePaths?.privacyPolicyCA} target="_blank" className="highlighted_tnC">
                        {intl.formatMessage({ id: 'privacy-policy' })}
                    </a>
                    .
                </small>
            </div>
        );
    };
    const handleMarketoFeed = () => {
        setIsMarketoConsentChecked(val => !val);
    };
    useEffect(() => {
        sessionStorage.setItem(
            STORAGE_CONFIG.SESSION_STORAGE.MARKETOFEED,
            userCompanyID == 1 ? true : guestUserDetails.marketoFeed
        );
    }, [guestUserDetails.marketoFeed]);

    useEffect(() => {
        if (validFirstName && validLastName && validPhoneNumber && validEmail) {
            const checkEmailAvailability = async () => {
                try {
                    const { newUser, error } = await checkIsEmailAvailable(guestUserDetails.email);
                    // if is_email_available is false it means email is not available to create new account
                    if (!error && !newUser) {
                        setIsExistingUser(true);
                        return;
                    }
                    localStorage.removeItem('yourDetails');
                    handleChange(YOUR_DETAILS_FIELDS.FIRST_NAME, guestUserDetails.firstName?.trim());
                    handleChange(YOUR_DETAILS_FIELDS.LAST_NAME, guestUserDetails.lastName?.trim());
                    handleStepChange(formStep.HTGO);
                } catch (err) {
                    logError(err, false, 'checkEmailAvailability');
                }
            };
            checkEmailAvailability();
        }
    }, [validFirstName, validLastName, validPhoneNumber, validEmail]);

    const signInHandler = () => {
        localStorage.setItem(
            'yourDetails',
            JSON.stringify({
                guestUserDetails: {
                    firstName: guestUserDetails?.firstName?.trim(),
                    lastName: guestUserDetails?.firstName?.trim(),
                    phoneNumber: guestUserDetails?.phoneNumber,
                    email: guestUserDetails?.email?.trim(),
                    marketoFeed: guestUserDetails?.marketoFeed
                }
            })
        );
        window.location.href = config.pagePaths.signInHandler();
    };

    const renderEditableYourDetails = () => {
        return (
            <>
                {isLoading && (
                    <div className={'loaderStyle'}>
                        <LoadingIndicator />
                    </div>
                )}
                <Name
                    name="firstName"
                    value={guestUserDetails.firstName}
                    handleInputChange={handleFieldChange}
                    dataTestId={checkoutDatalocator.checkout_yourdetails_firstName_txtfield_testid}
                    onValidate={setValidFirstName}
                    onErrorAnalytics={error => {
                        onErrorAnalytics(error, VARIABLE_CONFIG.CHECKOUT_YOUR_DETAILS_FIELDS.FIRST_NAME);
                    }}
                    submit={submit}
                    label={intl.formatMessage({ id: 'checkout:your-details-first-name' })}
                    inputAriaLabel={intl.formatMessage({ id: 'checkout:your-details-first-name-placeholder' })}
                    setSubmit={setSubmit}
                    required={required}
                    inputAriaRequired={true}
                />
                <Name
                    name="lastName"
                    label={intl.formatMessage({ id: 'checkout:your-details-last-name' })}
                    value={guestUserDetails.lastName}
                    handleInputChange={handleFieldChange}
                    inputAriaLabel={intl.formatMessage({ id: 'checkout:your-details-last-name-placeholder' })}
                    placeholder={intl.formatMessage({ id: 'checkout:your-details-last-name-placeholder' })}
                    dataTestId={checkoutDatalocator.checkout_yourdetails_lastName_txtfield_testid}
                    onValidate={setValidLastName}
                    onErrorAnalytics={error => {
                        onErrorAnalytics(error, VARIABLE_CONFIG.CHECKOUT_YOUR_DETAILS_FIELDS.LAST_NAME);
                    }}
                    required={required}
                    submit={submit}
                    inputAriaRequired={true}
                    setSubmit={setSubmit}
                />
                <PhoneNumber
                    label={intl.formatMessage({ id: 'create-project:phone-number' })}
                    value={guestUserDetails.phoneNumber}
                    handleInputChange={handleFieldChange}
                    dataTestId={checkoutDatalocator.checkout_yourdetails_phoneNumber_txtfield_testid}
                    onValidate={setValidPhoneNumber}
                    onErrorAnalytics={error => {
                        onErrorAnalytics(error, VARIABLE_CONFIG.CHECKOUT_YOUR_DETAILS_FIELDS.PHONE);
                    }}
                    submit={submit}
                    inputAriaLabel={intl.formatMessage({ id: 'create-project:phone-number-placeholder' })}
                    setSubmit={setSubmit}
                    required={required}
                    disclaimerMsg={phoneNumberDisclaimerSelf}
                />
                <Email
                    label={intl.formatMessage({ id: 'checkout:email-address' })}
                    value={guestUserDetails.email}
                    handleInputChange={handleFieldChange}
                    inputAriaLabel={intl.formatMessage({ id: 'checkout:your-details-email-address-placeholder' })}
                    dataTestId={checkoutDatalocator.checkout_yourdetails_emailAddress_txtfield_testid}
                    onValidate={setValidEmail}
                    onErrorAnalytics={error => {
                        onErrorAnalytics(error, VARIABLE_CONFIG.CHECKOUT_YOUR_DETAILS_FIELDS.EMAIL);
                    }}
                    required={required}
                    submit={submit}
                    setSubmit={setSubmit}
                    isExistingUser={isExistingUser}
                    setIsExistingUser={setIsExistingUser}
                />
                {isExistingUser && (
                    <div className="signInButton">
                        <Button
                            onClick={signInHandler}
                            type="button"
                            buttonAriaLabel={intl.formatMessage({ id: 'guest-checkout:title1' })}
                            className={'button button-outline-primary button-pb0'}
                            data-testid={checkoutDatalocator.checkout_yourdetails_signin_your_account}>
                            {intl.formatMessage({ id: 'guest-checkout:title1' })}
                        </Button>
                    </div>
                )}
                {userCompanyID == 2 && (
                    <div className="mktoFeedCheckboxContainer">
                        <Checkbox
                            label={renderMarketoLabel()}
                            isChecked={isMarketoConsentChecked}
                            onClick={handleMarketoFeed}
                            dataTestId={checkoutDatalocator.marketing_opt_in_message}
                            customAriaLabel={intl.formatMessage({ id: 'marketing-opt-in-message' })}
                        />
                    </div>
                )}
                <div
                    className={`${isExistingUser ? 'continueBtnWithSignIn' : 'continueBtn'}`}
                    data-testid={checkoutDatalocator.checkout_continue_CTA}>
                    <Button
                        type="button"
                        onClick={onContinue}
                        buttonAriaLabel={intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                        className="button button-primary button-block"
                        data-testid={checkoutDatalocator.checkout_continue_cta_testid}>
                        {intl.formatMessage({ id: 'checkout:save-continue-cta' })}
                    </Button>
                </div>
            </>
        );
    };

    return (
        <EditableView
            children={renderEditableYourDetails()}
            currentStep={currentStep}
            currentTitle={intl.formatMessage({ id: 'checkout:your-details-title' })}
            testId={checkoutDatalocator.checkout_yourdetails_link_testid}
        />
    );
};

export default EditYourDetails;

EditYourDetails.propTypes = {
    currentStep: number,
    handleStepChange: func,
    formStep: object
};
