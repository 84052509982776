import React, { useState, useEffect } from 'react';
import Input from '../../../atoms/input/Input';
import { intl, validateName, validatePrimaryContact } from '../../../utils/commonUtils';
import { validateNameField } from '../validateFields';

const Name = props => {
    const {
        label,
        name,
        handleInputChange,
        value,
        placeholder,
        dataTestId,
        inputAriaLabel,
        required,
        onValidate,
        requiredErrorMsg,
        formatErrorMsg,
        submit,
        setSubmit,
        errorId,
        inputAriaRequired,
        onErrorAnalytics
    } = props;

    const [errorMsg, setErrorMsg] = useState('');
    const validationMethod = () => {
        if (name === 'primaryContact') {
            return validatePrimaryContact;
        } else return validateName;
    };
    useEffect(() => {
        if (name === 'lastName') {
            validateNameField(
                required,
                value,
                setErrorMsg,
                onValidate,
                validationMethod(),
                submit,
                setSubmit,
                intl.formatMessage({ id: 'common:required-last-name-error' }),
                formatErrorMsg,
                onErrorAnalytics
            );
        } else {
            validateNameField(
                required,
                value,
                setErrorMsg,
                onValidate,
                validationMethod(),
                submit,
                setSubmit,
                requiredErrorMsg,
                formatErrorMsg,
                onErrorAnalytics
            );
        }
    }, [submit]);

    const validateNameFunc = text => {
        handleInputChange(text);
        setErrorMsg('');
        onValidate(false);
    };

    return (
        <Input
            label={label}
            name={name}
            value={value}
            placeholder={placeholder}
            handleInputChange={validateNameFunc}
            dataTestId={dataTestId}
            inputAriaLabel={inputAriaLabel}
            errorMsg={errorMsg}
            aria-required={inputAriaRequired}
            errorId={errorId}
        />
    );
};

export default Name;

Name.defaultProps = {
    name: 'firstName',
    inputAriaLabel:"",
    label: intl.formatMessage({ id: 'checkout:your-details-first-name' }),
    placeholder: intl.formatMessage({ id: 'checkout:your-details-first-name-placeholder' }),
    requiredErrorMsg: intl.formatMessage({ id: 'common:required-first-name-error' }),
    formatErrorMsg: intl.formatMessage({id: 'common:input-format-error'}),
    inputAriaRequired:false,
    inputAriaInvalid:false,
    onErrorAnalytics: ()=>{}
};
