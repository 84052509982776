import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons/AlertModalWithButtons';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useCheckUser } from '../../../hooks/useCheckUser';
import { ReadOnlyView } from '../../global/modules/stepForm';
import useCheckout from '../../../hooks/useCheckout';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import InstoreIcon from '../../../resources/images/instore.svg';
import ClockIcon from '../../../resources/images/clock.svg';
import ClockIcon2 from '../../../resources/images/pickup-time-icon.svg';
import './howToGetOrder.scss';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { useSunbeltLocation } from '../../../hooks/useSunbeltLocation';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import useComputeLocation from '../../../hooks/useComputeLocation';
import { logError } from '../../global/utils/logger';
import { useFilterState } from '../../cap';

const ReadOnlyHowToGetOrder = props => {
    const [{ userInfo }] = useCartState();
    const [{viewCart, projectDetails}, filterDispatch] = useFilterState();
    const intl = useIntl();
    const { clearCheckout } = useCheckout();
    const [showAlertModal, setShowAlertModal] = useState(false);
    const { sendEventsForClick } = useAnalyticsContext();
    const {
        handleStepChange,
        isInstore,
        storeDetails,
        startDate,
        endDate,
        deliveryTime,
        pickupTime,
        selectedStartTime,
        selectedEndTime,
        formStep
    } = props;
    const { storesCalls } = useComputeLocation();
    const sunbeltLocation = useSunbeltLocation();
    const userType = useCheckUser();
    const editHandler = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userType.toLowerCase()} select`,
                `${intl.formatMessage({ id: 'checkout:how-to-get-your-order-title' }).toLowerCase()} edit`
            );
        } catch (error) {
            logError(error, false, 'editHandler');
        }
        setShowAlertModal(true);
    };

    const handleMakeChangesClick = () => {
        if (isInstore) {
            storesCalls();
        }
        setShowAlertModal(false);
        handleStepChange(formStep.HTGO);
        clearCheckout(formStep, formStep.HTGO);
    };

    const handleCancelClick = () => {
        setShowAlertModal(false);
    };

    const renderReadOnlyBody = () => {
        return (
            <>
                {isInstore && (
                    <div className="order-details-item">
                        <InstoreIcon />
                        <div className="order-details-desc">
                            <div className="order-details-title">In-Store Pickup</div>
                            <span className="order-details-name">{storeDetails?.name}</span>
                            <span className="order-details-address">{storeDetails?.street}</span>
                            <span className="order-details-address">
                                {storeDetails?.city}, {storeDetails?.state} {storeDetails?.zip}{' '}
                            </span>
                        </div>
                    </div>
                )}
                <div className="order-details-item">
                    <ClockIcon />
                    <div className="order-details-desc">
                        <div className="order-details-title">{isInstore ? 'Pickup time' : 'Delivery time'}</div>
                        {isInstore ? (
                            <div>
                                {startDate} at {selectedStartTime}
                            </div>
                        ) : (
                            <div>
                                {startDate}, {deliveryTime}
                            </div>
                        )}
                    </div>
                </div>
                {userInfo.isCreditCustomer && !isInstore ? null : (
                    <div className="order-details-item">
                        <ClockIcon2 />
                        <div className="order-details-desc">
                            <div className="order-details-title"> {isInstore ? 'Return time' : 'Pickup time'}</div>
                            {isInstore ? (
                                <div>
                                    {endDate} at {selectedEndTime}
                                </div>
                            ) : (
                                <div>
                                    {endDate}, {pickupTime}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <ReadOnlyView
                children={renderReadOnlyBody()}
                title={intl.formatMessage({ id: 'checkout:how-to-get-your-order-title' })}
                testId={checkoutDatalocator.checkout_howtogetyourorder_link_testid}
                onEdit={editHandler}
            />
            <AlertModalWithButtons
                isOpen={showAlertModal}
                title={intl.formatMessage({ id: 'checkout:alert-heading' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:alert-body-content' })}
                handleContinueClick={handleMakeChangesClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:alert-body-button' })}
                handleCancelClick={handleCancelClick}
                cancelButtonText={intl.formatMessage({ id: 'account:address-delete-cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_crossicon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_makechanges_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_cancel_CTA_testid}
                titleDataLocator={checkoutDatalocator.checkout_doyouwanttomakechanges_heading_testid}
                warningQuestionDataLocator={checkoutDatalocator.checkout_changesrequiredyourinformartion_labels_testid}
                alertTooltipDataLocator={checkoutDatalocator.checkout_makechangesmodal_tooltip_testid}
            />
        </>
    );
};

export default ReadOnlyHowToGetOrder;
