import React from 'react';
import { useIntl } from 'react-intl';
import './orderdetails.scss';
import InstoreIcon from '../../../../resources/images/instore.svg';
import DateIcon from '../../../../resources/images/date.svg';
import CarIcon from '../../../../resources/images/car.svg';
import { checkoutDatalocator } from '../../../checkout/checkoutAndOrderSummary/dataLocators';
import { ReadOnlyView } from '../stepForm';

const OrderDetails = props => {
    const { address, onEdit, startDate, endDate, isCredit, isInStorePickup } = props;
    const intl = useIntl();

    const renderOrderDetails = () => {
        return (
            <>
                <div className="readonly-details-item">
                    {isInStorePickup ? <InstoreIcon /> : <CarIcon />}
                    <div className="readonly-details-desc">
                        <div
                            className="readonly-details-title"
                            data-testid={checkoutDatalocator.checkout_instorePickUp_label_testid}>
                            {isInStorePickup
                                ? intl.formatMessage({ id: 'order-details:in-store-heading' })
                                : intl.formatMessage({ id: 'order-details:round-trip-heading' })}
                        </div>
                        {isCredit && <span className="readonly-details-address">{isCredit}</span>}
                        <span
                            className="readonly-details-address"
                            data-testid={checkoutDatalocator.checkout_InstorePickUp_Address_label_testid}>
                            {address?.location?.split(',')[0] || address?.projectAddress1}
                        </span>
                        <span className="readonly-details-address">{address?.jobSiteAddr2 || ''}</span>
                        <span className="readonly-details-address">
                            {address?.jobSiteCity || address?.selectedProjectCity}
                            {', '}
                            {address?.jobSiteState || address?.selectedProjectState}{' '}
                            {address?.jobSiteZip || address?.selectedProjectZip}
                        </span>
                    </div>
                </div>
                <div className="readonly-details-item">
                    <DateIcon />
                    <div className="readonly-details-desc">
                        <div
                            className="readonly-details-title"
                            data-testid={checkoutDatalocator.checkout_instoreStartandEndDate_label_testid}>
                            {startDate} - {endDate}
                        </div>
                    </div>
                </div>
            </>
        );
    };

    return (
        <ReadOnlyView
            children={renderOrderDetails()}
            title={intl.formatMessage({ id: 'checkout:order-details-title' })}
            testId={checkoutDatalocator.checkout_orderdetails_editlink_testid}
            onEdit={onEdit}
        />
    );
};

export default OrderDetails;
