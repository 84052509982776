import React from 'react';
import { useIntl } from 'react-intl';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import Button from '../../../global/atoms/button';
import { checkoutDatalocator } from '../dataLocators';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import classes from './roundTripGuestOrderDetails.css';
import config from '../../../App/config';
import { logError } from '../../../global/utils/logger';

const RoundTripGuestOrderDetails = props => {
    const intl = useIntl();
    const { sendEventsForClick } = useAnalyticsContext();
    const handleMakeAccountClick = () => {
        //TODO add navigation to Create account screen
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                VARIABLE_CONFIG.EVENT_ACTION.GUEST_REGISTRATION,
                intl.formatMessage({ id: 'account:create-account' }).toLowerCase()
            );
        } catch (error) {
            logError(error, false, 'handleMakeAccountClick');
        }
        window.location.href = config.pagePaths.createAccount;
    };

    const signInHandler = () => {
        window.location.href = config.pagePaths.signInHandler();
    };
    
    return (
        <div className={classes.rootComponent}>
            <p className={classes.message} data-testid={checkoutDatalocator.guest_deliveryavailable_label_testid}>
                {intl.formatMessage({ id: 'checkout:guest-user-message' })}
            </p>
            <div className={classes.accountButton}>
                <Button
                    className="button button-primary button-block button-marginbottom"
                    type="button"
                    onClick={handleMakeAccountClick}
                    buttonAriaLabel={intl.formatMessage({ id: 'checkout:guest-user-make-account-btn' })}
                    data-testid={checkoutDatalocator.guest_createFreeAccount_cta_testid}>
                    {intl.formatMessage({ id: 'checkout:guest-user-make-account-btn' })}
                </Button>
            </div>
            <Button
                className="button button-outline-primary button-block button-marginbottom"
                type="button"
                onClick={signInHandler}
                buttonAriaLabel={intl.formatMessage({ id: 'guest-checkout:signin-cta' })}
                data-testid={checkoutDatalocator.guest_signIn_cta_testid}>
                {intl.formatMessage({ id: 'guest-checkout:signin-cta' })}
            </Button>
        </div>
    );
};
export default RoundTripGuestOrderDetails;
