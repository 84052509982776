import moment from 'moment';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import useCheckout from '../../../hooks/useCheckout';
import { useCheckUser } from '../../../hooks/useCheckUser';
import AlertModalWithButtons from '../../global/atoms/AlertModalWithButtons/AlertModalWithButtons';
import OrderDetails from '../../global/modules/orderdetails';
import { logError } from '../../global/utils/logger';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { useFilterState } from '../../cap';

const ReadOnlyOrderDetails = props => {
    const [{ userInfo }] = useCartState();
    const [{viewCart, projectDetails, startDate, endDate}, filterDispatch] = useFilterState();
    const { handleStepChange, formStep } = props;
    const intl = useIntl();
    const { clearCheckout } = useCheckout();
    const { isInStorePickup } = viewCart;
    const [showAlertModal, setShowAlertModal] = useState(false);
    const userType = useCheckUser();
    const { sendEventsForClick } = useAnalyticsContext();

    const getPrefilledLocationObject = () => {
        if (userInfo?.isCreditCustomer) {
            return projectDetails;
        } else {
            return viewCart;
        }
    };

    const onEdit = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userType.toLowerCase()} select`,
                `${intl.formatMessage({ id: 'checkout:order-details-title' }).toLowerCase()} edit`
            );
        } catch (error) {
            logError(error, false, 'onEdit');
        }
        setShowAlertModal(true);
    };

    const handleMakeChangesClick = () => {
        setShowAlertModal(false);
        handleStepChange(formStep.ORDER_DETAILS);
        clearCheckout(formStep, formStep.ORDER_DETAILS);
    };

    const handleCancelClick = () => {
        setShowAlertModal(false);
    };

    return (
        <>
            <OrderDetails
                isCredit={userInfo?.isCreditCustomer && userInfo?.isAuthenticatedUser ? userInfo.AccountName : ''}
                address={getPrefilledLocationObject()}
                startDate={moment(startDate).format('MMM DD, YYYY')}
                endDate={moment(endDate).format('MMM DD, YYYY')}
                onEdit={onEdit}
                isInStorePickup={isInStorePickup}
            />
            <AlertModalWithButtons
                isOpen={showAlertModal}
                title={intl.formatMessage({ id: 'checkout:alert-heading' })}
                warningQuestion={intl.formatMessage({ id: 'checkout:alert-body-content' })}
                handleContinueClick={handleMakeChangesClick}
                continueButtonText={intl.formatMessage({ id: 'checkout:alert-body-button' })}
                handleCancelClick={handleCancelClick}
                cancelButtonText={intl.formatMessage({ id: 'account:address-delete-cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_crossicon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_makechanges_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_cancel_CTA_testid}
                titleDataLocator={checkoutDatalocator.checkout_doyouwanttomakechanges_heading_testid}
                warningQuestionDataLocator={checkoutDatalocator.checkout_changesrequiredyourinformartion_labels_testid}
                alertTooltipDataLocator={checkoutDatalocator.checkout_makechangesmodal_tooltip_testid}
            />
        </>
    );
};

export default ReadOnlyOrderDetails;
