import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { RESET_CLICKS, SET_HOW_TO_GET_YOUR_ORDER_FIELDS } from '../../../../aem-core-components/actions/constants';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import { HOW_TO_GET_YOUR_ORDER } from '../../../../aem-core-components/components/Minicart/constants';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../../../constants/analyticsConstants/Ecommerce';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import useAnalytics from '../../../../hooks/useAnalytics';
import { useCheckAuthorityType } from '../../../../hooks/useCheckUser';
import useMedia from '../../../../hooks/useMedia';
import Error from '../../../../resources/images/error.svg';
import { useFilterState } from '../../../cap';
import { getDateDiffInHrs } from '../../../cap/utils/atputils';
import Alert from '../../../global/atoms/alert/alert';
import Dropdown from '../../../global/atoms/dropdown';
import { AUTHORITY_TYPE } from '../../../global/constants';
import RangePickerInput from '../../../global/modules/rangepicker';
import UnavailableRental from '../../../global/modules/unavailableRental/UnavailableRental';
import { filterStores } from '../../orderDetailsCheckout/utils/editOrderDetailsFunctions';
import { getDifferenceinHours, isDateDisabled } from '../../../global/utils/commonUtils';
import { logError, logWarning } from '../../../global/utils/logger';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import { CHECKOUT_PICKUPTIME_ID, CHECKOUT_RETURNTIME_ID } from '../../constants';
import './../howToGetOrder.scss';

export const PickUpandReturnTime = ({
    isPickUpError,
    pickUpTimeSlots,
    returnTimeSlots,
    selectedReturnTime,
    selectedPickupTime,
    isReturnError,
    setIsReturnError,
    setSelectedPickupTime,
    setSelectedReturnTime,
    currentOffSet,
    selectedStoreValueForDate,
    startDateError,
    endDateError,
    setStartDateError,
    setEndDateError,
    selectedEndDate,
    selectedStartDate,
    setSelectedEndDate,
    setSelectedStartDate,
    setNewDatesRequired,
    setIsPickUpError,
    resetPickupAndReturnTime,
    checkoutErrorHandlingAnalytics
}) => {
    const [{ howToGetYourOrderDetails, calendarDateInteraction }, dispatch] = useCartState();
    const [{ startDate, endDate, selectedStoreDetails }] = useFilterState();
    const intl = useIntl();
    const mediaType = useMedia();
    const [{ sendAnalyticsDatePickerViewEvent, payloadEcommerceActionAnalytics, sendAnalyticsDatePickerModalEvents }] =
        useAnalytics();
    const [minDate, setMinDate] = useState(new Date());
    const [showWarning, setShowWarning] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isEndDateEmpty, setIsEndDateEmpty] = useState(false);
    const [isStartDateEarly, setIsStartDateEarly] = useState(false);
    const [rangeState, setRangeState] = useState([
        {
            key: 'selection',
            startDate: startDate ? new Date(startDate) : new Date(),
            endDate: endDate ? new Date(endDate) : new Date(),
            color: '#F2F2F2'
        }
    ]);
    const { sendEventsForClick, sendEventsForDatePickerFormInteraction } = useAnalyticsContext();
    const isHolidayDate = isDateDisabled();
    const authorityType = useCheckAuthorityType();
    const isP2P = [AUTHORITY_TYPE.P2P].includes(authorityType);

    useEffect(() => {
        if (notInBestMatch()) {
            setSelectedEndDate('');
            setSelectedStartDate(setDefaultStartDate);
            setMinDate(setDefaultStartDate);
        }
        if (!notInBestMatch()) {
            setSelectedStartDate(startDate);
            setSelectedEndDate(endDate);
        }
    }, [selectedStoreValueForDate, howToGetYourOrderDetails.bestMatchStoresData]);

    useEffect(() => {
        if (pickUpTimeSlots && pickUpTimeSlots.length > 0 && !notInBestMatch()) {
            setNewDatesRequired(false);
        } else {
            setNewDatesRequired(true);
        }
    }, [pickUpTimeSlots]);

    const isStoreUnavailableToday = () => {
        if (moment(startDate).format('MMM DD, YYYY') === moment(currentOffSet).format('MMM DD, YYYY')) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (returnTimeSlots?.length === 0) {
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'htgo:store-return-date-closed-error' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.START_DATE
            );
        }
    }, []);

    const choseStoreAnalytics = () => {
        if (
            (new Date(startDate)?.getDay() == 0 && !selectedStoreDetails?.operatingHours?.[0]?.isOpen) ||
            (new Date(startDate)?.getDay() == 6 && !selectedStoreDetails?.operatingHours?.[6]?.isOpen)
        ) {
            try {
                sendEventsForClick(
                    VARIABLE_CONFIG.EVENT.UAEVENT,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                    VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                    `${VARIABLE_CONFIG.EVENT_ACTION.IN_STORE_PICKUP_ERROR}::${intl.formatMessage({
                        id: 'in-store_weekend_date'
                    })}`,
                    `${moment(startDate).format('l')}::${selectedStoreDetails?.pc}` // store number // pickup date
                );
            } catch (error) {
                logError(error, false, 'choseStoreAnalytics');
            }
        } else if (isStoreUnavailableToday()) {
            try {
                sendEventsForClick(
                    VARIABLE_CONFIG.EVENT.UAEVENT,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                    VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                    `${VARIABLE_CONFIG.EVENT_ACTION.IN_STORE_PICKUP_ERROR}::${intl.formatMessage({
                        id: 'start_date_updated_based_on_store_availability'
                    })}`,
                    `${moment(startDate).format('l')}::${selectedStoreDetails?.pc}` // store number // pickup date
                );
            } catch (error) {
                logError(error, false, 'choseStoreAnalytics');
            }
        }
    };
    useEffect(() => {
        choseStoreAnalytics();
    }, [isStoreUnavailableToday()]);

    const updateDateContext = (startDate, endDate) => {
        const diffInHours = getDateDiffInHrs(rangeState[0]?.startDate, moment().format('YYYY-MM-DDTHH:mm:ss'));
        if (
            rangeState[0]?.startDate != '' &&
            new Date(rangeState[0]?.startDate).getTime() == new Date(rangeState[0]?.endDate).getTime()
        ) {
            setShowWarning(true);
            return false;
        } else if (rangeState[0]?.endDate == '' || rangeState[0]?.startDate == '') {
            setIsEndDateEmpty(true);
            setShowWarning(true);
            return false;
        } else if (diffInHours <= 48 && isP2P) {
            setIsStartDateEarly(true);
            setShowWarning(true);
            return false;
        } else {
            resetPickupAndReturnTime();
            return true;
        }
    };

    const handleToggle = isDateClick => {
        try {
            if (!isOpen) {
                handleDatePickerViewAnalytics({ isDateClick });
            }
            if (isOpen && rangeState?.[0]?.startDate != '' && rangeState?.[0]?.endDate == '') {
                setIsEndDateEmpty(true);
                setShowWarning(true);
                return;
            }
            setIsOpen(!isOpen);
            setShowWarning(false);
            setIsEndDateEmpty(false);
        } catch (error) {
            logError(error, false, 'isDateClick');
        }
    };

    const setDefaultStartDate = () => {
        let nextAvailableDate = new Date(startDate);
        //T + N logic
        const dow = moment(startDate).day();
        let addDays = 4;
        if (dow === 3) {
            addDays = 5; //Add 5 days if selected start day is wednesday
        }
        if (dow === 2) {
            addDays = 6; //Add 6 days if selected start day is tuesday
        }
        nextAvailableDate.setDate(nextAvailableDate.getDate() + addDays);
        return nextAvailableDate;
    };
    const makePickupSlotArray = () => {
        const arrayToSend = [];
        pickUpTimeSlots.forEach(item => {
            const obj = {};
            obj.label = item;
            obj.value = item;
            arrayToSend.push(obj);
        });

        return arrayToSend;
    };

    const makeReturnSlotArray = () => {
        const arrayToSend = [];
        returnTimeSlots.forEach(item => {
            const obj = {};
            obj.label = item;
            obj.value = item;
            arrayToSend.push(obj);
        });
        return arrayToSend;
    };

    const handlePickupTime = selectedValue => {
        setIsPickUpError(false);
        setSelectedPickupTime(selectedValue);
        dispatch({
            type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
            key: HOW_TO_GET_YOUR_ORDER.SELECTED_PICKUP_TIME,
            value: selectedValue
        });
        setIsReturnError(false);
        if (selectedReturnTime && selectedPickupTime) {
            const start = moment(selectedStartDate);
            const startDateFormat = start.format('yyyy-MM-DD');
            const enddate = moment(selectedEndDate);
            const endDateFormat = enddate.format('yyyy-MM-DD');
            const sd = moment(`${startDateFormat} ${selectedValue}`, 'yyyy-MM-DD hh:mm A');
            const ed = moment(`${endDateFormat} ${selectedReturnTime}`, 'yyyy-MM-DD hh:mm A');
            const difference = getDifferenceinHours(sd, ed);
            if (difference < 24) {
                checkoutErrorHandlingAnalytics(
                    intl.formatMessage({ id: 'checkout:return-time-less-than-24hrs' }),
                    VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.RETURN_TIME
                );
                setIsReturnError(true);
            }
        }
    };

    const handleReturnTime = selectedValue => {
        setIsReturnError(false);
        setSelectedReturnTime(selectedValue);
        dispatch({
            type: SET_HOW_TO_GET_YOUR_ORDER_FIELDS,
            key: HOW_TO_GET_YOUR_ORDER.SELECTED_RETURN_TIME,
            value: selectedValue
        });
        // todo-------
        const start = moment(selectedStartDate);
        const startDateFormat = start.format('yyyy-MM-DD');
        const enddate = moment(selectedEndDate);
        const endDateFormat = enddate.format('yyyy-MM-DD');
        const sd = moment(`${startDateFormat} ${selectedPickupTime}`, 'yyyy-MM-DD hh:mm A');
        const ed = moment(`${endDateFormat} ${selectedValue}`, 'yyyy-MM-DD hh:mm A');
        const difference = getDifferenceinHours(sd, ed);
        if (difference < 24) {
            setIsReturnError(true);
            checkoutErrorHandlingAnalytics(
                intl.formatMessage({ id: 'checkout:return-time-less-than-24hrs' }),
                VARIABLE_CONFIG.CHECKOUT_FIELD_NAMES.RETURN_TIME
            );
            logWarning(intl.formatMessage({ id: 'common:required-input-error' }));
        }
    };
    const notInBestMatch = () => {
        //To check if a store in closest stores list is in Best Match
        let flag = false;
        const currentDatePlusOne = moment().add(1, 'days');
        let startDateLocal = moment(startDate);
        let filterStoreBasedOnDate = filterStores(howToGetYourOrderDetails.bestMatchStoresData, startDate);
        filterStoreBasedOnDate?.map(data => {
            if (data?.pc === selectedStoreValueForDate) {
                if (!(data?.isLastResortBranch && startDateLocal.isSameOrBefore(currentDatePlusOne))) {
                    flag = true;
                }
            }
        });
        if (flag) {
            return false;
        }
        return true;
    };

    const renderTimeSlotsWithoutErrors = isOriginalStartDate => {
        return (
            <div className={'HTGO_timeContainerRoot'}>
                <div className={'HTGO_timeContainer'}>
                    <div className={'HTGO_dateContainerItem'}>
                        <span data-testid={checkoutDatalocator.checkout_rentalsstart_label_testid}>
                            {intl.formatMessage({ id: 'checkout:rental-start' })}
                        </span>
                        <span>{`: `}</span>
                        <span data-testid={checkoutDatalocator.checkout_pickUptime_field_testid}>
                            {moment(isOriginalStartDate ? startDate : selectedStartDate).format('MMM DD, YYYY')}
                        </span>
                    </div>
                    <label htmlFor={CHECKOUT_PICKUPTIME_ID}>
                        <small className={'HTGO_dateContainerLabel'}>{intl.formatMessage({ id: 'pickup_time' })}</small>
                    </label>
                    <Dropdown
                        isError={isPickUpError}
                        options={makePickupSlotArray() || []}
                        onChangeDropdownValue={handlePickupTime}
                        selectedState={selectedPickupTime}
                        inputlabel={CHECKOUT_PICKUPTIME_ID}
                        dropDownTestId={checkoutDatalocator.checkout_PickUpTimedropdown_label_testid}
                        placeholderText={intl.formatMessage({ id: 'checkout:available-times' })}
                    />
                    {isPickUpError && (
                        <div className="HTGO_inlineError">
                            <Error />
                            <small>{intl.formatMessage({ id: 'common:required-input-error' })}</small>
                        </div>
                    )}
                </div>
                <div className={'HTGO_timeContainer'}>
                    <div className={'HTGO_dateContainerItem'}>
                        <span data-testid={checkoutDatalocator.checkout_rentalsend_label_testid}>
                            {intl.formatMessage({ id: 'checkout:rental-end' })}
                        </span>
                        <span>{`: `}</span>
                        <span data-testid={checkoutDatalocator.checkout_returnTime_field_testid}>
                            {moment(isOriginalStartDate ? endDate : selectedEndDate).format('MMM DD, YYYY')}
                        </span>
                    </div>
                    <label htmlFor={CHECKOUT_RETURNTIME_ID}>
                        <small className={'HTGO_dateContainerLabel'}>{intl.formatMessage({ id: 'return_time' })}</small>
                    </label>
                    <Dropdown
                        isError={isReturnError}
                        options={makeReturnSlotArray()}
                        onChangeDropdownValue={handleReturnTime}
                        selectedState={selectedReturnTime}
                        inputlabel={CHECKOUT_RETURNTIME_ID}
                        dropDownTestId={checkoutDatalocator.checkout_ReturnTimedropdown_label_testid}
                        placeholderText={intl.formatMessage({ id: 'checkout:available-times' })}
                    />
                    {isReturnError && (
                        <div className="HTGO_inlineError">
                            <Error />
                            <small>
                                {selectedReturnTime
                                    ? intl.formatMessage({ id: 'checkout:return-time-less-than-24hrs' })
                                    : intl.formatMessage({ id: 'common:required-input-error' })}
                            </small>
                        </div>
                    )}
                    {returnTimeSlots.length === 0 && (
                        <div className="HTGO_inlineError">
                            <Error />
                            <small>{intl.formatMessage({ id: 'htgo:store-return-date-closed-error' })}</small>
                        </div>
                    )}
                </div>
            </div>
        );
    };
    const showCantFullfillAlert = () => {
        if (!(selectedStartDate && selectedEndDate) || !pickUpTimeSlots.length) {
            return (
                <div className={'pickUpReturn_alertWarning'}>
                    <Alert
                        className="alert alert-withmargin"
                        type={'warning'}
                        message={intl.formatMessage({ id: 'store_cant_fullfill' })}
                        data-testid={checkoutDatalocator.checkout_this_store_cant_fillful}
                    />
                </div>
            );
        }
        return '';
    };

    const showTimeDropdowns = () => {
        setNewDatesRequired(true);
        if (selectedStartDate && selectedEndDate) {
            return renderTimeSlotsWithoutErrors(false);
        }
        return '';
    };

    const storesNotAvailableToday = () => {
        return (
            <div className={'pickUpReturn_warningMessage'}>
                {isStoreUnavailableToday() ? (
                    <>
                        <Alert
                            className="alert alert-withmargin"
                            type={'warning'}
                            message={intl.formatMessage({ id: 'store_cant_fullfill_today' })}
                        />
                    </>
                ) : (
                    <>
                        <Alert
                            className="alert alert-withmargin"
                            type={'warning'}
                            message={intl.formatMessage({ id: 'store_cant_fullfill' })}
                        />
                    </>
                )}
            </div>
        );
    };

    const checkIfDayDisabled = date => {
        if (isHolidayDate(date)) {
            return true;
        }
        const item = selectedStoreDetails?.operatingHours?.filter(day => {
            return day.dayOfWeek === date.getDay();
        });
        if (item && item?.[0]?.isOpen) return false;
        else return true;
    };

    const handleResetClick = () => {
        dispatch({ type: RESET_CLICKS });
        dispatch({ type: 'open' });
    };

    const handleDatePickerViewAnalytics = ({ isDateClick }) => {
        sendAnalyticsDatePickerViewEvent(isDateClick);
    };

    const handleDateEventsInteraction = date => {
        sendEventsForDatePickerFormInteraction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_PICKER_INTERACTION,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATE_FORM_NAME,
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_START_DATE_FORM_FIELD,
            date,
            payloadEcommerceActionAnalytics(false)
        );
    };

    const sendDatePickerModalEvents = (eventName, startDate, endDate) => {
        sendAnalyticsDatePickerModalEvents(eventName, startDate, endDate);
    };

    const renderErrorState = () => {
        if (notInBestMatch()) {
            return (
                <>
                    {' '}
                    {showCantFullfillAlert()}
                    {(!selectedStartDate || !selectedEndDate || !pickUpTimeSlots.length) && (
                        <div
                            className={'pickUpReturn_choose_from_available_dates'}
                            data-testid={checkoutDatalocator.checkout_choose_a_store_from_available_dates}>
                            {intl.formatMessage({ id: 'store_choose_from_available_dates' })}
                        </div>
                    )}
                    <RangePickerInput
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        selectedStartDate={selectedStartDate.toString()}
                        selectedEndDate={selectedEndDate.toString()}
                        setSelectedEndDate={setSelectedEndDate}
                        setSelectedStartDate={setSelectedStartDate}
                        checkIfDayDisabled={checkIfDayDisabled}
                        minDate={minDate}
                        endDateError={endDateError}
                        startDateError={startDateError}
                        setEndDateError={setEndDateError}
                        setStartDateError={setStartDateError}
                        saveOnSelectDate={false}
                        handleDatesOnDoneClick={updateDateContext}
                        handleResetClick={handleResetClick}
                        sendDatePickerViewAnalytics={handleDatePickerViewAnalytics}
                        showWarning={showWarning}
                        setShowWarning={setShowWarning}
                        handleToggle={handleToggle}
                        renderWarningComponent={
                            <UnavailableRental
                                showWarning={showWarning}
                                isOpen={isOpen}
                                handleToggle={handleToggle}
                                isEndDateEmpty={isEndDateEmpty}
                                isStartDateEarly={isStartDateEarly}
                            />
                        }
                        isEndDateEmpty={isEndDateEmpty}
                        setIsEndDateEmpty={setIsEndDateEmpty}
                        isStartDateEarly={isStartDateEarly}
                        setIsStartDateEarly={setIsStartDateEarly}
                        rangeState={rangeState}
                        setRangeState={setRangeState}
                        handleDateEventsInteraction={handleDateEventsInteraction}
                        sendDatePickerModalEvents={sendDatePickerModalEvents}
                        calendarDateInteraction={calendarDateInteraction}
                        mediaType={mediaType}
                    />
                    {pickUpTimeSlots.length > 0 && showTimeDropdowns()}
                </>
            );
        } else {
            return storesNotAvailableToday();
        }
    };

    const renderTimeSlots = () => {
        if (pickUpTimeSlots !== null) {
            if (pickUpTimeSlots?.length > 0 && !notInBestMatch()) {
                // Do not render this if store was in stores near me and not in best match
                return renderTimeSlotsWithoutErrors(true);
            } else {
                return renderErrorState();
            }
        }
    };

    return <>{renderTimeSlots()}</>;
};
