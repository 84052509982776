import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LoadingIndicator } from '../../../aem-core-components';
import {
    RESET_LOCATION_DATA,
    SET_CREDIT_NEWADDR_FLAG,
    SET_LOCATION_DATA,
    SET_VIEW_CART_FIELDS,
    UPDATE_PROJECT
} from '../../../aem-core-components/actions/constants';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { VIEW_CART } from '../../../aem-core-components/components/Minicart/constants';
import { useUserContext } from '../../../aem-core-components/context/UserContext';
import isObjectEmpty from '../../../aem-core-components/utils/isObjectEmpty';
import { useAnalyticsContext } from '../../../config/GoogleTagManagerEvents';
import { EVENT_PAGE_NAMES_CONFIG } from '../../../constants/analyticsConstants/Page';
import { VARIABLE_CONFIG } from '../../../constants/analyticsConstants/Variables';
import { ENTER_KEY } from '../../../constants/screenConstants';
import { STORAGE_CONFIG } from '../../../constants/storageConfig';
import useComputeLocation from '../../../hooks/useComputeLocation';
import useMedia from '../../../hooks/useMedia';
import useRoundTrip from '../../../hooks/useRoundTrip';
import useAnalytics from '../../../hooks/useAnalytics';
import Close from '../../../resources/images/cross.svg';
import Button from '../../global/atoms/button/button';
import Input from '../../global/atoms/input';
import { getlatLongByAddress } from '../../global/modules/location-autocomplete/api/getLocations';
import {
    setPrefilledAddressForCreateProject,
    validateCity,
    validateJobSite,
    validateZip
} from '../../global/utils/commonUtils';
import { logError } from '../../global/utils/logger';
import StoreModal from '../StoreModal';
import CheckoutPageAlerts from '../checkoutPageAlert/CheckoutPageAlerts';
import { ADDERESS_NOT_VALIDATED, INVALID_PHONE_NUMBER } from '../constants';
import SuggestedAddressModal from '../suggestedAddressModal';
import './createProject.scss';
import { createProjectDataLocators } from './dataLocators';
import CreateProjectAddress from './projectAddress/CreateProjectAddress';
import ProjectInstructions from './projectInstructions';
import ReviewDuplicates from './reviewDuplicates';
import { validateProjectAddress, validateProjectInstructions } from './utils/validateFunctions';
import { useFilterState } from '../../cap';
import { useCheckAuthorityType } from '../../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../../global/constants';

const CreateProject = props => {
    const intl = useIntl();
    const [{ userAccount }, dispatch] = useCartState();
    const [{ viewCart }, filterDispatch, { setSettingsCookieFunc }] = useFilterState();
    const [{ accountProjects }, { getAccountProjectsDotCom, getProjectsFromCookie, addProject }] = useUserContext();
    const { sendEventsForClick, sendEventsForEcommercePage, sendEventsForPageUser } = useAnalyticsContext();
    const [{ payloadEcommerceLocationActionAnalytics }] = useAnalytics();
    const { createProjectSource, pc } = viewCart;
    const { jobSiteAddr2, jobSiteZip, jobSiteCity, jobSiteState, location, showCreateProject } = viewCart || '';
    const [projectName, setProjectName] = useState('');
    const [streetAddress, setStreetAddress] = useState(location || '');
    const [selectedState, setSelectedState] = useState(jobSiteState);
    const [selectedCity, setSelectedCity] = useState(jobSiteCity);
    const [selectedZip, setSelectedZip] = useState(jobSiteZip);
    const [selectedAddress2, setSelectedAddress2] = useState(jobSiteAddr2);
    const [userPrefilledAddress, setUserPrefilledAddress] = useState('');
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState('');
    const [validPhoneNumber, setValidPhoneNumber] = useState(false);
    const [primaryContact, setPrimaryContact] = useState('');
    const [accessNotes, setAccessNotes] = useState('');
    const [poNumber, setPONumber] = useState('');
    const [submit, setSubmit] = useState(false);
    const [validProjectAddress, setValidProjectAddress] = useState(false);
    const [validProjectInstructions, setValidProjectInstructions] = useState(false);
    const [duplicates, setDuplicates] = useState([]);
    const [showReviewDuplicates, setShowReviewDuplicates] = useState(false);
    const mediaType = useMedia();
    const forMobile = mediaType === 'MOBILE' || false;
    const [avsModal, setAvsModal] = useState(false);
    const [suggestedAddressList, setSuggestedAddressList] = useState([]);
    const [currentAddressObj, setCurrentAddressObj] = useState({});
    const [newProject, setNewProject] = useState({});
    const [selectedProject, setSelectedProject] = useState({});
    const [isNewProject, setIsNewProject] = useState(true);
    const [addressErrorText, setAddressErrorText] = useState('');
    const [phoneErrorText, setPhoneErrorText] = useState('');
    const [error, setError] = useState('');
    const [isCreateProjectLoading, setIsCreateProjectLoading] = useState(false);
    const { cityAndZipValidationAddressComponent, getPC, canadaRestrictStateError, setCanadaRestrictStateError } =
        useComputeLocation();
    const [isManualAddress, setIsManualAddress] = useState(false);
    const projectInfoCookies = getProjectsFromCookie();
    const [createProjectAddressError, setCreateProjectAddressError] = useState({
        address: '',
        city: '',
        state: '',
        zipcode: ''
    });
    const [projectInstructionsError, setProjectInstructionsError] = useState({
        primaryContact: '',
        accessNotes: '',
        poNumber: ''
    });
    const [isValidProjectName, setIsValidProjectName] = useState(false);
    const [projectNameError, setProjectNameError] = useState('');
    const [addressChangedByAvs, setAddressChangedByAvs] = useState(false);
    const [avsAddress, setAvsAddress] = useState({});
    const userCompanyID = parseInt(localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID)) || 1;
    const { setAvsError, avsError, getAvsResponse } = useRoundTrip();
    const authorityType = useCheckAuthorityType();

    useEffect(() => {
        callEvents();
    }, []);

    useEffect(() => {
        if (suggestedAddressList && suggestedAddressList.length > 0) setAvsModal(true);
    }, [suggestedAddressList]);

    useEffect(() => {
        setCanadaRestrictStateError('');
    }, [isManualAddress]);
    useEffect(() => {
        setAddressErrorText('');
    }, [streetAddress]);
    useEffect(() => {
        setPhoneErrorText('');
    }, [selectedPhoneNumber]);

    useEffect(() => {
        setSelectedAddress2(jobSiteAddr2);
        setSelectedZip(jobSiteZip);
        setStreetAddress(location);
        setSelectedCity(jobSiteCity);
        setSelectedState(jobSiteState);
    }, [viewCart.location]);

    useEffect(() => {
        if (userAccount?.accountNumber) {
            if (accountProjects?.length === 0) {
                getAccountProjectsDotCom(userAccount?.accountNumber);
            }
        }
    }, [userAccount?.accountNumber]);
    useEffect(() => {
        setUserPrefilledAddress(setPrefilledAddressForCreateProject(location, jobSiteCity));
    }, [location, jobSiteCity]);

    useEffect(() => {
        if (submit && isValidProjectName && validProjectAddress && validProjectInstructions && validPhoneNumber) {
            setSubmit(false);
            let duplicateProjects = [];
            let reviewDuplicateProjects = [];
            for (let i in accountProjects) {
                if (accountProjects[i].jobName.toLowerCase() === projectName.toLowerCase()) {
                    setAddressErrorText(intl.formatMessage({ id: 'checkout:create-project-duplicate-project-name' }));
                    setIsCreateProjectLoading(false);
                    return;
                } else if (
                    accountProjects[i].city.toLowerCase() === selectedCity.toLowerCase() &&
                    accountProjects[i].state.toLowerCase() === selectedState.toLowerCase() &&
                    accountProjects[i].zip.includes(selectedZip)
                ) {
                    duplicateProjects.push(accountProjects[i]);
                    setAddressErrorText('');
                }
            }
            if (duplicateProjects.length === 0) {
                setSubmit(false);
                createProject();
            } else {
                for (let i in duplicateProjects) {
                    if (
                        (streetAddress.match(/\d+/) &&
                            duplicateProjects[i].address1.includes(streetAddress.match(/\d+/)[0])) ||
                        (duplicateProjects[i].address2 &&
                            selectedAddress2 &&
                            selectedAddress2.match(/\d+/) &&
                            duplicateProjects[i].address2.includes(selectedAddress2.match(/\d+/)[0]))
                    ) {
                        reviewDuplicateProjects.push(duplicateProjects[i]);
                    }
                }
                if (reviewDuplicateProjects.length === 0) {
                    createProject();
                } else {
                    setIsCreateProjectLoading(false);
                    showReviewScreen(reviewDuplicateProjects);
                }
            }
        } else if (submit && !validPhoneNumber) {
            setIsCreateProjectLoading(false);
        }
        if (createProjectSource === 'checkout') {
            document.querySelector('.header__checkout')?.classList?.add('header__checkout-none');
        }
        return () => {
            document.querySelector('.header__checkout')?.classList?.remove('header__checkout-none');
        };
    }, [
        showCreateProject,
        isValidProjectName,
        validProjectAddress,
        validProjectInstructions,
        validPhoneNumber,
        submit
    ]);

    const onPressProject = project => {
        setSelectedProject(project);
        if (project.jobNumber === 'new') {
            setIsNewProject(true);
        } else {
            setIsNewProject(false);
        }
    };

    const callEvents = () => {
        try {
            sendEventsForEcommercePage(
                VARIABLE_CONFIG.ECOMMERCE_PAGE.CREATE_PROJECT,
                localStorage.getItem('companyID') == 2
                    ? VARIABLE_CONFIG.CURRENCY_CODE.CANADA
                    : VARIABLE_CONFIG.CURRENCY_CODE.USA
            );
            sendEventsForPageUser(EVENT_PAGE_NAMES_CONFIG.PAGE_USER, payloadEcommerceLocationActionAnalytics());
        } catch (error) {
            logError(error, false, 'callEvents');
        }
    };

    const onChangeProjectName = text => {
        setProjectName(text.target.value);
        if (text.target.value) {
            setProjectNameError('');
            setAddressErrorText('');
            setIsValidProjectName(true);
        }
    };
    const handleChange = (key, value) => {
        filterDispatch({ type: SET_VIEW_CART_FIELDS, key, value });
    };
    const backToCheckout = () => {
        try {
            if (isValidProjectName && validProjectAddress && validPhoneNumber) {
                sendEventsForClick(
                    VARIABLE_CONFIG.EVENT.UAEVENT,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                    VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                    VARIABLE_CONFIG.EVENT_ACTION.CREATE_NEW_PROJECT,
                    VARIABLE_CONFIG.EVENT_LABEL.BACK_TO_ORDER_DETAILS_PAGE
                );
            }
        } catch (error) {
            logError(error, false, 'backToCheckout');
        }
        try {
            if (!submit) {
                sendEventsForClick(
                    VARIABLE_CONFIG.EVENT.UAEVENT,
                    VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                    VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                    VARIABLE_CONFIG.EVENT_ACTION.CREATE_NEW_PROJECT,
                    VARIABLE_CONFIG.EVENT_LABEL.BACK_TO_ORDER_DETAILS_PAGE
                );
            }
        } catch (error) {
            logError(error, false, 'backToCheckout');
        }
        const localViewCart = { ...viewCart };
        localStorage.setItem('isCreditNewAddress', false);
        localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY, false);
        localViewCart.showCreateProject = false;
        localStorage.setItem('viewCart', JSON.stringify(localViewCart));
        handleChange(VIEW_CART.SHOW_CREATE_PROJECT, false);
        dispatch({
            type: SET_CREDIT_NEWADDR_FLAG,
            isCreditNewAddress: false
        });
    };
    const showReviewScreen = duplicateProjects => {
        setNewProject({
            jobNumber: 'new',
            jobName: projectName,
            address1: streetAddress,
            address2: selectedAddress2,
            city: selectedCity,
            state: selectedState,
            zip: selectedZip
        });
        setDuplicates([...duplicateProjects]);
        setSelectedProject(newProject);
        toggleDuplicateView();
    };

    const submitForm = () => {
        setIsCreateProjectLoading(true);
        if (!validateJobSite(projectName)) {
            setProjectNameError(intl.formatMessage({ id: 'create-project:special-characters-not-allowed' }));
            setIsValidProjectName(false);
            setSubmit(false);
            setIsCreateProjectLoading(false);
        } else if (createProjectAddressError?.address !== '') {
            setSubmit(false);
            setIsCreateProjectLoading(false);
        } else {
            setSubmit(true);
            const createProjectAddress = {
                address: streetAddress,
                city: selectedCity,
                state: selectedState,
                zipcode: selectedZip
            };
            const projectInstructions = {
                primaryContact: primaryContact,
                accessNotes: accessNotes,
                poNumber: poNumber
            };
            if (!projectName?.trim()) {
                setProjectNameError(intl.formatMessage({ id: 'common:required-input-error' }));
                setSubmit(false);
                setAddressErrorText('');
                setIsValidProjectName(false);
                setIsCreateProjectLoading(false);
            }
            /* Showing error when user has not entered any input and if entered address details are incorrect */
            const isProjectAddressPresent = validateProjectAddress(
                createProjectAddress,
                setCreateProjectAddressError,
                intl
            );
            let isProjectAddressValid = true;
            if (!isProjectAddressPresent) {
                isProjectAddressValid = false;
            } else if (!validateAddressFields()) {
                setAddressChangedByAvs(false);
                setAvsError('');
                isProjectAddressValid = false;
            }
            const isProjectInstructionsValid = validateProjectInstructions(
                projectInstructions,
                setProjectInstructionsError,
                intl
            );
            setValidProjectAddress(isProjectAddressValid);
            setValidProjectInstructions(isProjectInstructionsValid);
            if (!isProjectAddressValid || !isProjectInstructionsValid) {
                setIsCreateProjectLoading(false);
            }
        }
    };

    const toggleDuplicateView = () => {
        setShowReviewDuplicates(!showReviewDuplicates);
    };

    const createNewProject = () => {
        if (isNewProject === true) {
            createProject();
        } else {
            sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE);
            filterDispatch({
                type: UPDATE_PROJECT,
                locationPC: selectedProject?.locationPC,
                projectName: selectedProject?.jobName,
                projectAddress1: selectedProject?.address1,
                selectedProjectJobId: selectedProject?.jobNumber,
                selectedRMJobId: selectedProject?.rmJobsiteId,
                selectedProjectLatititude: selectedProject?.latitude,
                selectedProjectLongitude: selectedProject?.longitude,
                selectedProjectState: selectedProject?.state,
                selectedProjectCity: selectedProject?.city,
                selectedProjectZip: selectedProject?.zip,
                primaryContactName: selectedProject?.contactName,
                phoneNumber: selectedProject?.contactPhone,
                accessNotes: selectedProject?.deliveryInstructions,
                poNumber: selectedProject?.poNumber,
                CJDLVY: selectedProject?.CJDLVY
            });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, selectedProject?.CJDLVY);
            toggleDuplicateView();
            backToCheckout();
        }
    };
    const checkAndSetAddressChange = (newValue, currentValue) => {
        if (newValue?.toLowerCase() !== currentValue?.toLowerCase()) {
            return true;
        }
        return false;
    };
    const postalZipErrorLabelHandler = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'order-details:postal-error-label' })
            : intl.formatMessage({ id: 'order-details:zip-error-label' });
    };

    const provinceStateErrorLabelHandler = () => {
        return userCompanyID == 2
            ? intl.formatMessage({ id: 'order-details:province-error-label' })
            : intl.formatMessage({ id: 'order-details:state-error-label' });
    };
    const validateAddressFields = () => {
        let isValid = true;
        const errors = {
            address: '',
            city: '',
            zipcode: '',
            state: ''
        };

        if (!streetAddress) {
            errors.address = intl.formatMessage({ id: 'order-details:address-error-label' });
            isValid = false;
        } else {
            errors.address = '';
        }

        if (!selectedCity) {
            isValid = false;
            errors.city = intl.formatMessage({ id: 'order-details:city-error-label' });
        } else if (selectedCity) {
            if (!validateCity(selectedCity)) {
                errors.city = intl.formatMessage({ id: 'order-details:city-error-splchar-label' });
                isValid = false;
            } else {
                errors.city = '';
            }
        }

        if (!selectedZip) {
            errors.zipcode = postalZipErrorLabelHandler();
            isValid = false;
        } else if (selectedZip) {
            if (!validateZip(selectedZip)) {
                errors.zipcode = postalZipErrorLabelHandler();
                isValid = false;
            } else {
                errors.zipcode = '';
            }
        }

        if (!selectedState) {
            errors.state = provinceStateErrorLabelHandler();
            isValid = false;
        } else {
            errors.state = '';
        }

        setCreateProjectAddressError({ ...errors });
        return isValid;
    };
    const createProject = async () => {
        setIsCreateProjectLoading(true);
        let lat = '';
        let long = '';
        /* To call verify API in case of manual address*/
        if (isManualAddress) {
            const addressObj = {
                streetAddress: streetAddress?.split(',')[0],
                selectedAddress2,
                selectedCity,
                selectedState,
                selectedZip
            };
            const { isValid, latFromAVS, longFromAVS, addressFromAvs, addrResult } = await getAvsResponse(
                addressObj,
                true
            );
            setAvsAddress({ isValid, latFromAVS, longFromAVS, addressFromAvs, addrResult });
            const hasDuplicates = duplicates?.length > 0;
            /*User have an multiple address linked to the selected address*/
            if (addrResult == 3) {
                setCurrentAddressObj(addressObj);
                setSuggestedAddressList(addressFromAvs);
                setIsCreateProjectLoading(false);
                setAddressChangedByAvs(false);
                /*checking user has duplicate projects created*/
                if (hasDuplicates > 0) {
                    showReviewScreen(duplicates);
                }
                return;
            } else if (!isValid) {
                setAddressChangedByAvs(false);
                setIsCreateProjectLoading(false);
                /*checking user has duplicate projects created*/
                if (hasDuplicates > 0) {
                    showReviewScreen(duplicates);
                }
                return;
            } else {
                setAvsError('');
                setCreateProjectAddressError({
                    ...createProjectAddressError,
                    address: '',
                    zipcode: '',
                    state: '',
                    city: ''
                });
                const { address1, address2, city, state, zip } = addressFromAvs;
                /* Validating  if user address is updated to the nearest match */
                if (
                    checkAndSetAddressChange(address1, userPrefilledAddress) ||
                    checkAndSetAddressChange(city, selectedCity) ||
                    checkAndSetAddressChange(state, selectedState) ||
                    checkAndSetAddressChange(zip, selectedZip)
                ) {
                    setUserPrefilledAddress(address1);
                    setStreetAddress(address1 || '');
                    setSelectedCity(city || '');
                    setSelectedState(state || '');
                    setSelectedZip(zip || '');
                    filterDispatch({
                        type: SET_LOCATION_DATA,
                        location: address1 || '',
                        jobSiteAddr2: address2 || '',
                        jobSiteCity: city || '',
                        jobSiteState: state || '',
                        jobSiteZip: zip || ''
                    });
                    setAddressChangedByAvs(true);
                    setIsManualAddress(false);
                    setIsCreateProjectLoading(false);
                    /*checking user has duplicate projects created*/
                    if (hasDuplicates > 0) {
                        showReviewScreen(duplicates);
                    }
                    return;
                } else {
                    setAddressChangedByAvs(false);
                    lat = latFromAVS;
                    long = longFromAVS;
                }
            }
        } else {
            /* User able to proceed if address is updated to nearest match and filled the correct details */
            if (addressChangedByAvs) {
                if (!avsError && avsAddress?.isValid) {
                    lat = avsAddress?.latFromAVS;
                    long = avsAddress?.longFromAVS;
                }
            } else if (suggestedAddressList?.length > 0) {
                lat = avsAddress?.latFromAVS;
                long = avsAddress?.longFromAVS;
            } else {
                /* User has selected address from autosuggestions */
                let geocodePayload =
                    suggestedAddressList?.length > 0
                        ? `${streetAddress}, ${selectedCity}, ${selectedState}, ${selectedZip}`
                        : streetAddress;
                const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
                const responseFromGeocode = await getlatLongByAddress(companyID, geocodePayload);
                const results = responseFromGeocode?.data?.results;
                if (
                    responseFromGeocode?.error ||
                    results?.length == 0 ||
                    isObjectEmpty(responseFromGeocode?.data) ||
                    !cityAndZipValidationAddressComponent(results[0])
                ) {
                    setCreateProjectAddressError({
                        ...createProjectAddressError,
                        address: intl.formatMessage({ id: 'order-details:address-error-label' })
                    });
                    setIsCreateProjectLoading(false);
                    return;
                } else {
                    setCreateProjectAddressError({
                        ...createProjectAddressError,
                        address: '',
                        zipcode: '',
                        state: '',
                        city: ''
                    });
                }

                lat = results[0]?.geometry?.location?.lat || '';
                long = results[0]?.geometry?.location?.lng || '';
            }
        }

        const { pcVal } = await getPC(lat, long);

        let delInstParts = accessNotes?.match(/.{1,40}/g) || [];
        let delInst1 = delInstParts[0];
        let delInst2 = delInstParts[1];
        let delInst3 = delInstParts[2];
        let delInst4 = delInstParts[3];

        let trimmedProjectName =
            authorityType === AUTHORITY_TYPE.P2P ? projectName?.trim()?.toUpperCase() : projectName?.trim();

        let payload = {
            accountId: String(userAccount?.accountNumber),
            projectLocation: streetAddress,
            projectName: trimmedProjectName,
            projectNumber: trimmedProjectName,
            status: 'C',
            updateOpenHeader: 'N',
            deliveryInstructions1: delInst1 ? delInst1 : '',
            deliveryInstructions2: delInst2 ? delInst2 : '',
            deliveryInstructions3: delInst3 ? delInst3 : '',
            deliveryInstructions4: delInst4 ? delInst4 : '',
            poNumber: poNumber,
            address: {
                line1: streetAddress?.split(',')?.[0],
                line2: selectedAddress2,
                city: selectedCity,
                state: selectedState,
                zip: selectedZip,
                location: String(pcVal)
            },
            contact: {
                name: primaryContact,
                phoneNumber: selectedPhoneNumber
            }
        };
        const { projectData, error, addressInfo } = await addProject(payload, localStorage.getItem('companyID') || 1);
        sessionStorage.removeItem(STORAGE_CONFIG.SESSION_STORAGE.RECOMMENDED_JOBSITE);
        if (error) {
            setShowReviewDuplicates(false);
            if (addressInfo?.addrResult == 3) {
                setCurrentAddressObj({
                    streetAddress,
                    selectedAddress2,
                    selectedCity,
                    selectedState,
                    selectedZip
                });
                setSuggestedAddressList(addressInfo?.addrList);
            } else {
                if (error) {
                    switch (error.errorKey) {
                        case ADDERESS_NOT_VALIDATED:
                            setAddressErrorText(
                                `${intl.formatMessage({ id: 'checkout:create-project-invalid-address' })} ${
                                    addressInfo.addrMsg
                                }`
                            );
                            break;
                        case INVALID_PHONE_NUMBER:
                            setPhoneErrorText(`${intl.formatMessage({ id: 'common:invalid-phone-error' })}`);
                            break;

                        default:
                            setError(`${error?.errorKey?.replaceAll('_', ' ') || error}`);
                            break;
                    }
                } else {
                    setAddressErrorText('');
                    setPhoneErrorText('');
                    setError('');
                }
            }
        } else {
            localStorage.setItem('isCreditNewAddress', false);
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.ISNEWADDRESSONOVERLAY, false);
            filterDispatch({
                type: UPDATE_PROJECT,
                locationPC: projectData?.locationPC,
                projectName: projectData?.jobName,
                projectAddress1: projectData?.address1,
                projectAddress2: projectData?.address2,
                selectedProjectJobId: projectData?.jobNumber,
                selectedRMJobId: projectData?.rmJobsiteId,
                selectedProjectLatititude: projectData?.latitude,
                selectedProjectLongitude: projectData?.longitude,
                selectedProjectState: projectData?.state,
                selectedProjectCity: projectData?.city,
                selectedProjectZip: projectData?.zip,
                primaryContactName: projectData?.contactName,
                phoneNumber: projectData?.contactPhone,
                accessNotes: projectData?.deliveryInstructions,
                poNumber: projectData?.customerPO,
                CJDLVY: projectData?.CJDLVY
            });
            localStorage.setItem(STORAGE_CONFIG.LOCAL_STORAGE.CJDLVY, projectData?.CJDLVY);
            dispatch({
                type: SET_CREDIT_NEWADDR_FLAG,
                isCreditNewAddress: false
            });
            filterDispatch({
                type: RESET_LOCATION_DATA
            });
            toggleDuplicateView();

            try {
                //TO BE review
                setSettingsCookieFunc(null, {
                    locationPC: projectData?.locationPC,
                    projectName: projectData?.jobName,
                    projectAddress1: projectData?.address1,
                    projectAddress2: projectData?.address2,
                    selectedProjectJobId: projectData?.jobNumber,
                    selectedProjectLatititude: projectData?.latitude,
                    selectedProjectLongitude: projectData?.longitude,
                    selectedProjectState: projectData?.state,
                    selectedProjectCity: projectData?.city,
                    selectedProjectZip: projectData?.zip,
                    primaryContactName: projectData?.contactName,
                    phoneNumber: projectData?.contactPhone,
                    accessNotes: projectData?.deliveryInstructions,
                    poNumber: projectData?.customerPO
                });
                backToCheckout();
                await getAccountProjectsDotCom(userAccount?.accountNumber, '', true);
            } catch (err) {
                logError(err, false, 'createProject ');
            }
        }
        setIsCreateProjectLoading(false);
    };

    const onPressAVSUpdateBtn = async addressObj => {
        const companyID = localStorage.getItem(STORAGE_CONFIG.LOCAL_STORAGE.COMPANYID) || '1';
        const response = await getlatLongByAddress(
            companyID,
            `${addressObj?.address1}, ${addressObj?.city}, ${addressObj?.state}, ${addressObj?.zip}`
        );
        let lat = '';
        let long = '';
        if (response?.data?.results?.length > 0) {
            const res = response?.data;
            lat = res?.results[0]?.geometry?.location.lat;
            long = res?.results[0]?.geometry?.location.lng;
            await filterDispatch({
                type: SET_LOCATION_DATA,
                location: addressObj?.address1,
                jobSiteCity: addressObj?.city,
                jobSiteState: addressObj?.state,
                jobSiteZip: addressObj?.zip,
                jobSiteAddr2: addressObj?.address2,
                lat,
                long,
                pc: viewCart?.pc,
                pcLat: viewCart?.pcLat,
                pcLong: viewCart?.pcLong
            });
            if (avsAddress?.addrResult == 3) {
                setAddressChangedByAvs(true);
                setIsManualAddress(false);
                setIsCreateProjectLoading(false);
            }
        }
        setAvsModal(false);
    };

    const handleKeyDown = e => {
        if (e.key === ENTER_KEY) {
            backToCheckout();
        }
    };

    return (
        <div className={`createProjectContainer ${isCreateProjectLoading ? `clicks-disabled` : ''}`}>
            {createProjectSource === 'checkout' && (
                <div className="checkoutGradientHeader">
                    <Button
                        customButtonAriaLabel={intl.formatMessage({ id: 'create-project:close-page' })}
                        onClick={backToCheckout}
                        tabIndex={0}>
                        <Close aria-hidden="true" tabIndex={'-1'} />
                    </Button>
                </div>
            )}
            <div className="createProjectBody">
                <h4 data-testid={createProjectDataLocators.projectDetailsHeaderText}>
                    {createProjectSource === 'minicart'
                        ? intl.formatMessage({ id: 'create-project:we-need-some-info' })
                        : intl.formatMessage({ id: 'create-project:create-a-new-project' })}
                </h4>
                <span data-testid={createProjectDataLocators.yourColleaguesAtAcmeText} className="createProjectSubText">
                    {intl.formatMessage({ id: 'create-project:your-coleagues' })}
                    {projectInfoCookies?.CurrentWynneAccountName || userAccount?.accountName}
                    {intl.formatMessage({ id: 'create-project:your-coleagues-will-see-text' })}
                </span>
                <div className="inputProjectName">
                    <Input
                        name="projectName"
                        label={intl.formatMessage({ id: 'create-project:project-name' })}
                        value={projectName}
                        maxLength="30"
                        handleInputChange={onChangeProjectName}
                        placeholder={intl.formatMessage({ id: 'create-project:project-name-placeholder' })}
                        dataTestId={createProjectDataLocators.projectNamePlaceHolder}
                        classes="projectNameEntry"
                        errorMsg={projectNameError}
                        inputAriaLabel={intl.formatMessage({ id: 'create-project:project-name-placeholder' })}
                    />
                </div>
                <CreateProjectAddress
                    streetAddress={streetAddress}
                    setStreetAddress={setStreetAddress}
                    selectedState={selectedState}
                    setSelectedState={setSelectedState}
                    selectedCity={selectedCity}
                    setSelectedCity={setSelectedCity}
                    selectedZip={selectedZip}
                    setSelectedZip={setSelectedZip}
                    selectedAddress2={selectedAddress2}
                    setSelectedAddress2={setSelectedAddress2}
                    selectedPhoneNumber={selectedPhoneNumber}
                    setSelectedPhoneNumber={setSelectedPhoneNumber}
                    createProjectAddressError={createProjectAddressError}
                    setCreateProjectAddressError={setCreateProjectAddressError}
                    submit={submit}
                    setSubmit={setSubmit}
                    setValidPhoneNumber={setValidPhoneNumber}
                    setIsManualAddress={setIsManualAddress}
                    canadaRestrictStateError={canadaRestrictStateError}
                    setAddressChangedByAvs={setAddressChangedByAvs}
                    setErrorText={setAvsError}
                    setSuggestedAddressList={setSuggestedAddressList}
                    userPrefilledAddress={userPrefilledAddress}
                    setUserPrefilledAddress={setUserPrefilledAddress}
                    isManualAddress={isManualAddress}
                />
                {isCreateProjectLoading && !showReviewDuplicates && (
                    <div className={'createProjectLoader'}>
                        <LoadingIndicator />
                    </div>
                )}
                <div className="projectInstructionsSection">
                    <ProjectInstructions
                        primaryContact={primaryContact}
                        setPrimaryContact={setPrimaryContact}
                        accessNotes={accessNotes}
                        setAccessNotes={setAccessNotes}
                        poNumber={poNumber}
                        setPONumber={setPONumber}
                        onContinueHandler={submitForm}
                        projectInstructionsError={projectInstructionsError}
                        setProjectInstructionsError={setProjectInstructionsError}
                        errorText={addressErrorText || phoneErrorText || error}
                        isCreateProjectLoading={isCreateProjectLoading}
                        canadaRestrictStateError={canadaRestrictStateError}
                        addressChangedByAvs={addressChangedByAvs}
                        avsErrorText={avsError}
                    />
                </div>
            </div>
            <CheckoutPageAlerts />
            {showReviewDuplicates && (
                <ReviewDuplicates
                    newProject={newProject}
                    duplicates={duplicates}
                    isNewProject={isNewProject}
                    handleClose={toggleDuplicateView}
                    createProject={createNewProject}
                    onPressProject={onPressProject}
                    showReviewDuplicates={showReviewDuplicates}
                    setShowReviewDuplicates={setShowReviewDuplicates}
                    isCreateProjectLoading={isCreateProjectLoading}
                />
            )}
            {avsModal &&
                (forMobile ? (
                    <SuggestedAddressModal
                        currentAddressObj={currentAddressObj}
                        suggestedAddressList={suggestedAddressList}
                        setShowSuggestedAddress={setAvsModal}
                        onPressUpdateBtn={onPressAVSUpdateBtn}
                        forMobile={forMobile}
                    />
                ) : (
                    <StoreModal modalDialogClass={'modal-dialog-avs'} isOpen={avsModal} onRequestClose={backToCheckout}>
                        <SuggestedAddressModal
                            currentAddressObj={currentAddressObj}
                            suggestedAddressList={suggestedAddressList}
                            setShowSuggestedAddress={setAvsModal}
                            onPressUpdateBtn={onPressAVSUpdateBtn}
                            forMobile={forMobile}
                        />
                    </StoreModal>
                ))}
        </div>
    );
};

export default React.memo(CreateProject);
