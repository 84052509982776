import React, { useState, useEffect } from 'react';
import Input from '../../../atoms/input/Input';
import { intl, formatMobileNumber, validatePhone } from '../../../utils/commonUtils';
import { validateField } from '../validateFields';

const PhoneNumber = props => {
    const {
        label,
        name,
        handleInputChange,
        value,
        placeholder,
        dataTestId,
        inputAriaLabel,
        required,
        onValidate,
        requiredErrorMsg,
        formatErrorMsg,
        submit,
        setSubmit,
        onErrorAnalytics,
        disclaimerMsg,
        isCCAErrorIcon,
        errorMessage,
        onFocus
    } = props;

    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        validateField(
            required,
            value,
            setErrorMsg,
            onValidate,
            validatePhone,
            submit,
            setSubmit,
            requiredErrorMsg,
            formatErrorMsg,
            onErrorAnalytics
        );
    }, [submit]);

    const validatePhoneFunc = text => {
        text.target.value = formatMobileNumber(text.target.value);
        handleInputChange(text);
        setErrorMsg('');
        onValidate(false);
    };

    return (
        <Input
            label={label}
            name={name}
            value={value}
            placeholder={placeholder}
            handleInputChange={validatePhoneFunc}
            dataTestId={dataTestId}
            inputAriaLabel={inputAriaLabel}
            errorMsg={errorMessage || errorMsg}
            disclaimerMsg={disclaimerMsg}
            isCCAErrorIcon={isCCAErrorIcon}
            handleInputFocus={onFocus}
            required={required}
        />
    );
};

export default PhoneNumber;

PhoneNumber.defaultProps = {
    name: 'phoneNumber',
    label: intl.formatMessage({ id: 'checkout:your-details-phone-number' }),
    placeholder: intl.formatMessage({ id: 'checkout:your-details-phone-number-placeholder' }),
    requiredErrorMsg: intl.formatMessage({ id: 'common:required-input-error' }),
    formatErrorMsg: intl.formatMessage({ id: 'common:format-phone-error' }),
    isCCAErrorIcon: false,
    onErrorAnalytics: () => {},
    onValidate: () => {},
    onFocus: () => {}
};
