import React, { useState, useEffect } from 'react';
import { array, shape, string } from 'prop-types';
import DownChev from '../../../resources/images/arrow-down.svg';
import CartIcon from '../../../resources/images/shopping-cart.svg';
import RentalReviewListView from './RentalReviewListView/RentalReviewListView';
import classes from './rentalreviewlist.css';
import { useIntl } from 'react-intl';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { useUserContext } from '../../../aem-core-components/context/UserContext';

const RentalReviewList = props => {
    const { cartItems, orderEstimates } = props;
    const intl = useIntl();
    const [{ userProfile }] = useUserContext();
    const [{ consumables, cart }] = useCartState();
    const { selectedRentalQtyAndPrice } = consumables;
    const [isListVisible, setIsListVisible] = useState(false);

    useEffect(() => {
        if (userProfile?.skip_qty && Object.keys(userProfile?.skip_qty).length > 0) {
            setIsListVisible(true);
        }
    }, [userProfile?.skip_qty])

    let listRender = cartItems?.map(item => (
        <RentalReviewListView
            item={item}
            key={item.product.sku}
            isRentalDatesEmpty={props.isRentalDatesEmpty}
            orderEstimates={orderEstimates}
        />
    ));

    return (
        <>
            <div className={classes.accordion__item}>
                <div className={classes.accordion__title} onClick={() => setIsListVisible(!isListVisible)}>
                    <div className={classes.accordian_heading}>
                        <CartIcon
                            className={classes.cartIconStyle}
                            data-testid={checkoutDatalocator.checkout_itemscounts_label_testid}
                        />{' '}
                        <span>{(cart?.total_quantity || 0) + selectedRentalQtyAndPrice?.totalRentalQty + selectedRentalQtyAndPrice?.totalAddonQty} items</span>
                    </div>
                    <div className={`${classes.expand_button} ${isListVisible && classes.chevronExpend}`}>
                        <DownChev className={`${classes.chevronIconStyle}`} />
                    </div>
                </div>
                {isListVisible && (
                    <div className={classes.accordion__body}>
                        <h6
                            className={classes.rentalsHeading}
                            data-testid={checkoutDatalocator.checkout_rentals_heading_testid}>
                            {intl.formatMessage({ id: 'checkout:rentals-heading' })}
                        </h6>
                        <div className={classes.listStyle}>{listRender}</div>
                    </div>
                )}
            </div>
        </>
    );
};

RentalReviewList.propTypes = {
    cartItems: array,
    classes: shape({
        root: string
    })
};

RentalReviewList.defaultProps = {
    cartItems: []
};
export default RentalReviewList;
