import React, { useState, useEffect } from 'react';
import Input from '../../../atoms/input/Input';
import { intl, validateEmail } from '../../../utils/commonUtils';
import { validateField } from '../validateFields';

const Email = props => {
    const {
        label,
        name,
        handleInputChange,
        value,
        placeholder,
        dataTestId,
        inputAriaLabel,
        required,
        onValidate,
        requiredErrorMsg,
        formatErrorMsg,
        isExistingUser,
        setIsExistingUser,
        submit,
        setSubmit,
        onErrorAnalytics
    } = props;

    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        validateField(
            required,
            value,
            setErrorMsg,
            onValidate,
            validateEmail,
            submit,
            setSubmit,
            requiredErrorMsg,
            formatErrorMsg,
            onErrorAnalytics
        );
    }, [submit]);

    useEffect(() => {
        if (isExistingUser) {
            setErrorMsg(intl.formatMessage({ id: 'checkout:you-details-existing-email-error' }));
            onValidate(false);
            setSubmit(false);
        }
    }, [isExistingUser, submit]);

    const validateEmailFunc = text => {
        handleInputChange(text);
        setErrorMsg('');
        setIsExistingUser(false);
        onValidate(false);
    };

    return (
        <Input
            label={label}
            name={name}
            value={value}
            placeholder={placeholder}
            handleInputChange={validateEmailFunc}
            dataTestId={dataTestId}
            inputAriaLabel={inputAriaLabel}
            errorMsg={errorMsg}
        />
    );
};

export default Email;

Email.defaultProps = {
    name: 'email',
    label: intl.formatMessage({ id: 'checkout:your-details-email-address' }),
    placeholder: intl.formatMessage({ id: 'checkout:your-details-email-address-placeholder' }),
    requiredErrorMsg: intl.formatMessage({ id: 'common:required-input-error' }),
    formatErrorMsg: intl.formatMessage({ id: 'common:format-email-error' }),
    isExistingUser: false,
    setIsExistingUser: () => {},
    onErrorAnalytics: () => {}
};
