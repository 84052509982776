import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import RentalEquipment from './RentalEquipment';
import RentalFooter from './RentalFooter';
import MobileView from '../../../global/modules/mobileView/MobileView';
import StoreModal from '../../StoreModal';
import { useCartState } from '../../../../aem-core-components/components/Minicart/cartContext';
import { useAnalyticsContext } from '../../../../config/GoogleTagManagerEvents';
import { useCheckUser } from '../../../../hooks/useCheckUser';
import {
    SET_CONSUMABLES_SELECTED_ITEMS,
    SET_SHOW_RENTAL_EQUIPMENT_SCREEN,
    SET_SAVED_AVAILABLE_QUANTITY
} from '../../../../aem-core-components/actions/constants';
import AlertModalWithButtons from '../../../global/atoms/AlertModalWithButtons';
import { checkoutDatalocator } from '../../checkoutAndOrderSummary/dataLocators';
import { VARIABLE_CONFIG } from '../../../../constants/analyticsConstants/Variables';
import isObjectEmpty from '../../../../aem-core-components/utils/isObjectEmpty';
import { getProducts } from '../../../../constants/analyticsConstants/getProducts';
import { logError } from '../../../global/utils/logger';
import { useFilterState } from '../../../cap';

const RentalEquipmentView = ({ isOpen, mediaType }) => {
    const intl = useIntl();
    const [isExitRentalModalOpen, setIsExitRentalModalOpen] = useState(false);
    const [isRentalEquipmentSelected, setIsRentalEquipmentSelected] = useState(false);
    const [{ consumables, cart }, dispatch] = useCartState();
    const [{ startDate, endDate }, filterDispatch] = useFilterState();
    const [selectedItemsDetails, setSelectedItemsDetails] = useState({ totalQuantity: 0, totalPrice: 0 });
    const [selectedItems, setSelectedItems] = useState(
        consumables?.selected[consumables?.selectedRentalEquipment?.equipmentInfo?.sku] || { rentals: {}, addons: {} }
    );
    const userType = useCheckUser();
    const { sendEventsForEcommerceAdd, sendEventsForClick, sendEventsForEcommerceRemove } = useAnalyticsContext();

    const addAccessory = (skuId, item) => {
        const updatedRentals = { ...selectedItems.rentals, [skuId]: item };
        if (item?.qty == 0) {
            delete updatedRentals[skuId];
        }
        setSelectedItems({ ...selectedItems, rentals: updatedRentals });
        setSelectedItemsDetails(
            calculateSelectedItemDetails({
                [consumables?.selectedRentalEquipment?.equipmentInfo?.sku]: {
                    ...selectedItems,
                    rentals: updatedRentals
                }
            })
        );
        setIsRentalEquipmentSelected(true);
    };

    const addAddon = (skuId, item) => {
        const updatedAddons = { ...selectedItems.addons, [skuId]: item };
        if (item?.qty == 0) {
            delete updatedAddons[skuId];
        }
        setSelectedItems({ ...selectedItems, addons: updatedAddons });
        setSelectedItemsDetails(
            calculateSelectedItemDetails({
                [consumables?.selectedRentalEquipment?.equipmentInfo?.sku]: { ...selectedItems, addons: updatedAddons }
            })
        );
        setIsRentalEquipmentSelected(true);
    };

    const calculateSelectedItemDetails = itemObj => {
        let totalRentalPrice = 0;
        let totalRentalQty = 0;
        let totalAddonPrice = 0;
        let totalAddonQty = 0;
        Object.keys(itemObj).forEach(item => {
            let selectedArray = Object.keys(itemObj[item]);
            if (selectedArray.includes('rentals')) {
                let rentalArray = Object.values(itemObj[item]['rentals']);
                rentalArray.forEach(itemRental => {
                    totalRentalQty += itemRental.qty;
                    totalRentalPrice += itemRental.qty * itemRental.price;
                });
            }
            if (selectedArray.includes('addons')) {
                let addonArray = Object.values(itemObj[item]['addons']);
                addonArray.forEach(itemAddon => {
                    totalAddonQty += itemAddon.qty;
                    totalAddonPrice += itemAddon.qty * itemAddon.price;
                });
            }
        });
        return { totalQuantity: totalRentalQty + totalAddonQty, totalPrice: totalRentalPrice + totalAddonPrice };
    };
    const getCount = accessories => {
        const val = Object.values(accessories);
        if (accessories.length != 0) {
            if (val.length == 1) {
                return val[0].qty;
            } else {
                return val.reduce((previousValue, currentValue) => previousValue.qty + currentValue.qty);
            }
        } else {
            return 0;
        }
    };
    const saveChanges = () => {
        dispatch({
            type: SET_CONSUMABLES_SELECTED_ITEMS,
            selectedItems: {
                ...consumables?.selected,
                [consumables?.selectedRentalEquipment?.equipmentInfo?.sku]: selectedItems
            }
        });
        dispatch({ type: SET_SHOW_RENTAL_EQUIPMENT_SCREEN, flag: false });
        dispatch({
            type: SET_SAVED_AVAILABLE_QUANTITY,
            key: 'savedAvailableQuantity',
            value: consumables?.availableQuantity
        });

        let consumablesAccessoriesCount = 0;
        const datesObj = {
            startDate,
            endDate
        };
        if (!isObjectEmpty(consumables?.selected)) {
            Object.values(consumables?.selected).map(consumable => {
                consumablesAccessoriesCount =
                    getCount(Object.values(consumable?.rentals)) + getCount(Object.values(consumable?.addons));
            });
            if (
                consumablesAccessoriesCount > selectedItemsDetails?.totalQuantity ||
                isNaN(consumablesAccessoriesCount)
            ) {
                try {
                    sendEventsForEcommerceRemove(
                        VARIABLE_CONFIG.EVENT_CATEGORY.ADD_ON,
                        getProducts(cart, datesObj, true, selectedItems)
                    );
                } catch (error) {
                    logError(error, false, 'saveChanges');
                }
            } else {
                try {
                    sendEventsForEcommerceAdd(
                        VARIABLE_CONFIG.EVENT_CATEGORY.ADD_ON,
                        getProducts(cart, datesObj, true, selectedItems)
                    );
                } catch (error) {
                    logError(error, false, 'saveChanges');
                }
            }
        } else {
            try {
                sendEventsForEcommerceAdd(
                    VARIABLE_CONFIG.EVENT_CATEGORY.ADD_ON,
                    getProducts(cart, datesObj, true, selectedItems)
                );
            } catch (error) {
                logError(error, false, 'saveChanges');
            }
        }
    };

    const handleIsExitRentalCancelClicked = () => {
        setIsExitRentalModalOpen(false);
    };
    const handleIsExitRentalReturnClicked = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userType.toLowerCase()} select`,
                VARIABLE_CONFIG.EVENT_LABEL.RETURN_TO_CHECKOUT
            );
        } catch (error) {
            logError(error, false, 'handleIsExitRentalReturnClicked');
        }
        dispatch({ type: SET_SHOW_RENTAL_EQUIPMENT_SCREEN, flag: false });
        setIsExitRentalModalOpen(false);
        setIsRentalEquipmentSelected(false);
        dispatch({
            type: SET_SAVED_AVAILABLE_QUANTITY,
            key: 'availableQuantity',
            value: consumables?.savedAvailableQuantity
        });
    };

    const handleRentalScreenBackClick = () => {
        try {
            sendEventsForClick(
                VARIABLE_CONFIG.EVENT.UAEVENT,
                VARIABLE_CONFIG.ECOMMERCE.UNDEFINED,
                VARIABLE_CONFIG.EVENT_CATEGORY.CHECKOUT_PAGE,
                `${userType.toLowerCase()} select`,
                VARIABLE_CONFIG.EVENT_LABEL.CANCEL_ADDING_ACCESSORIES
            );
        } catch (error) {
            logError(error, false, 'handleRentalScreenBackClick');
        }
        if (isRentalEquipmentSelected) {
            setIsExitRentalModalOpen(true);
        } else {
            dispatch({ type: SET_SHOW_RENTAL_EQUIPMENT_SCREEN, flag: false });
            setIsRentalEquipmentSelected(false);
        }
    };
    const mobileFooter = () => {
        return (
            <div className="mobile-view-footer mobile-view-footer-white">
                <RentalFooter
                    onCloseClick={handleRentalScreenBackClick}
                    selectedItemsDetails={selectedItemsDetails}
                    selectedItems={selectedItems}
                    saveChanges={saveChanges}
                />
            </div>
        );
    };
    return (
        <>
            {mediaType === 'MOBILE' ? (
                <MobileView
                    isOpen={isOpen}
                    onRequestClose={handleRentalScreenBackClick}
                    rootClass={'mobile-view-root mobile-rental-root'}
                    handleClose={handleRentalScreenBackClick}
                    footerContent={mobileFooter}>
                    <RentalEquipment
                        selectedItems={selectedItems}
                        addAccessory={addAccessory}
                        addAddon={addAddon}
                        saveChanges={saveChanges}
                        selectedItemsDetails={selectedItemsDetails}
                    />
                </MobileView>
            ) : (
                <StoreModal
                    classes="rental-equipment"
                    modalDialogClass={'modal-locationdialog'}
                    isOpen={isOpen}
                    onRequestClose={handleRentalScreenBackClick}>
                    <>
                        <RentalEquipment
                            selectedItems={selectedItems}
                            addAccessory={addAccessory}
                            addAddon={addAddon}
                            saveChanges={saveChanges}
                            handleCloseClick={handleRentalScreenBackClick}
                            selectedItemsDetails={selectedItemsDetails}
                        />
                    </>
                </StoreModal>
            )}
            {/* Warning for leaving accessories */}
            <AlertModalWithButtons
                isOpen={isExitRentalModalOpen}
                title={intl.formatMessage({ id: 'checkout:return-to-checkout-question' })}
                warningQuestion={intl.formatMessage({
                    id: 'checkout:return-to-checkout-modal-question'
                })}
                handleContinueClick={handleIsExitRentalReturnClicked}
                continueButtonText={intl.formatMessage({ id: 'checkout:return-to-checkout-text' })}
                handleCancelClick={handleIsExitRentalCancelClicked}
                cancelButtonText={intl.formatMessage({ id: 'common:cancel' })}
                closeIconDataLocator={checkoutDatalocator.checkout_accessories_cross_icon_testid}
                continueButtonDataLocator={checkoutDatalocator.checkout_accessories_returnToCheckout_CTA_testid}
                cancelButtonDataLocator={checkoutDatalocator.checkout_accessories_cancel_CTA_testid}
            />
        </>
    );
};

export default RentalEquipmentView;
