import { func, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useCartState } from '../../../aem-core-components/components/Minicart';
import { CollapsedView } from '../../global/modules/stepForm';
import classes from '../checkoutAndOrderSummary/checkoutAndOrderSummary.css';
import { checkoutDatalocator } from '../checkoutAndOrderSummary/dataLocators';
import EditProjectInfo from './editProjectInfo/EditProjectInfo';
import ReadOnlyProjectInfo from './ReadOnlyProjectInfo';
import { useFilterState } from '../../cap';

const CheckoutProjectInfo = props => {
    const intl = useIntl();
    const [{ userInfo, projectInfo, punchoutUserData }, dispatch] = useCartState();
    const [{viewCart, projectDetails}, filterDispatch] = useFilterState();
    const { currentStep, handleStepChange, formStep, isCustomAccount } = props;
    const stepNumber = formStep.PROJECT_DETAILS;

    const [primaryContactName, setPrimaryContactName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [accessNotes, setAccessNotes] = useState('');
    const [poNumber, setPoNumber] = useState('');

    useEffect(() => {
        setPrimaryContactName(
            projectInfo?.primaryContactName || projectDetails?.primaryContactName
        );
        setPhoneNumber(projectInfo?.phoneNumber || projectDetails?.phoneNumber);
        setAccessNotes(projectInfo?.accessNotes || projectDetails?.accessNotes || '');
        setPoNumber(punchoutUserData?.isPunchoutUser ? '' : projectInfo?.poNumber || projectDetails?.poNumber || '');
    }, [
        projectDetails?.primaryContactName,
        projectDetails?.phoneNumber,
        projectDetails?.accessNotes,
        projectDetails?.poNumber,
        viewCart?.isInStorePickup
    ]);

    return (
        <div className={`${classes.root} checkout__last`}>
            {currentStep < stepNumber && (
                <CollapsedView
                    stepNumber={formStep.PROJECT_DETAILS}
                    stepTitle={intl.formatMessage({ id: 'checkout:project-info' })}
                    testId={checkoutDatalocator.checkout_payment_link_testid}
                />
            )}
            {currentStep === stepNumber && (
                <EditProjectInfo
                    currentStep={currentStep}
                    handleStepChange={handleStepChange}
                    formStep={formStep}
                    isCustomAccount={isCustomAccount}
                    primaryContactName={primaryContactName}
                    phoneNumber={phoneNumber}
                    accessNotes={accessNotes}
                    poNumber={poNumber}
                    setPrimaryContactName={setPrimaryContactName}
                    setPhoneNumber={setPhoneNumber}
                    setAccessNotes={setAccessNotes}
                    setPoNumber={setPoNumber}
                />
            )}
            {currentStep > stepNumber && (
                <ReadOnlyProjectInfo
                    currentStep={currentStep}
                    handleStepChange={handleStepChange}
                    formStep={formStep}
                />
            )}
        </div>
    );
};

export default CheckoutProjectInfo;

CheckoutProjectInfo.propsType = {
    currentStep: number,
    handleStepChange: func,
    currentOffSet: string
};

CheckoutProjectInfo.defaultProps = {
    currentStep: 0,
    handleStepChange: () => {},
    currentOffSet: ''
};
