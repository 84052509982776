import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { LoadingIndicator } from '../../aem-core-components';
import { getCartDetails } from '../../aem-core-components/actions/cart/actions.js';
import { useCartState } from '../../aem-core-components/components/Minicart';
import useCartEstimate from '../../aem-core-components/components/Minicart/hooks/useCartEstimate.js';
import { GET_DEDICATED_PC, useUserContext } from '../../aem-core-components/context/UserContext.js';
import CART_DETAILS_QUERY from '../../aem-core-components/queries/query_cart_details.graphql';
import { useAwaitQuery } from '../../aem-core-components/utils/hooks.js';
import { useAnalyticsContext } from '../../config/GoogleTagManagerEvents.js';
import { EVENT_ECOMMERCE_NAMES_CONFIG } from '../../constants/analyticsConstants/Ecommerce.js';
import { VARIABLE_CONFIG } from '../../constants/analyticsConstants/Variables.js';
import { STORAGE_CONFIG } from '../../constants/storageConfig.js';
import { USER_TYPE } from '../../constants/userDetailsConstants.js';
import { useCheckAuthorityType, useCheckUser } from '../../hooks/useCheckUser.js';
import useCheckout from '../../hooks/useCheckout.js';
import { useDidMount } from '../../hooks/useDidMount.js';
import useMedia from '../../hooks/useMedia';
import useUserData from '../../hooks/useUserData.js';
import { RESET_SELECTED_STORE_DETAILS, SET_END_DATE, SET_START_DATE } from '../cap/constants.js';
import { useFilterState } from '../cap/filterContext.js';
import AlertModalWithButtons from '../global/atoms/AlertModalWithButtons/AlertModalWithButtons';
import { AUTHORITY_TYPE } from '../global/constants.js';
import { getURLParams, isAccountBlocked, isAccountClosed, isPaymentSectionVisited } from '../global/utils/commonUtils.js';
import { isValidString, logError } from '../global/utils/logger.js';
import OrderSummaryFactory from '../molecules/orderSummaryFactory/OrderSummaryFactory';
import { FACTORYSOURCE } from '../molecules/orderSummaryFactory/constants/orderSummaryFactoryConstants';
import { cookieValue } from '../../aem-core-components/utils/cookieUtils.js';
import RentalReviewList from './RentalReviewList';
import ElapsedTimeDateAlert from './elapsedTimeDateAlert';
import CheckoutPageAlerts from './checkoutPageAlert';
import RentalEquipmentView from './accessoriesAndAddons/rentalEquipment/RentalEquipmentView';
import {
    getAddPaymentAPI,
    getCustomAccountFields,
    getTokenAPI
} from './checkoutAndOrderSummary/api/getCheckoutAPIs.js';
import './checkout.scss';
import { checkoutDatalocator } from './checkoutAndOrderSummary/dataLocators';
import {
    DUKE_CORP_LINK,
    FORM_STEP_CASH,
    FORM_STEP_CASH_ADDONS,
    FORM_STEP_CASH_ADDONS_WO_OPTIONAL,
    FORM_STEP_CASH_WO_OPTIONAL,
    FORM_STEP_CREATE_QUOTE,
    FORM_STEP_CREATE_QUOTE_ADDONS,
    FORM_STEP_CREATE_QUOTE_ADDONS_WO_OPTIONAL,
    FORM_STEP_CREATE_QUOTE_WO_OPTIONAL,
    FORM_STEP_CREDIT,
    FORM_STEP_CREDIT_ADDONS,
    FORM_STEP_CREDIT_ADDONS_WO_OPTIONAL,
    FORM_STEP_CREDIT_WO_OPTIONAL,
    FORM_STEP_CUSTOM_CREDIT,
    FORM_STEP_CUSTOM_CREDIT_ADDONS,
    FORM_STEP_CUSTOM_CREDIT_ADDONS_WO_OPTIONAL,
    FORM_STEP_CUSTOM_CREDIT_WO_OPTIONAL,
    FORM_STEP_GUEST,
    FORM_STEP_GUEST_ADDONS,
    FORM_STEP_GUEST_ADDONS_WO_OPTIONAL,
    FORM_STEP_GUEST_WO_OPTIONAL
} from './constants.js';
import {
    RESET_EDIT_ON_CHECKOUT_COMPLETE,
    SET_CART_CONTEXT_FIELDS,
    SET_CLICKED_MAKE_CHANGES,
    SET_CREDIT_NEWADDR_FLAG,
    SET_PAYMENT_TOKEN_DATA,
    SET_PUNCHOUT_USER_DATA,
    SET_SELECTED_PAYMENT_METHOD,
    SET_SOURCES_LOADING_FLAG,
    SET_TBD_MODAL,
    SET_VIEW_CART_FIELDS,
    SET_YOUR_DETAILS,
    SHOW_GUEST_CHECKOUT_LOGIN
} from '../../aem-core-components/actions/constants.js';
import useAnalytics from '../../hooks/useAnalytics';
import config from '../App/config';
import { useAtp } from '../cap/hooks/useATP.js';

const { SESSION_STORAGE, LOCAL_STORAGE, COOKIES } = STORAGE_CONFIG;

const CheckoutStepsAndOrderSummary = ({
    isRentalDatesEmpty,
    orderEstimates,
    renderSteps,
    setCurrentStep,
    formStep,
    currentStep,
    setCustomBillingFields,
    isCustomAccount,
    setIsCustomAccount,
    setFormStep,
    isAccessoriesAddonsAvailable,
    setCurrentOffSet
}) => {
    const intl = useIntl();
    const mediaType = useMedia();
    const didMount = useDidMount();
    const { handleATPCart } = useAtp();
    const [
        {
            consumables,
            isLoading,
            isReservationClicked,
            tbdModal,
            cartId,
            cart,
            userInfo,
            optionalPlan,
            clickedMakeChanges,
            userAccount,
            currentOffset,
            isStepForward
        },
        dispatch
    ] = useCartState();
    const cartItems = cart?.items;
    const [{ userProfile, isUserProfileLoading, isProfileLoaded }, { dispatch: userDispatch, getProjectsFromCookie }] =
        useUserContext();
    const [{ viewCart, projectDetails, endDate }, filterDispatch] = useFilterState();
    const [{ getEstimates }] = useCartEstimate();
    const { refreshCart, checkoutFormStart, checkoutStepPayload, getCustomerId } = useCheckout();
    const {
        sendEventsForUpdateVirtualPath,
        sendEventsForEcommerceCheckoutOption,
        sendEventsForEcommercePageData,
        sendEventsForEcommerceAction
    } = useAnalyticsContext();
    const [{ payloadEcommerceStepActionAnalytics, payloadEcommercePageData, payloadEcommerceLocationActionAnalytics }] =
        useAnalytics();
    const params = getURLParams();
    const userType = useCheckUser();
    const authorityType = useCheckAuthorityType();
    const projectInfoCookies = getProjectsFromCookie();
    const isGuestCheckoutLogin = JSON.parse(localStorage.getItem(LOCAL_STORAGE.SHOWGUESTCHECKOUTLOGIN));
    const viewCartdata = JSON.parse(localStorage.getItem(LOCAL_STORAGE.VIEWCART)) || '';
    const sessionStartDate = localStorage.getItem(LOCAL_STORAGE.STARTDATE);
    const sessionEndDate = localStorage.getItem(LOCAL_STORAGE.ENDDATE);
    const sucessfullyTransmittedQuoteId = sessionStorage.getItem(SESSION_STORAGE.SUCCESSFULLY_TRANSMITTED_QUOTE_ID);
    const cartDetailsQuery = useAwaitQuery(CART_DETAILS_QUERY);

    const [sixDigitLat, setSixDigitLat] = useState(0);
    const [isCCLogin, setIsCCLogin] = useState(false);
    const [locationSet, setLocationSet] = useState(false);

    useEffect(() => {
        const data = JSON.parse(sessionStorage.getItem(SESSION_STORAGE.CARTCONTEXT));
        const cookieCart = cookieValue(COOKIES.CIF_CART);
        if (data && cookieCart) {
            if (params.TRANSUCCESS === 'TRUE') {
                dispatch({
                    type: SET_CART_CONTEXT_FIELDS,
                    cartContextData: { ...data[0] }
                });

                dispatch({ type: SET_CLICKED_MAKE_CHANGES, value: false });
                dispatch({ type: RESET_EDIT_ON_CHECKOUT_COMPLETE, isCheckoutEditResetComplete: false });

                setFormStep(JSON.parse(sessionStorage.getItem(SESSION_STORAGE.FORMSTEP)));
                if (params.CUSTOM2 === 'TRUE') {
                    async function fetchAddPaymentAPI() {
                        try {
                            dispatch({ type: 'beginLoading' });
                            await getAddPaymentAPI(true);
                            dispatch({
                                type: SET_SELECTED_PAYMENT_METHOD,
                                key: 'selectedPaymentMethod',
                                value: 0
                            });
                            setCurrentStep(parseInt(sessionStorage.getItem(SESSION_STORAGE.PAYMENTSTEP)));
                            dispatch({ type: 'endLoading' });
                        } catch (err) {
                            logError(err, false, 'fetchAddPaymentAPI');
                        }
                    }
                    fetchAddPaymentAPI();
                }
                if (params.CUSTOM2 !== 'TRUE') {
                    async function fetchPaymentToken() {
                        try {
                            dispatch({ type: 'beginLoading' });
                            const tokenData = await getTokenAPI();
                            sendEventsForEcommerceCheckoutOption(5, {
                                paymentMethod: tokenData?.data?.data?.ccBrand
                            });
                            sendEventsForUpdateVirtualPath(
                                VARIABLE_CONFIG.VIRTUAL_PAGE_TITLE.CHECKOUT_PAYMENT,
                                `/${
                                    localStorage.getItem('companyID') == 2
                                        ? VARIABLE_CONFIG.REGION.CANADA
                                        : VARIABLE_CONFIG.REGION.US
                                }/${VARIABLE_CONFIG.VIRTUAL_PAGE_URL.CHECKOUT_PAYMENT}`
                            );
                            dispatch({ type: SET_PAYMENT_TOKEN_DATA, value: tokenData?.data?.data });

                            setCurrentStep(parseInt(sessionStorage.getItem(SESSION_STORAGE.PAYMENTSTEP)));
                            dispatch({ type: 'endLoading' });
                        } catch (err) {
                            logError(err, false, 'fetchPaymentToken');
                        }
                    }
                    fetchPaymentToken();
                }
            }
            if (params.TRANSUCCESS !== 'TRUE' && isPaymentSectionVisited()) {
                dispatch({
                    type: SET_CART_CONTEXT_FIELDS,
                    cartContextData: { ...data[0] }
                });
                dispatch({ type: SET_CLICKED_MAKE_CHANGES, value: false });
                dispatch({ type: RESET_EDIT_ON_CHECKOUT_COMPLETE, isCheckoutEditResetComplete: false });
                setFormStep(JSON.parse(sessionStorage.getItem(SESSION_STORAGE.FORMSTEP)));
                setCurrentStep(parseInt(sessionStorage.getItem(SESSION_STORAGE.PAYMENTSTEP)));
            }
        }
        if (
            !cookieCart &&
            window.location.pathname.includes('checkout') &&
            !window.location.href.includes('author') &&
            !window.location.href.includes('localhost')
        ) {
            window.location.href = config?.pagePaths?.homePage;
        }
    }, []);

    useEffect(() => {
        //if loggin user with cart loaded && cc login
        if (viewCart?.lat) {
            setSixDigitLat(Math.floor(Number(viewCart?.lat) * 1000000) / 1000000);
        } else {
            setSixDigitLat(0);
        }
    }, [viewCart?.lat]);

    useEffect(() => {
        const checkoutEstimateCall = async () => {
            if (viewCart?.lat && cart?.total_quantity > 0 && !viewCart?.showCreateProject) {
                // estimates was calling before create project to avoid that !viewCart?.showCreateProject is introduced
                getEstimates();
            }
        };
        if (!isUserProfileLoading) {
            checkoutEstimateCall();
        }
    }, [sixDigitLat]);

    useEffect(() => {
        //if loggin user with cart loaded && cc login
        if (params?.SuccessfulLogin) {
            if (userInfo?.isAuthenticatedUser && cart?.items?.length > 0 && !isUserProfileLoading && !isCCLogin) {
                if (userType === USER_TYPE.CREDIT && projectDetails?.selectedProjectLongitude) {
                    ///credit profile loaded
                    setIsCCLogin(Boolean(params?.SuccessfulLogin));
                } else if (viewCart?.lat) {
                    //cash profile loaded
                    setIsCCLogin(Boolean(params?.SuccessfulLogin));
                }
            }
        }
    }, [
        cart?.items?.length,
        isUserProfileLoading,
        viewCart?.lat,
        userInfo?.isAuthenticatedUser,
        projectDetails?.selectedProjectLongitude
    ]);

    useEffect(() => {
        sessionStorage.setItem(SESSION_STORAGE.CHECKOUT_CURRENT_STEP, currentStep); // need currentStep dependency
        if (isCheckoutPageLoaded()) {
            document.querySelector('.editable-body')?.removeEventListener(
                VARIABLE_CONFIG.EVENT_STRUCTURE.CLICK,
                elem => {
                    checkoutFormStart(elem, formStep, currentStep);
                },
                true
            );
            document.querySelector('.editable-body')?.addEventListener(
                VARIABLE_CONFIG.EVENT_STRUCTURE.CLICK,
                elem => {
                    checkoutFormStart(elem, formStep, currentStep);
                },
                true
            );
        }
        return () => {
            document.querySelector('.editable-body')?.removeEventListener(
                VARIABLE_CONFIG.EVENT_STRUCTURE.CLICK,
                elem => {
                    checkoutFormStart(elem, formStep, currentStep);
                },
                true
            );
        };
    }, [isUserProfileLoading, cart?.id, currentStep, isGuestCheckoutLogin]);

    useEffect(() => {
        const customBillingFieldsCall = () => {
            if (userType === USER_TYPE.CREDIT && projectInfoCookies?.CurrentWynneAccount) {
                const corpLink = userProfile?.accounts?.find(
                    account => account?.id == projectInfoCookies?.CurrentWynneAccount
                )?.corplink;

                const apiFunction = async () => {
                    try {
                        const response = await getCustomAccountFields(
                            projectInfoCookies?.CurrentWynneAccount,
                            parseInt(corpLink)
                        );
                        setCustomBillingFields(response?.data?.data);
                        if (response?.data?.data?.customBillingFields?.length !== 0) {
                            setIsCustomAccount(true);
                        } else {
                            setIsCustomAccount(false);
                        }
                    } catch (err) {
                        logError(err, false, 'apiFunction');
                    }
                };
                if (corpLink) {
                    if (corpLink !== DUKE_CORP_LINK) {
                        apiFunction();
                    } else if (corpLink === DUKE_CORP_LINK) {
                        setIsCustomAccount(true);
                        setCustomBillingFields({});
                    }
                }
            }

            window.addEventListener(
                'pageshow',
                function (evt) {
                    if (evt.persisted) {
                        setTimeout(function () {
                            window.location.reload();
                        }, 10);
                    }
                },
                false
            );
        };
        if (projectInfoCookies?.CurrentWynneAccount && userType && !isUserProfileLoading) {
            customBillingFieldsCall();
        }
    }, [projectInfoCookies?.CurrentWynneAccount, userType, isUserProfileLoading]);

    useEffect(() => {
        const getFormStep = () => {
            if (userInfo.isAuthenticatedUser && userInfo.isCreditCustomer && authorityType === AUTHORITY_TYPE.P2P) {
                if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CREDIT.HTGO
                            ? FORM_STEP_CREATE_QUOTE_ADDONS
                            : FORM_STEP_CREATE_QUOTE
                    );
                } else {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CREDIT.HTGO
                            ? FORM_STEP_CREATE_QUOTE_ADDONS_WO_OPTIONAL
                            : FORM_STEP_CREATE_QUOTE_WO_OPTIONAL
                    );
                }
            } else if (userInfo.isAuthenticatedUser && userInfo.isCreditCustomer && !isCustomAccount) {
                if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CREDIT.HTGO
                            ? FORM_STEP_CREDIT_ADDONS
                            : FORM_STEP_CREDIT
                    );
                } else {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CREDIT.HTGO
                            ? FORM_STEP_CREDIT_ADDONS_WO_OPTIONAL
                            : FORM_STEP_CREDIT_WO_OPTIONAL
                    );
                }
            } else if (userInfo.isAuthenticatedUser && userInfo.isCreditCustomer && isCustomAccount) {
                if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CREDIT.HTGO && isCustomAccount
                            ? FORM_STEP_CUSTOM_CREDIT_ADDONS
                            : FORM_STEP_CUSTOM_CREDIT
                    );
                } else {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CREDIT.HTGO && isCustomAccount
                            ? FORM_STEP_CUSTOM_CREDIT_ADDONS_WO_OPTIONAL
                            : FORM_STEP_CUSTOM_CREDIT_WO_OPTIONAL
                    );
                }
            } else if (userInfo.isAuthenticatedUser && !userInfo.isCreditCustomer) {
                if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CASH.HTGO
                            ? FORM_STEP_CASH_ADDONS
                            : FORM_STEP_CASH
                    );
                } else {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CASH.HTGO
                            ? FORM_STEP_CASH_ADDONS_WO_OPTIONAL
                            : FORM_STEP_CASH_WO_OPTIONAL
                    );
                }
            } else if (!userInfo?.isAuthenticatedUser) {
                if (optionalPlan.isRPPCheckboxVisible || optionalPlan.isFuelCheckboxVisible) {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_GUEST.HTGO
                            ? FORM_STEP_GUEST_ADDONS
                            : FORM_STEP_GUEST
                    );
                } else {
                    setFormStep(
                        isAccessoriesAddonsAvailable() && currentStep > FORM_STEP_CASH.HTGO
                            ? FORM_STEP_GUEST_ADDONS_WO_OPTIONAL
                            : FORM_STEP_GUEST_WO_OPTIONAL
                    );
                }
            }
        };
        if ((!isPaymentSectionVisited() && !params.TRANSUCCESS) || clickedMakeChanges) {
            getFormStep();
        }
    }, [
        currentStep,
        userInfo?.isAuthenticatedUser,
        userInfo?.isCreditCustomer,
        isCustomAccount,
        optionalPlan,
        userAccount?.accountNumber
    ]);

    useEffect(() => {
        async function getData() {
            const creditNewAddr = await JSON.parse(localStorage.getItem(LOCAL_STORAGE.ISCREDITNEWADDRESS));
            // TODO
            // dispatch({ type: UPDATE_USER_INFO, userInfo: user || JSON.stringify(initialState.userInfo) });

            // dispatch({ type: SET_VIEW_CART_FIELDS, key: 'isInStorePickup', value: viewCartdata?.isInStorePickup });
            filterDispatch({
                type: SET_VIEW_CART_FIELDS,
                key: 'showCreateProject',
                value: viewCartdata?.showCreateProject
            });
            if (sessionStartDate && sessionEndDate) {
                filterDispatch({ type: SET_START_DATE, startDate: sessionStartDate });
                filterDispatch({ type: SET_END_DATE, endDate: sessionEndDate });
            }
            if (userType === USER_TYPE.CREDIT && creditNewAddr) {
                filterDispatch({
                    type: SET_VIEW_CART_FIELDS,
                    key: 'showCreateProject',
                    value: true
                });
            }
            dispatch({
                type: SHOW_GUEST_CHECKOUT_LOGIN,
                value: isGuestCheckoutLogin
            });
            filterDispatch({
                type: SET_VIEW_CART_FIELDS,
                key: 'createProjectSource',
                value: viewCartdata?.createProjectSource
            });

            // dispatch({ type: UPDATE_VIEW_CART, viewCart: viewCartdata || JSON.stringify(initialState.viewCart) });
            // implemented viewcart location fields from graphql
            const projectData = await localStorage.getItem(LOCAL_STORAGE.PROJECTDETAILS);
            const userProfile = JSON.parse(sessionStorage.getItem(SESSION_STORAGE.USERPROFILE));
            const isCreditNewAddress = await JSON.parse(localStorage.getItem(LOCAL_STORAGE.ISCREDITNEWADDRESS));
            const yourDetails = (await localStorage.getItem(LOCAL_STORAGE.YOURDETAILS)) || '';
            const overridePCList = (await JSON.parse(localStorage.getItem(LOCAL_STORAGE.OVERRIDEPCLIST))) || '';
            const punchoutUserData = sessionStorage.getItem(SESSION_STORAGE.PUNCHOUTUSERDATA) || '';
            dispatch({
                type: SET_SOURCES_LOADING_FLAG,
                isSourcesLoading: isValidString(sessionStorage.getItem(SESSION_STORAGE.ISSOURCESLOADING))
            });
            dispatch({
                type: SET_CREDIT_NEWADDR_FLAG,
                isCreditNewAddress: Boolean(isCreditNewAddress)
            });
            userDispatch({
                type: 'setUserProfile',
                userProfile
            });
            // TO DO
            // dispatch({
            //     type: UPDATE_PROJECT_DETAILS,
            //     projectDetails: projectData || JSON.stringify(initialState.projectDetails)
            // });
            userDispatch({ type: GET_DEDICATED_PC, overridePCList: overridePCList });
            // userDispatch({
            //     type: 'setAccountProjects',
            //     accountProjects: accountProjects || userState.accountProjects
            // });
            if (yourDetails) {
                dispatch({ type: SET_YOUR_DETAILS, guestUserDetails: JSON.parse(yourDetails) });
                setCurrentStep(FORM_STEP_GUEST.YOUR_DETAILS);
            }
            if (punchoutUserData) {
                dispatch({ type: SET_PUNCHOUT_USER_DATA, punchoutUserData: JSON.parse(punchoutUserData) });
            }
        }
        if (!isUserProfileLoading) {
            getData();
            setLocationSet(true);
        }
    }, [isUserProfileLoading]);

    useEffect(() => {
        if (locationSet) {
            filterDispatch({ type: RESET_SELECTED_STORE_DETAILS });
        }
    }, [viewCart?.lat, projectDetails?.selectedProjectLongitude]);

    useEffect(() => {
        const cartLoadFunction = () => {
            if (isProfileLoaded && cartId && !sucessfullyTransmittedQuoteId) {
                async function loadCart() {
                    try {
                        dispatch({ type: 'beginLoading' });
                        await getCartDetails({
                            cartDetailsQuery,
                            dispatch,
                            cartId,
                            filterDispatch,
                            handleATPCart
                        });
                        dispatch({ type: 'endLoading' });
                    } catch (err) {
                        logError(err, false, 'loadCart');
                    }
                }
                loadCart();
            }
        };
        cartLoadFunction();
    }, [isProfileLoaded, cartId]);

    useEffect(() => {
        // cart empty issue fixed when products are empty in cart in checkout page
        // DOTC-19565
        if (
            cart?.items?.length <= 0 &&
            !window.location.href.includes('author') &&
            !window.location.href.includes('localhost')
        ) {
            //to avoid redirection in author mode
            window.location.href = config?.pagePaths?.homePage;
        } else if (!isUserProfileLoading) {
            if (userType == USER_TYPE.CREDIT) {
                if (
                    isAccountClosed(userAccount) ||
                    (isAccountBlocked(userAccount) && !window.location.href.includes('author'))
                ) {
                    sessionStorage.setItem('isOpen', true);
                    window.location.href =
                        window.location.origin + config.pagePaths.homePage + '?showDelinquentModal=true';
                }
            }
        }
    }, [cart?.items, isUserProfileLoading]);

    useEffect(() => {
        if (isProfileLoaded && cart?.id && !isPaymentSectionVisited()) {
            getEstimates();
        }
    }, [isProfileLoaded, cart?.id]);

    useEffect(() => {
        // this useEffect only impacts CC =>checkout login
        if (didMount) {
            if ((!isPaymentSectionVisited() || clickedMakeChanges) && isProfileLoaded) {
                refreshCart();
            }
        }
    }, [isCCLogin, endDate, projectDetails?.selectedProjectLongitude, isProfileLoaded]);

    useEffect(() => {
        if (
            !isPaymentSectionVisited() &&
            cart?.total_quantity > 0 &&
            currentStep == 1 &&
            endDate &&
            isValidString(sessionStorage.getItem(STORAGE_CONFIG.SESSION_STORAGE.ISSOURCESLOADING)) &&
            !isCCLogin &&
            isProfileLoaded
        ) {
            refreshCart();
        }
        if (cart?.total_quantity > 0) {
            sendEventsForEcommercePageData(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_DATA,
                payloadEcommercePageData([], cart?.total_quantity)
            );
        }
    }, [cart?.total_quantity, isProfileLoaded]);

    useEffect(() => {
        setCurrentOffSet(currentOffset);
    }, [currentOffset]);

    useEffect(() => {
        if (
            (!(currentStep > formStep?.HTGO) ||
                (currentStep > formStep?.HTGO && !isAccessoriesAddonsAvailable()) ||
                (currentStep > formStep?.HTGO && formStep?.ACCESSORIES_AND_ADDONS)) &&
            isCheckoutPageLoaded() &&
            isGuestCheckoutLogin !== null
        ) {
            cart?.id && callEvents();
        }
    }, [currentStep, isUserProfileLoading, cart?.id, isGuestCheckoutLogin]);

    useEffect(() => {
        if (isAccessoriesAddonsAvailable() && currentStep > formStep?.HTGO) {
            callEvents();
        }
    }, [formStep]);

    const callEvents = () => {
        if (isStepForward) {
            sendEventsForEcommerceAction(
                EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STEP_COMPLETED,
                payloadEcommerceStepActionAnalytics(),
                checkoutStepPayload(formStep, currentStep, 1)
            );
        }
        sendEventsForEcommerceAction(
            EVENT_ECOMMERCE_NAMES_CONFIG.ECOMMERCE_CHECKOUT_STEP_VIEWED,
            payloadEcommerceStepActionAnalytics(),
            checkoutStepPayload(formStep, currentStep, 0)
        );
    };

    const isCheckoutPageLoaded = () => {
        if (!isUserProfileLoading && cart?.id && !isGuestCheckoutLogin) {
            return true;
        } else {
            return false;
        }
    };

    const renderAddOns = () => {
        if (consumables?.showRentalEquipmentScreen) {
            return <RentalEquipmentView mediaType={mediaType} isOpen={consumables?.showRentalEquipmentScreen} />;
        }
        return <></>;
    };

    const renderOrderSummary = () => {
        return (
            <>
                <h4
                    className="block-checkout-title"
                    data-testid={checkoutDatalocator.checkout_OrderSummary_heading_testid}>
                    {intl.formatMessage({ id: 'cart-order-summary:title' })}
                </h4>
                <div style={{ position: 'relative' }}>
                    <RentalReviewList
                        cartItems={cartItems}
                        isRentalDatesEmpty={isRentalDatesEmpty}
                        orderEstimates={orderEstimates}
                    />
                    <OrderSummaryFactory
                        title={intl.formatMessage({ id: 'cart-order-summary:title' })}
                        source={FACTORYSOURCE.CHECKOUT}
                        formStep={formStep}
                        currentStep={currentStep}
                    />
                </div>
            </>
        );
    };

    return (
        <section className={`block-gradient block-checkout ${isLoading && 'clicks-disabled'}`}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 col-lg-7 block-checkout-left checkout-steps-container">
                        <h4 className="block-checkout-title" data-testid={checkoutDatalocator.checkout_heading_testid}>
                            {intl.formatMessage({ id: 'checkout:checkout' })}
                        </h4>
                        {renderSteps()}
                        {isLoading && isReservationClicked && (
                            <div className={'submitReservationLoader'}>
                                <LoadingIndicator />
                            </div>
                        )}
                    </div>
                    <div className="col-lg-5 col-md-12 block-checkout-right">{renderOrderSummary()}</div>
                </div>
            </div>
            <ElapsedTimeDateAlert handleStepChange={setCurrentStep} formStep={formStep} currentStep={currentStep} />
            <CheckoutPageAlerts handleStepChange={setCurrentStep} formStep={formStep} />
            {renderAddOns()}
        </section>
    );
};

export default CheckoutStepsAndOrderSummary;
