import React from 'react';
import CheckoutProjectInfo from './checkoutProjectInfo';
import HowToGetOrder from './howToGetYourOrder';
import OrderDetailsCheckout from './orderDetailsCheckout';
import OptionalPlans from './optionalPlans';
import AccessoriesAndAddons from './accessoriesAndAddons';
import { useCartState } from '../../aem-core-components/components/Minicart';
import SubmitReservationButton from '../global/atoms/SubmitReservation';
import PunchoutOrderConfirmation from './punchoutOrderConfirmation/PunchoutOrderConfirmation';
import CustomAccountDetails from './checkoutAndOrderSummary/checkoutCustomAccountDetails/CheckoutCustomAccountDetails';
import TransmitOrSave from './transmitOrSave';
import useMedia from '../../hooks/useMedia';
import { useCheckAuthorityType } from '../../hooks/useCheckUser';
import { MEDIA_TYPE } from '../../constants/screenConstants';
import { AUTHORITY_TYPE } from '../global/constants';
import { useFilterState } from '../cap';

const CheckoutCreditUser = ({
    currentStep,
    setCurrentStep,
    currentOffSet,
    isCustomAccount,
    customBillingFields,
    formStep,
    isAccessoriesAddonsAvailable
}) => {
    const mediaType = useMedia();
    const [{ punchoutUserData }] = useCartState();
    const [{ viewCart }] = useFilterState();
    const { isInStorePickup } = viewCart;
    const authorityType = useCheckAuthorityType();
    const isSubmitReservationEnabled = currentStep === formStep.SUBMIT_RESERVATION;
    const isTransferOrderStep = currentStep === formStep.TRANSFER_ORDER;
    const isTransmitOrSave = currentStep === formStep.TRANSMIT_OR_SAVE;
    return (
        <>
            <OrderDetailsCheckout
                currentStep={currentStep}
                handleStepChange={setCurrentStep}
                currentOffSet={currentOffSet}
                formStep={formStep}
            />
            <HowToGetOrder
                currentStep={currentStep}
                handleStepChange={setCurrentStep}
                currentOffSet={currentOffSet}
                formStep={formStep}
                isCustomAccount={isCustomAccount}
            />
            {isAccessoriesAddonsAvailable() && currentStep > formStep.HTGO && (
                <AccessoriesAndAddons
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                    currentOffSet={currentOffSet}
                    formStep={formStep}
                    isCustomAccount={isCustomAccount}
                    isAccessoriesAddonsAvailable={isAccessoriesAddonsAvailable}
                />
            )}
            <OptionalPlans
                currentStep={currentStep}
                handleStepChange={setCurrentStep}
                formStep={formStep}
                isCustomAccount={isCustomAccount}
            />
            {(authorityType === AUTHORITY_TYPE.PUNCHOUT && isInStorePickup) ||
            authorityType === AUTHORITY_TYPE.P2P ? null : (
                <CheckoutProjectInfo
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                    formStep={formStep}
                    isCustomAccount={isCustomAccount}
                />
            )}
            {isCustomAccount && currentStep > formStep.PROJECT_DETAILS && (
                <CustomAccountDetails
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                    formStep={formStep}
                    customBillingFields={customBillingFields}
                />
            )}
            {mediaType === MEDIA_TYPE.DESKTOP && isSubmitReservationEnabled && !punchoutUserData?.isPunchoutUser && (
                <SubmitReservationButton
                    customClass={'submitCTAdesktopView'}
                    currentOffSet={currentOffSet}
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                />
            )}
            {mediaType === MEDIA_TYPE.DESKTOP && isTransferOrderStep && authorityType === AUTHORITY_TYPE.PUNCHOUT && (
                <PunchoutOrderConfirmation
                    customClass={'submitCTAdesktopView'}
                    currentOffSet={currentOffSet}
                    currentStep={currentStep}
                    handleStepChange={setCurrentStep}
                />
            )}

            {isTransmitOrSave && authorityType === AUTHORITY_TYPE.P2P && <TransmitOrSave />}
        </>
    );
};

export default CheckoutCreditUser;
