import React from 'react';
import { useIntl } from 'react-intl';
import AddItemCard from './AddItemCard';
import ViewAll from '../../../global/modules/viewAll';
import '../accessoriesAndAddons.scss';
import { useCartState } from '../../../../aem-core-components/components/Minicart';
import { generateSKU } from '../../../global/utils/commonUtils';
import { ENV_CONFIG } from '../../../../constants/envConfig';

const AccessoriesForRent = ({ rentables, selectedItems, addAccessory }) => {
    const [{ consumables }] = useCartState();
    const maxAccessoriesLimit = parseInt(ENV_CONFIG.MAX_ACCESSORIES);
    const maxQuantityLimit = parseInt(ENV_CONFIG.MAX_QUANTITY_LIMIT);
    const intl = useIntl();

    const renderItem = item => {
        const sku = generateSKU(item?.rentalCat, item?.rentalClass);
        return (
            <>
                <li className="add-item-container">
                    <AddItemCard
                        title={item?.description}
                        sku={generateSKU(item?.rentalCat, item?.rentalClass)}
                        type={intl.formatMessage({ id: 'checkout:rental-equipments-accessories-type' })}
                        price={parseFloat(consumables?.rentablesWithPrice[sku])}
                        selectedItems={selectedItems?.rentals}
                        rentalClass={item?.rentalClass}
                        handleValueChange={addAccessory}
                        remainingQuantity={
                            consumables?.availableQuantity?.remainingAccessories[sku]?.accessoriesRemainingQuantity
                        }
                        isAddOns={false}
                        availableQuantity={
                            consumables?.availableQuantity?.remainingAccessories[sku]?.accessoriesAvailableQuantity
                        }
                        maxQuantityLimit={maxQuantityLimit}
                        itemObj={item}></AddItemCard>
                </li>
            </>
        );
    };

    return (
        <ViewAll items={rentables} renderItem={renderItem} limit={maxAccessoriesLimit} viewAllClass="view-all-btn" />
    );
};

export default AccessoriesForRent;
