import { STORAGE_CONFIG } from '../../constants/storageConfig';
import { useCheckAuthorityType } from '../../hooks/useCheckUser';
import { AUTHORITY_TYPE } from '../global/constants';

const { LOCAL_STORAGE, SESSION_STORAGE, COOKIES } = STORAGE_CONFIG;

const authorityType = useCheckAuthorityType();

export const shouldFuelChargeBeChecked = (fuelCharges, defaultCheckStatus = {}) => {
    if (fuelCharges && parseInt(fuelCharges) > 0) {
        switch (authorityType) {
            case AUTHORITY_TYPE.P2P:
                return defaultCheckStatus['PFOcheck'];

            default:
                return false;
        }
    }

    return false;
};

/**
 * Transforms an array of items into an array of objects. Each object in the new array has two properties: 'cart_item_uid' and 'quantity'.
 *
 * @param {Array<{uid: string, quantity: number}>} items - An array of objects, where each object represents an item. Each item object should have a 'uid' and a 'quantity' property.
 * @returns {Array<{cart_item_uid: string, quantity: number}>} - An array of objects. Each object in the array has two properties: 'cart_item_uid' and 'quantity'.
 * @example
 *
 * const items = [
 *   { uid: 'SKU-1', quantity: 1, ...},
 *   { uid: 'SKU-2', quantity: 2, ...},
 * ];
 *
 * const result = formatBySKU(items);
 *
 * console.log(result);
 * // Output:
 * // [
 * //   { cart_item_uid: 'SKU-1', quantity: 1 },
 * //   { cart_item_uid: 'SKU-2', quantity: 2 },
 * // ]
 */
export const formatBySKU = items => items.map(({ uid, quantity }) => ({ cart_item_uid: uid, quantity: quantity }));

//this below method will convert this type of array
// [
//     {
//       "keyId": "PFOcheck",
//       "value": "TRUE"
//     }
//   ]
// into below format
// { PFOcheck: true}

const optionalPlanKeys = ['PFOcheck', 'RPPcheck'];

const fallbackOptionalPlanKeys = {
    //this will take precedence compared secondaryOptionalPlanKeys
    PFOcheck: 'ALL-PFOcheck',
    RPPcheck: 'ALL-RPPcheck'
};
/**
 * Maps the status of optional plan keys to their corresponding boolean values.
 * If a key is not present in the data, it defaults to the value of the corresponding fallback key.
 */
export const keyStatusMap = data => {
    let res = {};
    // Iterate over each optional plan key
    optionalPlanKeys.forEach(key => {
        // Find the entry for the current key in the data
        const entry = data.find(({ keyId }) => keyId === key);
        // If the entry is not found, look for the fallback key
        if (!entry) {
            const fallbackKey = fallbackOptionalPlanKeys[key];
            const fallbackEntry = data.find(({ keyId }) => keyId === fallbackKey);
            // Use the value of the fallback key, or true if neither is found
            res[key] = fallbackEntry ? fallbackEntry.value === 'TRUE' : true;
        } else {
            // Use the value of the primary key if found
            res[key] = entry.value === 'TRUE';
        }
    });
    return res;
};
